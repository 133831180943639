import PillAutorizado from "../../components/pillEstatus/PillAutorizado";
import PillCapturando from "../../components/pillEstatus/PillCapturando";
import PillCerrado from "../../components/pillEstatus/PillCerrado";
import PillCotizacion from "../../components/pillEstatus/PillCotizacion";
import PillProduccion from "../../components/pillEstatus/PillProduccion";
import { cortarTexto } from "../../utils/cortarTexto";
//

///
export default function useCotizacionesColumns() {
  const cotizacionesColumns = [
    {
      title: "ID",
      dataIndex: "cotizacionID",
      width: 50,
    },
    {
      title: "Fecha",
      dataIndex: "fecha",
      width: 90,
    },
    {
      title: "Cliente",
      dataIndex: "cliente",
      width: 150,
      render: (texto) => cortarTexto(texto, 20),
    },
    {
      title: "Representante",
      dataIndex: "representante",
      width: 100,
      render: (texto) => cortarTexto(texto, 20),
    },
    {
      title: "OC",
      dataIndex: "oc",
      width: 100,
      render: (texto) => cortarTexto(texto, 20),
    },
    {
      title: "Marca",
      dataIndex: "marca",
      width: 130,
      render: (texto) => cortarTexto(texto, 20),
    },

    {
      title: "No de Parte",
      dataIndex: "noParte",
      width: 140,
      render: (texto) => cortarTexto(texto, 20),
    },
    {
      title: "Sub",
      dataIndex: "subestatus",
      width: 53,
    },
    {
      title: "Estatus",
      width: 100,
      render: ({ cotizacionEstatusID, cotizacionEstatus }) => {
        if (cotizacionEstatusID === 1) {
          return <PillCapturando />;
        }
        if (cotizacionEstatusID === 2) {
          return <PillCotizacion />;
        }
        if (cotizacionEstatusID === 3) {
          return <PillAutorizado />;
        }
        if (cotizacionEstatusID === 4) {
          return <PillProduccion />;
        }
        if (cotizacionEstatusID === 5) {
          return <PillCerrado />;
        }
        return <></>;
      },
    },
    // {
    //   title: "",
    //   width: 30,
    //   render: (text, record) => (
    //     <Space>
    //       <Tooltip title="Ve Detalles">
    //         <EyeOutlined
    //           onClick={() => handleClick(record)}
    //           className="eyeIcon"
    //         />
    //       </Tooltip>
    //     </Space>
    //   ),
    // },
  ];

  return { cotizacionesColumns };
}
