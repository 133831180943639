import { useDispatch, useSelector } from "react-redux";
import InputLabelNumber from "../../../components/input/InputLabelNumber";
import { Col, Row } from "antd";
import { changeCotizacion } from "../../../context/actions/cotizacionesActions";
import { useEffect, useRef } from "react";
import { setSeccionModificada } from "../../../context/actions/stepActions";

export default function ArticulosTotales() {
  const dispatch = useDispatch();
  const { opcion1, opcion2, opcion3, opcion4, opcion5, items } = useSelector(
    (state) => state.cotizacionesReducer.cotizacion
  );

  const { opcion1Err, opcion2Err, opcion3Err, opcion4Err, opcion5Err } =
    useSelector((state) => state.erroresReducer);

  const onChange = ({ target: { name, value } }) => {
    dispatch(changeCotizacion({ [name]: value }));
    const u = { [name]: Number(value) };

    dispatch(changeCotizacion(setUtilidad(u)));
    dispatch(setSeccionModificada({ articulos: true }));
  };

  const setUtilidad = (u) => {
    const { value, name } = u;

    let salida;
    if (Number(value) >= 1000 && Number(value) < 2500) salida = 65;
    else if (Number(value) >= 2500 && Number(value) < 5000) salida = 45;
    else if (Number(value) >= 5000) salida = 30;
    else salida = 100;
    if (name === "opcion2") return { utilidadPorcentajeOpcion2: salida };
    if (name === "opcion3") return { utilidadPorcentajeOpcion3: salida };
    if (name === "opcion4") return { utilidadPorcentajeOpcion4: salida };
    if (name === "opcion5") return { utilidadPorcentajeOpcion5: salida };
  };

  return (
    <>
      <hr />
      <Row gutter={10}>
        <Col>
          <InputLabelNumber
            error={opcion1Err}
            onChange={onChange}
            name="opcion1"
            value={opcion1}
            label={`Opcion 1`}
          />
        </Col>
        <Col>
          <InputLabelNumber
            error={opcion2Err}
            onChange={onChange}
            name="opcion2"
            value={opcion2}
            label="Opcion 2"
          />
        </Col>
        <Col>
          <InputLabelNumber
            error={opcion3Err}
            onChange={onChange}
            name="opcion3"
            value={opcion3}
            label="Opcion 3"
          />
        </Col>
        <Col>
          <InputLabelNumber
            error={opcion4Err}
            onChange={onChange}
            name="opcion4"
            value={opcion4}
            label="Opcion 4"
          />
        </Col>
        <Col>
          <InputLabelNumber
            error={opcion5Err}
            onChange={onChange}
            name="opcion5"
            value={opcion5}
            label="Opcion 5"
          />
        </Col>
        <Col>
          <div
            className="NoArticulos"
            style={{
              textAlign: "center",
              fontSize: 16,
              backgroundColor: "#ccc",
              borderRadius: 5,
              marginTop: 10,
              padding: 10,
              color: "#fff",
              fontWeight: 600,
            }}
          >
            {items.length} Artículos
          </div>
        </Col>
      </Row>
    </>
  );
}
