import { Input } from "antd";
const { TextArea } = Input;

export default function TextareaLabel({
  label,
  placeholder,
  onChange,
  rows,
  name,
  value,
}) {
  return (
    <div>
      <div className="inputLabel">{label}</div>
      <TextArea
        name={name}
        rows={rows}
        className="input"
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        style={{ width: "100%" }}
      />
    </div>
  );
}
