import { useSelector } from "react-redux";
import InputSinNumber from "../../../components/input/InputSinNumber";
import ColOpcion from "../Calculos/renglonesCostos/ColOpcion";
import ColTitulo from "../Calculos/renglonesCostos/ColTitulo";
import RowCosto from "../Calculos/renglonesCostos/RowCosto";

export default function Total() {
  const {
    totalOpcion1,
    totalOpcion2,
    totalOpcion3,
    totalOpcion4,
    totalOpcion5,
  } = useSelector((state) => state.cotizacionesReducer.cotizacion);

  return (
    <RowCosto>
      <ColTitulo>Total (USD)</ColTitulo>
      <ColOpcion>
        <InputSinNumber value={totalOpcion1} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={totalOpcion2} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={totalOpcion3} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={totalOpcion4} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={totalOpcion5} disabled />
      </ColOpcion>
    </RowCosto>
  );
}
