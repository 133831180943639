import { useHistory } from "react-router";
import CardPage from "../../layout/CardPage";
import Forma from "./Forma";
import SubmitCreate from "./SubmitCreate";

export default function ClientesCreate() {
  const history = useHistory();
  return (
    <>
      <CardPage
        titulo="Agregar Cliente"
        tituloBoton="Regresar"
        accionBoton={() => history.push("/clientes")}
      >
        <Forma />
      </CardPage>
      <SubmitCreate />
    </>
  );
}
