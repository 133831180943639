import { Col, Row } from "antd";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BotonSubmit from "../../components/Boton/BotonSubmit";
import InputLabel from "../../components/input/InputLabel";
import { startPostCategoriaItem } from "../../context/actions/catalogosActions";

export default function _ElementosAdd() {
  const { categoriaSelected } = useSelector((state) => state.catalogosReducer);
  const [nombre, setNombre] = useState("");
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(startPostCategoriaItem(nombre));
    setNombre("");
  };

  return (
    <Row gutter={10}>
      <Col span={12}>
        <InputLabel
          value={nombre}
          label="Nombre de Elemento"
          onChange={({ target }) => setNombre(target.value)}
        />
      </Col>
      <Col span={12} style={{ marginTop: "auto" }}>
        <BotonSubmit
          disabled={categoriaSelected === 0}
          onClick={handleClick}
          tituloBoton="Agregar"
        />
      </Col>
    </Row>
  );
}
