import { Col, Row, Select } from "antd";
import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import BotonSubmit from "../../../components/Boton/BotonSubmit";
import PillAutorizado from "../../../components/pillEstatus/PillAutorizado";
import { opcionesCotizacion } from "../../../constants/cons";
import {
  startPostEstatus,
  startPostEstatusPPD,
} from "../../../context/actions/estatusActions";

export default function Autorizado() {
  const dispatch = useDispatch();
  const { cotizacionID } = useParams();

  const { estatusPPD } = useSelector(
    (state) => state.cotizacionesReducer.cotizacion
  );

  const handleClick = () => {
    const body = { cotizacionID, cotizacionEstatusID: 4 };
    dispatch(startPostEstatus(body));
  };

  const changeEstatus = (val) => {
    const body = {
      estatusPPD: val,
      cotizacionID,
    };
    dispatch(startPostEstatusPPD(body));
  };
  /////
  return (
    <>
      <PillAutorizado />
      <Row gutter={[10, 10]}>
        <Col span={24}>
          <Select
            style={{ width: "100%" }}
            onChange={(val) => changeEstatus(val)}
            options={opcionesCotizacion}
            placeholder="Seleccione"
            value={estatusPPD}
          />
        </Col>
        <Col span={24}>
          <BotonSubmit tituloBoton="Ir a Producción" onClick={handleClick} />
        </Col>
      </Row>
    </>
  );
}
