import { useSelector } from "react-redux";
import InputSinNumber from "../../../../components/input/InputSinNumber";
import ColOpcion from "./ColOpcion";
import ColTitulo from "./ColTitulo";
import RowCosto from "./RowCosto";

export default function TiempoSetupOffset() {
  const {
    tiempoMaquinaSetupOffsetOpcion1,
    tiempoMaquinaSetupOffsetOpcion2,
    tiempoMaquinaSetupOffsetOpcion3,
    tiempoMaquinaSetupOffsetOpcion4,
    tiempoMaquinaSetupOffsetOpcion5,
  } = useSelector((state) => state.cotizacionesReducer.cotizacion);

  return (
    <RowCosto>
      <ColTitulo>Tiempo setup offset (mins)</ColTitulo>
      <ColOpcion>
        <InputSinNumber value={tiempoMaquinaSetupOffsetOpcion1} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={tiempoMaquinaSetupOffsetOpcion2} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={tiempoMaquinaSetupOffsetOpcion3} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={tiempoMaquinaSetupOffsetOpcion4} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={tiempoMaquinaSetupOffsetOpcion5} disabled />
      </ColOpcion>
    </RowCosto>
  );
}
