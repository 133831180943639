import { Button } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { startDeleteArticulo } from "../../context/actions/articulosActions";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export const SubmitEliminar = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { articuloID } = useParams();
  const { cotizaciones } = useSelector(
    (state) => state.articulosReducer.articulosEnCotizacion
  );

  const handleEliminar = () => {
    dispatch(startDeleteArticulo(articuloID)).then((resp) => {
      if (resp === "ok") history.push("/articulos");
    });
  };

  ///
  return (
    <Button
      onClick={handleEliminar}
      disabled={cotizaciones.length > 0}
      type="link"
      danger
    >
      Eliminar Artículo
    </Button>
  );
};
