import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import {
  cleanCotExterna,
  cleanCotizacion,
  cleanTermindos,
  setTipoCaptura,
  startLoadCotizaciones,
} from "../../context/actions/cotizacionesActions";
import { setStep } from "../../context/actions/stepActions";

import CardPage from "../../layout/CardPage";
import Lista from "./Lista";

export default function Cotizaciones() {
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(startLoadCotizaciones());
    dispatch(cleanCotizacion());
    dispatch(cleanTermindos());
    dispatch(setStep(1));
  }, []);

  const handleNew = () => {
    dispatch(setTipoCaptura("nueva"));
    dispatch(cleanCotExterna())
    history.push("/cotizaciones/view/0");
  };
  return (
    <CardPage
      titulo="Cotizaciones"
      tituloBoton="Crear cotización"
      accionBoton={handleNew}
    >
      <Lista />
    </CardPage>
  );
}
