import {  useSelector } from "react-redux";
import InputSinNumber from "../../../../components/input/InputSinNumber";

import ColOpcion from "./ColOpcion";
import ColTitulo from "./ColTitulo";
import RowCosto from "./RowCosto";

export default function TintasSerigrafiaRSS() {

  const {
     tintasSerigrafiaRSSOpcion1,
    tintasSerigrafiaRSSOpcion2,
    tintasSerigrafiaRSSOpcion3,
    tintasSerigrafiaRSSOpcion4,
    tintasSerigrafiaRSSOpcion5,
  } = useSelector((state) => state.cotizacionesReducer.cotizacion);


  return (
    <RowCosto>
      <ColTitulo>Tintas serigrafía RSS</ColTitulo>
      <ColOpcion>
        <InputSinNumber value={tintasSerigrafiaRSSOpcion1} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={tintasSerigrafiaRSSOpcion2} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={tintasSerigrafiaRSSOpcion3} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={tintasSerigrafiaRSSOpcion4} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={tintasSerigrafiaRSSOpcion5} disabled />
      </ColOpcion>
    </RowCosto>
  );
}
