import { useSelector } from "react-redux";
import ArticulosListaItem from "./_ArticulosListaItem";

//
export default function ArticulosLista() {
  const { items } = useSelector(
    (state) => state.cotizacionesReducer.cotizacion
  );

  return (
    <>
      <div style={{ marginTop: 20 }}>
        {items.map((item, i) => (
          <ArticulosListaItem
            key={i}
            articulo={item.articulo}
            nuevoReimpresion={item.nuevoReimpresion}
            cantidad={item.cantidad}
            index={i}
            cotizacionItemsID={item.cotizacionItemsID}
          />
        ))}
      </div>
    </>
  );
}
