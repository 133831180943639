import { useSelector } from "react-redux";
import InputSinNumber from "../../../../components/input/InputSinNumber";

import ColOpcion from "./ColOpcion";
import ColTitulo from "./ColTitulo";
import RowCosto from "./RowCosto";

export default function CostoPantallaBarnizEspecial() {

  const {

    costoPantallaBarnizEspecialOpcion1,
    costoPantallaBarnizEspecialOpcion2,
    costoPantallaBarnizEspecialOpcion3,
    costoPantallaBarnizEspecialOpcion4,
    costoPantallaBarnizEspecialOpcion5,
  } = useSelector((state) => state.cotizacionesReducer.cotizacion);



  return (
    <RowCosto>
      <ColTitulo>Costo pantalla barniz especial</ColTitulo>
      <ColOpcion>
        <InputSinNumber value={costoPantallaBarnizEspecialOpcion1} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={costoPantallaBarnizEspecialOpcion2} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={costoPantallaBarnizEspecialOpcion3} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={costoPantallaBarnizEspecialOpcion4} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={costoPantallaBarnizEspecialOpcion5} disabled />
      </ColOpcion>
    </RowCosto>
  );
}
