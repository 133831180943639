import { Button, Col, Row } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { SinGuardar } from "../../../components/Boton/SinGuardar";
import { Aplicar } from "../../../context/actions/EmpaqueAction";
export default function BotonUtilidad() {
  const dispatch = useDispatch();
  const {
    seccionModificada: { calculoUtilidad },
  } = useSelector((state) => state.cotizacionesReducer);

  const handleClick = () => {
    dispatch(Aplicar());
  };

  return (
    <Row justify="center" style={{ marginBottom: 20 }}>
      <Col span={22} style={{ textAlign: "center" }}>
        <Button onClick={handleClick} style={{ width: 300 }} type="primary">
          Cálculo final con utilidad
        </Button>

        <div style={{ height: 20 }}>
          <SinGuardar show={calculoUtilidad} />
        </div>
      </Col>
    </Row>
  );
}
