import { useSelector } from "react-redux";
import InputSinNumber from "../../../../components/input/InputSinNumber";

import ColOpcion from "./ColOpcion";
import ColTitulo from "./ColTitulo";
import RowCosto from "./RowCosto";

export default function CostoSubstrato() {
  const {
    costoSubstratoOpcion1,
    costoSubstratoOpcion2,
    costoSubstratoOpcion3,
    costoSubstratoOpcion4,
    costoSubstratoOpcion5,
  } = useSelector((state) => state.cotizacionesReducer.cotizacion);

  return (
    <RowCosto>
      <ColTitulo>Costo substrato</ColTitulo>
      <ColOpcion>
        <InputSinNumber value={costoSubstratoOpcion1} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={costoSubstratoOpcion2} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={costoSubstratoOpcion3} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={costoSubstratoOpcion4} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={costoSubstratoOpcion5} disabled />
      </ColOpcion>
    </RowCosto>
  );
}
