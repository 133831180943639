import React from "react";
import { useHistory } from "react-router-dom";
import CardPage from "../../layout/CardPage";
import Acciones from "./acciones/Acciones";
import Archivos from "./archivos/Archivos";
import Estatus from "./estatus/Estatus";

export default function SeccionDerecha() {
  const history = useHistory();
  return (
    <CardPage
      titulo="Acciones"
      tituloBoton="Regresar"
      accionBoton={() => history.push("/")}
    >
      <Estatus />
      <Acciones />
      <Archivos />
    </CardPage>
  );
}
