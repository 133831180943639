
import {  useSelector } from "react-redux";
import InputSinNumber from "../../../../components/input/InputSinNumber";

import ColOpcion from "./ColOpcion";
import ColTitulo from "./ColTitulo";
import RowCosto from "./RowCosto";

export default function CambioPlacas() {

  const {
    cambioPlacasOpcion1,
    cambioPlacasOpcion2,
    cambioPlacasOpcion3,
    cambioPlacasOpcion4,
    cambioPlacasOpcion5,
  } = useSelector((state) => state.cotizacionesReducer.cotizacion);


  return (
    <RowCosto>
      <ColTitulo>Cambio de placas / pantallas</ColTitulo>
      <ColOpcion>
        <InputSinNumber value={cambioPlacasOpcion1} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={cambioPlacasOpcion2} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={cambioPlacasOpcion3} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={cambioPlacasOpcion4} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={cambioPlacasOpcion5} disabled />
      </ColOpcion>
    </RowCosto>
  );
}
