import { Col, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { setCotizacionExterna } from "../../../context/actions/cotizacionesActions";
import ColTitulo from "../Calculos/renglonesCostos/ColTitulo";
import RowCosto from "../Calculos/renglonesCostos/RowCosto";

export const Notas = () => {
  const dispatch = useDispatch();

  const { notas } = useSelector(
    (state) => state.cotizacionesReducer.cotizacionExterna
  );

  const handleChange = ({ target }) => {
    dispatch(
      setCotizacionExterna({
        [target.name]: target.value,
      })
    );
  };

  //
  return (
    <RowCosto>
      <ColTitulo>Notas</ColTitulo>
      <Col xs={24} md={12}>
        <Input.TextArea
          onChange={handleChange}
          rows={4}
          name="notas"
          value={notas}
        ></Input.TextArea>
      </Col>
    </RowCosto>
  );
};
