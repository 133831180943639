import moment from "moment";

export const articuloInit = {
  nombre: "",
  especificacion: "",
  descripcion: "",
  grupo: "",
  categoria: "",
  estatus: "",
  proveedorID: 0,
  costo: 0,
  unidad: "",
  fechaAlta: moment(),
  across_inch: 0,
  across_cm: 0,
  down_inch: 0,
  down_cm: 0,
  gap_acorss_inch: 0,
  gap_acorss_cm: 0,
  gap_down_inch: 0,
  gap_down_cm: 0,
  radius: 0,
  qty_across: 0,
  qty_down: 0,
};

export const articulosInitial = {
  articulo: articuloInit,
  articulos: [],
  articulosEnCotizacion: {
    cotizaciones:[]
  }
};
