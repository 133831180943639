import React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import DropdownCatalogo from "../../../components/DropdownCatalogo";
import DropDownTextNum from "../../../components/DropdownCatalogo/DropDownTextNum";
import DropDownTextNumNullable from "../../../components/DropdownCatalogo/DropDownTextNumNullable";
import { grupos } from "../../../constants/cons";
import {
  changeCotizacion,
  selectTermiandos,
} from "../../../context/actions/cotizacionesActions";
import { setSeccionModificada } from "../../../context/actions/stepActions";
import { setTerminado } from "../../../context/actions/terminadosActions";
import useGetArticulosByGrupo from "../../../hooks/useGetArticulosByGrupo";
import escogeArticulo from "../../../utils/escogeArticulo";
import DatosArticulo from "./_DatosArticulo";

export default function Sustrato() {
  const { sustrato } = useSelector((state) => state.terminadosReducer);

  const dispatch = useDispatch();
  const { articulosGrupo } = useGetArticulosByGrupo(grupos.SUSTRATO);

  const { sustratoID } = useSelector(
    (state) => state.cotizacionesReducer.cotizacion
  );
  const onChange = async ({ target: { name, value } }) => {
    const sustrato = await escogeArticulo(articulosGrupo, value);

    dispatch(changeCotizacion({ [name]: value }));
    dispatch(changeCotizacion({ costoMSISustrato: sustrato.costo }));
    dispatch(setTerminado({ sustrato }));
    dispatch(setSeccionModificada({ terminados: true }));

  };

  return (
    <>
      <DropDownTextNumNullable
        label="Sustrato"
        value={sustratoID}
        name="sustratoID"
        grupo={grupos.SUSTRATO}
        onChange={onChange}
      />

      <DatosArticulo articulo={sustrato} />
    </>
  );
}
