import axios from "axios";
import FileDownload from "js-file-download";

///////////////////////////////////////////////////////////////////////////////////////////

export async function get(url) {
  try {
    const { data } = await axios.get(url);
    return data;
  } catch (e) {
    console.log(e);
    return -1;
  }
}

///////////////////////////////////////////////////////////////////////////////////////////
export async function post(url, body) {
  try {
    const { data } = await axios.post(url, body);
    return data;
  } catch (e) {
    console.log(e);
    return -1;
  }
}

///////////////////////////////////////////////////////////////////////////////////////////
export async function put(url, body) {
  try {
    const { data } = await axios.put(url, body);
    return data;
  } catch (e) {
    console.log(e);
    return -1;
  }
}

///////////////////////////////////////////////////////////////////////////////////////////

export async function dele(url) {
  try {
    const { data } = await axios.delete(url);
    return { code: 1, data };
  } catch (e) {
    return { code: -1, data: e.response.data };
  }
}

///////////////////////////////////////////////////////////////////////////////////////////

export async function getFile(url, name) {
  try {
    await axios({
      url,
      method: "GET",
      responseType: "blob", // Important
    }).then((response) => {
      FileDownload(response.data, name);
    });
  } catch (e) {
    return { code: -1, data: e.response.data };
  }
}
