import { useHistory } from "react-router";
import Forma from "./Forma";
import CardPage from "../../layout/CardPage";
import SubmitCreate from "./SubmitCreate";

export default function ProveedoresCreate() {
  const history = useHistory();

  return (
    <>
      <CardPage
        titulo="Agregar Proveedor"
        tituloBoton="Regresar"
        accionBoton={() => history.push("/proveedores")}
      >
        <Forma />
      </CardPage>
      <SubmitCreate />
    </>
  );
}
