import { useSelector } from "react-redux";
import InputSinNumber from "../../../../components/input/InputSinNumber";

import ColOpcion from "./ColOpcion";
import ColTitulo from "./ColTitulo";
import RowCosto from "./RowCosto";

export default function CostoTiempoMaquinaBaseBlancaDigital() {
  const {
    costoTiempoMaquinaBaseBlancaDigitalOpcion1,
    costoTiempoMaquinaBaseBlancaDigitalOpcion2,
    costoTiempoMaquinaBaseBlancaDigitalOpcion3,
    costoTiempoMaquinaBaseBlancaDigitalOpcion4,
    costoTiempoMaquinaBaseBlancaDigitalOpcion5,
  } = useSelector((state) => state.cotizacionesReducer.cotizacion);

  return (
    <RowCosto>
      <ColTitulo>Costo tiempo máquina por base blanca digital</ColTitulo>
      <ColOpcion>
        <InputSinNumber
          value={costoTiempoMaquinaBaseBlancaDigitalOpcion1}
          disabled
        />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber
          value={costoTiempoMaquinaBaseBlancaDigitalOpcion2}
          disabled
        />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber
          value={costoTiempoMaquinaBaseBlancaDigitalOpcion3}
          disabled
        />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber
          value={costoTiempoMaquinaBaseBlancaDigitalOpcion4}
          disabled
        />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber
          value={costoTiempoMaquinaBaseBlancaDigitalOpcion5}
          disabled
        />
      </ColOpcion>
    </RowCosto>
  );
}
