import { Spin } from "antd";
import { useSelector } from "react-redux";
import ArticulosCreate from "./articulos/_ArticulosCreate";
import ArticulosLista from "./articulos/_ArticulosLista";
import ArticulosTotales from "./articulos/_ArticulosTotales";

export default function FormaArticulos() {
  const { downloadingData } = useSelector((state) => state.loadingReducer);

  return (
    <div>
      {downloadingData ? (
        <Spin />
      ) : (
        <>
          <ArticulosCreate />
          <ArticulosLista />
          <ArticulosTotales />
        </>
      )}
    </div>
  );
}
