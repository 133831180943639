import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setPiezasImpresion,
  setRepeticionPlantillaImpresion,
} from "../../../context/actions/cotizacionesCotizadorActions";

export default function useFormaCotizador() {
  const dispatch = useDispatch();
  const {
    acrossGap,
    acrossMedida,
    downGap,
    downMedida,
    etiquetasRepeticion,
    anchoBanda,
    piezasAcross,
    piezasImpresion,
    tipoCambio,
    costoDadoFoli,
    costoDadoGrabado,
    costoDadoSuajeDepreciacion,
    costoDadoSuaje,
    desglozarCostoDados,
    sistemaCotizado,
    impresionOffset,
    cortePRIMERA,
    noMaquinasAdicionales,
    requiereBaseBlancaDigital,
    porcentajeBaseBlancaDigital,
    repeticionPlantillaImpresion,
    items,
  } = useSelector((state) => state.cotizacionesReducer.cotizacion);

  useEffect(() => {
    dispatch(setRepeticionPlantillaImpresion());
  }, [downMedida, downGap, etiquetasRepeticion]);

  useEffect(() => {
    dispatch(setPiezasImpresion());
  }, [piezasAcross, etiquetasRepeticion]);
}
