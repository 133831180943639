import { types } from "../types/types";

export const paisesReducer = (state = [], action) => {
  switch (action.type) {
    case types.storePaises:
      return [...action.payload];

    default:
      return state;
  }
};
