import React from "react";
import { useSelector } from "react-redux";
import Autorizado from "./_Autorizado";
import Cerrado from "./_Cerrado";
import Produccion from "./_Produccion";
import Cotizacion from "./_Cotizacion";
import { Spin } from "antd";
import Capturando from "./_Capturando";

export default function Estatus() {
  const { cotizacionEstatusID } = useSelector(
    (state) => state.cotizacionesReducer.cotizacion
  );
  
  if (cotizacionEstatusID == 1) return <Capturando />;
  if (cotizacionEstatusID == 2) return <Cotizacion />;
  if (cotizacionEstatusID == 3) return <Autorizado />;
  if (cotizacionEstatusID == 4) return <Produccion />;
  if (cotizacionEstatusID == 5) return <Cerrado />;

  return <Spin />;
}
