import { useDispatch } from "react-redux";
import BotonSubmit from "../../components/Boton/BotonSubmit";
import { startPutConstantes } from "../../context/actions/constantesActions";

export default function SubmitActualizar() {
  const dispatch = useDispatch();

  const handleClick = () => dispatch(startPutConstantes());

  return (
    <div style={{ marginTop: 20 }}>
      <BotonSubmit onClick={handleClick} tituloBoton="Actualizar Datos" />
    </div>
  );
}
