import { Col, Tooltip } from "antd";
import InputSin from "../../components/input/InputSin";

export default function ColData({ tooltip, texto, name, value, onChange }) {
  return (
    <>
      <Col xs={20} md={10} xl={6}>
        {tooltip ? (
          <div>
            <Tooltip title={tooltip}>{texto}</Tooltip>
          </div>
        ) : (
          <div>{texto}</div>
        )}
      </Col>
      <Col xs={4} md={2}>
        <InputSin name={name} value={value} onChange={onChange} />
      </Col>
    </>
  );
}
