import { URL_HISTORIAL } from "../../constants/url";
import {
  composeErrorNotification,
  composeSuccessNotification,
} from "../../utils/composeNotification";
import { get, post } from "../../utils/fetch";
import { types } from "../types/types";
import { notification } from "./notificationActions";

export const startLoadHistorial = (cotizacionID) => {
  return async (dispatch) => {
    const data = await get(`${URL_HISTORIAL}/${cotizacionID}`);

    if (data !== -1) {
      dispatch(storeHistorial(data));
    } else {
      dispatch(
        notification(
          composeErrorNotification("Error de conexion", "GET historial")
        )
      );
    }
  };
};

const storeHistorial = (payload) => ({
  type: types.storeHistorial,
  payload,
});

////////////

export const startPostHistorial = (comentarios, cotizacionID) => {
  return async (dispatch, getState) => {
    const { user } = getState().userReducer;
    const body = {
      applicationUserID: user.Id,
      comentarios,
      cotizacionID: Number(cotizacionID),
    };

    const data = await post(`${URL_HISTORIAL}`, body);
    console.log(data);
    if (data !== -1) {
      dispatch(notification(composeSuccessNotification("Historial Agregado")));
      dispatch(startLoadHistorial(Number(cotizacionID)));
    } else {
      dispatch(
        notification(
          composeErrorNotification("Error de conexion", "POST historial")
        )
      );
    }
  };
};
