import { Col } from "antd";
import React from "react";

export default function LabelValorTexto({ label, valor }) {
  return (
    <Col xs={24} sm={12} xl={8}>
      <span className="label">{label}: </span>
      <span className="valorSpan">{valor}</span>
    </Col>
  );
}
