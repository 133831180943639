import { URL_VER } from "../../constants/url";
import { composeErrorNotification } from "../../utils/composeNotification";
import { get } from "../../utils/fetch";
import { types } from "../types/types";

import { notification } from "./notificationActions";

export const startGetVer = () => {
  return async (dispatch) => {
    const data = await get(URL_VER);

    if (data !== -1) {
      dispatch(storeVer(data));
    } else {
      dispatch(notification(composeErrorNotification("Get Ver")));
    }
  };
};

const storeVer = (ver) => ({
  type: types.storeVer,
  payload: ver,
});
