import { useSelector } from "react-redux";
import InputSinNumber from "../../../../components/input/InputSinNumber";

import ColOpcion from "./ColOpcion";
import ColTitulo from "./ColTitulo";
import RowCosto from "./RowCosto";

export default function MSITotal() {
  const {
    MSITotalOpcion1,
    MSITotalOpcion2,
    MSITotalOpcion3,
    MSITotalOpcion4,
    MSITotalOpcion5,
  } = useSelector((state) => state.cotizacionesReducer.cotizacion);

  return (
    <RowCosto>
      <ColTitulo>MSI total</ColTitulo>
      <ColOpcion>
        <InputSinNumber value={MSITotalOpcion1} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={MSITotalOpcion2} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={MSITotalOpcion3} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={MSITotalOpcion4} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={MSITotalOpcion5} disabled />
      </ColOpcion>
    </RowCosto>
  );
}
