import { Col, Row, Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";
import InputSin from "../../components/input/InputSin";
import { changeConstantes } from "../../context/actions/constantesActions";
import ColData from "./ColData";

export default function Forma() {
  const dispatch = useDispatch();
  const {
    ipH_EPSON,
    costO_MAQUINA_HORA_IMPRESION,
    costO_MAQUINA_HORA_ACABADO,
    costO_MAQUINA_HORA_CORTE,
    velocidaD_CORTE,
    duracioN_DADO_SUAJE,
    ipH_MAQUINAS_ACABADO,
    costO_SERVICIO_EPSON,
    costO_SETUP_DIGITAL,
    costO_SETUP_CONVENCIONAL,
    costO_SETUP_ACABADOS,
    cargO_HORA_USO_PRIMERA,
    costO_TIMPO_MAQUINA_PARO_CAMBIO_COLOR,
    costO_TIMPO_MAQUINA_CAMBIO_PLACAS,
    cargO_INFORMACION_VARIABLE,
    costO_UNITARIO_PREPARACION_ARCHIVOS,
    costO_PLACA_OFFSET,
    costO_NEGATIVO,
    costO_PLACA_BARNIZ,
    costO_MSI_TINTA_SEMIROTATIVA,
    costO_MSI_TINTA_SERIGRAFIA,
    costO_TONER_CMYK_MSI,
    costO_TONER_BLANCO_MSI,
    tiempoMaquinaCambioPlacas,
    tiempoMaquinaSetupOffset,
    tiempoMaquinaCambioColor,
  } = useSelector((state) => state.constantesReducer);

  const onChange = ({ target: { name, value } }) => {
    console.log({ name, value });
    dispatch(changeConstantes({ [name]: value }));
  };

  return (
    <>
      <Row gutter={[10, 10]} style={{ marginBottom: 10 }}>
        <ColData
          Tooltip="10 ft/m = 600 ft/hr"
          texto="IPH Epson"
          name="ipH_EPSON"
          value={ipH_EPSON}
          onChange={onChange}
        />

        <ColData
          Tooltip="$95 Digital / $145 Convencional"
          texto="Costo máquina por hora de IMPRESIÓN"
          name="costO_MAQUINA_HORA_IMPRESION"
          value={costO_MAQUINA_HORA_IMPRESION}
          onChange={onChange}
        />

        <ColData
          texto="Costo máquina por hora de ACABADO"
          name="costO_MAQUINA_HORA_ACABADO"
          value={costO_MAQUINA_HORA_ACABADO}
          onChange={onChange}
        />

        <ColData
          texto="Costo máquina por hora de CORTE"
          name="costO_MAQUINA_HORA_CORTE"
          value={costO_MAQUINA_HORA_CORTE}
          onChange={onChange}
        />

        <ColData
          tooltip="30 ft / min"
          texto="Velocidad de CORTE"
          name="velocidaD_CORTE"
          value={velocidaD_CORTE}
          onChange={onChange}
        />

        <ColData
          tooltip="Golpes"
          texto="Duración dado de suaje"
          name="duracioN_DADO_SUAJE"
          value={duracioN_DADO_SUAJE}
          onChange={onChange}
        />

        <ColData
          tooltip="RSS, ROP 46 imp / min"
          texto="IPH para máquinas de acabado"
          name="ipH_MAQUINAS_ACABADO"
          value={ipH_MAQUINAS_ACABADO}
          onChange={onChange}
        />

        <ColData
          tooltip="por MSI"
          texto="Costo de servicio EPSON"
          name="costO_SERVICIO_EPSON"
          value={costO_SERVICIO_EPSON}
          onChange={onChange}
        />

        <ColData
          tooltip="10 min RSS por color"
          texto="Costo de setup DIGITAL"
          name="costO_SETUP_DIGITAL"
          value={costO_SETUP_DIGITAL}
          onChange={onChange}
        />

        <ColData
          tooltip="6 min por color"
          texto="Costo de setup CONVENCIONAL"
          name="costO_SETUP_CONVENCIONAL"
          value={costO_SETUP_CONVENCIONAL}
          onChange={onChange}
        />

        <ColData
          tooltip="20 min"
          texto="Costo de setup ACABADOS"
          name="costO_SETUP_ACABADOS"
          value={costO_SETUP_ACABADOS}
          onChange={onChange}
        />

        <ColData
          texto="Costo por Hr uso PRIMERA"
          name="cargO_HORA_USO_PRIMERA"
          value={cargO_HORA_USO_PRIMERA}
          onChange={onChange}
        />

        <ColData
          tooltip="cambio de color 10 min"
          texto="Costo tiempo máquina por paro"
          name="costO_TIMPO_MAQUINA_PARO_CAMBIO_COLOR"
          value={costO_TIMPO_MAQUINA_PARO_CAMBIO_COLOR}
          onChange={onChange}
        />

        <ColData
          tooltip="o pantallas 10min"
          texto="Costo tiempo máquina por cambio placas"
          name="costO_TIMPO_MAQUINA_CAMBIO_PLACAS"
          value={costO_TIMPO_MAQUINA_CAMBIO_PLACAS}
          onChange={onChange}
        />

        <ColData
          tooltip="Por Millar de impresiones"
          texto="Cargo por información variable"
          name="cargO_INFORMACION_VARIABLE"
          value={cargO_INFORMACION_VARIABLE}
          onChange={onChange}
        />

        <ColData
          texto="Costo unitario por preparación de archivos digitales"
          name="costO_UNITARIO_PREPARACION_ARCHIVOS"
          value={costO_UNITARIO_PREPARACION_ARCHIVOS}
          onChange={onChange}
        />

        <ColData
          texto="Costo placa offset / tipografía"
          name="costO_PLACA_OFFSET"
          value={costO_PLACA_OFFSET}
          onChange={onChange}
        />

        <ColData
          texto="Costo negativo y pantalla serigrafía"
          name="costO_NEGATIVO"
          value={costO_NEGATIVO}
          onChange={onChange}
        />

        <ColData
          texto="Costo placa barniz"
          name="costO_PLACA_BARNIZ"
          value={costO_PLACA_BARNIZ}
          onChange={onChange}
        />

        <ColData
          texto="Costo por MSI tinta semirotativa"
          name="costO_MSI_TINTA_SEMIROTATIVA"
          value={costO_MSI_TINTA_SEMIROTATIVA}
          onChange={onChange}
        />

        <ColData
          texto="Costo por MSI tinta serigrafía"
          name="costO_MSI_TINTA_SERIGRAFIA"
          value={costO_MSI_TINTA_SERIGRAFIA}
          onChange={onChange}
        />

        <ColData
          texto="Costo toner cmyk en MSI"
          name="costO_TONER_CMYK_MSI"
          value={costO_TONER_CMYK_MSI}
          onChange={onChange}
        />

        <ColData
          texto="Costo toner BLANCO en MSI"
          name="costO_TONER_BLANCO_MSI"
          value={costO_TONER_BLANCO_MSI}
          onChange={onChange}
        />
        <ColData
          texto="Tiempo maquina setup offset (mins):"
          name="tiempoMaquinaSetupOffset"
          value={tiempoMaquinaSetupOffset}
          onChange={onChange}
        />
        <ColData
          texto="Tiempo maquina cambio placas (mins):"
          name="tiempoMaquinaCambioPlacas"
          value={tiempoMaquinaCambioPlacas}
          onChange={onChange}
        />
        <ColData
          texto="Tiempo maquina cambio color:"
          name="tiempoMaquinaCambioColor"
          value={tiempoMaquinaCambioColor}
          onChange={onChange}
        />
      </Row>
    </>
  );
}
