export default function getTextOptions(array) {
  let salida = [];
  array.map((a) => {
    salida = [...salida, { text: a, value: a }];
  });

  return salida;
}


