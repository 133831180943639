import { EyeOutlined } from "@ant-design/icons";
import { useHistory } from "react-router";

export default function useProveedoresColumns() {
  const history = useHistory();

  const handleClick = ({ proveedorID }) =>
    history.push(`/proveedores/detail/${proveedorID}`);

  const proveedoresColumns = [
    {
      title: "Nombre",
      dataIndex: "nombre",
    },
    {
      title: "Contacto Nombre",
      dataIndex: "contactoNombre",
    },
    {
      title: "Contacto Teléfono",
      dataIndex: "contactoTelefono",
    },
    {
      title: "Contacto Email",
      dataIndex: "contactoEmail",
    },
    {
      title: "País",
      dataIndex: "pais",
    },
    {
      title: "Estatus",
      dataIndex: "estatus",
    },
    {
      title: "",
      width: 30,
      render: (text, record) => (
        <EyeOutlined onClick={() => handleClick(record)} className="eyeIcon" />
      ),
    },
  ];

  return { proveedoresColumns };
}
