
import { useSelector } from "react-redux";
import InputSinNumber from "../../../../components/input/InputSinNumber";

import ColOpcion from "./ColOpcion";
import ColTitulo from "./ColTitulo";
import RowCosto from "./RowCosto";

export default function BarnizMaquina() {
  const {
   barnizMaquinaOpcion1,
    barnizMaquinaOpcion2,
    barnizMaquinaOpcion3,
    barnizMaquinaOpcion4,
    barnizMaquinaOpcion5,
  } = useSelector((state) => state.cotizacionesReducer.cotizacion);

  return (
    <RowCosto>
      <ColTitulo>Barniz máquina</ColTitulo>
      <ColOpcion>
        <InputSinNumber value={barnizMaquinaOpcion1} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={barnizMaquinaOpcion2} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={barnizMaquinaOpcion3} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={barnizMaquinaOpcion4} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={barnizMaquinaOpcion5} disabled />
      </ColOpcion>
    </RowCosto>
  );
}
