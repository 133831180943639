import { EyeOutlined, CopyOutlined } from "@ant-design/icons";
import { Col, Row, Tooltip } from "antd";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import PillAutorizado from "../../components/pillEstatus/PillAutorizado";
import PillCapturando from "../../components/pillEstatus/PillCapturando";
import PillCerrado from "../../components/pillEstatus/PillCerrado";
import PillCotizacion from "../../components/pillEstatus/PillCotizacion";
import PillProduccion from "../../components/pillEstatus/PillProduccion";
import {
  startBackorder,
  startDuplicar,
} from "../../context/actions/clientesActions";

//
export default function useCotizacionesColumns() {
  const history = useHistory();
  const dispatch = useDispatch();
  const handleClick = ({ cotizacionID }) =>
    history.push(`/cotizaciones/details/${cotizacionID}`);

  const handleDuplicar = ({ cotizacionID, clienteID }) =>
    dispatch(startDuplicar({ cotizacionID, clienteID }));

  const handleBackorder = ({ cotizacionID, clienteID }) =>
    dispatch(startBackorder({ cotizacionID, clienteID }));

  const columns = [
    {
      title: "ID",
      dataIndex: "cotizacionID",
    },
    {
      title: "Fecha",
      dataIndex: "fecha",
    },
    {
      title: "Representante",
      dataIndex: "representante",
    },

    {
      title: "Marca",
      dataIndex: "marca",
    },
    {
      title: "Backorder",
      dataIndex: "backorder",
    },
    {
      title: "No Parte",
      dataIndex: "noParte",
    },
    {
      title: "Estatus",
      width: 120,
      render: ({ cotizacionEstatusID, cotizacionEstatus }) => {
        if (cotizacionEstatusID === 1) {
          return <PillCapturando />;
        }
        if (cotizacionEstatusID === 2) {
          return <PillCotizacion />;
        }
        if (cotizacionEstatusID === 3) {
          return <PillAutorizado />;
        }
        if (cotizacionEstatusID === 4) {
          return <PillProduccion />;
        }
        if (cotizacionEstatusID === 5) {
          return <PillCerrado />;
        }
        return <></>;
      },
    },

    {
      title: "",
      width: 100,
      render: (text, record) => (
        <Row gutter={10}>
          <Col>
            <Tooltip title="ver">
              <EyeOutlined
                onClick={() => handleClick(record)}
                className="eyeIcon"
              />
            </Tooltip>
          </Col>
          <Col>
            <Tooltip title="duplicar">
              <CopyOutlined
                onClick={() => handleDuplicar(record)}
                className="eyeIcon"
              />
            </Tooltip>
          </Col>
          <Col>
            <Tooltip title="backorder">
              <CopyOutlined
                onClick={() => handleBackorder(record)}
                className="eyeIcon"
              />
            </Tooltip>
          </Col>
        </Row>
      ),
    },
  ];

  return { columns };
}
