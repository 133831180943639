import { useDispatch, useSelector } from "react-redux";
import InputSinNumber from "../../../components/input/InputSinNumber";
import { changeCotizacion } from "../../../context/actions/cotizacionesActions";
import { setSeccionModificada } from "../../../context/actions/stepActions";
import ColOpcion from "../Calculos/renglonesCostos/ColOpcion";
import ColTitulo from "../Calculos/renglonesCostos/ColTitulo";
import RowCosto from "../Calculos/renglonesCostos/RowCosto";

export default function Velocidad() {
  const dispatch = useDispatch();
  const {
    velocidadOpcion1,
    velocidadOpcion2,
    velocidadOpcion3,
    velocidadOpcion4,
    velocidadOpcion5,
  } = useSelector((state) => state.cotizacionesReducer.cotizacion);

  const onChange = ({ target: { name, value } }) => {
    dispatch(changeCotizacion({ [name]: value }));
    dispatch(setSeccionModificada({ terminados: true }));

  };

  return (
    <RowCosto>
      <ColTitulo>Velocidad</ColTitulo>
      <ColOpcion>
        <InputSinNumber
          onChange={onChange}
          name="velocidadOpcion1"
          value={velocidadOpcion1}
        />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber
          onChange={onChange}
          name="velocidadOpcion2"
          value={velocidadOpcion2}
        />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber
          onChange={onChange}
          name="velocidadOpcion3"
          value={velocidadOpcion3}
        />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber
          onChange={onChange}
          name="velocidadOpcion4"
          value={velocidadOpcion4}
        />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber
          onChange={onChange}
          name="velocidadOpcion5"
          value={velocidadOpcion5}
        />
      </ColOpcion>
    </RowCosto>
  );
}
