import { Spin } from "antd";
import { useSelector } from "react-redux";
import { Fade } from "react-reveal";
import CardPage from "../../../layout/CardPage";
import ArticulosCreate from "./_ArticulosCreate";
import ArticulosLista from "./_ArticulosLista";
import ArticulosTotales from "./_ArticulosTotales";
import ButtonContinuar from "./_ButtonContinuar";

export default function FormaArticulos() {
  const { downloadingData } = useSelector((state) => state.loadingReducer);

  return (
    <CardPage titulo="Selección de artículos">
      {downloadingData ? (
        <Spin />
      ) : (
        <>
          <ArticulosCreate />
          <ArticulosLista />
          <ArticulosTotales />
        </>
      )}

      <ButtonContinuar />
    </CardPage>
  );
}
