import { Button, Col, Row } from "antd";
import { Fade } from "react-reveal";
import BotonCard from "../components/Boton/BotonCard";

export default function CardPage({
  children,
  titulo,
  tituloBoton,
  accionBoton,
}) {
  return (
    <Fade>
      <div style={{ marginBottom: 15 }}>
        <Row>
          <Col span={12} className="card__titulo_container">
            <div className="card__titulo">{titulo}</div>
          </Col>
          <Col span={12} className="card__accion">
            {tituloBoton && (
              <BotonCard onClick={accionBoton} tituloBoton={tituloBoton} />
            )}
          </Col>
        </Row>

        <div className="card__contenido">{children}</div>
      </div>
    </Fade>
  );
}
