import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import DropdownCatalogo from "../../../components/DropdownCatalogo";
import DropDownTextNum from "../../../components/DropdownCatalogo/DropDownTextNum";
import DropDownTextNumNullable from "../../../components/DropdownCatalogo/DropDownTextNumNullable";
import { grupos } from "../../../constants/cons";
import { changeCotizacion, selectTermiandos } from "../../../context/actions/cotizacionesActions";
import { setSeccionModificada } from "../../../context/actions/stepActions";
import { setTerminado } from "../../../context/actions/terminadosActions";
import useGetArticulosByGrupo from "../../../hooks/useGetArticulosByGrupo";
import escogeArticulo from "../../../utils/escogeArticulo";
import DatosArticulo from "./_DatosArticulo";

export default function Laminado() {
  const { laminado } = useSelector((state) => state.terminadosReducer);

  const dispatch = useDispatch();
  const { articulosGrupo } = useGetArticulosByGrupo(grupos.LAMINADO);
  const { laminadoID } = useSelector(
    (state) => state.cotizacionesReducer.cotizacion
  );
  const onChange = async ({ target: { name, value } }) => {
    const laminado = await escogeArticulo(articulosGrupo, value);

    dispatch(changeCotizacion({ [name]: value }));
    dispatch(changeCotizacion({ costoMSILaminado: laminado.costo }));
    dispatch(setTerminado({ laminado }));
    dispatch(setSeccionModificada({ terminados: true }));


  };

  return (
    <>
      <DropDownTextNumNullable
        label="Laminado"
        value={laminadoID}
        name="laminadoID"
        grupo={grupos.LAMINADO}
        onChange={onChange}
      />
      <DatosArticulo articulo={laminado} />
    </>
  );
}
