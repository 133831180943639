import React from "react";
import { ambiente, ver } from "../constants/url";
import { useSelector } from "react-redux";

export const Ver = () => {
  const { verBack } = useSelector((state) => state.userReducer);
  return (
    <>
      <div className="ver">B {verBack}</div>
      <div className="ver">
        F {ver} {ambiente}
      </div>
    </>
  );
};
