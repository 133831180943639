import { useSelector } from "react-redux";
import InputSinNumber from "../../../../components/input/InputSinNumber";

import ColOpcion from "./ColOpcion";
import ColTitulo from "./ColTitulo";
import RowCosto from "./RowCosto";

export default function BarnizEspecialRSS() {
  const {
    barnizEspecialRSSOpcion1,
    barnizEspecialRSSOpcion2,
    barnizEspecialRSSOpcion3,
    barnizEspecialRSSOpcion4,
    barnizEspecialRSSOpcion5,
  } = useSelector((state) => state.cotizacionesReducer.cotizacion);

  return (
    <RowCosto>
      <ColTitulo>Barniz especial RSS</ColTitulo>
      <ColOpcion>
        <InputSinNumber value={barnizEspecialRSSOpcion1} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={barnizEspecialRSSOpcion2} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={barnizEspecialRSSOpcion3} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={barnizEspecialRSSOpcion4} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={barnizEspecialRSSOpcion5} disabled />
      </ColOpcion>
    </RowCosto>
  );
}
