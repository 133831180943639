import { Row } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ColResponsive from "../../components/Col/ColResponsive";
import ColResponsiveDoble from "../../components/Col/ColResponsiveDoble";
import InputLabel from "../../components/input/InputLabel";
import SelectLabel from "../../components/input/SelectLabel";
import TextareaLabel from "../../components/input/TextareaLabel";
import { changeProveedor } from "../../context/actions/proveedoresActions";
import usePaises from "../../hooks/usePaises";

export default function Forma() {
  const { getPaisesOptions, getEstadosOptions } = usePaises();
  const [estadosOptions, setEstadosOptions] = useState([]);

  const paisesOptions = getPaisesOptions();

  const { catalogos } = useSelector((state) => state.catalogosReducer);
  const dispatch = useDispatch();
  const {
    nombre,
    calleNo,
    notas,
    poblacion,
    estado,
    cp,
    pais,
    contactoNombre,
    contactoTelefono,
    contactoEmail,
  } = useSelector((state) => state.proveedoresReducer.proveedor);

  ///
  const onChange = ({ target: { name, value } }) => {
    dispatch(changeProveedor({ [name]: value }));
  };

  const onChangePais = ({ target: { name, value } }) => {
    setEstadosOptions(getEstadosOptions(value));
    dispatch(changeProveedor({ [name]: value }));
  };


  useEffect(() => {
    setEstadosOptions(getEstadosOptions(Number(pais)));
  }, [pais]);

  return (
    <>
      <Row style={{ marginBottom: 10 }}>
        <ColResponsive>
          <InputLabel
            name="nombre"
            value={nombre}
            onChange={onChange}
            label="Empresa"
          />
        </ColResponsive>
      </Row>
      <Row gutter={[10, 10]} style={{ marginBottom: 10 }}>
        <ColResponsive>
          <InputLabel
            name="calleNo"
            value={calleNo}
            onChange={onChange}
            label="Calle y no."
          />
        </ColResponsive>
        <ColResponsive>
          <InputLabel
            name="poblacion"
            value={poblacion}
            onChange={onChange}
            label="Población"
          />
        </ColResponsive>
        <ColResponsive>
          <InputLabel name="cp" value={cp} onChange={onChange} label="CP" />
        </ColResponsive>
      </Row>
      <Row gutter={[10, 10]} style={{ marginBottom: 10 }}>
        <ColResponsive>
          <SelectLabel
            name="pais"
            value={Number(pais)}
            onChange={onChangePais}
            label="País"
            options={paisesOptions}
          />
        </ColResponsive>
        <ColResponsive>
          <SelectLabel
            name="estado"
            value={Number(estado)}
            onChange={onChange}
            label="Estado"
            options={estadosOptions}
          />
        </ColResponsive>
      </Row>
      <Row gutter={[10, 10]} style={{ marginBottom: 10 }}>
        <ColResponsive>
          <InputLabel
            name="contactoNombre"
            value={contactoNombre}
            onChange={onChange}
            label="Nombre contacto"
          />
        </ColResponsive>
        <ColResponsive>
          <InputLabel
            name="contactoTelefono"
            value={contactoTelefono}
            onChange={onChange}
            label="Teléfono contacto"
          />
        </ColResponsive>
        <ColResponsive>
          <InputLabel
            name="contactoEmail"
            value={contactoEmail}
            onChange={onChange}
            label="Email contacto"
          />
        </ColResponsive>
      </Row>
      <Row gutter={[10, 10]} style={{ marginBottom: 10 }}>
        <ColResponsiveDoble>
          <TextareaLabel
            name="notas"
            value={notas}
            onChange={onChange}
            label="Notas"
          />
        </ColResponsiveDoble>
      </Row>
    </>
  );
}
