import { useHistory } from "react-router";
import BotonSubmit from "../../components/Boton/BotonSubmit";
import CardPage from "../../layout/CardPage";
import Forma from "./Forma";
import SubmitCreate from "./SubmitCreate";

export default function ArticulosCreate() {
  const history = useHistory();

  return (<>
    <CardPage
      titulo="Agregar Artículo"
      tituloBoton="Regresar"
      accionBoton={() => history.push("/articulos")}
    >
      <Forma />

    
    </CardPage>
      <SubmitCreate />
 </> );
}
