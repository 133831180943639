import { Select } from "antd";
import React from "react";
import { useSelector } from "react-redux";
const { Option } = Select;
export const SelectSubestatus = ({ subestatusID, setSubestatusID }) => {
  const { subestatus } = useSelector((state) => state.cotizacionesReducer);
  //
  return (
    <Select
      value={subestatusID}
      style={{ width: "100%", marginBottom: 5, marginTop: 10 }}
      onChange={(val) => setSubestatusID(val)}
      placeholder="Subestatus"
    >
      {subestatus.map((s, i) => (
        <Option key={i} value={s.subestatusID}>
          {s.nombre}
        </Option>
      ))}
    </Select>
  );
};
