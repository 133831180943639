import { Col, Row } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import SelectLabel from "../../../components/input/SelectLabel";
import TextareaLabel from "../../../components/input/TextareaLabel";
import { changeCotizacion } from "../../../context/actions/cotizacionesActions";
import { setSeccionModificada } from "../../../context/actions/stepActions";
import { getCatalogoText } from "../../../utils/getCatalogo";

export default function Acabados() {
  const dispatch = useDispatch();
  const {
    maquina1,
    maquina2,
    maquina3,
    maquina4,
    maquina5,
    acabado1,
    acabado2,
    acabado3,
    acabado4,
    acabado5,
    observaciones,
  } = useSelector((state) => state.cotizacionesReducer.cotizacion);
  const { catalogos } = useSelector((state) => state.catalogosReducer);

  const maquinas = getCatalogoText(catalogos, "Máquinas");
  const acabados = getCatalogoText(catalogos, "Acabados");

  const handleChange = ({ target: { name, value } }) => {
    dispatch(changeCotizacion({ [name]: value }));
    dispatch(setSeccionModificada({ cotizador: true }));
  };

  return (
    <>
      <Row gutter={[10, 10]} style={{ marginBottom: 10 }}>
        <Col xs={12} md={6}>
          <SelectLabel
            onChange={handleChange}
            options={maquinas}
            label="Maquina 1"
            name="maquina1"
            value={maquina1}
          />
        </Col>
        <Col xs={12} md={6}>
          <SelectLabel
            onChange={handleChange}
            options={acabados}
            label="Acabado 1"
            name="acabado1"
            value={acabado1}
          />
        </Col>

        <Col xs={12} md={6}>
          <SelectLabel
            onChange={handleChange}
            options={maquinas}
            label="Maquina 2"
            name="maquina2"
            value={maquina2}
          />
        </Col>
        <Col xs={12} md={6}>
          <SelectLabel
            onChange={handleChange}
            options={acabados}
            label="Acabado 2"
            name="acabado2"
            value={acabado2}
          />
        </Col>

        <Col xs={12} md={6}>
          <SelectLabel
            onChange={handleChange}
            options={maquinas}
            label="Maquina 3"
            name="maquina3"
            value={maquina3}
          />
        </Col>
        <Col xs={12} md={6}>
          <SelectLabel
            onChange={handleChange}
            options={acabados}
            label="Acabado 3"
            name="acabado3"
            value={acabado3}
          />
        </Col>
        <Col xs={12} md={6}>
          <SelectLabel
            onChange={handleChange}
            options={maquinas}
            label="Maquina 4"
            name="maquina4"
            value={maquina4}
          />
        </Col>
        <Col xs={12} md={6}>
          <SelectLabel
            onChange={handleChange}
            options={acabados}
            label="Acabado 4"
            name="acabado4"
            value={acabado4}
          />
        </Col>

        <Col xs={12} md={6}>
          <SelectLabel
            onChange={handleChange}
            options={maquinas}
            label="Maquina 5"
            name="maquina5"
            value={maquina5}
          />
        </Col>
        <Col xs={12} md={6}>
          <SelectLabel
            onChange={handleChange}
            options={acabados}
            label="Acabado 5"
            name="acabado5"
            value={acabado5}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6}>
          <TextareaLabel
            label="Observaciones"
            onChange={handleChange}
            rows={4}
            value={observaciones}
            name="observaciones"
          />
        </Col>
      </Row>
    </>
  );
}
