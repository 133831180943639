import React from "react";

export const SinGuardar = ({ show, style }) => {
  if (show !== true) return <></>;

  return (
    <div
      style={{ color: "#ad7100", marginRight: 10, fontWeight: 600, ...style }}
    >
      sin guadrar
    </div>
  );
};
