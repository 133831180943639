import { Col, Input, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import InputLabel from "../../components/input/InputLabel";
import InputSin from "../../components/input/InputSin";
import { changeArticulo } from "../../context/actions/articulosActions";

export default function FormaMedidas() {
  const dispatch = useDispatch();

  const {
    across_inch,
    down_inch,
    gap_acorss_inch,
    gap_down_inch,
    radius,
    qty_across,
    qty_down,
  } = useSelector((state) => state.articulosReducer.articulo);
  const onChange = ({ target: { name, value } }) => {
    dispatch(changeArticulo({ [name]: value }));
  };

  return (
    <>
      <Row gutter={[10, 10]} style={{ marginBottom: 10 }}>
        <Col xs={4}></Col>
        <Col
          xs={10}
          md={8}
          lg={6}
          style={{ textAlign: "center", fontWeight: "600" }}
        >
          Inch
        </Col>
      </Row>
      <Row gutter={[10, 10]} style={{ marginBottom: 10 }}>
        <Col xs={4}>Across</Col>
        <Col xs={10} md={8} lg={6}>
          <InputSin
            name="across_inch"
            value={across_inch}
            onChange={onChange}
          />
        </Col>
      </Row>
      <Row gutter={[10, 10]} style={{ marginBottom: 10 }}>
        <Col xs={4}>Down</Col>
        <Col xs={10} md={8} lg={6}>
          <InputSin name="down_inch" value={down_inch} onChange={onChange} />
        </Col>
      </Row>
      <Row gutter={[10, 10]} style={{ marginBottom: 10 }}>
        <Col xs={4}>Gap Across</Col>
        <Col xs={10} md={8} lg={6}>
          <InputSin
            name="gap_acorss_inch"
            value={gap_acorss_inch}
            onChange={onChange}
          />
        </Col>
      </Row>
      <Row gutter={[10, 10]} style={{ marginBottom: 10 }}>
        <Col xs={4}>Gap Down</Col>
        <Col xs={10} md={8} lg={6}>
          <InputSin
            name="gap_down_inch"
            value={gap_down_inch}
            onChange={onChange}
          />
        </Col>
      </Row>
      <Row gutter={[10, 10]} style={{ marginBottom: 10 }}>
        <Col xs={4}>Radius</Col>
        <Col xs={10} md={8} lg={6}>
          <InputSin name="radius" value={radius} onChange={onChange} />
        </Col>
      </Row>
      <Row gutter={[10, 10]} style={{ marginBottom: 10 }}>
        <Col xs={4}>Qty Across</Col>
        <Col xs={10} md={8} lg={6}>
          <InputSin name="qty_across" value={qty_across} onChange={onChange} />
        </Col>
      </Row>
      <Row gutter={[10, 10]} style={{ marginBottom: 10 }}>
        <Col xs={4}>Qty Down</Col>
        <Col xs={10} md={8} lg={6}>
          <InputSin name="qty_down" value={qty_down} onChange={onChange} />
        </Col>
      </Row>
    </>
  );
}
