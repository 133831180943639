import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import {
  startGetCotizacionesByArticulo,
  startLoadArticulo,
} from "../../context/actions/articulosActions";
import CardPage from "../../layout/CardPage";
import Forma from "./Forma";
import SubmitEdit from "./SubmitEdit";
import { ArticulosEnCotizaciones } from "./ArticulosEnCotizaciones";
import { SubmitEliminar } from "./SubmitEliminar";
import { Col, Divider, Row } from "antd";

export default function ArticulosDetails() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { articuloID } = useParams();

  useEffect(() => {
    dispatch(startLoadArticulo(articuloID));
    dispatch(startGetCotizacionesByArticulo(articuloID));
  }, []);

  return (
    <>
      <CardPage
        titulo="Agregar Artículo"
        tituloBoton="Regresar"
        accionBoton={() => history.push("/articulos")}
      >
        <Forma />
        <SubmitEdit />
<Divider />
        <Row>
          <Col span={4} style={{paddingTop: 5}}>
            <SubmitEliminar />
          </Col>
          <Col span={20}>
            <ArticulosEnCotizaciones />
          </Col>
        </Row>
      </CardPage>
    </>
  );
}
