import { Table } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import useCotizacionesColumns from "./useCotizacionesColumns";

export const ListaCotizaciones = () => {
  const { cotizacionesByCliente } = useSelector(
    (state) => state.clientesReducer
  );

  const { columns } = useCotizacionesColumns();

  return (
    <Table
      rowKey="cotizacionID"
      columns={columns}
      dataSource={cotizacionesByCliente}
    />
  );
};
