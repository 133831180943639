import { useSelector } from "react-redux";

export default function useGetCatalogoTextNum(grupo) {
  let catalogo = [];
  const { articulos } = useSelector((state) => state.articulosReducer);
  articulos.map((a) => {
    if (a.grupo === grupo)
      catalogo = [
        ...catalogo,
        { text: `${a.nombre} - ${a.descripcion} `, value: a.articuloID },
      ];
  });

  return { catalogo };
}
