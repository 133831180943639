export const widthMD = 768;
export const multiplicadorEmpaque = 0.006;

export const fases = {
  fase1: 1,
  fase2: 2,
  fase3: 3,
  fase4: 4,
};

export const grupos = {
  DADO: "Dado",
  SUSTRATO: "Sustrato",
  BARNIZ: "Barniz",
  LAMINADO: "Laminado",
  FOIL: "Foil",
  BARNIZESPECIAL: "Barniz especial",
};

export const opcionesCotizacion = [
  {
    value: "Sin arte",
    label: "Sin arte",
  },
  {
    value: "Armar arte",
    label: "Armar arte",
  },
  {
    value: "Detenida",
    label: "Detenida",
  },
  {
    value: "En aprobación",
    label: "En aprobación",
  },
  {
    value: "Planilla",
    label: "Planilla",
  },
  {
    value: "Impresion",
    label: "Impresion",
  },
  {
    value: "Muestras",
    label: "Muestras",
  },
  {
    value: "Prueba",
    label: "Prueba",
  },
];
