import { Row } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import ColResponsive from "../../components/Col/ColResponsive";
import InputLabel from "../../components/input/InputLabel";
import SelectLabel from "../../components/input/SelectLabel";
import { changeCliente } from "../../context/actions/clientesActions";
import usePaises from "../../hooks/usePaises";
import { getCatalogoText } from "../../utils/getCatalogo";

export default function Forma() {
  const { getPaisesOptions, getEstadosOptions } = usePaises();
  const [estadosOptions, setEstadosOptions] = useState([]);
  const paisesOptions = getPaisesOptions();

  const { catalogos } = useSelector((state) => state.catalogosReducer);

  const [estado, setEstado] = useState()
  const [pais, setPais] = useState()
  const dispatch = useDispatch();
  const {
    nombre,
    apellidoPaterno,
    apellidoMaterno,
    rfc,
    emailFacturacion,
    regimenFiscal,
    terminosPago,
    cfdi,
    calle,
    noExt,
    colonia,
    poblacion,
    cp,
    limiteCredito,
    moneda,
    zonaImpuestos,
    contactoNombre,
    contactoTelefono,
    contactoEmail,
    sector,
    estadoIndex,
  } = useSelector((state) => state.clientesReducer.cliente);


  useEffect(() => {
    if(estadoIndex)
    {
      console.log({este: estadoIndex.paisID})
      setEstadosOptions(getEstadosOptions(estadoIndex.paisID));
      setEstado(estadoIndex?.estadoID)
      setPais(estadoIndex?.paisID)
    }

  }, [estadoIndex]);

  console.log(estado)

  ///
  const onChange = ({ target: { name, value } }) => {
    dispatch(changeCliente({ [name]: value }));

  };

  const onChangeEstado = ({ target: { name, value } }) => {
    dispatch(changeCliente({ [name]: value }));
    setEstado(value)
  };

  const onChangePais = ({ target: { name, value } }) => {
  
    setEstadosOptions(getEstadosOptions(value));
    dispatch(changeCliente({ [name]: value }));
    setPais(value)
    setEstado(null)
  };

  /// opociones
  const regimenFiscalOptions = getCatalogoText(catalogos, "Régimen fiscal");
  const terminosPagoOptions = getCatalogoText(catalogos, "Términos de pago");
  const usoCFDIOptions = getCatalogoText(catalogos, "Uso de CFDI");
  const monedaOptions = getCatalogoText(catalogos, "Moneda");
  const zonaImpuestosOptions = getCatalogoText(catalogos, "Zona de impuestos");
  const sectorOptions = getCatalogoText(catalogos, "Sector");

  ////////////////
  return (
    <>
      <Row gutter={[10, 10]} style={{ marginBottom: 10 }}>
        <ColResponsive>
          <InputLabel
            name="nombre"
            value={nombre}
            onChange={onChange}
            label="Nombre"
          />
        </ColResponsive>
        <ColResponsive>
          <InputLabel
            name="apellidoPaterno"
            value={apellidoPaterno}
            onChange={onChange}
            label="Apellido Paterno"
          />
        </ColResponsive>
        <ColResponsive>
          <InputLabel
            name="apellidoMaterno"
            value={apellidoMaterno}
            onChange={onChange}
            label="Apellido Materno"
          />
        </ColResponsive>

        <ColResponsive>
          <InputLabel name="rfc" value={rfc} onChange={onChange} label="RFC" />
        </ColResponsive>

        <ColResponsive>
          <InputLabel
            name="emailFacturacion"
            value={emailFacturacion}
            onChange={onChange}
            label="Email de Facturación"
          />
        </ColResponsive>

        <ColResponsive>
          <SelectLabel
            name="regimenFiscal"
            value={regimenFiscal}
            onChange={onChange}
            label="Réginem Fiscal"
            options={regimenFiscalOptions}
          />
        </ColResponsive>
        <ColResponsive>
          <SelectLabel
            name="terminosPago"
            value={terminosPago}
            onChange={onChange}
            label="Términos de pago"
            options={terminosPagoOptions}
          />
        </ColResponsive>
        <ColResponsive>
          <SelectLabel
            name="cfdi"
            value={cfdi}
            onChange={onChange}
            label="Uso de CFDI"
            options={usoCFDIOptions}
          />
        </ColResponsive>
      </Row>
      <Row gutter={[10, 10]} style={{ marginBottom: 10 }}>
        <ColResponsive>
          <InputLabel
            name="calle"
            value={calle}
            onChange={onChange}
            label="Calle"
          />
        </ColResponsive>
        <ColResponsive>
          <InputLabel
            name="noExt"
            value={noExt}
            onChange={onChange}
            label="No."
          />
        </ColResponsive>
        <ColResponsive>
          <InputLabel
            name="colonia"
            value={colonia}
            onChange={onChange}
            label="Colonia"
          />
        </ColResponsive>
        <ColResponsive>
          <InputLabel
            name="poblacion"
            value={poblacion}
            onChange={onChange}
            label="Población"
          />
        </ColResponsive>
        <ColResponsive>
          <SelectLabel
            name="pais"
            value={pais}
            onChange={onChangePais}
            label="País"
            options={paisesOptions}
          />
        </ColResponsive>

        <ColResponsive>
          <SelectLabel
            name="estadoIndexID"
            value={estado}
            onChange={onChangeEstado}
            label="Estado"
            options={estadosOptions}
          />
        </ColResponsive>

        <ColResponsive>
          <InputLabel name="cp" value={cp} onChange={onChange} label="CP" />
        </ColResponsive>
      </Row>
      <Row gutter={[10, 10]} style={{ marginBottom: 10 }}>
        <ColResponsive>
          <InputLabel
            name="limiteCredito"
            value={limiteCredito}
            onChange={onChange}
            label="Límite de crédito"
          />
        </ColResponsive>
        <ColResponsive>
          <SelectLabel
            name="moneda"
            value={moneda}
            onChange={onChange}
            label="Moneda"
            options={monedaOptions}
          />
        </ColResponsive>
        <ColResponsive>
          <SelectLabel
            name="zonaImpuestos"
            value={zonaImpuestos}
            onChange={onChange}
            label="Zona de Impuestos"
            options={zonaImpuestosOptions}
          />
        </ColResponsive>
      </Row>
      <Row gutter={[10, 10]} style={{ marginBottom: 10 }}>
        <ColResponsive>
          <InputLabel
            name="contactoNombre"
            value={contactoNombre}
            onChange={onChange}
            label="Nombre de Contacto"
          />
        </ColResponsive>
        <ColResponsive>
          <InputLabel
            name="contactoTelefono"
            value={contactoTelefono}
            onChange={onChange}
            label="Teléfono de Contacto"
          />
        </ColResponsive>
        <ColResponsive>
          <InputLabel
            name="contactoEmail"
            value={contactoEmail}
            onChange={onChange}
            label="Email de Contacto"
          />
        </ColResponsive>
        <ColResponsive>
          <SelectLabel
            name="sector"
            value={sector}
            onChange={onChange}
            label="Sector"
            options={sectorOptions}
          />
        </ColResponsive>
      </Row>
    </>
  );
}
