import { useSelector } from "react-redux";
import InputSinNumber from "../../../../components/input/InputSinNumber";

import ColOpcion from "./ColOpcion";
import ColTitulo from "./ColTitulo";
import RowCosto from "./RowCosto";

export default function TonerBlanco() {

  const {
  tonerBlancoOpcion1,
    tonerBlancoOpcion2,
    tonerBlancoOpcion3,
    tonerBlancoOpcion4,
    tonerBlancoOpcion5,
  } = useSelector((state) => state.cotizacionesReducer.cotizacion);


  return (
    <RowCosto>
      <ColTitulo>Toner (blanco)</ColTitulo>
      <ColOpcion>
        <InputSinNumber value={tonerBlancoOpcion1} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={tonerBlancoOpcion2} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={tonerBlancoOpcion3} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={tonerBlancoOpcion4} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={tonerBlancoOpcion5} disabled />
      </ColOpcion>
    </RowCosto>
  );
}
