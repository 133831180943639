import { Col } from "antd";
import FormatMoney from "../../../components/money/FormatMoney";

export default function ColMoney({ value }) {
  return (
    <Col span={4} style={{ textAlign: "right" }}>
      {<FormatMoney value={value} />}
    </Col>
  );
}
