import {
  URL_CATALOGO,
  URL_CATALOGO_ITEMS,
  URL_COTIZACIONES_ITEMS,
} from "../../constants/url";
import {
  composeErrorNotification,
  composeSuccessNotification,
} from "../../utils/composeNotification";
import { dele, get, post } from "../../utils/fetch";
import { types } from "../types/types";
import { notification } from "./notificationActions";

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const startLoadCatalogos = () => {
  return async (dispatch) => {
    const data = await get(URL_CATALOGO);
    if (data == -1) {
      // error
      return;
    }
    dispatch(storeCatalogos(data));
  };
};

const storeCatalogos = (payload) => ({
  type: types.storeCatalogos,
  payload,
});

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const setCategoriaSelected = (payload) => ({
  type: types.setCategoriaSelected,
  payload,
});
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const startPostCategoriaItem = (nombre) => {
  return async (dispatch, getState) => {
    const { categoriaSelected: catalogoID } = getState().catalogosReducer;
    const data = await post(URL_CATALOGO_ITEMS, { catalogoID, nombre });

    if (data !== -1) {
      dispatch(notification(composeSuccessNotification(data)));
      dispatch(startLoadCatalogos());
    } else {
      dispatch(
        notification(
          composeErrorNotification("Error de conexion", "Post cat-item")
        )
      );
    }
  };
};
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const startDeleCatalogoItem = (id) => {
  return async (dispatch) => {
    const response = await dele(`${URL_CATALOGO_ITEMS}/${id}`);
    if (response.code !== -1) {
      dispatch(notification(composeSuccessNotification(response.data)));
      dispatch(startLoadCatalogos());
    } else {
      dispatch(
        notification(
          composeErrorNotification("Error de conexion", response.data)
        )
      );
    }
  };
};
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
