import { catalogosInitial } from "../initial/catalogosInitial";
import { types } from "../types/types";

export const catalogosReducer = (state = catalogosInitial, action) => {
  switch (action.type) {
    case types.storeCatalogos:
      return {
        ...state,
        catalogos: action.payload,
      };

    case types.setCategoriaSelected:
      return {
        ...state,
        categoriaSelected: action.payload,
      };

    default:
      return state;
  }
};
