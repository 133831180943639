import { useSelector } from "react-redux";
import InputSinNumber from "../../../../components/input/InputSinNumber";

import ColOpcion from "./ColOpcion";
import ColTitulo from "./ColTitulo";
import RowCosto from "./RowCosto";

export default function CantidadSolicitada() {
  const { opcion1, opcion2, opcion3, opcion4, opcion5 } = useSelector(
    (state) => state.cotizacionesReducer.cotizacion
  );

  return (
    <RowCosto>
      <ColTitulo>Cantidad solicitada</ColTitulo>
      <ColOpcion>
        <InputSinNumber value={opcion1} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={opcion2} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={opcion3} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={opcion4} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={opcion5} disabled />
      </ColOpcion>
    </RowCosto>
  );
}
