import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import DropdownCatalogo from "../../../components/DropdownCatalogo";
import DropDownTextNum from "../../../components/DropdownCatalogo/DropDownTextNum";
import DropDownTextNumNullable from "../../../components/DropdownCatalogo/DropDownTextNumNullable";
import { grupos } from "../../../constants/cons";
import {
  changeCotizacion,
  selectTermiandos,
} from "../../../context/actions/cotizacionesActions";
import { setSeccionModificada } from "../../../context/actions/stepActions";
import { setTerminado } from "../../../context/actions/terminadosActions";
import useGetArticulosByGrupo from "../../../hooks/useGetArticulosByGrupo";
import escogeArticulo from "../../../utils/escogeArticulo";
import DatosArticulo from "./_DatosArticulo";

export default function BarnizEspecial() {
  const { barnizEspecial } = useSelector((state) => state.terminadosReducer);
  const dispatch = useDispatch();
  const { articulosGrupo } = useGetArticulosByGrupo(grupos.BARNIZESPECIAL);
  const { barnizEspecialID } = useSelector(
    (state) => state.cotizacionesReducer.cotizacion
  );
  const onChange = async ({ target: { name, value } }) => {
    const barnizEspecial = await escogeArticulo(articulosGrupo, value);

    dispatch(changeCotizacion({ [name]: value }));
    dispatch(
      changeCotizacion({ costoMSIBarnizEspecial: barnizEspecial.costo })
    );
    dispatch(setTerminado({ barnizEspecial }));
    dispatch(setSeccionModificada({ terminados: true }));

  };

  return (
    <>
      <DropDownTextNumNullable
        label="Barniz Especial"
        value={barnizEspecialID}
        name="barnizEspecialID"
        grupo={grupos.BARNIZESPECIAL}
        onChange={onChange}
      />
      <DatosArticulo articulo={barnizEspecial} />
    </>
  );
}
