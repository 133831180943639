
import {  useSelector } from "react-redux";
import InputSinNumber from "../../../../components/input/InputSinNumber";

import ColOpcion from "./ColOpcion";
import ColTitulo from "./ColTitulo";
import RowCosto from "./RowCosto";

export default function CambioColor() {

  const {
    cambioColorOpcion1,
    cambioColorOpcion2,
    cambioColorOpcion3,
    cambioColorOpcion4,
    cambioColorOpcion5,
  } = useSelector((state) => state.cotizacionesReducer.cotizacion);

  return (
    <RowCosto>
      <ColTitulo>Cambio de color</ColTitulo>
      <ColOpcion>
        <InputSinNumber value={cambioColorOpcion1} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={cambioColorOpcion2} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={cambioColorOpcion3} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={cambioColorOpcion4} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={cambioColorOpcion5} disabled />
      </ColOpcion>
    </RowCosto>
  );
}
