import { Col, Row } from "antd";
import useLoadCatalogos from "../../hooks/useLoadCatalogos";
import Categorias from "./_Categorias";
import Elementos from "./_Elementos";

export default function Catalogos() {
  useLoadCatalogos()
  return (
    <>
      <Row gutter={[10,10]}>
        <Col xs={24} md={12}>
          <Categorias />
        </Col>
        <Col xs={24} md={12}>
          <Elementos />
        </Col>
      </Row>
    </>
  );
}
