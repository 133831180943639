import {  useSelector } from "react-redux";
import InputSinNumber from "../../../../components/input/InputSinNumber";

import ColOpcion from "./ColOpcion";
import ColTitulo from "./ColTitulo";
import RowCosto from "./RowCosto";

export default function PantallaRSS() {

  const {

    pantallaRSSOpcion1,
    pantallaRSSOpcion2,
    pantallaRSSOpcion3,
    pantallaRSSOpcion4,
    pantallaRSSOpcion5,
  } = useSelector((state) => state.cotizacionesReducer.cotizacion);


  return (
    <RowCosto>
      <ColTitulo>Pantalla RSS</ColTitulo>
      <ColOpcion>
        <InputSinNumber value={pantallaRSSOpcion1} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={pantallaRSSOpcion2} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={pantallaRSSOpcion3} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={pantallaRSSOpcion4} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={pantallaRSSOpcion5} disabled />
      </ColOpcion>
    </RowCosto>
  );
}
