import { useSelector } from "react-redux";
import InputSinNumber from "../../../../components/input/InputSinNumber";

import ColOpcion from "./ColOpcion";
import ColTitulo from "./ColTitulo";
import RowCosto from "./RowCosto";

export default function MSIRequeridosSetup() {
  const {
    MSIRequeridosSetupOpcion1,
    MSIRequeridosSetupOpcion2,
    MSIRequeridosSetupOpcion3,
    MSIRequeridosSetupOpcion4,
    MSIRequeridosSetupOpcion5,
  } = useSelector((state) => state.cotizacionesReducer.cotizacion);

  return (
    <RowCosto>
      <ColTitulo>
        MSI requeridos para set up (40mts x color "O", 10 mts "DyS")
      </ColTitulo>
      <ColOpcion>
        <InputSinNumber value={MSIRequeridosSetupOpcion1} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={MSIRequeridosSetupOpcion2} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={MSIRequeridosSetupOpcion3} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={MSIRequeridosSetupOpcion4} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={MSIRequeridosSetupOpcion5} disabled />
      </ColOpcion>
    </RowCosto>
  );
}
