import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  cleanCliente,
  startLoadClientes,
} from "../context/actions/clientesActions";

export default function useLoadClientes() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(startLoadClientes());
    dispatch(cleanCliente());
  }, []);
}
