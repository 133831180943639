import { Col, DatePicker, Modal, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";

import React from "react";
import { useHistory, useParams } from "react-router-dom";
import LabelValor from "../../../components/labelValor/LabelValor";
import LabelValorTexto from "../../../components/labelValor/LabelValorTexto";
import CardPage from "../../../layout/CardPage";
import Historial from "./Historial";

import ColMoney from "./ColMoney";
import { parseDate } from "../../../utils/parseDate";
import Eliminar from "./Eliminar";
import { useState } from "react";
import LabelValorClick from "../../../components/labelValor/LabelValorClick";
import {
  setTipoCaptura,
  startPostUpdateFechaDTO,
} from "../../../context/actions/cotizacionesActions";
import parseFloat2 from "../../../utils/parseFloat2";

export default function Datos() {
  const history = useHistory();
  const dispatch = useDispatch();
  ///
  const { cotizacionID } = useParams();
  const [showModal, setShowModal] = useState(false);
  const [fechaEntrega, setFechaEntrega] = useState("");
  ///
  const { cotizacion: c } = useSelector((state) => state.cotizacionesReducer);
  const handleEditar = () => {
    dispatch(setTipoCaptura("editar"));
    history.push(`/cotizaciones/view/${cotizacionID}`);
  };

  ///

  const handleOkFecha = () => {
    if (fechaEntrega === "") {
      setShowModal(false);
      return;
    }

    const body = {
      fechaEntrega,
      cotizacionID,
    };
    dispatch(startPostUpdateFechaDTO(body));
    setFechaEntrega("");
    setShowModal(false);
  };

  return (
    <CardPage
      titulo="Datos de Cotización"
      accionBoton={handleEditar}
      tituloBoton="Editar"
    >
      {c.backorder !== 0 && (
        <div>
          <Row gutter={[10, 10]}>
            <LabelValor label="Backorder" valor={c.backorder} />
          </Row>
        </div>
      )}

      <Modal
        visible={showModal}
        title="Cambiar Fecha de Entrega"
        onOk={handleOkFecha}
        onCancel={() => setShowModal(false)}
      >
        <DatePicker onChange={(val1, val2) => setFechaEntrega(val2)} />
      </Modal>

      <Row gutter={[10, 10]}>
        <LabelValor label="Cotización" valor={c.cotizacionID} />
        <LabelValor label="Fecha" valor={parseDate(c.fecha)} />
        <LabelValor label="Cliente" valor={c.cliente?.nombre} />
        <LabelValor label="Representante" valor={c.representante} />

        <LabelValor
          label="Fecha de autorizacion"
          valor={parseDate(c.fechaAutorizacion)}
        />

        <LabelValorClick
          onClick={() => setShowModal(true)}
          label="Fecha de entrega"
          valor={parseDate(c.fechaEntrega)}
        />

        <LabelValor label="Orden de compra" valor={c.oc} />
        <LabelValor label="Opción seleccioanda" valor={c.opcionSeleccionada} />

        <LabelValorTexto label="Marca" valor={c.marca} />
        <LabelValorTexto label="No de parte" valor={c.noParte} />
        <LabelValorTexto label="Material" valor={c.articuloSustrato?.nombre} />

        <LabelValorTexto label="Tamaño terminado" valor={c.tamanoTerminado} />
        <LabelValorTexto label="Artículos" valor={c.items.length} />
        <LabelValorTexto label="Barniz" valor={c.articuloBarniz?.nombre} />

        <LabelValorTexto label="Tintas" valor={c.noTintas} />
        <LabelValorTexto
          label="Selección de color"
          valor={c.requiereSeleccionColor ? "Si" : "No"}
        />
        <LabelValorTexto label="Laminado" valor={c.articuloLaminado?.nombre} />

        <LabelValorTexto label="Piezas por rollo" valor={c.piezasPorRollo} />
        <LabelValorTexto label="Presentación" valor={c.presentacion} />
        <LabelValorTexto label="Foil" valor={c.articuloFoil?.nombre} />

        <LabelValorTexto label="Aplicación" valor={c.aplicacion} />
        <LabelValorTexto label="Posicion" valor={c.posicion} />
        <LabelValorTexto
          label="Barniz especial"
          valor={c.articuloBarnizEspecial?.nombre}
        />

        <LabelValorTexto label="Ancho minimo" valor={c.anchoMinimo} />
        <LabelValorTexto label="Ancho de banda" valor={c.anchoBanda} />
      </Row>
      <Row style={{ marginTop: 20 }} gutter={[10, 10]}>
        <Col span={4}></Col>
        <Col
          span={4}
          style={{ borderBottom: "1px solid silver", textAlign: "center" }}
        >
          <span className="label">Opcion 1</span>
        </Col>
        <Col
          span={4}
          style={{ borderBottom: "1px solid silver", textAlign: "center" }}
        >
          <span className="label">Opcion 2</span>
        </Col>
        <Col
          span={4}
          style={{ borderBottom: "1px solid silver", textAlign: "center" }}
        >
          <span className="label">Opcion 3</span>
        </Col>
        <Col
          span={4}
          style={{ borderBottom: "1px solid silver", textAlign: "center" }}
        >
          <span className="label">Opcion 4</span>
        </Col>
        <Col
          span={4}
          style={{ borderBottom: "1px solid silver", textAlign: "center" }}
        >
          <span className="label">Opcion 5</span>
        </Col>
      </Row>
      <Row gutter={[10, 10]}>
        <Col span={4}>
          <span className="label">Cantidad</span>
        </Col>
        <Col span={4} style={{ textAlign: "right" }}>
          <div style={{ marginRight: 15 }}>{c.opcion1}</div>
        </Col>
        <Col span={4} style={{ textAlign: "right" }}>
          <div style={{ marginRight: 15 }}>{c.opcion2}</div>
        </Col>
        <Col span={4} style={{ textAlign: "right" }}>
          <div style={{ marginRight: 15 }}>{c.opcion3}</div>
        </Col>
        <Col span={4} style={{ textAlign: "right" }}>
          <div style={{ marginRight: 15 }}>{c.opcion4}</div>
        </Col>
        <Col span={4} style={{ textAlign: "right" }}>
          <div style={{ marginRight: 15 }}>{c.opcion5}</div>
        </Col>
      </Row>
      <Row gutter={[10, 10]}>
        <Col span={4}>
          <span className="label">Unitario (Dlls)</span>
        </Col>
        <ColMoney value={c.totalPUOpcion1} />
        <ColMoney value={c.totalPUOpcion2} />
        <ColMoney value={c.totalPUOpcion3} />
        <ColMoney value={c.totalPUOpcion4} />
        <ColMoney value={c.totalPUOpcion5} />
      </Row>
      <Row gutter={[10, 10]}>
        <Col span={4}>
          <span className="label">Total (Dlls)</span>
        </Col>
        <ColMoney value={parseFloat2(c.totalOpcion1)} />
        <ColMoney value={parseFloat2(c.totalOpcion2)} />
        <ColMoney value={parseFloat2(c.totalOpcion3)} />
        <ColMoney value={parseFloat2(c.totalOpcion4)} />
        <ColMoney value={parseFloat2(c.totalOpcion5)} />
      </Row>

      <Row gutter={[10, 10]} style={{ marginTop: 15 }}>
        <Col span={4}>
          <span className="label">UT</span>
        </Col>
        <Col span={4} style={{ textAlign: "right" }}>
          {parseFloat2(c.utilidadPorcentajeOpcion1)} %
        </Col>
        <Col span={4} style={{ textAlign: "right" }}>
          {parseFloat2(c.utilidadPorcentajeOpcion2)} %
        </Col>
        <Col span={4} style={{ textAlign: "right" }}>
          {parseFloat2(c.utilidadPorcentajeOpcion3)} %
        </Col>
        <Col span={4} style={{ textAlign: "right" }}>
          {parseFloat2(c.utilidadPorcentajeOpcion4)} %
        </Col>
        <Col span={4} style={{ textAlign: "right" }}>
          {parseFloat2(c.utilidadPorcentajeOpcion5)} %
        </Col>
      </Row>

      <Row gutter={[10, 10]}>
        <Col span={4}>
          <span className="label">Total mts</span>
        </Col>
        <Col span={4} style={{ textAlign: "right" }}>
          {parseFloat2(c.metrosLinealesRequeridosOpcion1)}
        </Col>
        <Col span={4} style={{ textAlign: "right" }}>
          {parseFloat2(c.metrosLinealesRequeridosOpcion2)}
        </Col>
        <Col span={4} style={{ textAlign: "right" }}>
          {parseFloat2(c.metrosLinealesRequeridosOpcion3)}
        </Col>
        <Col span={4} style={{ textAlign: "right" }}>
          {parseFloat2(c.metrosLinealesRequeridosOpcion4)}
        </Col>
        <Col span={4} style={{ textAlign: "right" }}>
          {parseFloat2(c.metrosLinealesRequeridosOpcion5)}
        </Col>
      </Row>

      <Historial />
      <Eliminar />
    </CardPage>
  );
}
