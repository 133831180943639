import {
  cotExternaVacia,
  cotizacionesInitial,
  cotizacionInitial,
  seccionModificadaVacio,
  utilidadCero,
} from "../initial/cotizacionesInitial";
import { types } from "../types/types";

export const cotizacionesReducer = (state = cotizacionesInitial, action) => {
  switch (action.type) {
    case types.storeSubestatus:
      return {
        ...state,
        subestatus: action.payload,
      };

    case types.cleanCotExterna:
      return {
        ...state,
        cotizacionExterna: cotExternaVacia,
      };

    case types.setCotizacionExternaFull:
      return {
        ...state,
        cotizacionExterna: action.payload,
      };

    case types.setCotizacionExterna:
      return {
        ...state,
        cotizacionExterna: {
          ...state.cotizacionExterna,
          ...action.payload,
        },
      };
    case types.setFiltro:
      return {
        ...state,
        filtro: {
          ...state.filtro,
          ...action.payload,
        },
      };
    case types.setTipoCaptura:
      return {
        ...state,
        cotizacion: { ...state.cotizacion, tipoCaptura: action.payload },
      };
    case types.cleanSeccionModificada:
      return {
        ...state,
        seccionModificada: {
          ...state.seccionModificada,
          ...seccionModificadaVacio,
        },
      };

    case types.setSeccionModificada:
      return {
        ...state,
        seccionModificada: {
          ...state.seccionModificada,
          ...action.payload,
        },
      };

    case types.ceroUtildiad:
      return {
        ...state,
        cotizacion: { ...state.cotizacion, ...utilidadCero },
      };
    case types.storeCotizaciones:
      return {
        ...state,
        cotizaciones: action.payload,
      };

    case types.changeCotizacion:
      return {
        ...state,
        cotizacion: { ...state.cotizacion, ...action.payload },
      };

    case types.addCotizacionItem:
      return {
        ...state,
        cotizacion: {
          ...state.cotizacion,
          items: [...state.cotizacion.items, action.payload],
        },
      };

    case types.removeCotizacionItem:
      const newArray = [...state.cotizacion.items];
      newArray.splice(action.payload, 1);
      return {
        ...state,
        cotizacion: {
          ...state.cotizacion,
          items: [...newArray],
        },
      };

    case types.storeCotizacion:
      return {
        ...state,
        cotizacion: { ...state.cotizacion, ...action.payload },
      };

    case types.cleanCotizacion:
      return {
        ...state,
        cotizacion: cotizacionInitial,
      };

    case types.setOpcion1: {
      return {
        ...state,
        cotizacion: {
          ...state.cotizacion,
          opcion1: action.payload,
        },
      };
    }

    case types.setStep:
      return {
        ...state,
        cotizacion: {
          ...state.cotizacion,
          step: action.payload,
        },
      };

    case types.storeFiles:
      return {
        ...state,
        files: action.payload,
      };

    default:
      return state;
  }
};
