import { useSelector } from "react-redux";

export default function usePaises() {
  const paises = useSelector((state) => state.paisesReducer);

  const getEstadosOptions = (paisID) => {
 
    let estados = [];
    paises.map((p) => {
      if (p.paisID === paisID) {
        p.estados.map((e) => {
          estados = [...estados, { text: e.nombre, value: e.estadoID }];
        });
      }
    });


    return estados;
  };

  const getPaisesOptions = () => {
    let salida = [];
    paises.map((p) => {
      salida = [...salida, { text: p.nombre, value: p.paisID }];
    });
    return salida;
  };
  return { getPaisesOptions, getEstadosOptions };
}
