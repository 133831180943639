import { useDispatch, useSelector } from "react-redux";

import InputSinNumber from "../../../components/input/InputSinNumber";
import { changeCotizacion } from "../../../context/actions/cotizacionesActions";
import { setSeccionModificada } from "../../../context/actions/stepActions";

import ColOpcion from "../Calculos/renglonesCostos/ColOpcion";
import ColTitulo from "../Calculos/renglonesCostos/ColTitulo";
import RowCosto from "../Calculos/renglonesCostos/RowCosto";

export default function UtilidadPorcentaje() {
  const dispatch = useDispatch();
  const {
    subtotalOpcion1,
    utilidadPorcentajeOpcion1,
    utilidadPorcentajeOpcion2,
    utilidadPorcentajeOpcion3,
    utilidadPorcentajeOpcion4,
    utilidadPorcentajeOpcion5,
  } = useSelector((state) => state.cotizacionesReducer.cotizacion);

  const onChange = ({ target: { name, value } }) => {
    dispatch(changeCotizacion({ [name]: value }));
    dispatch(setSeccionModificada({ calculoUtilidad: true }));
  };

  return (
    <>
      <RowCosto>
        <ColTitulo>% Utilidad</ColTitulo>
        <ColOpcion>
          <InputSinNumber
            onChange={onChange}
            name="utilidadPorcentajeOpcion1"
            value={utilidadPorcentajeOpcion1}
          />
        </ColOpcion>
        <ColOpcion>
          <InputSinNumber
            onChange={onChange}
            name="utilidadPorcentajeOpcion2"
            value={utilidadPorcentajeOpcion2}
          />
        </ColOpcion>
        <ColOpcion>
          <InputSinNumber
            onChange={onChange}
            name="utilidadPorcentajeOpcion3"
            value={utilidadPorcentajeOpcion3}
          />
        </ColOpcion>
        <ColOpcion>
          <InputSinNumber
            onChange={onChange}
            name="utilidadPorcentajeOpcion4"
            value={utilidadPorcentajeOpcion4}
          />
        </ColOpcion>
        <ColOpcion>
          <InputSinNumber
            onChange={onChange}
            name="utilidadPorcentajeOpcion5"
            value={utilidadPorcentajeOpcion5}
          />
        </ColOpcion>
      </RowCosto>
    </>
  );
}
