import { useDispatch } from "react-redux";
import BotonSubmit from "../../components/Boton/BotonSubmit";
import { startPostArticulo } from "../../context/actions/articulosActions";

export default function SubmitCreate() {
  const dispatch = useDispatch();
  const handleClick = () => dispatch(startPostArticulo());
  return (
    <div style={{ marginTop: 20, marginBottom: 20 }}>
      <BotonSubmit
        onClick={handleClick}
        tituloBoton="Agregar Artículo"
      />
    </div>
  );
}
