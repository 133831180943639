import { Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import ColResponsive from "../../components/Col/ColResponsive";
import ColResponsiveDentro from "../../components/Col/ColResponsiveDentro";
import InputLabel from "../../components/input/InputLabel";
import InputLabelNumber from "../../components/input/InputLabelNumber";
import SelectLabel from "../../components/input/SelectLabel";
import SelectLabelAplicacion from "../../components/input/SelectLabelAplicacion";
import SelectLabelBool from "../../components/input/SelectLabelBool";
import SelectLabelSinFiltro from "../../components/input/SelectLabelSinFiltro";
import { changeCotizacion } from "../../context/actions/cotizacionesActions";
import { getClientesOptions } from "../../utils/getClientesOptions";
import { getNumberOptions } from "../../utils/getNumberOptions";

export default function FormaCliente() {
  const dispatch = useDispatch();
  const {
    clienteID,
    marca,
    representante,
    noParte,
    tamanoTerminado,
    noTintas,
    requiereSeleccionColor,
    noColoresPMS,
    porcentajeImpresion,
    posicion,
    requiereCorteIndividual,
    aplicacion,
    noCambioPlacas,
    noCambiosColor,
    piezasPorRollo,
    requierePressProof,
    requiereInformacionVariable,
    requiereBarnizMini,
    requiereBarnizEspecial,
    requiereBarnizEspecialCobertura,
    requiereFoilCobertura,
  } = useSelector((state) => state.cotizacionesReducer.cotizacion);
  const { clientes } = useSelector((state) => state.clientesReducer);

  const clientesOptions = getClientesOptions(clientes);

  const onChange = ({ target: { name, value } }) => {
    dispatch(changeCotizacion({ [name]: value }));
  };

  const tintasOptions = getNumberOptions(10);
  const noColoresPMSOptions = getNumberOptions(10);
  const posicionOptions = getNumberOptions(6);
  const noCambioPlacasOptions = getNumberOptions(4);
  const noCambiosColorOptions = getNumberOptions(4);

  return (
    <>
      <Row gutter={[10, 10]} style={{ marginBottom: 10 }}>
        <ColResponsiveDentro>
          <SelectLabel
            name="clienteID"
            value={clienteID}
            onChange={onChange}
            label="Cliente"
            options={clientesOptions}
          />
        </ColResponsiveDentro>

        <ColResponsiveDentro>
          <InputLabel
            name="marca"
            value={marca}
            onChange={onChange}
            label="Marca"
          />
        </ColResponsiveDentro>
        <ColResponsiveDentro>
          <InputLabel
            name="representante"
            value={representante}
            onChange={onChange}
            label="Representante"
          />
        </ColResponsiveDentro>
        <ColResponsiveDentro>
          <InputLabel
            name="noParte"
            value={noParte}
            onChange={onChange}
            label="No. de parte"
          />
        </ColResponsiveDentro>
      </Row>
      <Row gutter={[10, 10]} style={{ marginBottom: 10 }}>
        <ColResponsiveDentro>
          <InputLabel
            name="tamanoTerminado"
            value={tamanoTerminado}
            onChange={onChange}
            label="Tamaño terminado"
          />
        </ColResponsiveDentro>

        <ColResponsiveDentro>
          <SelectLabelSinFiltro
            name="noTintas"
            value={noTintas}
            onChange={onChange}
            label="No. de tintas"
            options={tintasOptions}
          />
        </ColResponsiveDentro>
        <ColResponsiveDentro>
          <SelectLabelBool
            name="requiereSeleccionColor"
            value={requiereSeleccionColor}
            onChange={onChange}
            label="Selección de color"
          />
        </ColResponsiveDentro>
        <ColResponsiveDentro>
          <SelectLabelSinFiltro
            name="noColoresPMS"
            value={noColoresPMS}
            onChange={onChange}
            label="No. colores PMS"
            options={noColoresPMSOptions}
          />
        </ColResponsiveDentro>
        <ColResponsiveDentro>
          <InputLabelNumber
            name="porcentajeImpresion"
            value={porcentajeImpresion}
            onChange={onChange}
            label="% impresión"
          />
        </ColResponsiveDentro>
        <ColResponsiveDentro>
          <SelectLabel
            name="posicion"
            value={posicion}
            onChange={onChange}
            label="Posición"
            options={posicionOptions}
          />
        </ColResponsiveDentro>
        <ColResponsiveDentro>
          <SelectLabelBool
            name="requiereCorteIndividual"
            value={requiereCorteIndividual}
            onChange={onChange}
            label="Corte individual"
          />
        </ColResponsiveDentro>

        <ColResponsiveDentro>
          <SelectLabelAplicacion
            name="aplicacion"
            value={aplicacion}
            onChange={onChange}
            label="Aplicación"
          />
        </ColResponsiveDentro>

        <ColResponsiveDentro>
          <InputLabelNumber
            name="noCambioPlacas"
            value={noCambioPlacas}
            onChange={onChange}
            label="No. cambio placas"
          />
        </ColResponsiveDentro>

        <ColResponsiveDentro>
          <InputLabelNumber
            name="noCambiosColor"
            value={noCambiosColor}
            onChange={onChange}
            label="No. cambios color"
          />
        </ColResponsiveDentro>

        <ColResponsiveDentro>
          <InputLabelNumber
            name="piezasPorRollo"
            value={piezasPorRollo}
            onChange={onChange}
            label="Piezas por rollo"
          />
        </ColResponsiveDentro>

        <ColResponsiveDentro>
          <SelectLabelBool
            name="requierePressProof"
            value={requierePressProof}
            onChange={onChange}
            label="Press proof"
          />
        </ColResponsiveDentro>

        <ColResponsiveDentro>
          <SelectLabelBool
            name="requiereInformacionVariable"
            value={requiereInformacionVariable}
            onChange={onChange}
            label="Información variable"
          />
        </ColResponsiveDentro>

        

        <ColResponsiveDentro>
          <SelectLabelBool
            name="requiereBarnizMini"
            value={requiereBarnizMini}
            onChange={onChange}
            label="Requiere barniz MINI"
          />
        </ColResponsiveDentro>

        <ColResponsiveDentro>
          <SelectLabelBool
            name="requiereBarnizEspecial"
            value={requiereBarnizEspecial}
            onChange={onChange}
            label="Requiere barniz especial"
          />
        </ColResponsiveDentro>
       
        <ColResponsiveDentro>
          <InputLabelNumber
            name="requiereBarnizEspecialCobertura"
            value={requiereBarnizEspecialCobertura}
            onChange={onChange}
            label="% barniz especial"
          />
        </ColResponsiveDentro>

      </Row>
    </>
  );
}
