import { useSelector } from "react-redux";
import InputSinNumber from "../../../../components/input/InputSinNumber";

import ColOpcion from "./ColOpcion";
import ColTitulo from "./ColTitulo";
import RowCosto from "./RowCosto";

export default function TintasSemirotativa() {
  const {
    tintasSemirotativaOpcion1,
    tintasSemirotativaOpcion2,
    tintasSemirotativaOpcion3,
    tintasSemirotativaOpcion4,
    tintasSemirotativaOpcion5,
  } = useSelector((state) => state.cotizacionesReducer.cotizacion);

  return (
    <RowCosto>
      <ColTitulo>Tintas semirotativa</ColTitulo>
      <ColOpcion>
        <InputSinNumber value={tintasSemirotativaOpcion1} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={tintasSemirotativaOpcion2} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={tintasSemirotativaOpcion3} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={tintasSemirotativaOpcion4} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={tintasSemirotativaOpcion5} disabled />
      </ColOpcion>
    </RowCosto>
  );
}
