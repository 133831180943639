import { Button, Spin } from "antd";

export default function BotonCard({
  tituloBoton,
  onClick,
  loading,
  icon,
  style,
}) {
  if (loading) return <Spin size="small" />;
  return (
    <Button
      onClick={onClick}
      loading={loading}
      size="small"
      type="text"
      className="botonCard"
      icon={icon}
      style={style}
    >
      {tituloBoton}
    </Button>
  );
}
