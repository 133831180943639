export const clienteInitial = {
  nombre: "",
  apellidoPaterno: "",
  apellidoMaterno: "",
  rfc: "",
  emailFacturacion: "",
  regimenFiscal: "",
  terminosPago: "",
  cfdi: "",
  Calle: "",
  noExt: "",
  noInt: "",
  colonia: "",
  poblacion: "",
  estado: "",
  cp: "",
  pais: "",
  limiteCredito: 0,
  moneda: "",
  telefono: "",
  zonaImpuestos: "",
  contactoTelefono: "",
  contactoNombre: "",
  contactoEmail: "",
  sector: "",
  notas: "",
};

export const clientesInitial = {
  cliente: clienteInitial,
  cotizacionesByCliente:[],
  clientes: [],
};
