import React from "react";

export default function DatosArticulo({ articulo }) {
  if (articulo === undefined || articulo === null) return <></>;
  return (
    <div style={{ fontSize: 10, marginTop: 5 }}>
      <div>
        <b>Nombre:</b> {articulo.nombre}
      </div>
      <div>
        <b>Descripción:</b> {articulo.descripcion}
      </div>
      <div>
        <b>Costo:</b> {articulo.costo}
      </div>
      <div>
        <b>Proveedor:</b> {articulo.proveedor}
      </div>
    </div>
  );
}
