import { loadingInitial } from "../initial/loadingInitial";
import { types } from "../types/types";

export const loadingReducer = (state = loadingInitial, action) => {
  switch (action.type) {
    case types.setLoadingStatus:
      return {
        ...state,
        downloadingData: action.payload,
      };

    case types.setCalculing:
      return {
        ...state,
        calculing: action.payload,
      };

    case types.setLoading:
      return {
        ...state,
        ...action.payload
      };

    default:
      return state;
  }
};
