import { useSelector } from "react-redux";
import ElementosList2 from "./_ElementosLst2";

export default function ElementosLst() {
  const { catalogos, categoriaSelected } = useSelector(
    (state) => state.catalogosReducer
  );
  const items = catalogos.filter((cat) => cat.catalogoID === categoriaSelected);

  return <ElementosList2 items={items} />;
}
