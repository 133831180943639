export function getCatalogo(array, tipo) {
  let salida = [];
  array.map((cat) => {
    if (cat.nombre === tipo) {
      cat.catalogoItems.map((item) => {
        salida = [...salida, { value: item.catalogoItemID, text: item.nombre }];
      });
    }
  });

  return salida;
}

export function getCatalogoText(array, tipo) {
  let salida = [{ value: "", text: "" }];
  array.map((cat) => {
    if (cat.nombre === tipo) {
      cat.catalogoItems.map((item) => {
        salida = [...salida, { value: item.nombre, text: item.nombre }];
      });
    }
  });

  return salida;
}
