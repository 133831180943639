import { useHistory } from "react-router";
import useLoadArticulos from "../../hooks/useLoadArticulos";
import CardPage from "../../layout/CardPage";
import Lista from "./Lista";

export default function Articulos() {
  const history = useHistory();
  useLoadArticulos();
  return (
    <CardPage
      titulo="Artículos"
      tituloBoton="Agregar Articulo"
      accionBoton={() => history.push("/articulos/create")}
    >
      <Lista />
    </CardPage>
  );
}
