import { types } from "../types/types";

export const setLoadingStatus = (payload) => ({
  type: types.setLoadingStatus,
  payload,
});

export const setCalculing = (payload) => ({
  type: types.setCalculing,
  payload,
});

export const setLoading = (payload) => ({
  type: types.setLoading,
  payload,
});
