import { Select } from "antd";
const { Option } = Select;

export const EstatusSelect = ({ value, onChange, style }) => {
  return (
    <Select
      value={value}
      style={{ width: "100%", ...style }}
      onChange={onChange}
      placeholder="Estatus"
    >
      <Option value={null}>Todos</Option>
      <Option value={1}>Capturando</Option>
      <Option value={2}>Cotización</Option>
      <Option value={3}>Autorizado</Option>
      <Option value={4}>Producción</Option>
      <Option value={5}>Cerrado</Option>
    </Select>
  );
};
