import { Col } from "antd";
import { widthMD } from "../../../../constants/cons";
import useWindowDimensions from "../../../../hooks/useWindowDimensions";

export default function ColTitulo({ children, style }) {
  const { width } = useWindowDimensions();
  return (
    <Col
      xs={24}
      md={12}
      xl={8}
      style={{ textAlign: width >= widthMD ? "right" : "center", ...style }}
    >
      {children}
    </Col>
  );
}
