import { useSelector } from "react-redux";
import InputSinNumber from "../../../../components/input/InputSinNumber";
import ColOpcion from "./ColOpcion";
import ColTitulo from "./ColTitulo";
import RowCosto from "./RowCosto";

export default function TiempoCambioColor() {
  const {
    tiempoMaquinaCambioColorOpcion1,
    tiempoMaquinaCambioColorOpcion2,
    tiempoMaquinaCambioColorOpcion3,
    tiempoMaquinaCambioColorOpcion4,
    tiempoMaquinaCambioColorOpcion5,
  } = useSelector((state) => state.cotizacionesReducer.cotizacion);

  return (
    <RowCosto>
      <ColTitulo>Tiempo cambio color (mins)</ColTitulo>
      <ColOpcion>
        <InputSinNumber value={tiempoMaquinaCambioColorOpcion1} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={tiempoMaquinaCambioColorOpcion2} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={tiempoMaquinaCambioColorOpcion3} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={tiempoMaquinaCambioColorOpcion4} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={tiempoMaquinaCambioColorOpcion5} disabled />
      </ColOpcion>
    </RowCosto>
  );
}
