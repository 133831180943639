import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { cleanProveedor, startLoadProveedores } from "../context/actions/proveedoresActions";

export default function useLoadProveedores() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(startLoadProveedores());
    dispatch(cleanProveedor());
  }, []);
}
