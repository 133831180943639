import { Select } from "antd";
const { Option } = Select;

export const AniosSelect = ({ value, onChange, style }) => {
  let anios = [];
  const ahora = new Date().getFullYear();
  for (let i = 2022; i <= ahora; i++) {
    anios = [...anios, i];
  }

  return (
    <Select
      
      value={value}
      style={{ width: "100%", ...style }}
      onChange={onChange}
    >
      {anios.map((a, i) => (
        <Option key={i} value={a}>
          {a}
        </Option>
      ))}
    </Select>
  );
};
