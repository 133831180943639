export const proveedorInitial = {
  nombre: "",
  calleNo: "",
  poblacion: "",
  estado: "",
  pais: "",
  CP: "",
  contactoTelefono: "",
  contactoNombre: "",
  contactoEmail: "",
  notas: "",
};

export const proveedoresInitial = {
  proveedor: proveedorInitial,
  proveedores: [],
};
