import { Col, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import InputSinNumber from "../../components/input/InputSinNumber";
import { changeCotizacion } from "../../context/actions/cotizacionesActions";
import ImgPosicion from "../../assets/Posicion.PNG";

export default function FormaMedidas() {
  const dispatch = useDispatch();

  const { acrossMedida, acrossGap, acrossRadio, downMedida, downGap } =
    useSelector((state) => state.cotizacionesReducer.cotizacion);

  const onChange = ({ target: { name, value } }) => {
    dispatch(changeCotizacion({ [name]: value }));
  };

  return (
    <>
      <Row gutter={[10, 10]} style={{ marginBottom: 10 }}>
        <Col span={3}></Col>
        <Col span={7} className="tituloGrid">
          Medida
        </Col>
        <Col span={7} className="tituloGrid">
          Gap
        </Col>
        <Col span={7} className="tituloGrid">
          Radio
        </Col>
      </Row>
      <Row gutter={[10, 10]} style={{ marginBottom: 10 }}>
        <Col span={3} className="labelGrid">
          Across
        </Col>
        <Col span={7}>
          <InputSinNumber
            name="acrossMedida"
            value={acrossMedida}
            onChange={onChange}
          />
        </Col>
        <Col span={7}>
          <InputSinNumber name="acrossGap" value={acrossGap} onChange={onChange} />
        </Col>
        <Col span={7}>
          <InputSinNumber
            name="acrossRadio"
            value={acrossRadio}
            onChange={onChange}
          />
        </Col>
      </Row>
      <Row gutter={[10, 10]} style={{ marginBottom: 10 }}>
        <Col span={3} className="labelGrid">
          Down
        </Col>
        <Col span={7}>
          <InputSinNumber name="downMedida" value={downMedida} onChange={onChange} />
        </Col>
        <Col span={7}>
          <InputSinNumber name="downGap" value={downGap} onChange={onChange} />
        </Col>
        <Col span={7}></Col>
      </Row>
      <Row>
        <Col span={24} style={{textAlign: 'center'}}>
          <img src={ImgPosicion} className="imgPosicion" />
          <div className="textoNaranja">Posiciones</div>
        </Col>
      </Row>
    </>
  );
}
