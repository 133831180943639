import { types } from "../types/types";

export const historialReducer = (state = [], action) => {
  switch (action.type) {
    case types.storeHistorial:
      return [...action.payload];

    default:
      return state;
  }
};
