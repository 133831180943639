import { useSelector } from "react-redux";
import InputSinNumber from "../../../../components/input/InputSinNumber";

import ColOpcion from "./ColOpcion";
import ColTitulo from "./ColTitulo";
import RowCosto from "./RowCosto";

export default function CostoLaminado() {

  const {
     costoLaminadoOpcion1,
    costoLaminadoOpcion2,
    costoLaminadoOpcion3,
    costoLaminadoOpcion4,
    costoLaminadoOpcion5,
  } = useSelector((state) => state.cotizacionesReducer.cotizacion);

 
  return (
    <RowCosto>
      <ColTitulo>Costo Laminado</ColTitulo>
      <ColOpcion>
        <InputSinNumber value={costoLaminadoOpcion1} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={costoLaminadoOpcion2} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={costoLaminadoOpcion3} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={costoLaminadoOpcion4} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={costoLaminadoOpcion5} disabled />
      </ColOpcion>
    </RowCosto>
  );
}
