import { Col, Row } from "antd";

export default function PresentaComentarios({ comentarios }) {
  //
  if (!comentarios || comentarios.length === 0) return <div></div>;

  return (
    <div className="historialVentana">
      {comentarios.map((c, i) => (
        <Row gutter={[10,3]} key={i} >
          <Col style={{color: 'orange'}}>{c.fecha}</Col>
          <Col>{c.nombre}</Col>
          <Col>{c.comentarios}</Col>
        </Row>
      ))}
    </div>
  );
}
