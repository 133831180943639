import { clienteInitial, clientesInitial } from "../initial/clientesInitial";
import { types } from "../types/types";

export const clientesReducer = (state = clientesInitial, action) => {
  switch (action.type) {
    case types.storeCotizacionesByCliente:
      return {
        ...state,
        cotizacionesByCliente: action.payload,
      };
    case types.storeClientes:
      return {
        ...state,
        clientes: action.payload,
      };

    case types.changeCliente:
      return {
        ...state,
        cliente: {
          ...state.cliente,
          ...action.payload,
        },
      };

    case types.storeClienteFUll:
      return {
        ...state,
        cliente: action.payload,
      };

    case types.cleanCliente:
      return {
        ...state,
        cliente: clienteInitial,
      };

    default:
      return state;
  }
};
