import { types } from "../types/types";

export const validacionFases = (fase) => {
  return (dispatch, getState) => {
    let salida = true;

    if (fase === "fase1") salida = dispatch(ValidacionFase1());
    else if (fase === "fase2") salida = dispatch(ValidacionFase2());
    else if (fase === "fase3") salida = dispatch(ValidacionFase3());
    //else if (fase === "fase4") salida = true;

    // else if (fase === "fase4") url = URL_COTIZACIONES_FASE4;
    // else if (fase === "faseFinal") url = URL_COTIZACIONES_FASE_FINAL;

    return salida;
  };
};

const ValidacionFase1 = () => {
  return (dispatch, getState) => {
    let errores = 0;
    const { cotizacion } = getState().cotizacionesReducer;

    // Cliente
    errores += dispatch(vacios("clienteID", cotizacion.clienteID));
    errores += dispatch(vacios("marca", cotizacion.marca));
    errores += dispatch(diagonal("marca", cotizacion.marca));
    errores += dispatch(vacios("representante", cotizacion.representante));
    errores += dispatch(vacios("noParte", cotizacion.noParte));
    errores += dispatch(vacios("tamanoTerminado", cotizacion.tamanoTerminado));
    //errores += dispatch(sinCeroVacio("noTintas", cotizacion.noTintas));
    errores += dispatch(
      vacios("requiereSeleccionColor", cotizacion.requiereSeleccionColor)
    );
    errores += dispatch(
      porcentaje("porcentajeImpresion", cotizacion.porcentajeImpresion)
    );
    errores += dispatch(
      porcentaje(
        "requiereBarnizEspecialCobertura",
        cotizacion.requiereBarnizEspecialCobertura
      )
    );
    errores += dispatch(
      porcentaje("requiereFoilCobertura", cotizacion.requiereFoilCobertura)
    );
    errores += dispatch(
      vacios("requiereCorteIndividual", cotizacion.requiereCorteIndividual)
    );
    errores += dispatch(vacios("noCambioPlacas", cotizacion.noCambioPlacas));
    errores += dispatch(vacios("noCambiosColor", cotizacion.noCambiosColor));
    errores += dispatch(
      sinCeroVacio("piezasPorRollo", cotizacion.piezasPorRollo)
    );
    errores += dispatch(
      vacios("requierePressProof", cotizacion.requierePressProof)
    );
    errores += dispatch(
      vacios(
        "requiereInformacionVariable",
        cotizacion.requiereInformacionVariable
      )
    );
    errores += dispatch(vacios("lab", cotizacion.lab));
    errores += dispatch(vacios("tiempoEntrega", cotizacion.tiempoEntrega));
    errores += dispatch(vacios("presentacion", cotizacion.presentacion));
    errores += dispatch(vacios("aplicacion", cotizacion.aplicacion));
    errores += dispatch(vacios("grabado", cotizacion.grabado));

    //Medidas
    errores += dispatch(vacios("posicion", cotizacion.posicion));
    // errores += dispatch(ceroVacio("dadoMedidas", cotizacion.dadoMedidas));

    return errores === 0;
  };
};

const ValidacionFase2 = () => {
  return (dispatch, getState) => {
    let errores = 0;
    const { cotizacion } = getState().cotizacionesReducer;

    errores += dispatch(ceroVacio("opcion1", cotizacion.opcion1));
    errores += dispatch(ceroVacio("opcion2", cotizacion.opcion2));
    errores += dispatch(ceroVacio("opcion3", cotizacion.opcion3));
    errores += dispatch(ceroVacio("opcion4", cotizacion.opcion4));
    errores += dispatch(ceroVacio("opcion5", cotizacion.opcion5));

    return errores === 0;
  };
};

const ValidacionFase3 = () => {
  return (dispatch, getState) => {
    let errores = 0;
    const { cotizacion } = getState().cotizacionesReducer;

    errores += dispatch(sinCeroVacio("anchoBanda", cotizacion.anchoBanda));
    errores += dispatch(sinCeroVacio("piezasAcross", cotizacion.piezasAcross));
    errores += dispatch(ceroVacio("tipoCambio", cotizacion.tipoCambio));
    errores += dispatch(vacios("moneda", cotizacion.moneda));
    errores += dispatch(vacios("costoDadoSuaje", cotizacion.costoDadoSuaje));
    errores += dispatch(
      vacios(
        "costoDadoSuajeDepreciacion",
        cotizacion.costoDadoSuajeDepreciacion
      )
    );

    errores += dispatch(
      vacios("costoDadoGrabado", cotizacion.costoDadoGrabado)
    );
    errores += dispatch(vacios("costoDadoFoil", cotizacion.costoDadoFoil));
    errores += dispatch(vacios("sistemaCotizado", cotizacion.sistemaCotizado));

    errores += dispatch(
      vacios("noMaquinasAdicionales", cotizacion.noMaquinasAdicionales)
    );
    errores += dispatch(
      porcentaje(
        "porcentajeBaseBlancaDigital",
        cotizacion.porcentajeBaseBlancaDigital
      )
    );

    return errores === 0;
  };
};

const ValidacionFase4 = () => {
  return (dispatch, getState) => {};
};

const porcentaje = (name, value) => {
  return (dispatch) => {
    const prop = `${name}Err`;
    if (value === undefined || value === null || value === "") {
      const node = { [prop]: true };
      dispatch(setError(node));
      return 1;
    } else if (Number(value) < 0 || Number(value) > 100) {
      const node = { [prop]: true };
      dispatch(setError(node));
      return 1;
    } else {
      const node = { [prop]: false };
      dispatch(setError(node));
      return 0;
    }
  };
};

const diagonal = (name, value) => {
  return (dispatch) => {
    const prop = `${name}Err`;

    if (
      value.indexOf("/") > -1 ||
      value.indexOf("\\") > -1 ||
      value.indexOf("'") > -1 ||
      value.indexOf('"') > -1
    ) {
      const node = { [prop]: true };
      dispatch(setError(node));
      return 1;
    } else {
      const node = { [prop]: false };
      dispatch(setError(node));
      return 0;
    }
  };
};

const vacios = (name, value) => {
  return (dispatch) => {
    //  console.log({ name, value });
    const prop = `${name}Err`;
    if (value === undefined || value === null || value === "") {
      const node = { [prop]: true };
      dispatch(setError(node));
      return 1;
    } else {
      const node = { [prop]: false };
      dispatch(setError(node));
      return 0;
    }
  };
};

const ceroVacio = (name, value) => {
  return (dispatch) => {
    const prop = `${name}Err`;

    if (value === undefined || value === null || value === "") {
      const node = { [prop]: true };
      dispatch(setError(node));
      return 1;
    } else if (Number(value) < 0) {
      const node = { [prop]: true };
      dispatch(setError(node));
      return 1;
    } else {
      const node = { [prop]: false };
      dispatch(setError(node));
      return 0;
    }
  };
};

const sinCeroVacio = (name, value) => {
  return (dispatch) => {
    const prop = `${name}Err`;
    if (value === undefined || value === null || value === "") {
      const node = { [prop]: true };
      dispatch(setError(node));
      return 1;
    } else if (Number(value) <= 0) {
      const node = { [prop]: true };
      dispatch(setError(node));
      return 1;
    } else {
      const node = { [prop]: false };
      dispatch(setError(node));
      return 0;
    }
  };
};

const setError = (payload) => ({
  type: types.setError,
  payload,
});
