import { Button, Divider } from "antd";
import React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

export const ArticulosEnCotizaciones = () => {
  const history = useHistory();
  const { total, cotizaciones } = useSelector(
    (state) => state.articulosReducer.articulosEnCotizacion
  );

  const cambia = (cotizacionID) =>
    history.push(`/cotizaciones/details/${cotizacionID}`);

  //
  return (
    <>
      <div>Usado en cotizaciones: {total}</div>
      <div>
        Algunas cotizaciones:{" "}
        {cotizaciones.map((a) => (
          <span className="textoClick" danger onClick={() => cambia(a)}>
            {a}{" "}
          </span>
        ))}
      </div>
    </>
  );
};
