import { Col, Row } from "antd";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import DatePickerLabel from "../../components/input/DatePickerLabel";
import InputLabel from "../../components/input/InputLabel";
import SelectLabel from "../../components/input/SelectLabel";
import TextareaLabel from "../../components/input/TextareaLabel";
import { changeArticulo } from "../../context/actions/articulosActions";
import { getCatalogo, getCatalogoText } from "../../utils/getCatalogo";
import { getProveedoresOptions } from "../../utils/getProveedoresOptions";
import { parseDate2 } from "../../utils/parseDate2";

export default function _FormaArticulo() {
  const dispatch = useDispatch();
  const { catalogos } = useSelector((state) => state.catalogosReducer);
  const { proveedores } = useSelector((state) => state.proveedoresReducer);

  const { articulo } = useSelector((state) => state.articulosReducer);
  const {
    nombre,
    especificacion,
    fechaAlta,
    descripcion,
    grupo,
    categoria,
    estatus,
    proveedorID,
    costo,
    unidad,
  } = articulo;

  ////////
  const onChange = ({ target: { name, value } }) => {
    console.log(value)
    dispatch(changeArticulo({ [name]: value }));
  };

  const grupoOptions = getCatalogoText(catalogos, "Grupo");
  const categoriaOptions = getCatalogoText(catalogos, "Categoría");
  const estatusOptions = getCatalogoText(catalogos, "Estatus");
  const unidadOptions = getCatalogoText(catalogos, "Unidad");
  const proveedorOptions = getProveedoresOptions(proveedores);

  return (
    <>
      <Row gutter={[10, 10]}>
        <Col xs={24} md={8}>
          <InputLabel
            name="nombre"
            value={nombre}
            onChange={onChange}
            label="Nombre"
          />
        </Col>
        <Col xs={24} md={8}>
          <InputLabel
            name="especificacion"
            value={especificacion}
            onChange={onChange}
            label="Especificación"
          />
        </Col>

        <Col xs={24} md={8}>
          <DatePickerLabel
            name="fechaAlta"
            value={moment(fechaAlta)}
            
            onChange={onChange}
            label="Fecha de alta"
            options={[]}
          />
    
        </Col>
        <Col xs={24} md={16}>
          <TextareaLabel
            name="descripcion"
            value={descripcion}
            onChange={onChange}
            rows={3}
            label="Descripción"
          />
        </Col>
      </Row>
      <Row gutter={[10, 10]}>
        <Col xs={24} md={8}>
          <SelectLabel
            name="grupo"
            value={grupo}
            onChange={onChange}
            label="Grupo"
            options={grupoOptions}
          />
        </Col>
        <Col xs={24} md={8}>
          <SelectLabel
            name="categoria"
            value={categoria}
            onChange={onChange}
            label="Categoría"
            options={categoriaOptions}
          />
        </Col>
        <Col xs={24} md={8}>
          <SelectLabel
            name="estatus"
            value={estatus}
            onChange={onChange}
            label="Estatus"
            options={estatusOptions}
          />
        </Col>
        <Col xs={24} md={8}>
          <SelectLabel
            name="proveedorID"
            value={proveedorID}
            onChange={onChange}
            label="Proveedor"
            options={proveedorOptions}
          />
        </Col>
        <Col xs={24} md={8}>
          <InputLabel
            name="costo"
            value={costo}
            onChange={onChange}
            label="Costo"
          />
        </Col>
        <Col xs={24} md={8}>
          <SelectLabel
            name="unidad"
            value={unidad}
            onChange={onChange}
            label="Unidad"
            options={unidadOptions}
          />
        </Col>
      </Row>
    </>
  );
}
