import { useHistory } from "react-router";
import CardPage from "../../layout/CardPage";
import CategoriasAdd from "./_CategoriasAdd";
import CategoriasLst from "./_CategoriasLst";

export default function _Categorias() {
  const history = useHistory();

  return <CardPage titulo="Categorías">
 
      <CategoriasLst />
  </CardPage>;
}
