import { Button, Divider, Row } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { startCreatePDF } from "../../../context/actions/cotizacionesActions";

export default function Acciones() {
  const dispatch = useDispatch();
  const { cotizacionID } = useParams();
  const { cotizacionEstatusID } = useSelector(
    (state) => state.cotizacionesReducer.cotizacion
  );

  const handleClick = (tipo) => {
    dispatch(startCreatePDF(cotizacionID, tipo));
  };

  return (
    <div style={{ paddingTop: 20 }}>
      <Row>
        {Number(cotizacionEstatusID) === 2 && (
          <>
            <Button onClick={() => handleClick("ES_USD")} type="text">
              Crear Cot ES-USD
            </Button>
            <Button onClick={() => handleClick("ES_MXN")} type="text">
              Crear Cot ES-MXN
            </Button>
            <Button onClick={() => handleClick("IN_USD")} type="text">
              Crear Cot IN-USD
            </Button>
          </>
        )}

        {Number(cotizacionEstatusID) === 3 && (
          <Button onClick={() => handleClick("OPPD")} type="text">
            Crear OPPD
          </Button>
        )}
        {Number(cotizacionEstatusID) === 4 && (
          <Button onClick={() => handleClick("OP")} type="text">
            Crear OP
          </Button>
        )}
      </Row>
    </div>
  );
}
