import { constantesInitial } from "../initial/constantesInitial";
import { types } from "../types/types";

export const constantesReducer = (state = constantesInitial, action) => {
  switch (action.type) {
    case types.storeConstantes:
      return { ...action.payload };

    case types.changeConstantes:
      return { ...state, ...action.payload };

    default:
      return state;
  }
};
