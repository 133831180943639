import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import DropDownTextNum from "../../../components/DropdownCatalogo/DropDownTextNum";
import DropDownTextNumNullable from "../../../components/DropdownCatalogo/DropDownTextNumNullable";
import { grupos } from "../../../constants/cons";
import {
  changeCotizacion,
  selectTermiandos,
} from "../../../context/actions/cotizacionesActions";
import { setSeccionModificada } from "../../../context/actions/stepActions";
import { setTerminado } from "../../../context/actions/terminadosActions";
import useGetArticulosByGrupo from "../../../hooks/useGetArticulosByGrupo";
import escogeArticulo from "../../../utils/escogeArticulo";
import DatosArticulo from "./_DatosArticulo";

export default function Barniz() {
  const dispatch = useDispatch();
  const { barniz } = useSelector((state) => state.terminadosReducer);
  const { articulosGrupo } = useGetArticulosByGrupo(grupos.BARNIZ);
  const { barnizID } = useSelector(
    (state) => state.cotizacionesReducer.cotizacion
  );

  //
  const onChange = async ({ target: { name, value } }) => {
    const barniz = await escogeArticulo(articulosGrupo, value);

    dispatch(changeCotizacion({ [name]: value }));
    dispatch(changeCotizacion({ costoMSIBarniz: barniz.costo }));
    dispatch(setTerminado({ barniz }));
    dispatch(setSeccionModificada({ terminados: true }));
  };

  ///
  return (
    <>
      <DropDownTextNumNullable
        label="Barniz"
        value={barnizID}
        name="barnizID"
        grupo={grupos.BARNIZ}
        onChange={onChange}
      />
      <DatosArticulo articulo={barniz} />
    </>
  );
}
