export const constantesInitial = {
  constantesID: 0,
  ipH_EPSON: 0,
  costO_MAQUINA_HORA_IMPRESION: 0,
  costO_MAQUINA_HORA_ACABADO: 0,
  costO_MAQUINA_HORA_CORTE: 0,
  velocidaD_CORTE: 0,
  duracioN_DADO_SUAJE: 0,
  ipH_MAQUINAS_ACABADO: 0,
  costO_SERVICIO_EPSON: 0,
  costO_SETUP_DIGITAL: 0,
  costO_SETUP_CONVENCIONAL: 0,
  costO_SETUP_ACABADOS: 0,
  cargO_HORA_USO_PRIMERA: 0,
  costO_TIMPO_MAQUINA_PARO_CAMBIO_COLOR:     0,
  costO_TIMPO_MAQUINA_CAMBIO_PLACAS: 0,
  cargO_INFORMACION_VARIABLE: 0,
  costO_UNITARIO_PREPARACION_ARCHIVOS: 0,
  costO_PLACA_OFFSET: 0,
  costO_NEGATIVO: 0,
  costO_PLACA_BARNIZ: 0,
  costO_MSI_TINTA_SEMIROTATIVA: 0,
  costO_MSI_TINTA_SERIGRAFIA: 0,
  costO_TONER_CMYK_MSI: 0,
  costO_TONER_BLANCO_MSI: 0,
};
