import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { startLoadCatalogos } from "../context/actions/catalogosActions";

export default function useLoadCatalogos() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(startLoadCatalogos());
  }, []);
}
