import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { cleanArticulo, startLoadArticulos } from "../context/actions/articulosActions";
import { startLoadConstantes } from "../context/actions/constantesActions";
import { startLoadPaises } from "../context/actions/paisesActions";
import { startLoadProveedores } from "../context/actions/proveedoresActions";

export default function useLoadArticulos() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(startLoadArticulos());
    dispatch(startLoadConstantes());
    dispatch(startLoadProveedores());
    dispatch(startLoadPaises());
    dispatch(cleanArticulo());
  }, []);
}
