import { Input } from "antd";

export default function InputSin({ value, placeholder, onChange, name }) {

  
  return (
    <Input
      className="input"
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      name={name}
    />
  );
}
