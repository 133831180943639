import { Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import ColResponsiveDentro from "../../../components/Col/ColResponsiveDentro";
import ColResponsiveDoble from "../../../components/Col/ColResponsiveDoble";
import InputLabel from "../../../components/input/InputLabel";
import InputLabelNumber from "../../../components/input/InputLabelNumber";
import SelectLabel from "../../../components/input/SelectLabel";
import SelectLabelAplicacion from "../../../components/input/SelectLabelAplicacion";
import SelectLabelBool from "../../../components/input/SelectLabelBool";
import SelectLabelPresentacion from "../../../components/input/SelectLabelPresentacion";
import SelectLabelSinFiltro from "../../../components/input/SelectLabelSinFiltro";
import TextareaLabel from "../../../components/input/TextareaLabel";
import { changeCotizacion } from "../../../context/actions/cotizacionesActions";
import { setSeccionModificada } from "../../../context/actions/stepActions";
import { getClientesOptions } from "../../../utils/getClientesOptions";
import { getNumberOptions } from "../../../utils/getNumberOptions";

export default function FormaCliente() {
  const dispatch = useDispatch();
  const {
    clienteID,
    marca,
    representante,
    noParte,
    tamanoTerminado,
    noTintas,
    requiereSeleccionColor,
    noColoresPMS,
    porcentajeImpresion,
    requiereCorteIndividual,
    aplicacion,
    noCambioPlacas,
    noCambiosColor,
    piezasPorRollo,
    requierePressProof,
    requiereInformacionVariable,
    requiereBarnizMini,
    requiereBarnizEspecial,
    requiereBarnizEspecialCobertura,
    requiereLaminado,
    requiereFoil,
    requiereFoilCobertura,
    lab,
    tiempoEntrega,
    grabado,
    presentacion,
    direccionEnvio,
  } = useSelector((state) => state.cotizacionesReducer.cotizacion);

  const {
    clienteIDErr,
    marcaErr,
    representanteErr,
    noParteErr,
    tamanoTerminadoErr,
    noTintasErr,
    requiereSeleccionColorErr,
    porcentajeImpresionErr,
    requiereCorteIndividualErr,

    noCambioPlacasErr,
    noCambiosColorErr,
    piezasPorRolloErr,
    requierePressProofErr,
    requiereInformacionVariableErr,
    labErr,
    tiempoEntregaErr,
    presentacionErr,
    aplicacionErr,
    grabadoErr,
    requiereBarnizEspecialCoberturaErr,
    requiereFoilCoberturaErr,
  } = useSelector((state) => state.erroresReducer);

  const { clientes } = useSelector((state) => state.clientesReducer);

  const clientesOptions = getClientesOptions(clientes);

  const onChange = ({ target: { name, value } }) => {
    dispatch(changeCotizacion({ [name]: value }));
    dispatch(setSeccionModificada({ solicitudCotizacion: true }));
  };

  const tintasOptions = getNumberOptions(10);
  const noColoresPMSOptions = getNumberOptions(10);

  return (
    <>
      <Row gutter={[10, 10]} style={{ marginBottom: 10 }}>
        <ColResponsiveDentro>
          <SelectLabel
            error={clienteIDErr}
            name="clienteID"
            value={clienteID}
            onChange={onChange}
            label="Cliente"
            options={clientesOptions}
          />
        </ColResponsiveDentro>

        <ColResponsiveDentro>
          <InputLabel
            error={marcaErr}
            name="marca"
            value={marca}
            onChange={onChange}
            label="Marca"
          />
        </ColResponsiveDentro>
        <ColResponsiveDentro>
          <InputLabel
            error={representanteErr}
            name="representante"
            value={representante}
            onChange={onChange}
            label="Representante"
          />
        </ColResponsiveDentro>
        <ColResponsiveDentro>
          <InputLabel
            error={noParteErr}
            name="noParte"
            value={noParte}
            onChange={onChange}
            label="No. de parte"
          />
        </ColResponsiveDentro>
      </Row>
      <Row gutter={[10, 10]} style={{ marginBottom: 10 }}>
        <ColResponsiveDentro>
          <InputLabel
            error={tamanoTerminadoErr}
            name="tamanoTerminado"
            value={tamanoTerminado}
            onChange={onChange}
            label="Tamaño terminado"
          />
        </ColResponsiveDentro>

        <ColResponsiveDentro>
          <InputLabelNumber
            error={noTintasErr}
            name="noTintas"
            value={noTintas}
            onChange={onChange}
            label="No. de tintas"
            options={tintasOptions}
          />
        </ColResponsiveDentro>
        <ColResponsiveDentro>
          <SelectLabelBool
            error={requiereSeleccionColorErr}
            name="requiereSeleccionColor"
            value={requiereSeleccionColor}
            onChange={onChange}
            label="Selección de color"
          />
        </ColResponsiveDentro>
        <ColResponsiveDentro>
          <InputLabelNumber
            name="noColoresPMS"
            value={noColoresPMS}
            onChange={onChange}
            label="No. colores PMS"
            options={noColoresPMSOptions}
          />
        </ColResponsiveDentro>
        <ColResponsiveDentro>
          <InputLabelNumber
            error={porcentajeImpresionErr}
            name="porcentajeImpresion"
            value={porcentajeImpresion}
            onChange={onChange}
            label="% impresión"
          />
        </ColResponsiveDentro>

        <ColResponsiveDentro>
          <SelectLabelBool
            error={requiereCorteIndividualErr}
            name="requiereCorteIndividual"
            value={requiereCorteIndividual}
            onChange={onChange}
            label="Corte individual"
          />
        </ColResponsiveDentro>

        <ColResponsiveDentro>
          <SelectLabelAplicacion
            error={aplicacionErr}
            name="aplicacion"
            value={aplicacion}
            onChange={onChange}
            label="Aplicación"
          />
        </ColResponsiveDentro>

        <ColResponsiveDentro>
          <InputLabelNumber
            error={noCambioPlacasErr}
            name="noCambioPlacas"
            value={noCambioPlacas}
            onChange={onChange}
            label="No. cambio placas"
          />
        </ColResponsiveDentro>

        <ColResponsiveDentro>
          <InputLabelNumber
            error={noCambiosColorErr}
            name="noCambiosColor"
            value={noCambiosColor}
            onChange={onChange}
            label="No. cambios color"
          />
        </ColResponsiveDentro>

        <ColResponsiveDentro>
          <InputLabelNumber
            error={piezasPorRolloErr}
            name="piezasPorRollo"
            value={piezasPorRollo}
            onChange={onChange}
            label="Piezas por rollo"
          />
        </ColResponsiveDentro>

        <ColResponsiveDentro>
          <SelectLabelBool
            error={requierePressProofErr}
            name="requierePressProof"
            value={requierePressProof}
            onChange={onChange}
            label="Press proof"
          />
        </ColResponsiveDentro>

        <ColResponsiveDentro>
          <SelectLabelBool
            error={requiereInformacionVariableErr}
            name="requiereInformacionVariable"
            value={requiereInformacionVariable}
            onChange={onChange}
            label="Información variable"
          />
        </ColResponsiveDentro>

        <ColResponsiveDentro>
          <SelectLabelBool
            name="requiereBarnizMini"
            value={requiereBarnizMini}
            onChange={onChange}
            label="Requiere barniz MINI"
          />
        </ColResponsiveDentro>

        <ColResponsiveDentro>
          <SelectLabelBool
            name="requiereBarnizEspecial"
            value={requiereBarnizEspecial}
            onChange={onChange}
            label="Requiere barniz especial"
          />
        </ColResponsiveDentro>

        <ColResponsiveDentro>
          <InputLabelNumber
            error={requiereBarnizEspecialCoberturaErr}
            name="requiereBarnizEspecialCobertura"
            value={requiereBarnizEspecialCobertura}
            onChange={onChange}
            label="% barniz especial"
          />
        </ColResponsiveDentro>

        <ColResponsiveDentro>
          <SelectLabelBool
            name="requiereLaminado"
            value={requiereLaminado}
            onChange={onChange}
            label="Requiere Laminado"
          />
        </ColResponsiveDentro>
        <ColResponsiveDentro>
          <SelectLabelBool
            name="requiereFoil"
            value={requiereFoil}
            onChange={onChange}
            label="Requiere Foil"
          />
        </ColResponsiveDentro>
        <ColResponsiveDentro>
          <InputLabelNumber
            error={requiereFoilCoberturaErr}
            name="requiereFoilCobertura"
            value={requiereFoilCobertura}
            onChange={onChange}
            label="% Foil"
          />
        </ColResponsiveDentro>
        <ColResponsiveDentro>
          <SelectLabelBool
            error={grabadoErr}
            name="grabado"
            value={grabado}
            onChange={onChange}
            label="Grabado"
          />
        </ColResponsiveDentro>

        <ColResponsiveDentro>
          <InputLabel
            error={labErr}
            name="lab"
            value={lab}
            onChange={onChange}
            label="Lab"
          />
        </ColResponsiveDentro>

        <ColResponsiveDentro>
          <InputLabel
            error={tiempoEntregaErr}
            name="tiempoEntrega"
            value={tiempoEntrega}
            onChange={onChange}
            label="Tiempo de entrega"
          />
        </ColResponsiveDentro>
        <ColResponsiveDentro>
          <SelectLabelPresentacion
            error={presentacionErr}
            name="presentacion"
            value={presentacion}
            onChange={onChange}
            label="Presentación"
          />
        </ColResponsiveDentro>
        <ColResponsiveDoble>
          <TextareaLabel
            name="direccionEnvio"
            label="Direccio Envio"
            value={direccionEnvio}
            onChange={onChange}
            rows={4}
          />
        </ColResponsiveDoble>
      </Row>
    </>
  );
}
