import { useSelector } from "react-redux";
import InputSinNumber from "../../../../components/input/InputSinNumber";

import ColOpcion from "./ColOpcion";
import ColTitulo from "./ColTitulo";
import RowCosto from "./RowCosto";

export default function CostoSetupOffset() {

  const {

    costoSetupOffsetOpcion1,
    costoSetupOffsetOpcion2,
    costoSetupOffsetOpcion3,
    costoSetupOffsetOpcion4,
    costoSetupOffsetOpcion5,
  } = useSelector((state) => state.cotizacionesReducer.cotizacion);


  return (
    <RowCosto>
      <ColTitulo>Costo setup offeset</ColTitulo>
      <ColOpcion>
        <InputSinNumber value={costoSetupOffsetOpcion1} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={costoSetupOffsetOpcion2} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={costoSetupOffsetOpcion3} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={costoSetupOffsetOpcion4} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={costoSetupOffsetOpcion5} disabled />
      </ColOpcion>
    </RowCosto>
  );
}
