import { useSelector } from "react-redux";
import InputSinNumber from "../../../../components/input/InputSinNumber";
import ColOpcion from "./ColOpcion";
import ColTitulo from "./ColTitulo";
import RowCosto from "./RowCosto";

export default function CostoDados() {
  const {
    costoDadosOpcion1,
    costoDadosOpcion2,
    costoDadosOpcion3,
    costoDadosOpcion4,
    costoDadosOpcion5,
  } = useSelector((state) => state.cotizacionesReducer.cotizacion);


  return (
    <RowCosto>
      <ColTitulo>Costo dados</ColTitulo>
      <ColOpcion>
        <InputSinNumber value={costoDadosOpcion1} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={costoDadosOpcion2} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={costoDadosOpcion3} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={costoDadosOpcion4} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={costoDadosOpcion5} disabled />
      </ColOpcion>
    </RowCosto>
  );
}
