import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import InputSinNumber from "../../../components/input/InputSinNumber";
import { setCotizacionExterna } from "../../../context/actions/cotizacionesActions";

import ColOpcion from "../Calculos/renglonesCostos/ColOpcion";
import ColTitulo from "../Calculos/renglonesCostos/ColTitulo";
import RowCosto from "../Calculos/renglonesCostos/RowCosto";
import parseFloat4 from "../../../utils/parseFloat4";

export const Total = () => {
  const dispatch = useDispatch();

  const {
    total1,
    total2,
    total3,
    total4,
    total5,
    fleteOpcion1,
    fleteOpcion2,
    fleteOpcion3,
    fleteOpcion4,
    fleteOpcion5,
    utilidad1,
    utilidad2,
    utilidad3,
    utilidad4,
    utilidad5,
    precioCotizado1,
    precioCotizado2,
    precioCotizado3,
    precioCotizado4,
    precioCotizado5,
  } = useSelector((state) => state.cotizacionesReducer.cotizacionExterna);

  useEffect(() => {
    setNewValor({
      total1: parseFloat4(
        Number(precioCotizado1) + Number(fleteOpcion1) + Number(utilidad1)
      ),
    });
  }, [precioCotizado1, fleteOpcion1, utilidad1]);

  useEffect(() => {
    setNewValor({
      total2: parseFloat4(
        Number(precioCotizado2) + Number(fleteOpcion2) + Number(utilidad2)
      ),
    });
  }, [precioCotizado2, fleteOpcion2, utilidad2]);

  useEffect(() => {
    setNewValor({
      total3: parseFloat4(
        Number(precioCotizado3) + Number(fleteOpcion3) + Number(utilidad3)
      ),
    });
  }, [precioCotizado3, fleteOpcion3, utilidad3]);

  useEffect(() => {
    setNewValor({
      total4: parseFloat4(
        Number(precioCotizado4) + Number(fleteOpcion4) + Number(utilidad4)
      ),
    });
  }, [precioCotizado4, fleteOpcion4, utilidad4]);

  useEffect(() => {
    setNewValor({
      total5: parseFloat4(
        Number(precioCotizado5) + Number(fleteOpcion5) + Number(utilidad5)
      ),
    });
  }, [precioCotizado5, fleteOpcion5, utilidad5]);

  //

  const setNewValor = (ele) => dispatch(setCotizacionExterna(ele));

  //
  return (
    <RowCosto>
      <ColTitulo>Total</ColTitulo>
      <ColOpcion>
        <InputSinNumber disabled name="total1" value={total1} />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber disabled name="total2" value={total2} />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber disabled name="total3" value={total3} />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber disabled name="total4" value={total4} />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber disabled name="total5" value={total5} />
      </ColOpcion>
    </RowCosto>
  );
};
