import { CheckCircleOutlined } from "@ant-design/icons";
import { Col, Divider, Input, Row } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { getNumberComa } from "../../../utils/getNumberComa";

export const CantidadesFinales = ({
  opcionSeleccionada,
  cantidadesNuevas,
  setCantidadesNuevas,
}) => {
  const { items, opcion1, opcion2, opcion3, opcion4, opcion5 } = useSelector(
    (state) => state.cotizacionesReducer.cotizacion
  );

  const getTotalOpcion = () => {
    if (opcionSeleccionada === "1") return opcion1;
    if (opcionSeleccionada === "2") return opcion2;
    if (opcionSeleccionada === "3") return opcion3;
    if (opcionSeleccionada === "4") return opcion4;
    if (opcionSeleccionada === "5") return opcion5;
    return "err";
  };

  const handleCantidad = (id, valor) => {
    const laClave = cantidadesNuevas.filter((a) => a.clave !== id);
    setCantidadesNuevas([...laClave, { clave: id, valor: Number(valor) }]);
  };
  const getNewTotal = () => {
    let total = 0;
    cantidadesNuevas.map((a) => {
      total += a.valor;
    });
    return total;
  };

  const getValue = (id) => {
    let n = cantidadesNuevas.filter((a) => a.clave === id);
    if (n.length === 0) return 0;
    return n[0].valor;
  };

  if (!items) return <></>;

  return (
    <div>
      <Row gutter={10} className="titulo1">
        <Col span={11}>Artículo</Col>
        <Col span={5}>N / R</Col>
        <Col span={4}>Capturado</Col>
        <Col span={4}>Final</Col>
      </Row>
      {items.map((a, i) => (
        <div key={i}>
          <Row gutter={10} className="itemRow">
            <Col span={11}>{a.articulo}</Col>
            <Col span={5}>{a.nuevoReimpresion}</Col>
            <Col span={4} style={{ textAlign: "right" }}>
              <span
                style={{ cursor: "pointer" }}
                onClick={() => handleCantidad(a.cotizacionItemsID, a.cantidad)}
              >
                {getNumberComa(a.cantidad)}
              </span>
            </Col>
            <Col span={4}>
              <Input
                value={getValue(a.cotizacionItemsID)}
                type="number"
                size="small"
                onChange={({ target }) =>
                  handleCantidad(a.cotizacionItemsID, target.value)
                }
              />
            </Col>
          </Row>
        </div>
      ))}
      <Divider />

      <Row>
        <Col span={12}>
          <div>
            Opcion Seleccionada ({opcionSeleccionada}):{" "}
            {getNumberComa(getTotalOpcion())}
          </div>
          <div>Total Capturado: {getNumberComa(getNewTotal())} </div>
        </Col>
        <Col span={12}>
          {getTotalOpcion() === getNewTotal() && (
            <CheckCircleOutlined
              style={{ marginTop: 0, fontSize: 40, color: "#6e80e6" }}
            />
          )}
        </Col>
      </Row>
    </div>
  );
};
