import { DeleteOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import { useDispatch } from "react-redux";
import { startDeleCatalogoItem } from "../../context/actions/catalogosActions";

export default function ElementosList2({ items }) {
const dispatch = useDispatch()
  const handleDelete = (catalogoItemID) => dispatch(startDeleCatalogoItem(catalogoItemID))

  if (items.length > 0) {
    const elementos = items[0].catalogoItems;
    return (
      <div>
        {elementos.map((c, i) => (
          <Row key={i} className="rowItemElemento">
            <Col span={22}> {c.nombre}</Col>
            <Col span={2} style={{textAlign: "right"}}>
              <DeleteOutlined onClick={()=>handleDelete(c.catalogoItemID)} className="eyeIcon" />
            </Col>
          </Row>
        ))}
      </div>
    );
  } else {
    return <div className="escojaCategoria">Seleccione una categoría</div>;
  }
}
