import {
  proveedoresInitial,
  proveedorInitial,
} from "../initial/proveedoresInitial";
import { types } from "../types/types";

export const proveedoresReducer = (state = proveedoresInitial, action) => {
  switch (action.type) {
    case types.storeProveedores:
      return {
        ...state,
        proveedores: action.payload,
      };

    case types.changeProveedor:
      return {
        ...state,
        proveedor: {
          ...state.proveedor,
          ...action.payload,
        },
      };

    case types.storeProveedorFull:
      return {
        ...state,
        proveedor: action.payload,
      };

    case types.cleanProveedor:
      return {
        ...state,
        proveedor: proveedorInitial,
      };

    default:
      return state;
  }
};
