import { Button, Col, Input, Row } from "antd";
import React, { useState } from "react";
import { SelectSubestatus } from "./SelectSubestatus";
import BotonSubmit from "../../../../components/Boton/BotonSubmit";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { startPostSubestatus } from "../../../../context/actions/cotizacionesActions";
import { useEffect } from "react";

export const Subestatus = () => {
  const dispatch = useDispatch();
  const [subestatusID, setSubestatusID] = useState();
  const [comentarioSubestatus, setComentarioSubestatus] = useState("");
  const { cotizacionID } = useParams();
  const { cotizacion } = useSelector((state) => state.cotizacionesReducer);
  //

  useEffect(() => {
    if (cotizacion.subestatusID) setSubestatusID(cotizacion.subestatusID);
    if (cotizacion.comentarioSubestatus)
      setComentarioSubestatus(cotizacion.comentarioSubestatus);
  }, [cotizacion]);

  const handleClick = () => {
    const body = {
      subestatusID,
      comentarioSubestatus,
      cotizacionID: Number(cotizacionID),
    };

    dispatch(startPostSubestatus(body));
  };

  //
  return (
    <Row>
      <Col span={24}>
        <SelectSubestatus
          subestatusID={subestatusID}
          setSubestatusID={setSubestatusID}
        />
      </Col>
      <Col span={24}>
        <Input.TextArea
          onChange={({ target }) => setComentarioSubestatus(target.value)}
          placeholder="comentarios"
          value={comentarioSubestatus}
        />
      </Col>
      <Col span={24} style={{ paddingTop: 5 }}>
        <BotonSubmit
          disabled={!subestatusID}
          onClick={handleClick}
          tituloBoton="Actualizar"
        />
      </Col>
    </Row>
  );
};
