import { useHistory } from "react-router";
import CardPage from "../../layout/CardPage";
import Forma from "./Forma";

export default function CatalogosCreate() {
    const history = useHistory();

    return (
      <CardPage
      titulo="Agregar Catálogo"
      tituloBoton="Regresar"
      accionBoton={() => history.push("/catalogos")}
    >
      <Forma />
    </CardPage>
    );
}
