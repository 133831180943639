import { Select } from "antd";
const { Option } = Select;

export default function SelectLabelNullable({
  label,
  onChange,
  placeholder,
  options,
  value,
  name,
  error,
}) {
  const handleChange = (val) => {
    onChange({ target: { name, value: val } });
  };

  return (
    <div>
      <div className={`inputLabel ${error && "error"} `}>{label}</div>
      <Select
        showSearch
        value={value}
        style={{ width: "100%" }}
        placeholder={placeholder}
        onChange={handleChange}
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        <Option value={0}>Ninguno</Option>
        {options.map((o) => (
          <Option key={o.value} value={o.value}>
            {o.text}
          </Option>
        ))}
      </Select>
    </div>
  );
}
