import { useHistory } from "react-router";
import CardPage from "../../layout/CardPage";
import ElementosAdd from "./_ElementosAdd";
import ElementosLst from "./_ElementosLst";

export default function _Elementos() {
  const history = useHistory();

  return (
    <CardPage
      titulo="Elementos"
    >
      <ElementosAdd />
      <ElementosLst />
    </CardPage>
  );
}
