import { Col, Row } from "antd";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  startLoadCotizacion,
  startLoadFiles,
} from "../../context/actions/cotizacionesActions";
import { startLoadHistorial } from "../../context/actions/historialActions";
import Datos from "./datos/Datos";
import SeccionDerecha from "./_SeccionDerecha";

export default function Details() {
  const dispatch = useDispatch();
  const { cotizacionID } = useParams();

  useEffect(() => {
    dispatch(startLoadCotizacion(cotizacionID));
    dispatch(startLoadFiles(cotizacionID));
    dispatch(startLoadHistorial(cotizacionID));
  }, []);

  return (
    <Row gutter={[10, 10]}>
      <Col span={16}>
        <Datos />
      </Col>
      <Col span={8}>
        <SeccionDerecha />
      </Col>
    </Row>
  );
}
