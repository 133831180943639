import { useSelector } from "react-redux";
import InputSinNumber from "../../../../components/input/InputSinNumber";
import ColOpcion from "./ColOpcion";
import ColTitulo from "./ColTitulo";
import RowCosto from "./RowCosto";


export default function TiempoCambioPlacas() {
  const {
    tiempoMaquinaCambioPlacasOpcion1,
    tiempoMaquinaCambioPlacasOpcion2,
    tiempoMaquinaCambioPlacasOpcion3,
    tiempoMaquinaCambioPlacasOpcion4,
    tiempoMaquinaCambioPlacasOpcion5,
  } = useSelector((state) => state.cotizacionesReducer.cotizacion);

  return (
    <RowCosto>
      <ColTitulo>Tiempo cambio placas (mins)</ColTitulo>
      <ColOpcion>
        <InputSinNumber value={tiempoMaquinaCambioPlacasOpcion1} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={tiempoMaquinaCambioPlacasOpcion2} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={tiempoMaquinaCambioPlacasOpcion3} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={tiempoMaquinaCambioPlacasOpcion4} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={tiempoMaquinaCambioPlacasOpcion5} disabled />
      </ColOpcion>
    </RowCosto>
  );
}
