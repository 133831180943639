import { Col, Divider, Modal, Row, Select } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import BotonSubmit from "../../../components/Boton/BotonSubmit";
import DatePickerLabel from "../../../components/input/DatePickerLabel";
import InputLabel from "../../../components/input/InputLabel";
import SelectLabel from "../../../components/input/SelectLabel";
import PillCotizacion from "../../../components/pillEstatus/PillCotizacion";
import { startPostEstatus } from "../../../context/actions/estatusActions";
import { CantidadesFinales } from "./_CantidadesFinales";
import { startPostConfirmarCotizacion } from "../../../context/actions/cotizacionesActions";
import { Subestatus } from "./subestatus/Subestatus";
const { Option } = Select;

///
export default function Cotizacion() {
  const [cantidadesNuevas, setCantidadesNuevas] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const { cotizacionID } = useParams();
  const dispatch = useDispatch();
  const [body, setBody] = useState({});

  const { opcion1, opcion2, opcion3, opcion4, opcion5 } = useSelector(
    (state) => state.cotizacionesReducer.cotizacion
  );
  let options = [{ text: "Opción 1", value: "1" }];
  if (opcion2 > 0) options = [...options, { text: "Opción 2", value: "2" }];
  if (opcion3 > 0) options = [...options, { text: "Opción 3", value: "3" }];
  if (opcion4 > 0) options = [...options, { text: "Opción 4", value: "4" }];
  if (opcion5 > 0) options = [...options, { text: "Opción 5", value: "5" }];

  const handleClick = () => {
    const final = {
      ...body,
      cotizacionID,
      cotizacionEstatusID: 3,
      cantidadesNuevas,
    };

    dispatch(startPostConfirmarCotizacion(final));
    setShowModal(false);
  };

  function onChange({ target }) {
    setBody({ ...body, [target.name]: target.value });
  }

  const valida = () => {
    if (!body.opcionSeleccionada) return true;
    if (!body.fechaEntrega) return true;
    if (!body.oc) return true;
    return false;
  };

  /////
  return (
    <>
      <div style={{ marginBottom: 10 }}>
        <PillCotizacion />
      </div>

      <Subestatus />
      <Divider />
      <Row gutter={[10, 10]}>
        <Col span={12}>
          <SelectLabel
            onChange={onChange}
            label="Opcion seleccioanda"
            options={options}
            value={body.opcionSeleccionada}
            name="opcionSeleccionada"
          />
        </Col>
        <Col span={12}>
          <DatePickerLabel
            onChange={onChange}
            label="Fecha de entrega"
            name="fechaEntrega"
          />
        </Col>
        <Col span={12}>
          <InputLabel onChange={onChange} label="O.C." name="oc" />
        </Col>
        <Col span={12} style={{ paddingTop: 20 }}>
          <BotonSubmit
            disabled={valida()}
            tituloBoton="Continuar"
            onClick={() => setShowModal(true)}
          />
        </Col>
      </Row>

      <Modal
        visible={showModal}
        title="Cantidades Finales"
        onOk={handleClick}
        onCancel={() => setShowModal(false)}
      >
        <CantidadesFinales
          cantidadesNuevas={cantidadesNuevas}
          setCantidadesNuevas={setCantidadesNuevas}
          opcionSeleccionada={body.opcionSeleccionada}
        />
      </Modal>
    </>
  );
}
