import { Col } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import BotonCard from "../../components/Boton/BotonCard";
import { AniosSelect } from "../../components/Select/AniosSelect";
import { MesesSelect } from "../../components/Select/MesesSelect";
import {
  setFiltro,
  startLoadCotizaciones,
} from "../../context/actions/cotizacionesActions";
import { EstatusSelect } from "../../components/Select/EstatusSelect";

export const ControlFechas = () => {
  const dispatch = useDispatch();
  const { mes, anio, cotizacionEstatusID } = useSelector(
    (state) => state.cotizacionesReducer.filtro
  );
  const { cotizaciones } = useSelector((state) => state.cotizacionesReducer);

  const handleChange = (val, ele) => {
    const body = {
      [ele]: val,
    };
    dispatch(setFiltro(body));
  };

  const handleFiltrar = () => dispatch(startLoadCotizaciones());
  return (
    <>
      <Col span={2}>{cotizaciones.length} registros</Col>
      <Col span={3}>
        <MesesSelect value={mes} onChange={(val) => handleChange(val, "mes")} />
      </Col>
      <Col span={3}>
        <AniosSelect
          value={anio}
          onChange={(val) => handleChange(val, "anio")}
        />
      </Col>
      <Col span={3}>
        <EstatusSelect
          value={cotizacionEstatusID}
          onChange={(val) => handleChange(val, "cotizacionEstatusID")}
        />
      </Col>
      <Col span={3}>
        <BotonCard onClick={handleFiltrar} tituloBoton="Filtrar" />
      </Col>
    </>
  );
};
