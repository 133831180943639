import { DownloadOutlined } from "@ant-design/icons";
import { Col, Input, Row, Table } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import BotonCard from "../../components/Boton/BotonCard";
import {
  startBuscaCotizacion,
  startGetXLS,
} from "../../context/actions/cotizacionesActions";
import { ControlFechas } from "./ControlFechas";
import useCotizacionesColumns from "./useCotizacionesColumns";

export default function Lista() {
  const history = useHistory();
  const { cotizacionesColumns } = useCotizacionesColumns();
  const { cotizaciones } = useSelector((state) => state.cotizacionesReducer);
  const dispatch = useDispatch();

  const [listaFinal, setListaFinal] = useState([]);

  useEffect(() => {
    setListaFinal(cotizaciones);
  }, [cotizaciones]);

  // const filter = ({ target }) => {
  //   if (target.value !== "") {
  //     var newList = cotizaciones.filter(
  //       (a) =>
  //         (a.cotizacionID && a.cotizacionID == target.value) ||
  //         (a.cliente &&
  //           a.cliente.toLowerCase().includes(target.value.toLowerCase())) ||
  //         (a.marca &&
  //           a.marca.toLowerCase().includes(target.value.toLowerCase())) ||
  //         (a.noParte &&
  //           a.noParte.toLowerCase().includes(target.value.toLowerCase()))
  //     );
  //     setListaFinal(newList);
  //   } else {
  //     setListaFinal(cotizaciones);
  //   }
  // };

  const filterBackend = ({ target: { value } }) => {
    dispatch(startBuscaCotizacion(value));
  };

  const getXLS = (cotizacionEstatusID) =>
    dispatch(startGetXLS(cotizacionEstatusID));

  ////
  return (
    <>
      <Row style={{ marginBottom: 10 }} gutter={10}>
        <Col span={4}>
          <Input onChange={filterBackend} placeholder="buscar" />
        </Col>
        <Col span={3}>
          <BotonCard
            onClick={() => getXLS(3)}
            tituloBoton="OPPDs"
            icon={<DownloadOutlined />}
          />
        </Col>
        <Col span={3}>
          <BotonCard
            onClick={() => getXLS(4)}
            tituloBoton="OPs"
            icon={<DownloadOutlined />}
          />
        </Col>
        <ControlFechas />
      </Row>
      <Table
        size="small"
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) =>
              history.push(`/cotizaciones/details/${record.cotizacionID}`),
          };
        }}
        rowClassName="renglonTabla"
        rowKey="cotizacionID"
        columns={cotizacionesColumns}
        dataSource={listaFinal}
      />
    </>
  );
}
