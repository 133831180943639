
import { useSelector } from "react-redux";
import InputSinNumber from "../../../../components/input/InputSinNumber";

import ColOpcion from "./ColOpcion";
import ColTitulo from "./ColTitulo";
import RowCosto from "./RowCosto";

export default function CargoInfoVariable() {

  const { 
    cargoInfoVariableOpcion1,
    cargoInfoVariableOpcion2,
    cargoInfoVariableOpcion3,
    cargoInfoVariableOpcion4,
    cargoInfoVariableOpcion5,
  } = useSelector((state) => state.cotizacionesReducer.cotizacion);

  return (
    <RowCosto>
      <ColTitulo>Cargo información variable</ColTitulo>
      <ColOpcion>
        <InputSinNumber value={cargoInfoVariableOpcion1} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={cargoInfoVariableOpcion2} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={cargoInfoVariableOpcion3} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={cargoInfoVariableOpcion4} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={cargoInfoVariableOpcion5} disabled />
      </ColOpcion>
    </RowCosto>
  );
}
