import { Input } from "antd";

export default function InputLabelNumber({
  name,
  label,
  placeholder,
  onChange,
  value,
  readOnly,
  error,
}) {
  return (
    <div>
      <div className={`inputLabel ${error && "error"} `}>{label}</div>
      <Input
        value={value}
        type="number"
        name={name}
        className="input"
        placeholder={placeholder}
        onChange={onChange}
        readOnly={readOnly}
      />
    </div>
  );
}
