import { URL_PROVEEDORES } from "../../constants/url";
import {
  composeErrorNotification,
  composeSuccessNotification,
} from "../../utils/composeNotification";
import { get, post, put } from "../../utils/fetch";
import { types } from "../types/types";
import { notification } from "./notificationActions";

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const startLoadProveedores = () => {
  return async (dispatch) => {
    const data = await get(URL_PROVEEDORES);

    if (data !== -1) {
      dispatch(storeProveedores(data));
    } else {
      dispatch(
        notification(
          composeErrorNotification("Error de conexion", "Get proveedores")
        )
      );
    }
  };
};

const storeProveedores = (payload) => ({
  type: types.storeProveedores,
  payload,
});

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const startLoadProveedor = (id) => {
  return async (dispatch) => {
    const data = await get(`${URL_PROVEEDORES}/${id}`);

    if (data !== -1) {
      dispatch(storeProveedorFull(data));
    } else {
      dispatch(
        notification(
          composeErrorNotification("Error de conexion", "Get proveedores")
        )
      );
    }
  };
};

const storeProveedorFull = (payload) => ({
  type: types.storeProveedorFull,
  payload,
});

export const cleanProveedor = () => ({
  type: types.cleanProveedor,
  payload: null,
});

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const changeProveedor = (elemento) => ({
  type: types.changeProveedor,
  payload: elemento,
});

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const startPostProveedor = () => {
  return async (dispatch, getState) => {
    const { proveedor } = getState().proveedoresReducer;
    const data = await post(URL_PROVEEDORES, proveedor);
    if (data !== -1) {
      dispatch(notification(composeSuccessNotification(data)));
    } else {
      dispatch(
        notification(
          composeErrorNotification("Error de conexion", "Post proveedor")
        )
      );
    }
  };
};

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


export const startPutProveedor = () => {
  return async (dispatch, getState) => {
    const { proveedor } = getState().proveedoresReducer;
    const data = await put(`${URL_PROVEEDORES}/${proveedor.proveedorID}`, proveedor);
    if (data !== -1) {
      dispatch(notification(composeSuccessNotification(data)));
    } else {
      dispatch(
        notification(
          composeErrorNotification("Error de conexion", "Put proveedor")
        )
      );
    }
  };
};

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
