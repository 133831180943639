import { useSelector } from "react-redux";
import InputSinNumber from "../../../../components/input/InputSinNumber";

import ColOpcion from "./ColOpcion";
import ColTitulo from "./ColTitulo";
import RowCosto from "./RowCosto";

export default function CostoSetupDigital() {

  const {
    costoSetupDigitalOpcion1,
    costoSetupDigitalOpcion2,
    costoSetupDigitalOpcion3,
    costoSetupDigitalOpcion4,
    costoSetupDigitalOpcion5,
  } = useSelector((state) => state.cotizacionesReducer.cotizacion);


  return (
    <RowCosto>
      <ColTitulo>Costo setup digital</ColTitulo>
      <ColOpcion>
        <InputSinNumber value={costoSetupDigitalOpcion1} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={costoSetupDigitalOpcion2} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={costoSetupDigitalOpcion3} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={costoSetupDigitalOpcion4} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={costoSetupDigitalOpcion5} disabled />
      </ColOpcion>
    </RowCosto>
  );
}
