import { Input } from "antd";

export default function InputSinNumber({
  value,
  placeholder,
  onChange,
  name,
  disabled,
}) {
  return (
    <Input
      step={2}
      type="number"
      className="input"
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      name={name}
      disabled={disabled}
    />
  );
}
