import React from "react";
import { useState } from "react";
import CardPage from "../../../layout/CardPage";
import { BotonActualizar } from "./BotonActualizar";
import { Cotizacion } from "./Cotizacion";
import { Flete } from "./Flete";
import { Notas } from "./Notas";
import { PrecioCotizado } from "./PrecioCotizado";
import { Proveedor } from "./Proveedor";
import { Total } from "./Total";
import { Utilidad } from "./Utilidad";

export const CotizacionExterna = () => {
  const [show, setShow] = useState(false);
  return (
    <CardPage
      titulo="Cotización Externa"
      tituloBoton={show ? "Ocultar" : "Mostrar"}
      accionBoton={() => setShow(!show)}
    >
      {show && (
        <>
          <Cotizacion />
          <Proveedor />
          <PrecioCotizado />
          <Flete />
          <Utilidad />
          <Total />
          <Notas />
          <BotonActualizar />
        </>
      )}
    </CardPage>
  );
};
