import { Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import ColResponsive from "../../../components/Col/ColResponsive";
import InputLabel from "../../../components/input/InputLabel";
import { changeCotizacion } from "../../../context/actions/cotizacionesActions";
import { setSeccionModificada } from "../../../context/actions/stepActions";
import Barniz from "./_Barniz";
import BarnizEspecial from "./_BarnizEspecial";
import Foil from "./_Foil";
import Laminado from "./_Laminado";
import Sustrato from "./_Sustrato";

export default function TermiandosDatos() {
  return (
    <>
      <Row gutter={[10, 10]} style={{ marginBottom: 10 }}>
        <ColResponsive>
          <Sustrato />
        </ColResponsive>
        <ColResponsive>
          <Barniz />
        </ColResponsive>
        <ColResponsive>
          <Laminado />
        </ColResponsive>
        <ColResponsive>
          <Foil />
        </ColResponsive>
        <ColResponsive>
          <BarnizEspecial />
        </ColResponsive>
      </Row>
    </>
  );
}
