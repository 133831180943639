import { useSelector } from "react-redux";
import InputSinNumber from "../../../components/input/InputSinNumber";
import parseFloat4 from "../../../utils/parseFloat4";
import ColOpcion from "../Calculos/renglonesCostos/ColOpcion";
import ColTitulo from "../Calculos/renglonesCostos/ColTitulo";
import RowCosto from "../Calculos/renglonesCostos/RowCosto";

export default function TotalPUMXN() {
  const {
    totalPUOpcion1,
    totalPUOpcion2,
    totalPUOpcion3,
    totalPUOpcion4,
    totalPUOpcion5,
    tipoCambio,
  } = useSelector((state) => state.cotizacionesReducer.cotizacion);

  return (
    <RowCosto>
      <ColTitulo>P/U (MXN)</ColTitulo>
      <ColOpcion>
        <InputSinNumber
          value={parseFloat4(totalPUOpcion1 * tipoCambio)}
          disabled
        />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber
          value={parseFloat4(totalPUOpcion2 * tipoCambio)}
          disabled
        />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber
          value={parseFloat4(totalPUOpcion3 * tipoCambio)}
          disabled
        />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber
          value={parseFloat4(totalPUOpcion4 * tipoCambio)}
          disabled
        />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber
          value={parseFloat4(totalPUOpcion5 * tipoCambio)}
          disabled
        />
      </ColOpcion>
    </RowCosto>
  );
}
