import { useSelector } from "react-redux";
import InputSinNumber from "../../../../components/input/InputSinNumber";

import ColOpcion from "./ColOpcion";
import ColTitulo from "./ColTitulo";
import RowCosto from "./RowCosto";

export default function TonerCMYK() {
  const {
  tonerCMYKOpcion1,
    tonerCMYKOpcion2,
    tonerCMYKOpcion3,
    tonerCMYKOpcion4,
    tonerCMYKOpcion5,
  } = useSelector((state) => state.cotizacionesReducer.cotizacion);

  
  return (
    <RowCosto>
      <ColTitulo>Toner (cmyk)</ColTitulo>
      <ColOpcion>
        <InputSinNumber value={tonerCMYKOpcion1} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={tonerCMYKOpcion2} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={tonerCMYKOpcion3} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={tonerCMYKOpcion4} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={tonerCMYKOpcion5} disabled />
      </ColOpcion>
    </RowCosto>
  );
}
