import { Divider, List } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { startLoadPDF } from "../../../context/actions/cotizacionesActions";

///
export default function Archivos() {
  const { files } = useSelector((state) => state.cotizacionesReducer);
  const { cotizacionID } = useParams();
  const dispatch = useDispatch();

  const handleClick = (file) => {
    dispatch(startLoadPDF(cotizacionID, file));
  };

  ///
  return (
    <>
      <Divider orientation="left" plain>
        Archivos
      </Divider>
      <List
        style={{ maxHeight: 350, overflow: "auto" }}
        size="small"
        dataSource={files}
        renderItem={(item) => (
          <List.Item
            className="fileItem"
            onClick={() => handleClick(item)}
            style={{ fontSize: 11 }}
          >
            {item}
          </List.Item>
        )}
      />
    </>
  );
}
