import { useDispatch, useSelector } from "react-redux";
import InputSinNumber from "../../../components/input/InputSinNumber";
import { changeCotizacion } from "../../../context/actions/cotizacionesActions";
import { setSeccionModificada } from "../../../context/actions/stepActions";
import ColOpcion from "../Calculos/renglonesCostos/ColOpcion";
import ColTitulo from "../Calculos/renglonesCostos/ColTitulo";
import RowCosto from "../Calculos/renglonesCostos/RowCosto";

export default function Fletes() {
  const dispatch = useDispatch();
  const {
    fleteOpcion1,
    fleteOpcion2,
    fleteOpcion3,
    fleteOpcion4,
    fleteOpcion5,
  } = useSelector((state) => state.cotizacionesReducer.cotizacion);

  const onChange = ({ target: { name, value } }) => {
    dispatch(changeCotizacion({ [name]: Number(value) }));
    dispatch(setSeccionModificada({ terminados: true }));
  };

  return (
    <RowCosto>
      <ColTitulo>Fletes</ColTitulo>
      <ColOpcion>
        <InputSinNumber
          onChange={onChange}
          name="fleteOpcion1"
          value={fleteOpcion1}
        />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber
          onChange={onChange}
          name="fleteOpcion2"
          value={fleteOpcion2}
        />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber
          onChange={onChange}
          name="fleteOpcion3"
          value={fleteOpcion3}
        />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber
          onChange={onChange}
          name="fleteOpcion4"
          value={fleteOpcion4}
        />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber
          onChange={onChange}
          name="fleteOpcion5"
          value={fleteOpcion5}
        />
      </ColOpcion>
    </RowCosto>
  );
}
