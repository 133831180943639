//const URL_BASE = "https://localhost:44397";
//const URL_BASE = "https://impresiones-back.secure9000.net";

import pjson from "../../package.json";

export const ver = pjson.version;
export const nameProject = pjson.name;
export const { ambiente } = window["runConfig"];
export const { URL_BASE } = window["runConfig"];

export const URL_CLIENTES = `${URL_BASE}/api/clientes`;
export const URL_BASE_XLS = `${URL_BASE}/api/XLS`;
export const URL_VER = `${URL_BASE}/api/ver`;

export const URL_PROVEEDORES = `${URL_BASE}/api/proveedores`;
export const URL_ARTICULOS = `${URL_BASE}/api/articulos`;
export const URL_CONSTANTES = `${URL_BASE}/api/constantes/1`; // solo el primer registro se trae siempre
export const URL_CATALOGO = `${URL_BASE}/api/catalogo`;
export const URL_CATALOGO_ITEMS = `${URL_BASE}/api/CatalogoItems`;
export const URL_PAISES = `${URL_BASE}/api/pais`;
export const URL_COTIZACIONES = `${URL_BASE}/api/cotizaciones`;
export const URL_COTIZACIONES_ITEMS = `${URL_BASE}/api/cotizacionitems`;

export const URL_BASE_FILES = `${URL_BASE}/api/files`;

export const URL_FILES = `${URL_BASE}/api/files/getfiles`;
export const URL_GET_PDF = `${URL_BASE}/api/files/getpdf`;
export const URL_CREATE_PDF = `${URL_BASE}/api/pdf`;

export const URL_COTIZACIONES_FASE1 = `${URL_BASE}/api/cotizaciones/fase1`;
export const URL_COTIZACIONES_FASE2 = `${URL_BASE}/api/cotizaciones/fase2`;
export const URL_COTIZACIONES_FASE3 = `${URL_BASE}/api/cotizaciones/fase3`;
export const URL_COTIZACIONES_FASE4 = `${URL_BASE}/api/cotizaciones/fase4`;
export const URL_COTIZACIONES_FASE_FINAL = `${URL_BASE}/api/cotizaciones/faseFinal`;

export const URL_LOGIN = `${URL_BASE}/api/auth/login`;
export const URL_HISTORIAL = `${URL_BASE}/api/historial`;

// ESTATUS
export const URL_POST_ESTATUS = `${URL_BASE}/api/estatus`;
export const URL_POST_ESTATUSPPD = `${URL_BASE}/api/estatus/EstatusPPD`;
