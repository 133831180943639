import React from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import BotonSubmit from "../../../components/Boton/BotonSubmit";
import PillProduccion from "../../../components/pillEstatus/PillProduccion";
import { startPostEstatus } from "../../../context/actions/estatusActions";

export default function Produccion() {
  const { cotizacionID } = useParams();
  const dispatch = useDispatch();

  const handleClick = () => {
    const body = { cotizacionID, cotizacionEstatusID: 5 };
    dispatch(startPostEstatus(body));
  };

  return (
    <>
      <PillProduccion />
      <BotonSubmit tituloBoton="Cerrar" onClick={handleClick} />
    </>
  );
}
