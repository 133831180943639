import CurrencyFormat from "react-currency-format";

export default function FormatMoney({ value }) {
  return (
    <CurrencyFormat
      value={value}
      displayType={"text"}
      thousandSeparator={true}
      prefix={"$"}
      style={{ marginRight: 15 }}
    />
  );
}
