import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { setCategoriaSelected } from "../../context/actions/catalogosActions";

///
export default function CategoriasLst() {
  const [selected, setSelected] = useState(0)
  const { catalogos } = useSelector((state) => state.catalogosReducer);
  const dispatch = useDispatch();

  const handleClick = (categoriaID) => {
    dispatch(setCategoriaSelected(categoriaID));
    setSelected(categoriaID)
  }

  ///
  return (
    <div>
      {catalogos.map((c, i) => (
        <div
          onClick={() => handleClick(c.catalogoID)}
          key={i}
          className={selected===c.catalogoID ? "rowItem selectedItem":"rowItem"}
        >
          {c.nombre}
        </div>
      ))}
    </div>
  );
}
