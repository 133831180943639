import { notification } from "antd";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";

export default function Notificacion() {

  const {
    notification: { type, message, description, dateTime, duration },
  } = useSelector((state) => state.notificationReducer);

  useEffect(() => {
    openNotificationWithIcon();
  }, [dateTime]);

  const openNotificationWithIcon = () => {
    if (dateTime !== "") {
      notification[type]({
        message,
        description,
        placement: "bottomRight",
        bottom: 50,
        duration,
        rtl: true,
      });
    }
  };

  return <></>;
}
