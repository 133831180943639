import {
  URL_BASE_FILES,
  URL_BASE_XLS,
  URL_COTIZACIONES,
  URL_COTIZACIONES_FASE1,
  URL_COTIZACIONES_FASE2,
  URL_COTIZACIONES_FASE3,
  URL_COTIZACIONES_FASE4,
  URL_COTIZACIONES_FASE_FINAL,
  URL_COTIZACIONES_ITEMS,
  URL_CREATE_PDF,
  URL_FILES,
  URL_GET_PDF,
} from "../../constants/url";
import {
  composeErrorNotification,
  composeSuccessNotification,
} from "../../utils/composeNotification";
import { dele, get, getFile, post, put } from "../../utils/fetch";
import parseFloat3 from "../../utils/parseFloat3";
import { cotExternaVacia } from "../initial/cotizacionesInitial";
import { types } from "../types/types";
import { validacionFases } from "./erroresActions";
import { setLoadingStatus } from "./loadingActions";
import { notification } from "./notificationActions";
import {
  cleanSeccionModificada,
  setSeccionModificada,
  setStep,
} from "./stepActions";
import { setTerminado } from "./terminadosActions";

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const startLoadCotizaciones = () => {
  return async (dispatch, getState) => {
    const { mes, anio, cotizacionEstatusID } =
      getState().cotizacionesReducer.filtro;

    let coID = cotizacionEstatusID ? `${cotizacionEstatusID}` : "";
    const data = await get(
      `${URL_COTIZACIONES}/GetCotizaciones/${mes}/${anio}/${coID}`
    );

    if (data !== -1) {
      dispatch(storeCotizaciones(data));
    } else {
      dispatch(
        notification(
          composeErrorNotification("Error de conexion", "GET Cotizaciones")
        )
      );
    }
  };
};

const storeCotizaciones = (payload) => ({
  type: types.storeCotizaciones,
  payload,
});

export const startBuscaCotizacion = (value) => {
  return async (dispatch, getState) => {
    const { mes, anio } = getState().cotizacionesReducer.filtro;
    let url = `${URL_COTIZACIONES}`;
    url +=
      value !== ""
        ? `/BuscaCotizacion/${value}`
        : `/GetCotizaciones/${mes}/${anio}`;

    console.log(url);
    const data = await get(url);

    if (data !== -1) {
      dispatch(storeCotizaciones(data));
    } else {
      dispatch(
        notification(
          composeErrorNotification("Error de conexion", "GET Cotizaciones")
        )
      );
    }
  };
};
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const changeCotizacion = (elemento) => ({
  type: types.changeCotizacion,
  payload: elemento,
});

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const setDatosDado = (articuloID, desdeCarga) => {
  return (dispatch, getState) => {
    const { articulos } = getState().articulosReducer;

    articulos.map((a) => {
      if (a.articuloID == articuloID) {
        let medidas = {
          dadoMedidas: articuloID,

          descripcion: a.descripcion,
          qty_across: parseFloat3(a.qty_across),
          qty_down: parseFloat3(a.qty_down),
        };

        if (desdeCarga !== true) {
          medidas = {
            ...medidas,
            acrossMedida: parseFloat3(a.across_inch),
            acrossGap: parseFloat3(a.gap_acorss_inch),
            acrossRadio: parseFloat3(a.radius),
            downMedida: parseFloat3(a.down_inch),
            downGap: parseFloat3(a.gap_down_inch),
          };
        }
        dispatch(changeCotizacion(medidas));
      }
    });
  };
};

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const startLoadCotizacion = (cotizacionID, showLoading = true) => {
  return async (dispatch) => {
    if (showLoading) dispatch(setLoadingStatus(true));

    const data = await get(`${URL_COTIZACIONES}/${cotizacionID}`);

    if (data !== -1) {
      dispatch(storeCotizacion(data));
      dispatch(selectTermiandos(data));
      dispatch(setDatosDado(data.dadoMedidas, true));
      dispatch(startGetCotizacionExterna(cotizacionID));
    } else {
      dispatch(
        notification(
          composeErrorNotification("Error de conexion", "GET Cotización")
        )
      );
    }

    if (showLoading) dispatch(setLoadingStatus(false));
  };
};

export const storeCotizacion = (payload) => ({
  type: types.storeCotizacion,
  payload,
});

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const selectTermiandos = ({
  sustratoID,
  barnizID,
  laminadoID,
  foilID,
  barnizEspecialID,
}) => {
  return async (dispatch, getState) => {
    let salida = {
      sustrato: {},
      laminado: {},
      barniz: {},
      foil: {},
      barnizEspecial: {},
    };
    const { articulos } = getState().articulosReducer;

    //
    articulos.map((a) => {
      if (a.articuloID == sustratoID) {
        salida = {
          ...salida,
          sustrato: a,
        };
        dispatch(changeCotizacion({ costoMSISustrato: a.costo }));
      }
      if (a.articuloID == barnizID) {
        salida = {
          ...salida,
          barniz: a,
        };
        dispatch(changeCotizacion({ costoMSIBarniz: a.costo }));
      }
      if (a.articuloID == laminadoID) {
        salida = {
          ...salida,
          laminado: a,
        };
        dispatch(changeCotizacion({ costoMSILaminado: a.costo }));
      }
      if (a.articuloID == foilID) {
        salida = {
          ...salida,
          foil: a,
        };
        dispatch(changeCotizacion({ costoMSIFoil: a.costo }));
      }
      if (a.articuloID == barnizEspecialID) {
        salida = {
          ...salida,
          barnizEspecial: a,
        };
        dispatch(changeCotizacion({ costoMSIBarnizEspecial: a.costo }));
      }
    });

    dispatch(setTerminado(salida));
  };
};

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const startLoadFiles = (cotizacionID) => {
  return async (dispatch) => {
    const data = await get(`${URL_FILES}/${cotizacionID}`);

    if (data !== -1) {
      dispatch(storeFiles(data));
    } else {
      dispatch(
        notification(composeErrorNotification("Error de conexion", "Get Files"))
      );
    }
  };
};

const storeFiles = (payload) => ({
  type: types.storeFiles,
  payload,
});

export const startLoadPDF = (cotizacionID, file) => {
  return async () => {
    await getFile(`${URL_GET_PDF}/${cotizacionID}/${file}`, file);
  };
};

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const startCreatePDF = (cotizacionID, tipo) => {
  return async (dispatch) => {
    const data = await get(`${URL_CREATE_PDF}/${cotizacionID}/${tipo}`);

    if (data !== -1) {
      dispatch(notification(composeSuccessNotification(data)));
      dispatch(startLoadFiles(cotizacionID));
    } else {
      dispatch(
        notification(
          composeErrorNotification("Error de conexion", "Create PDF")
        )
      );
    }
  };
};

/////////////////

export const cleanTermindos = () => ({
  type: types.cleanTerminados,
  payload: null,
});

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const startPostCotizacion = () => {
  return async (dispatch, getState) => {
    const { cotizacion } = getState().cotizacionesReducer;
    const data = await post(URL_COTIZACIONES, cotizacion);
    if (data !== -1) {
      dispatch(notification(composeSuccessNotification(data)));
      dispatch(setStep(2));
    } else {
      dispatch(
        notification(
          composeErrorNotification("Error de conexion", "Post Cotización")
        )
      );
    }
  };
};

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const startPostFase = (fase) => {
  return async (dispatch, getState) => {
    const validacion = dispatch(validacionFases(fase));

    if (!validacion) {
      dispatch(
        notification(
          composeErrorNotification("Error de formulario", "verificar campos")
        )
      );
      return;
    }

    const url = dispatch(getURL(fase));
    const { cotizacion } = getState().cotizacionesReducer;

    const data = await post(url, cotizacion);

    if (data !== -1) {
      dispatch(notification(composeSuccessNotification("Registro guardado")));
      dispatch(setStep(data.step));
      dispatch(changeCotizacion({ cotizacionID: data.cotizacionID }));
      dispatch(cleanSeccionModificada());
      dispatch(setSeccionModificada({ guardar: false }));
      return 0;
    } else {
      dispatch(
        notification(
          composeErrorNotification("Error de conexion", `En ${fase}`)
        )
      );
    }

    //
  };
};

////

export const startPostUpdateFechaDTO = (body) => {
  return async (dispatch) => {
    console.log(body);
    const data = await post(`${URL_COTIZACIONES}/UpdateFecha`, body);
    if (data !== -1) {
      dispatch(startLoadCotizacion(body.cotizacionID));
      dispatch(
        notification(composeSuccessNotification("Registro actualizado"))
      );
    } else {
      dispatch(
        notification(
          composeErrorNotification("Error de conexion", "UpdateFecha")
        )
      );
    }
  };
};
/////////

const getURL = (fase) => {
  return () => {
    let url = "";
    if (fase === "fase1") url = URL_COTIZACIONES_FASE1;
    else if (fase === "fase2") url = URL_COTIZACIONES_FASE2;
    else if (fase === "fase3") url = URL_COTIZACIONES_FASE3;
    else if (fase === "fase4") url = URL_COTIZACIONES_FASE4;
    else if (fase === "faseFinal") url = URL_COTIZACIONES_FASE_FINAL;

    return url;
  };
};
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const addCotizacionItem = (payload) => ({
  type: types.addCotizacionItem,
  payload,
});

export const removeCotizacionItem = (payload) => ({
  type: types.removeCotizacionItem,
  payload,
});

export const startSetOpcion1 = () => {
  return (dispatch, getState) => {
    const { items } = getState().cotizacionesReducer.cotizacion;
    let nuevoTotal = 0;
    items.map((a) => {
      nuevoTotal += Number(a.cantidad);
    });

    dispatch(setOpcion1(nuevoTotal));
  };
};

export const setOpcion1 = (payload) => {
  return {
    type: types.setOpcion1,
    payload,
  };
};

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const startPutCotizacion = () => {
  return async (dispatch, getState) => {
    const { cotizacion } = getState().cotizacionesReducer;
    const data = await put(
      `${URL_COTIZACIONES}/${cotizacion.cotizacionID}`,
      cotizacion
    );
    if (data !== -1) {
      dispatch(notification(composeSuccessNotification(data)));
    } else {
      dispatch(
        notification(
          composeErrorNotification("Error de conexion", "Put Cotización")
        )
      );
    }
  };
};

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const startDeleCotizacionItem = (id) => {
  return async (dispatch) => {
    const data = await dele(`${URL_COTIZACIONES_ITEMS}/${id}`);
    if (data.code !== -1) {
      dispatch(notification(composeSuccessNotification(data.data)));
    } else {
      dispatch(
        notification(composeErrorNotification("Error de conexion", data.data))
      );
    }
  };
};

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const startPostCotizacionItem = (body) => {
  return async (dispatch) => {
    const data = await post(`${URL_COTIZACIONES_ITEMS}`, body);
    if (data !== -1) {
      dispatch(notification(composeSuccessNotification(data)));
    } else {
      dispatch(
        notification(
          composeErrorNotification("Error de conexion", "Dele Cotización item")
        )
      );
    }
  };
};

/////////////////////////////////////////////////////////////////

export const startUpdateCantidadItem = (body) => {
  return async (dispatch) => {
    const url = `${URL_COTIZACIONES_ITEMS}/UpdateCantidad`;
    const data = await post(url, body);
    if (data !== -1) {
      dispatch(notification(composeSuccessNotification(data)));

      setTimeout(() => {
        dispatch(startLoadCotizacion(body.cotizacionID, false));
      }, 300);
    } else {
      dispatch(
        notification(
          composeErrorNotification("Error de conexion", "Update item")
        )
      );
    }
  };
};

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const startUpdateOpcion1 = () => {
  return async (dispatch, getState) => {
    const { cotizacionID, opcion1 } = getState().cotizacionesReducer.cotizacion;

    const data = await post(`${URL_COTIZACIONES}/UpdateOpcion1`, {
      cotizacionID,
      opcion1,
    });
    if (data !== -1) {
      //
    } else {
      dispatch(
        notification(
          composeErrorNotification("Error de conexion", "Update opcion1")
        )
      );
    }
  };
};
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const cleanCotizacion = () => ({
  type: types.cleanCotizacion,
  payload: null,
});

///////
export const startDeleCotizacion = (cotizacionID) => {
  return async (dispatch) => {
    const url = `${URL_BASE_FILES}/DeleCotizacion/${cotizacionID}`;
    const data = await dele(url);
    if (data !== -1) {
      dispatch(startLoadCotizaciones());
      dispatch(notification(composeSuccessNotification(data)));
    } else {
      dispatch(
        notification(
          composeErrorNotification("Error de conexion", "Dele cotizacion")
        )
      );
    }
  };
};

////////////////////////////

export const startGetXLS = (cotizacionEstatusID) => {
  return async (dispatch) => {
    const url = `${URL_BASE_XLS}/${cotizacionEstatusID}`;
    const nombre = cotizacionEstatusID === 3 ? "OPPD.xls" : "OP.xls";
    const data = await getFile(url, nombre);
    if (data !== -1) {
    } else {
      dispatch(
        notification(composeErrorNotification("Error de conexion", "Get XLS"))
      );
    }
  };
};

////////////////
export const setTipoCaptura = (tipo) => ({
  type: types.setTipoCaptura,
  payload: tipo,
});

//////////
export const setFiltro = (ele) => ({
  type: types.setFiltro,
  payload: ele,
});

/////

export const startPostCotizacionExterna = () => {
  return async (dispatch, getState) => {
    const url = `${URL_COTIZACIONES}/PostCotizacionExterna`;
    const {
      cotizacionExterna,
      cotizacion: { cotizacionID },
    } = getState().cotizacionesReducer;
    const body = {
      ...cotizacionExterna,
      cotizacionID,
    };
    console.log(body);
    const data = await post(url, body);
    if (data !== -1) {
      dispatch(notification(composeSuccessNotification(data)));
    } else {
      dispatch(
        notification(
          composeErrorNotification("Error de conexion", "PostCotizacionExterna")
        )
      );
    }
  };
};
export const setCotizacionExterna = (ele) => ({
  type: types.setCotizacionExterna,
  payload: ele,
});

export const startGetCotizacionExterna = (cotizacionID) => {
  return async (dispatch, getState) => {
    const url = `${URL_COTIZACIONES}/GetCotizacionExterna/${cotizacionID}`;

    const data = await get(url);

    console.log({ data });
    if (data !== -1) {
      if (data !== "") {
        dispatch(setCotizacionExternaFull(data));
      } else dispatch(setCotizacionExternaFull(cotExternaVacia));
    } else {
      dispatch(
        notification(
          composeErrorNotification("Error de conexion", "GetCotizacionExterna")
        )
      );
    }
  };
};

export const setCotizacionExternaFull = (ele) => ({
  type: types.setCotizacionExternaFull,
  payload: ele,
});

//
export const startPostConfirmarCotizacion = (body) => {
  return async (dispatch) => {
    const url = `${URL_COTIZACIONES}/PostConfirmarCotizacion`;
    const data = await post(url, body);

    if (data !== -1) {
      dispatch(notification(composeSuccessNotification(data)));
      dispatch(startLoadCotizacion(body.cotizacionID, false));
    } else {
      dispatch(
        notification(
          composeErrorNotification(
            "Error de conexion",
            "PostConfirmarCotizacion"
          )
        )
      );
    }
  };
};

///////////

export const cleanCotExterna = () => ({
  type: types.cleanCotExterna,
  payload: null,
});

///////////////////////////

export const startPostSubestatus = (body) => {
  return async (dispatch, getState) => {
    const { Id } = getState().userReducer.user;
    const url = `${URL_COTIZACIONES}/PostSubestatus`;
    console.log(body);
    const data = await post(url, { ...body, ApplicationUserID: Id });

    if (data !== -1) {
      dispatch(notification(composeSuccessNotification(data)));
      dispatch(startLoadCotizacion(body.cotizacionID, false));
    } else {
      dispatch(
        notification(
          composeErrorNotification("Error de conexion", "PostSubestatus")
        )
      );
    }
  };
};

//

export const startGetSubestatus = () => {
  return async (dispatch) => {
    const url = `${URL_COTIZACIONES}/GetSubestatus`;
    const data = await get(url);

    if (data !== -1) {
      dispatch(storeSubestatus(data));
    } else {
      dispatch(
        notification(
          composeErrorNotification("Error de conexion", "GetSubestatus")
        )
      );
    }
  };
};

const storeSubestatus = (payload) => ({
  type: types.storeSubestatus,
  payload,
});
