import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ArticulosListaItem from "./_ArticulosListaItem";

//
export default function ArticulosLista() {
  const [total, setTotal] = useState(0);
  const { items } = useSelector(
    (state) => state.cotizacionesReducer.cotizacion
  );

  useEffect(() => {
    let totalItems = 0;
    items.map((a) => {
      totalItems += Number(a.cantidad);
    });

    setTotal(totalItems);
  }, [items]);

  return (
    <>
      <div style={{ marginTop: 20 }}>
        {items.map((item, i) => (
          <ArticulosListaItem key={i} item={item} index={i} />
        ))}
      </div>
      total items: {total}
    </>
  );
}
