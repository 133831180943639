import { useDispatch } from "react-redux";
import BotonSubmit from "../../components/Boton/BotonSubmit";
import { startPutArticulo } from "../../context/actions/articulosActions";

export default function SubmitEdit() {
  const dispatch = useDispatch();
  const handleClick = () => dispatch(startPutArticulo());
  return (
    <>
      <div>
        <BotonSubmit onClick={handleClick} tituloBoton="Actualizar Artículo" />
      </div>
    </>
  );
}
