import { Col, Row } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import InputSinNumber from "../../../components/input/InputSinNumber";
import { UnoCien } from "../../../components/Select/UnoCien";
import { setCotizacionExterna } from "../../../context/actions/cotizacionesActions";
import parseFloat2 from "../../../utils/parseFloat2";

import ColOpcion from "../Calculos/renglonesCostos/ColOpcion";
import ColTitulo from "../Calculos/renglonesCostos/ColTitulo";
import RowCosto from "../Calculos/renglonesCostos/RowCosto";
import parseFloat4 from "../../../utils/parseFloat4";

export const Utilidad = () => {
  const dispatch = useDispatch();

  const {
    utilidad1,
    utilidad2,
    utilidad3,
    utilidad4,
    utilidad5,
    porcentajeUtilidad,

    precioCotizado1,
    precioCotizado2,
    precioCotizado3,
    precioCotizado4,
    precioCotizado5,
  } = useSelector((state) => state.cotizacionesReducer.cotizacionExterna);

  useEffect(() => {
    setNewValor({
      utilidad1: parseFloat4(precioCotizado1 * porcentajeUtilidad),
    });
  }, [precioCotizado1, porcentajeUtilidad]);

  useEffect(() => {
    setNewValor({
      utilidad2: parseFloat4(precioCotizado2 * porcentajeUtilidad),
    });
  }, [precioCotizado2, porcentajeUtilidad]);

  useEffect(() => {
    setNewValor({
      utilidad3: parseFloat4(precioCotizado3 * porcentajeUtilidad),
    });
  }, [precioCotizado3, porcentajeUtilidad]);

  useEffect(() => {
    setNewValor({
      utilidad4: parseFloat4(precioCotizado4 * porcentajeUtilidad),
    });
  }, [precioCotizado4, porcentajeUtilidad]);

  useEffect(() => {
    setNewValor({
      utilidad5: parseFloat4(precioCotizado5 * porcentajeUtilidad),
    });
  }, [precioCotizado5, porcentajeUtilidad]);

  ////////////////
  const handleChange = ({ target }) => {
    setNewValor({
      [target.name]: Number(target.value),
    });
  };

  const handleChangePorcentaje = (val) => {
    setNewValor({
      porcentajeUtilidad: Number(val),
    });
  };

  const setNewValor = (ele) => dispatch(setCotizacionExterna(ele));

  ////////////////
  return (
    <RowCosto>
      <ColTitulo>
        <Row gutter={10} align="end">
          <Col span={6}>
            <div style={{ marginBottom: 3 }}>Utilidad</div>
            <UnoCien
              value={porcentajeUtilidad}
              onChange={handleChangePorcentaje}
              size="small"
            />
          </Col>
        </Row>
      </ColTitulo>

      <ColOpcion>
        <InputSinNumber
          name="utilidad1"
          onChange={handleChange}
          value={utilidad1}
        />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber
          name="Utilidad2"
          onChange={handleChange}
          value={utilidad2}
        />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber
          name="Utilidad3"
          onChange={handleChange}
          value={utilidad3}
        />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber
          name="Utilidad4"
          onChange={handleChange}
          value={utilidad4}
        />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber
          name="Utilidad5"
          onChange={handleChange}
          value={utilidad5}
        />
      </ColOpcion>
    </RowCosto>
  );
};
