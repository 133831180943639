import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { startLoadCliente, startLoadCotizacionesCliente } from "../../context/actions/clientesActions";
import CardPage from "../../layout/CardPage";
import SubmitEdit from "./SubmitEdit";
import Forma from "./Forma";
import { ListaCotizaciones } from "./ListaCotizaciones";

export default function ClientesDetails() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { clienteID } = useParams();

  useEffect(() => {
    dispatch(startLoadCliente(clienteID));
    dispatch(startLoadCotizacionesCliente(clienteID));
  }, []);
  return (
    <>
      <CardPage
        titulo="Información de Cliente"
        tituloBoton="Regresar"
        accionBoton={() => history.push("/clientes")}
      >
        <Forma />
        <SubmitEdit />
      </CardPage>

      <CardPage titulo="Cotizaciones">
        <ListaCotizaciones />
      </CardPage>
    </>
  );
}
