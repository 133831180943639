import { useSelector } from "react-redux";
import InputSinNumber from "../../../../components/input/InputSinNumber";

import ColOpcion from "./ColOpcion";
import ColTitulo from "./ColTitulo";
import RowCosto from "./RowCosto";

export default function DepreciacionDadoSuaje() {
  const {
   depreciacionDadoSuajeOpcion1,
    depreciacionDadoSuajeOpcion2,
    depreciacionDadoSuajeOpcion3,
    depreciacionDadoSuajeOpcion4,
    depreciacionDadoSuajeOpcion5,
  } = useSelector((state) => state.cotizacionesReducer.cotizacion);



  return (
    <RowCosto>
      <ColTitulo>Depreciación dado de suaje</ColTitulo>
      <ColOpcion>
        <InputSinNumber value={depreciacionDadoSuajeOpcion1} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={depreciacionDadoSuajeOpcion2} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={depreciacionDadoSuajeOpcion3} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={depreciacionDadoSuajeOpcion4} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={depreciacionDadoSuajeOpcion5} disabled />
      </ColOpcion>
    </RowCosto>
  );
}
