import { useDispatch } from "react-redux";
import BotonSubmit from "../../components/Boton/BotonSubmit";
import { startPostCliente } from "../../context/actions/clientesActions";

export default function SubmitCreate() {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(startPostCliente())
  };
  return (
    <div style={{ marginTop: 20 }}>
      <BotonSubmit onClick={handleClick} tituloBoton="Agregar Cliente" />
    </div>
  );
}
