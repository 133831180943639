import { Col, Row, Spin } from "antd";
import { useSelector } from "react-redux";
import CardPage from "../../../layout/CardPage";
import BarnizEspecialRSS from "./renglonesCostos/BarnizEspecialRSS";
import BarnizMaquina from "./renglonesCostos/BarnizMaquina";
import CambioColor from "./renglonesCostos/CambioColor";
import CambioPlacas from "./renglonesCostos/CambioPlacas";
import CantidadSolicitada from "./renglonesCostos/CantidadSolicitada";
import CantidadTotal from "./renglonesCostos/CantidadTotal";
import CargoInfoVariable from "./renglonesCostos/CargoInfoVariable";
import CargoPreparacionArte from "./renglonesCostos/CargoPreparacionArte";
import CostoCorteIndividual from "./renglonesCostos/CostoCorteIndividual";
import CostoDados from "./renglonesCostos/CostoDados";
import CostoLaminado from "./renglonesCostos/CostoLaminado";
import CostoPantallaBarnizEspecial from "./renglonesCostos/CostoPantallaBarnizEspecial";
import CostoPreprensaOffset from "./renglonesCostos/CostoPreprensaOffset";
import CostoSetupDigital from "./renglonesCostos/CostoSetupDigital";
import CostoSetupMaquinasAdicionales from "./renglonesCostos/CostoSetupMaquinasAdicionales";
import CostoSetupOffset from "./renglonesCostos/CostoSetupOffset";
import CostoSetupSerigrafia from "./renglonesCostos/CostoSetupSerigrafia";
import CostoSustrato from "./renglonesCostos/CostoSubstrato";
import CostoTiempoMaquinaAdicional from "./renglonesCostos/CostoTiempoMaquinaAdicional";
import CostoTiempoMaquinaBaseBlancaDigital from "./renglonesCostos/CostoTiempoMaquinaBaseBlancaDigital";
import CostoTiempoMaquinaImpDigital from "./renglonesCostos/CostoTiempoMaquinaImpDigital";
import CostoTiempoMaquinaImpOffset from "./renglonesCostos/CostoTiempoMaquinaImpOffset";
import CostoTiempoMaquinaImpRSS from "./renglonesCostos/CostoTiempoMaquinaImpRSS";
import CostoUsoPrimera from "./renglonesCostos/CostoUsoPrimera";
import DepreciacionDadoSuaje from "./renglonesCostos/DepreciacionDadoSuaje";
import Empaque from "./renglonesCostos/Empaque";
import Foil from "./renglonesCostos/Foil";
import MSIRequeridosAcabados from "./renglonesCostos/MSIRequeridosAcabados";
import MSIRequeridosImpresion from "./renglonesCostos/MSIRequeridosImpresion";
import MSIRequeridosSetup from "./renglonesCostos/MSIRequeridosSetup";
import MSITotal from "./renglonesCostos/MSITotal";
import NoGolpesCalculo from "./renglonesCostos/NoGolpesCalculo";
import PantallaRSS from "./renglonesCostos/PantallaRSS";
import ServicioEpson from "./renglonesCostos/ServicioEpson";
import TamanoPlantillaImpresion from "./renglonesCostos/TamanoPlantillaImpresion";
import TiempoMaquinaImpDigital from "./renglonesCostos/_TiempoImpresionDigital";
import TintasSemirotativa from "./renglonesCostos/TintasSemirotativa";
import TintasSerigrafiaRSS from "./renglonesCostos/TintasSerigrafiaRSS";
import TonerBlanco from "./renglonesCostos/TonerBlanco";
import TonerCMYK from "./renglonesCostos/TonerCMYK";
import TotalPiesLineales from "./renglonesCostos/TotalPiesLineales";
import CostoMateriasPrimas from "./renglonesCostos/_CostoMateriasPrimas";
import MetrosLinealesRequeridos from "./renglonesCostos/_MetrosLinealesRequeridos";
import { GastosSubcontratados } from "./renglonesCostos/_GastosSubcontratados";
import TiempoImpresionDigital from "./renglonesCostos/_TiempoImpresionDigital";
import TiempoTotalImpresion from "./renglonesCostos/_TiempoTotalImpresion";
import TiempoSetupOffset from "./renglonesCostos/_TiempoSetupOffset";
import TiempoCambioPlacas from "./renglonesCostos/_TiempoCambioPlacas";
import TiempoCambioColor from "./renglonesCostos/_TiempoCambioColor";
import { CotizacionExterna } from "./renglonesCostos/CotizacionExterna";

export default function FormaCalculoCostos() {
  const { calculing } = useSelector((state) => state.loadingReducer);

  if (calculing) return <Spin />;

  return (
    <CardPage titulo="Cálculos">
      <CantidadSolicitada />

      <CantidadTotal />
      <TiempoMaquinaImpDigital />
      <TamanoPlantillaImpresion />
      <MSIRequeridosImpresion />
      <MSIRequeridosSetup />
      <MSIRequeridosAcabados />
      <MSITotal />
      <TotalPiesLineales />
      <NoGolpesCalculo />

      <hr />
      <div>Costos</div>
      <CostoTiempoMaquinaImpDigital />
      <CostoTiempoMaquinaImpRSS />
      <CostoTiempoMaquinaImpOffset />
      <CostoTiempoMaquinaAdicional />
      <CostoTiempoMaquinaBaseBlancaDigital />
      <CostoSetupOffset />
      <CostoSetupDigital />
      <CostoSetupSerigrafia />

      <CostoSetupMaquinasAdicionales />
      <CostoSustrato />
      <CostoLaminado />
      <CostoCorteIndividual />
      <CostoPreprensaOffset />
      <DepreciacionDadoSuaje />
      <CambioPlacas />
      <CambioColor />
      <CargoPreparacionArte />

      <CostoUsoPrimera />
      <CargoInfoVariable />
      <PantallaRSS />
      <CostoPantallaBarnizEspecial />
      <CostoDados />

      <TintasSemirotativa />

      <TintasSerigrafiaRSS />
      <TonerCMYK />
      <TonerBlanco />
      <BarnizEspecialRSS />

      <BarnizMaquina />
      <ServicioEpson />
      <Foil />

      <Empaque />

      <GastosSubcontratados />
      <CotizacionExterna />

      <hr />
      <MetrosLinealesRequeridos />
      <CostoMateriasPrimas />

      <TiempoImpresionDigital />
      <TiempoTotalImpresion />
      <TiempoSetupOffset />
      <TiempoCambioPlacas />
      <TiempoCambioColor />
    </CardPage>
  );
}
