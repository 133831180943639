import { useSelector } from "react-redux";
import InputSinNumber from "../../../../components/input/InputSinNumber";

import ColOpcion from "./ColOpcion";
import ColTitulo from "./ColTitulo";
import RowCosto from "./RowCosto";

export default function Foil() {
  const { foilOpcion1, foilOpcion2, foilOpcion3, foilOpcion4, foilOpcion5 } =
    useSelector((state) => state.cotizacionesReducer.cotizacion);

  return (
    <RowCosto>
      <ColTitulo>Foil</ColTitulo>
      <ColOpcion>
        <InputSinNumber value={foilOpcion1} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={foilOpcion2} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={foilOpcion3} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={foilOpcion4} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={foilOpcion5} disabled />
      </ColOpcion>
    </RowCosto>
  );
}
