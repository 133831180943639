import { Spin } from "antd";
import { Suspense, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { consLogged } from "../constants/consLogged";
import { ver } from "../constants/url";
import { startLoadInitial } from "../context/actions/initialActions";
import { verificaLogin } from "../context/actions/loginActions";
import { LayoutPage } from "../layout/LayoutPage";
import Articulos from "../views/articulos/Articulos";
import ArticulosCreate from "../views/articulos/ArticulosCreate";
import ArticulosDetails from "../views/articulos/ArticulosDetails";
import Login from "../views/Auth/Login";
import Catalogos from "../views/catalogos/Catalogos";
import CatalogosCreate from "../views/catalogos/CatalogosCreate";
import Clientes from "../views/clientes/Clientes";
import ClientesCreate from "../views/clientes/ClientesCreate";
import ClientesDetails from "../views/clientes/ClientesDetails";
import Constantes from "../views/constantes/Constantes";
import Cotizaciones from "../views/cotizaciones/Cotizaciones";
import CotizacionesCreate from "../views/cotizaciones/CotizacionesCreate";
import CotizacionesDetail from "../views/cotizaciones/CotizacionesDetail";
import Details from "../views/details/Details";
import Proveedores from "../views/proveedores/Proveedores";
import ProveedoresCreate from "../views/proveedores/ProveedoresCreate";
import ProveedoresDetails from "../views/proveedores/ProveedoresDetails";
import ViewCotizacion from "../views/ViewCotizacion/ViewCotizacion";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";

export default function Routes() {
  const dispatch = useDispatch();
  const { logged } = useSelector((state) => state.userReducer);

  useEffect(() => {
    dispatch(verificaLogin());
    dispatch(startLoadInitial());
  }, []);

  if (logged === consLogged.INICIANDO)
    return (
      <div style={{ textAlign: "center", marginTop: 50 }}>
        <Spin size="large" />
      </div>
    );

  return (
    <Router>
      <Switch>
        <PublicRoute
          isAuthenticated={logged}
          exact
          path="/login"
          component={Login}
        />
        <LayoutPage>
          <Suspense
            fallback={
              <div style={{ marginTop: 30 }}>
                {" "}
                <Spin size="large" />
              </div>
            }
          >
            <PrivateRoute
              isAuthenticated={logged}
              exact
              path="/"
              component={Cotizaciones}
            />

            <PrivateRoute
              isAuthenticated={logged}
              exact
              path="/cotizaciones/create"
              component={CotizacionesCreate}
            />

            <PrivateRoute
              isAuthenticated={logged}
              exact
              path="/cotizaciones/details/:cotizacionID"
              component={Details}
            />

            <PrivateRoute
              isAuthenticated={logged}
              exact
              path="/cotizaciones/view/:cotizacionID"
              component={ViewCotizacion}
            />

            <PrivateRoute
              isAuthenticated={logged}
              exact
              path="/clientes"
              component={Clientes}
            />
            <PrivateRoute
              isAuthenticated={logged}
              exact
              path="/clientes/create"
              component={ClientesCreate}
            />

            <PrivateRoute
              isAuthenticated={logged}
              exact
              path="/clientes/detail/:clienteID"
              component={ClientesDetails}
            />

            <PrivateRoute
              isAuthenticated={logged}
              exact
              path="/proveedores"
              component={Proveedores}
            />

            <PrivateRoute
              isAuthenticated={logged}
              exact
              path="/proveedores/detail/:proveedorID"
              component={ProveedoresDetails}
            />

            <PrivateRoute
              isAuthenticated={logged}
              exact
              path="/proveedores/create"
              component={ProveedoresCreate}
            />

            <PrivateRoute
              isAuthenticated={logged}
              exact
              path="/articulos"
              component={Articulos}
            />

            <PrivateRoute
              isAuthenticated={logged}
              exact
              path="/articulos/create"
              component={ArticulosCreate}
            />

            <PrivateRoute
              isAuthenticated={logged}
              exact
              path="/articulos/details/:articuloID"
              component={ArticulosDetails}
            />

            <PrivateRoute
              isAuthenticated={logged}
              exact
              path="/catalogos"
              component={Catalogos}
            />

            <PrivateRoute
              isAuthenticated={logged}
              exact
              path="/catalogos/create"
              component={CatalogosCreate}
            />

            <PrivateRoute
              isAuthenticated={logged}
              exact
              path="/constantes"
              component={Constantes}
            />
          </Suspense>
          
        </LayoutPage>
      </Switch>
    </Router>
  );
}
