import { Col, Row } from "antd";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import InputSin from "../../../components/input/InputSin";
import InputSinNumber from "../../../components/input/InputSinNumber";
import SelectSin from "../../../components/input/SelectSin";
import BotonCard from "../../../components/Boton/BotonCard";
import {
  addCotizacionItem,
  startPostCotizacionItem,
  startSetOpcion1,
  startUpdateOpcion1,
  sumaOpcion1,
} from "../../../context/actions/cotizacionesActions";
import getTextOptions from "../../../utils/getTextOptions";

////
export default function ArticulosCreate() {
  const dispatch = useDispatch();
  const [articuloNew, setAriculoNew] = useState({});
  const onChange = ({ target: { name, value } }) => {
    setAriculoNew((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const { cotizacionID } = useSelector(
    (state) => state.cotizacionesReducer.cotizacion
  );
  const { articulo, nuevoReimpresion, cantidad } = articuloNew;

  const handleClick = async () => {
    if (
      articulo &&
      articulo !== "" &&
      nuevoReimpresion &&
      nuevoReimpresion !== "" &&
      cantidad &&
      cantidad !== ""
    ) {
      await dispatch(addCotizacionItem(articuloNew));
      dispatch(startSetOpcion1());

      if (cotizacionID !== 0) {
        await dispatch(
          startPostCotizacionItem({ ...articuloNew, cotizacionID })
        );
        dispatch(startUpdateOpcion1());
      }

      setAriculoNew({});
    }
  };
  const arteOptions = getTextOptions(["Nuevo", "Reimpresión"]);

  ////
  return (
    <>
      <Row gutter={10}>
        <Col xs={8} sm={6} xl={4}>
          Artículo
        </Col>
        <Col xs={8} sm={6} xl={4}>
          Arte
        </Col>
        <Col xs={8} sm={6} xl={4}>
          Cantidad
        </Col>
      </Row>
      <Row gutter={10}>
        <Col xs={8} sm={6} xl={4}>
          <InputSin name="articulo" value={articulo} onChange={onChange} />
        </Col>
        <Col xs={8} sm={6} xl={4}>
          <SelectSin
            name="nuevoReimpresion"
            value={nuevoReimpresion}
            onChange={onChange}
            options={arteOptions}
          />
        </Col>

        <Col xs={8} sm={6} xl={4}>
          <InputSinNumber
            name="cantidad"
            value={cantidad}
            onChange={onChange}
          />
        </Col>
        <Col xs={12} sm={6} xl={4}>
          <BotonCard onClick={handleClick} tituloBoton="Agregar" />
        </Col>
      </Row>
    </>
  );
}
