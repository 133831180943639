import { useDispatch } from "react-redux";
import BotonSubmit from "../../../components/Boton/BotonSubmit";
import { startPostCotizacion } from "../../../context/actions/cotizacionesActions";

export default function SubmitCreate() {
  const dispatch = useDispatch();

  const handleClick = () => dispatch(startPostCotizacion());

  return (
    <div style={{ marginTop: 20, marginBottom: 30 }}>
      <BotonSubmit onClick={handleClick} tituloBoton="Crear Cotización" />
    </div>
  );
}
