import { Col } from "antd";
import React from "react";

export default function LabelValor({ label, valor }) {
  return (
    <Col xs={24} sm={12} md={10} xl={6}>
      <div className="label">{label}</div>
      <div className="valor">{valor}</div>
    </Col>
  );
}
