import { useDispatch, useSelector } from "react-redux";
import InputLabelNumber from "../../../components/input/InputLabelNumber";
import { Button, Col, Row } from "antd";
import { changeCotizacion } from "../../../context/actions/cotizacionesActions";
import BotonCard from "../../../components/Boton/BotonCard";
import { useState } from "react";

export default function ArticulosTotales() {
  const dispatch = useDispatch();
  const { opcion1, opcion2, opcion3, opcion4, opcion5 } = useSelector(
    (state) => state.cotizacionesReducer.cotizacion
  );

  const [opciones, setOpciones] = useState({
    opcion1,
    opcion2,
    opcion3,
    opcion4,
    opcion5,
  });

  const onChange = ({ target: { name, value } }) => {
    setOpciones({ ...opciones, [name]: Number(value) });
  };

  const handleClick = () => {
    console.log(opciones);
    dispatch(changeCotizacion(opciones));
  };

  return (
    <>
      <hr />
      <Row gutter={10}>
        <Col>
          <InputLabelNumber
            onChange={onChange}
            name="opcion1"
            value={opciones.opcion1}
            label="Opcion 1"
            readOnly
          />
        </Col>
        <Col>
          <InputLabelNumber
            onChange={onChange}
            name="opcion2"
            value={opciones.opcion2}
            label="Opcion 2"
          />
        </Col>
        <Col>
          <InputLabelNumber
            onChange={onChange}
            name="opcion3"
            value={opciones.opcion3}
            label="Opcion 3"
          />
        </Col>
        <Col>
          <InputLabelNumber
            onChange={onChange}
            name="opcion4"
            value={opciones.opcion4}
            label="Opcion 4"
          />
        </Col>
        <Col>
          <InputLabelNumber
            onChange={onChange}
            name="opcion5"
            value={opciones.opcion5}
            label="Opcion 5"
          />
        </Col>
        <Col style={{ paddingTop: 20 }}>
          <BotonCard onClick={handleClick} tituloBoton="Aplicar" />
        </Col>
      </Row>
    </>
  );
}
