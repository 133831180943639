import { Col, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import BotonCheck from "../../../components/Boton/BotonCheck";
import { SinGuardar } from "../../../components/Boton/SinGuardar";
import { startPostFase } from "../../../context/actions/cotizacionesActions";

////
export default function ButtonContinuar() {
  const {
    cotizacion: { step },
    seccionModificada: { solicitudCotizacion },
  } = useSelector((state) => state.cotizacionesReducer);
  const dispatch = useDispatch();
  const accionBoton = () => {
    dispatch(startPostFase("fase1"));
  };

  return (
    <Row justify="end">
      <Col>
        <SinGuardar show={solicitudCotizacion} />
      </Col>
      <Col>
        <BotonCheck
          tooltip="Continuar"
          onClick={accionBoton}
          style={step > 1 ? { color: "green" } : { color: "#ad7100" }}
        />
      </Col>
    </Row>
  );
}
