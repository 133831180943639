import { useSelector } from "react-redux";
import InputSinNumber from "../../../../components/input/InputSinNumber";

import ColOpcion from "./ColOpcion";
import ColTitulo from "./ColTitulo";
import RowCosto from "./RowCosto";

export default function CostoTiempoMaquinaImpRSS() {
  const {

    costoTiempoMaquinaImpRSSOpcion1,
    costoTiempoMaquinaImpRSSOpcion2,
    costoTiempoMaquinaImpRSSOpcion3,
    costoTiempoMaquinaImpRSSOpcion4,
    costoTiempoMaquinaImpRSSOpcion5,
  } = useSelector((state) => state.cotizacionesReducer.cotizacion);

    return (
    <RowCosto>
      <ColTitulo>Costo tiempo máquina IMPRESIÓN RSS</ColTitulo>
      <ColOpcion>
        <InputSinNumber value={costoTiempoMaquinaImpRSSOpcion1} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={costoTiempoMaquinaImpRSSOpcion2} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={costoTiempoMaquinaImpRSSOpcion3} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={costoTiempoMaquinaImpRSSOpcion4} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={costoTiempoMaquinaImpRSSOpcion5} disabled />
      </ColOpcion>
    </RowCosto>
  );
}
