import React, { useEffect, useState } from "react";
import { Select } from "antd";
const { Option } = Select;

export const UnoCien = ({ value, onChange, placeholder, size }) => {
  const [numeros, setNumeros] = useState([]);

  useEffect(() => {
    var salida = [];
    for (let i = 0; i <= 100; i++) salida = [...salida, i];
    setNumeros(salida);
  }, []);

  //
  return (
    <Select
      size={size}
      placeholder={placeholder}
      value={value}
      style={{ width: "100%" }}
      onChange={onChange}
    >
      {numeros.map((n, i) => (
        <Option key={i} value={n / 100}>{n}%</Option>
      ))}
    </Select>
  );
};
