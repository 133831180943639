import { useHistory } from "react-router";
import useLoadProveedores from "../../hooks/useLoadProveedores";
import CardPage from "../../layout/CardPage";
import Lista from "./Lista";

export default function Proveedores() {
  const history = useHistory();
  useLoadProveedores();
  return (
    <CardPage
      titulo="Proveedores"
      tituloBoton="Agregar Proveedor"
      accionBoton={() => history.push("/proveedores/create")}
    >
      <Lista />
    </CardPage>
  );
}
