import { DeleteOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  removeCotizacionItem,
  startDeleCotizacionItem,
  startSetOpcion1,
  startUpdateOpcion1,
} from "../../../context/actions/cotizacionesActions";
import NumberFormat from "react-number-format";

///
export default function ArticulosListaItem({
  articulo,
  nuevoReimpresion,
  cantidad,
  index,
  cotizacionItemsID,
}) {
  const dispatch = useDispatch();

  const handleClick = async () => {
    await dispatch(removeCotizacionItem(index));
    dispatch(startSetOpcion1());

    if (cotizacionItemsID !== 0) {
      await dispatch(startDeleCotizacionItem(cotizacionItemsID));
      dispatch(startUpdateOpcion1());
    }
  };

  ///
  return (
    <Row gutter={10}>
      <Col xs={7} sm={6} xl={4} style={{ textAlign: "center" }}>
        {articulo}
      </Col>
      <Col xs={7} sm={6} xl={4}>
        {nuevoReimpresion}
      </Col>
      <Col xs={7} sm={6} xl={4} style={{ textAlign: "right" }}>
        <NumberFormat
          value={cantidad}
          displayType={"text"}
          thousandSeparator={true}
        />
        {}
      </Col>
      <Col span={3}>
        <DeleteOutlined onClick={handleClick} className="eyeIcon" />
      </Col>
    </Row>
  );
}
