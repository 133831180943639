import React from "react";
import InputSinNumber from "../../../../components/input/InputSinNumber";
import RowCosto from "./RowCosto";
import ColOpcion from "./ColOpcion";
import ColTitulo from "./ColTitulo";
import { Tooltip } from "antd";
import { useSelector } from "react-redux";

import parseFloat2 from "../../../../utils/parseFloat2";

export const CotizacionExterna = () => {
  const { total1, total2, total3, total4, total5 } = useSelector(
    (state) => state.cotizacionesReducer.cotizacionExterna
  );

  ////
  return (
    <RowCosto>
      <ColTitulo style={{ color: "orange", cursor: "help" }}>
        <Tooltip title="Si hay maquila aplica este total">
          Cotización Externa
        </Tooltip>
      </ColTitulo>
      <ColOpcion>
        <InputSinNumber disabled value={parseFloat2(total1 || 0)} />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber disabled value={parseFloat2(total2 || 0)} />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber disabled value={parseFloat2(total3 || 0)} />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber disabled value={parseFloat2(total4 || 0)} />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber disabled value={parseFloat2(total5 || 0)} />
      </ColOpcion>
    </RowCosto>
  );
};
