import { useDispatch, useSelector } from "react-redux";
import InputSinNumber from "../../../components/input/InputSinNumber";
import { setCotizacionExterna } from "../../../context/actions/cotizacionesActions";
import ColOpcion from "../Calculos/renglonesCostos/ColOpcion";
import ColTitulo from "../Calculos/renglonesCostos/ColTitulo";
import RowCosto from "../Calculos/renglonesCostos/RowCosto";

export const PrecioCotizado = () => {
  const dispatch = useDispatch();
  const {
    precioCotizado1,
    precioCotizado2,
    precioCotizado3,
    precioCotizado4,
    precioCotizado5,
  } = useSelector((state) => state.cotizacionesReducer.cotizacionExterna);

  const handleChange = ({ target }) => {
    dispatch(
      setCotizacionExterna({
        [target.name]: Number(target.value),
      })
    );
  };



  //
  return (
    <RowCosto>
      <ColTitulo>Precio cotizado</ColTitulo>
      <ColOpcion>
        <InputSinNumber
          name="precioCotizado1"
          onChange={handleChange}
          value={precioCotizado1}
        />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber
          name="precioCotizado2"
          onChange={handleChange}
          value={precioCotizado2}
        />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber
          name="precioCotizado3"
          onChange={handleChange}
          value={precioCotizado3}
        />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber
          name="precioCotizado4"
          onChange={handleChange}
          value={precioCotizado4}
        />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber
          name="precioCotizado5"
          onChange={handleChange}
          value={precioCotizado5}
        />
      </ColOpcion>
    </RowCosto>
  );
};
