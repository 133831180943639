
import Fletes from "./_Fletes";
import PorcentajeMerma from "./_PorcentajeMerma";
import Velocidad from "./_Velocidad";

export default function Adicionales() {
    return (
        <div>
     
            <PorcentajeMerma />
            <Velocidad />
            <Fletes />
        </div>
    )
}
