import { Select } from "antd";
const { Option } = Select;

export const MesesSelect = ({ value, onChange, style }) => {
  return (
    <Select

      value={value}
      style={{ width: "100%", ...style }}
      onChange={onChange}
    >
      <Option value={1}>Ene</Option>
      <Option value={2}>Feb</Option>
      <Option value={3}>Mar</Option>
      <Option value={4}>Abr</Option>
      <Option value={5}>May</Option>
      <Option value={6}>Jun</Option>
      <Option value={7}>Jul</Option>
      <Option value={8}>Ago</Option>
      <Option value={9}>Sep</Option>
      <Option value={10}>Oct</Option>
      <Option value={11}>Nov</Option>
      <Option value={12}>Dic</Option>
    </Select>
  );
};
