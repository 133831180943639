import { useSelector } from "react-redux";
import InputSinNumber from "../../../../components/input/InputSinNumber";

import ColOpcion from "./ColOpcion";
import ColTitulo from "./ColTitulo";
import RowCosto from "./RowCosto";

export default function CostoSetupMaquinasAdicionales() {

  const {

    costoSetupMaquinasAdicionalesOpcion1,
    costoSetupMaquinasAdicionalesOpcion2,
    costoSetupMaquinasAdicionalesOpcion3,
    costoSetupMaquinasAdicionalesOpcion4,
    costoSetupMaquinasAdicionalesOpcion5,
  } = useSelector((state) => state.cotizacionesReducer.cotizacion);


  return (
    <RowCosto>
      <ColTitulo>Costo setup maquinas adicionales</ColTitulo>
      <ColOpcion>
        <InputSinNumber value={costoSetupMaquinasAdicionalesOpcion1} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={costoSetupMaquinasAdicionalesOpcion2} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={costoSetupMaquinasAdicionalesOpcion3} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={costoSetupMaquinasAdicionalesOpcion4} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={costoSetupMaquinasAdicionalesOpcion5} disabled />
      </ColOpcion>
    </RowCosto>
  );
}
