import { startLoadArticulos } from "./articulosActions";
import { startLoadCatalogos } from "./catalogosActions";
import { startLoadClientes } from "./clientesActions";
import { startLoadConstantes } from "./constantesActions";
import { startGetSubestatus, startLoadSubestatus } from "./cotizacionesActions";
import { startLoadPaises } from "./paisesActions";
import { startLoadProveedores } from "./proveedoresActions";
import { startGetVer } from "./verActions";

export const startLoadInitial = () => {
  return (dispatch) => {
    dispatch(startGetVer());
    dispatch(startLoadCatalogos());
    dispatch(startLoadConstantes());
    dispatch(startLoadProveedores());
    dispatch(startLoadClientes());
    dispatch(startLoadPaises());
    dispatch(startLoadArticulos());
    dispatch(startGetSubestatus());
  };
};
