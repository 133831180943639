import { useSelector } from "react-redux";
import InputSinNumber from "../../../components/input/InputSinNumber";
import ColOpcion from "../Calculos/renglonesCostos/ColOpcion";
import ColTitulo from "../Calculos/renglonesCostos/ColTitulo";
import RowCosto from "../Calculos/renglonesCostos/RowCosto";

export default function TotalPU() {
  const {
    totalPUOpcion1,
    totalPUOpcion2,
    totalPUOpcion3,
    totalPUOpcion4,
    totalPUOpcion5,
  } = useSelector((state) => state.cotizacionesReducer.cotizacion);

  return (
    <RowCosto>
      <ColTitulo>P/U (USD)</ColTitulo>
      <ColOpcion>
        <InputSinNumber value={totalPUOpcion1} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={totalPUOpcion2} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={totalPUOpcion3} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={totalPUOpcion4} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={totalPUOpcion5} disabled />
      </ColOpcion>
    </RowCosto>
  );
}
