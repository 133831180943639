import { URL_ARTICULOS } from "../../constants/url";
import {
  composeErrorNotification,
  composeSuccessNotification,
} from "../../utils/composeNotification";
import { get, post, put } from "../../utils/fetch";
import { types } from "../types/types";
import { notification } from "./notificationActions";

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const startLoadArticulos = () => {
  return async (dispatch) => {
    const data = await get(URL_ARTICULOS);

    if (data !== -1) {
      dispatch(storeArticulos(data));
    } else {
      dispatch(
        notification(
          composeErrorNotification("Error de conexion", "Get artículos")
        )
      );
    }
  };
};

const storeArticulos = (payload) => ({
  type: types.storeArticulos,
  payload,
});

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const startLoadArticulo = (id) => {
  return async (dispatch) => {
    const data = await get(`${URL_ARTICULOS}/${id}`);

    if (data !== -1) {
      dispatch(storeArticuloFull(data));
    } else {
      dispatch(
        notification(
          composeErrorNotification("Error de conexion", "Get artículo")
        )
      );
    }
  };
};

const storeArticuloFull = (payload) => ({
  type: types.storeArticuloFull,
  payload,
});

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const changeArticulo = (elemento) => ({
  type: types.changeArticulo,
  payload: elemento,
});

export const cleanArticulo = () => ({
  type: types.cleanArticulo,
  payload: null,
});

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const startPostArticulo = () => {
  return async (dispatch, getState) => {
    const { articulo } = getState().articulosReducer;
    const data = await post(URL_ARTICULOS, articulo);

    if (data !== -1) {
      dispatch(notification(composeSuccessNotification(data)));
    } else {
      dispatch(
        notification(
          composeErrorNotification("Error de conexion", "Post artículo")
        )
      );
    }
  };
};

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const startPutArticulo = () => {
  return async (dispatch, getState) => {
    const { articulo } = getState().articulosReducer;
    const data = await put(`${URL_ARTICULOS}/${articulo.articuloID}`, articulo);

    if (data !== -1) {
      dispatch(notification(composeSuccessNotification(data)));
    } else {
      dispatch(
        notification(
          composeErrorNotification("Error de conexion", "Put artículo")
        )
      );
    }
  };
};

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const startDeleteArticulo = (articuloID) => {
  return async (dispatch) => {
    const data = await get(`${URL_ARTICULOS}/DeleteArticulo/${articuloID}`);

    if (data !== -1) {
      return "ok"

    } else {
      dispatch(
        notification(
          composeErrorNotification("startEliminarArticulo", data.error)
        )
      );
    }
  };
};

///////////////////////////

export const startGetCotizacionesByArticulo = (articuloID) => {
  return async (dispatch) => {
    const data = await get(
      `${URL_ARTICULOS}/GetCotizacionesByArticulo/${articuloID}`
    );

    console.log({ data });
    if (data !== -1) {
      dispatch(setArticulosEnCotizacion(data));
    } else {
      dispatch(
        notification(
          composeErrorNotification("GetCotizacionesByArticulo", data.error)
        )
      );
    }
  };
};

const setArticulosEnCotizacion = (payload) => ({
  type: types.setArticulosEnCotizacion,
  payload,
});
