import { useSelector } from "react-redux";
import InputSinNumber from "../../../../components/input/InputSinNumber";

import ColOpcion from "./ColOpcion";
import ColTitulo from "./ColTitulo";
import RowCosto from "./RowCosto";

export default function TiempoImpresionDigital() {
  const {
    tiempoMaquinaImpDigitalOpcion1,
    tiempoMaquinaImpDigitalOpcion2,
    tiempoMaquinaImpDigitalOpcion3,
    tiempoMaquinaImpDigitalOpcion4,
    tiempoMaquinaImpDigitalOpcion5,
  } = useSelector((state) => state.cotizacionesReducer.cotizacion);

  return (
    <RowCosto>
      <ColTitulo>Tiempo impresión digital (Hrs)</ColTitulo>
      <ColOpcion>
        <InputSinNumber value={tiempoMaquinaImpDigitalOpcion1} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={tiempoMaquinaImpDigitalOpcion2} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={tiempoMaquinaImpDigitalOpcion3} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={tiempoMaquinaImpDigitalOpcion4} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={tiempoMaquinaImpDigitalOpcion5} disabled />
      </ColOpcion>
    </RowCosto>
  );
}
