import { articulosReducer } from "../reducers/articulosReducer";
import { authErrReducer } from "../reducers/authErrReducer";
import { catalogosReducer } from "../reducers/catalogosReducer";
import { clientesReducer } from "../reducers/clientesReducer";
import { constantesReducer } from "../reducers/constantesReducer";
import { cotizacionesReducer } from "../reducers/cotizacionesReducer";
import { erroresReducer } from "../reducers/erroresReducer";
import { historialReducer } from "../reducers/historialReducer";
import { loadingReducer } from "../reducers/loadingReducer";
import { mensajesReducer } from "../reducers/mensajesReducer";
import { notificationReducer } from "../reducers/notificationReducer";
import { paisesReducer } from "../reducers/paisesReducer";
import { proveedoresReducer } from "../reducers/proveedoresReducer";
import { terminadosReducer } from "../reducers/terminadosReducer";
import { userReducer } from "../reducers/userReducer";

export const allReducers = {
  clientesReducer: clientesReducer,
  proveedoresReducer: proveedoresReducer,
  articulosReducer: articulosReducer,
  constantesReducer: constantesReducer,
  catalogosReducer: catalogosReducer,
  mensajesReducer: mensajesReducer,
  loadingReducer: loadingReducer,
  notificationReducer: notificationReducer,
  paisesReducer: paisesReducer,
  cotizacionesReducer: cotizacionesReducer,
  authErrReducer: authErrReducer,
  userReducer: userReducer,
  terminadosReducer: terminadosReducer,
  historialReducer: historialReducer,
  erroresReducer: erroresReducer,
};
