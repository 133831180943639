import { useHistory } from "react-router";
import CardPage from "../../layout/CardPage";
import FormaArticulos from "./FormaArticulos";
import FormaCotizacion from "./FormaCotizacion";
import SubmitCreate from "./botones/SubmitCreate";

export default function CotizacionesCreate() {
  const history = useHistory();
  return (
    <>
      <CardPage
        titulo="Solicitud de Cotización"
        tituloBoton="Regresar"
        accionBoton={() => history.push("/")}
      >
        <FormaCotizacion />
      </CardPage>

      <CardPage titulo="Datos de Artículos">
        <FormaArticulos />
      </CardPage>

      <SubmitCreate />
    </>
  );
}
