import { Button, Tooltip } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";

export default function BotonCheck({ tooltip, onClick, style }) {
  return (
    <Tooltip title={tooltip}>
      <CheckCircleOutlined
        onClick={onClick}
        className="IconCheck"
        style={style}
      />
    </Tooltip>
  );
}
