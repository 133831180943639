import { useSelector } from "react-redux";
import SelectLabel from "../input/SelectLabel";
import { grupos } from "../../constants/cons";

////
export default function DropdownDado({ onChange, value, name, label, error }) {
  const { articulos } = useSelector((state) => state.articulosReducer);
  const dados = articulos.filter((a) => a.grupo === grupos.DADO);
  let opciones = [];
  dados.map((d) => {
    opciones = [
      ...opciones,
      {
        text: `${d.nombre} - ${d.categoria} (${d.across_inch},${d.down_inch}) - ${d.descripcion}`,
        value: d.articuloID,
      },
    ];
  });


  ///
  return (
    <SelectLabel
      error={error}
      label={label}
      onChange={onChange}
      placeholder="Selecciona"
      options={opciones}
      value={value}
      name={name}
      style={{ fontSize: 12 }}
    />
  );
}
