import { URL_PAISES } from "../../constants/url";
import { get } from "../../utils/fetch";
import { types } from "../types/types";


export const startLoadPaises = () => {
    return async (dispatch) => {
      const data = await get(URL_PAISES);
      if (data == -1) {
        // error
        return;
      }
      dispatch(storePaises(data));
    };
  };
  
  const storePaises = (payload) => ({
    type: types.storePaises,
    payload,
  });