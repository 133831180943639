import { EyeOutlined } from "@ant-design/icons";
import { useHistory } from "react-router";
export default function useClientesColumns() {
  const history = useHistory();

  const handleClick = ({ clienteID }) =>
    history.push(`/clientes/detail/${clienteID}`);

  const clientesColumns = [
    {
      title: "Nombre",
      dataIndex: "nombre",
    },
    {
      title: "Contacto Nombre",
      dataIndex: "contactoNombre",
    },
    {
      title: "Contacto Teléfono",
      dataIndex: "contactoTelefono",
    },
    {
      title: "Contacto Email",
      dataIndex: "contactoEmail",
    },
    {
      title: "País",
      dataIndex: "pais",
    },
    {
      title: "Estatus",
      dataIndex: "estatus",
    },
    {
      title: "",
      width: 30,
      render: (text, record) => (
        <EyeOutlined onClick={() => handleClick(record)} className="eyeIcon" />
      ),
    },
  ];

  return { clientesColumns };
}
