import { useSelector } from "react-redux";
import BotonEnviar from "./_BotonEnviar";
import { CalculadoraUnitario } from "./_CalculadoraUnitario";
import Total from "./_Total";
import TotalMM from "./_TotalM";
import TotalPU from "./_TotalPU";
import TotalPUMXN from "./_TotalPUMXN";
import Utilidad from "./_Utilidad";

//
export default function Totales() {
  const { utilidadOpcion1 } = useSelector(
    (state) => state.cotizacionesReducer.cotizacion
  );

  if (utilidadOpcion1 === 0) return <></>;
  return (
    <>
      <Utilidad />
      <Total />
      <TotalPU />
      <TotalPUMXN />
      <TotalMM />
      <BotonEnviar />
      <CalculadoraUnitario />
    </>
  );
}
