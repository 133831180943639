import { useHistory } from "react-router";
import Forma from "./Forma";
import CardPage from "../../layout/CardPage";
import SubmitEdit from "./SubmitEdit";
import { startLoadProveedor } from "../../context/actions/proveedoresActions";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

export default function ProveedoresDetails() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { proveedorID } = useParams();

  useEffect(() => {
    dispatch(startLoadProveedor(proveedorID));
  }, []);

  return (
    <>
      <CardPage
        titulo="Editar Proveedor"
        tituloBoton="Regresar"
        accionBoton={() => history.push("/proveedores")}
      >
        <Forma />
        <SubmitEdit />
      </CardPage>
    </>
  );
}
