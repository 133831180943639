import { Divider, Select } from "antd";
const { Option } = Select;

export default function SelectLabelPresentacion({
  label,
  onChange,
  placeholder,
  value,
  name,
  error,
}) {
  const handleChange = (val) => {
    onChange({ target: { name, value: val } });
  };

  return (
    <div>
      <div className={`inputLabel ${error && "error"} `}>{label}</div>

      <Select
        value={value}
        placeholder={placeholder}
        onChange={handleChange}
        style={{ width: "100%" }}
      >
        <Option value="Rollo">Rollo</Option>
        <Option value="Hoja">Hoja</Option>
        <Option value="Pieza">Pieza</Option>
      </Select>
    </div>
  );
}
