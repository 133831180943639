import { Col, Row, Spin } from "antd";
import FormaCliente from "./_FormaCliente";
import FormaMedidas from "./_FormaMedidas";
import { useDispatch, useSelector } from "react-redux";

export default function FormaCotizacion() {
  const { downloadingData } = useSelector((state) => state.loadingReducer);

  return (
    <>
      { downloadingData ? (<Spin />) : (
        <Row gutter={[10, 10]} style={{ marginBottom: 10 }}>
          <Col xs={24} md={14}>
            <FormaCliente />
          </Col>
          <Col xs={24} md={10}>
            <FormaMedidas />
          </Col>
        </Row>
      )}
    </>
  );
}
