import { useSelector } from "react-redux";
import InputSinNumber from "../../../../components/input/InputSinNumber";

import ColOpcion from "./ColOpcion";
import ColTitulo from "./ColTitulo";
import RowCosto from "./RowCosto";

export default function Empaque() {
  const {
    empaqueOpcion1,
    empaqueOpcion2,
    empaqueOpcion3,
    empaqueOpcion4,
    empaqueOpcion5,
  } = useSelector((state) => state.cotizacionesReducer.cotizacion);

  return (
    <RowCosto>
      <ColTitulo>Empaque</ColTitulo>
      <ColOpcion>
        <InputSinNumber value={empaqueOpcion1} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={empaqueOpcion2} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={empaqueOpcion3} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={empaqueOpcion4} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={empaqueOpcion5} disabled />
      </ColOpcion>
    </RowCosto>
  );
}
