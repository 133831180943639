import { multiplicadorEmpaque } from "../../constants/cons";
import parseFloat2 from "../../utils/parseFloat2";
import parseFloat3 from "../../utils/parseFloat3";
import parseFloat4 from "../../utils/parseFloat4";
import { changeCotizacion } from "./cotizacionesActions";
import { cleanSeccionModificada, setSeccionModificada } from "./stepActions";

export const setEmpaque = () => {
  return (dispatch, getState) => {
    const {
      costoTiempoMaquinaImpDigitalOpcion1,
      costoTiempoMaquinaImpDigitalOpcion2,
      costoTiempoMaquinaImpDigitalOpcion3,
      costoTiempoMaquinaImpDigitalOpcion4,
      costoTiempoMaquinaImpDigitalOpcion5,

      costoTiempoMaquinaImpRSSOpcion1,
      costoTiempoMaquinaImpRSSOpcion2,
      costoTiempoMaquinaImpRSSOpcion3,
      costoTiempoMaquinaImpRSSOpcion4,
      costoTiempoMaquinaImpRSSOpcion5,

      costoTiempoMaquinaImpOffsetOpcion1,
      costoTiempoMaquinaImpOffsetOpcion2,
      costoTiempoMaquinaImpOffsetOpcion3,
      costoTiempoMaquinaImpOffsetOpcion4,
      costoTiempoMaquinaImpOffsetOpcion5,

      costoTiempoMaquinaAdicionalOpcion1,
      costoTiempoMaquinaAdicionalOpcion2,
      costoTiempoMaquinaAdicionalOpcion3,
      costoTiempoMaquinaAdicionalOpcion4,
      costoTiempoMaquinaAdicionalOpcion5,

      costoTiempoMaquinaBaseBlancaDigitalOpcion1,
      costoTiempoMaquinaBaseBlancaDigitalOpcion2,
      costoTiempoMaquinaBaseBlancaDigitalOpcion3,
      costoTiempoMaquinaBaseBlancaDigitalOpcion4,
      costoTiempoMaquinaBaseBlancaDigitalOpcion5,

      costoSetupOffsetOpcion1,
      costoSetupOffsetOpcion2,
      costoSetupOffsetOpcion3,
      costoSetupOffsetOpcion4,
      costoSetupOffsetOpcion5,

      costoSetupDigitalOpcion1,
      costoSetupDigitalOpcion2,
      costoSetupDigitalOpcion3,
      costoSetupDigitalOpcion4,
      costoSetupDigitalOpcion5,

      costoSetupSerigrafiaOpcion1,
      costoSetupSerigrafiaOpcion2,
      costoSetupSerigrafiaOpcion3,
      costoSetupSerigrafiaOpcion4,
      costoSetupSerigrafiaOpcion5,

      costoSetupMaquinasAdicionalesOpcion1,
      costoSetupMaquinasAdicionalesOpcion2,
      costoSetupMaquinasAdicionalesOpcion3,
      costoSetupMaquinasAdicionalesOpcion4,
      costoSetupMaquinasAdicionalesOpcion5,

      costoSubstratoOpcion1,
      costoSubstratoOpcion2,
      costoSubstratoOpcion3,
      costoSubstratoOpcion4,
      costoSubstratoOpcion5,

      costoLaminadoOpcion1,
      costoLaminadoOpcion2,
      costoLaminadoOpcion3,
      costoLaminadoOpcion4,
      costoLaminadoOpcion5,

      costoCorteIndividualOpcion1,
      costoCorteIndividualOpcion2,
      costoCorteIndividualOpcion3,
      costoCorteIndividualOpcion4,
      costoCorteIndividualOpcion5,

      costoPreprensaOffsetOpcion1,
      costoPreprensaOffsetOpcion2,
      costoPreprensaOffsetOpcion3,
      costoPreprensaOffsetOpcion4,
      costoPreprensaOffsetOpcion5,

      depreciacionDadoSuajeOpcion1,
      depreciacionDadoSuajeOpcion2,
      depreciacionDadoSuajeOpcion3,
      depreciacionDadoSuajeOpcion4,
      depreciacionDadoSuajeOpcion5,

      cambioPlacasOpcion1,
      cambioPlacasOpcion2,
      cambioPlacasOpcion3,
      cambioPlacasOpcion4,
      cambioPlacasOpcion5,

      cambioColorOpcion1,
      cambioColorOpcion2,
      cambioColorOpcion3,
      cambioColorOpcion4,
      cambioColorOpcion5,

      cargoPreparacionArteOpcion1,
      cargoPreparacionArteOpcion2,
      cargoPreparacionArteOpcion3,
      cargoPreparacionArteOpcion4,
      cargoPreparacionArteOpcion5,

      costoUsoPrimeraOpcion1,
      costoUsoPrimeraOpcion2,
      costoUsoPrimeraOpcion3,
      costoUsoPrimeraOpcion4,
      costoUsoPrimeraOpcion5,

      cargoInfoVariableOpcion1,
      cargoInfoVariableOpcion2,
      cargoInfoVariableOpcion3,
      cargoInfoVariableOpcion4,
      cargoInfoVariableOpcion5,

      pantallaRSSOpcion1,
      pantallaRSSOpcion2,
      pantallaRSSOpcion3,
      pantallaRSSOpcion4,
      pantallaRSSOpcion5,

      costoPantallaBarnizEspecialOpcion1,
      costoPantallaBarnizEspecialOpcion2,
      costoPantallaBarnizEspecialOpcion3,
      costoPantallaBarnizEspecialOpcion4,
      costoPantallaBarnizEspecialOpcion5,

      costoDadosOpcion1,
      costoDadosOpcion2,
      costoDadosOpcion3,
      costoDadosOpcion4,
      costoDadosOpcion5,

      tintasSemirotativaOpcion1,
      tintasSemirotativaOpcion2,
      tintasSemirotativaOpcion3,
      tintasSemirotativaOpcion4,
      tintasSemirotativaOpcion5,

      tintasSerigrafiaRSSOpcion1,
      tintasSerigrafiaRSSOpcion2,
      tintasSerigrafiaRSSOpcion3,
      tintasSerigrafiaRSSOpcion4,
      tintasSerigrafiaRSSOpcion5,

      tonerCMYKOpcion1,
      tonerCMYKOpcion2,
      tonerCMYKOpcion3,
      tonerCMYKOpcion4,
      tonerCMYKOpcion5,

      tonerBlancoOpcion1,
      tonerBlancoOpcion2,
      tonerBlancoOpcion3,
      tonerBlancoOpcion4,
      tonerBlancoOpcion5,

      barnizEspecialRSSOpcion1,
      barnizEspecialRSSOpcion2,
      barnizEspecialRSSOpcion3,
      barnizEspecialRSSOpcion4,
      barnizEspecialRSSOpcion5,

      barnizMaquinaOpcion1,
      barnizMaquinaOpcion2,
      barnizMaquinaOpcion3,
      barnizMaquinaOpcion4,
      barnizMaquinaOpcion5,

      servicioEpsonOpcion1,
      servicioEpsonOpcion2,
      servicioEpsonOpcion3,
      servicioEpsonOpcion4,
      servicioEpsonOpcion5,

      foilOpcion1,
      foilOpcion2,
      foilOpcion3,
      foilOpcion4,
      foilOpcion5,

      fleteOpcion1,
      fleteOpcion2,
      fleteOpcion3,
      fleteOpcion4,
      fleteOpcion5,

      opcion1,
      opcion2,
      opcion3,
      opcion4,
      opcion5,
      tipoCaptura,

      totalMaquinaSetup1,
      totalMaquinaSetup2,
      totalMaquinaSetup3,
      totalMaquinaSetup4,
      totalMaquinaSetup5,
    } = getState().cotizacionesReducer.cotizacion;

    const { total1, total2, total3, total4, total5 } =
      getState().cotizacionesReducer.cotizacionExterna;

    const costoOpcion1 = parseFloat2(
      parseFloat4(Number(costoTiempoMaquinaImpDigitalOpcion1)) +
        parseFloat4(Number(costoTiempoMaquinaImpRSSOpcion1)) +
        parseFloat4(Number(costoTiempoMaquinaImpOffsetOpcion1)) +
        parseFloat4(Number(costoTiempoMaquinaAdicionalOpcion1)) +
        parseFloat4(Number(costoTiempoMaquinaBaseBlancaDigitalOpcion1)) +
        parseFloat4(Number(costoSetupOffsetOpcion1)) +
        parseFloat4(Number(costoSetupDigitalOpcion1)) +
        parseFloat4(Number(costoSetupSerigrafiaOpcion1)) +
        parseFloat4(Number(costoSetupMaquinasAdicionalesOpcion1)) +
        parseFloat4(Number(costoSubstratoOpcion1)) +
        parseFloat4(Number(costoLaminadoOpcion1)) +
        parseFloat4(Number(costoCorteIndividualOpcion1)) +
        parseFloat4(Number(costoPreprensaOffsetOpcion1)) +
        parseFloat4(Number(depreciacionDadoSuajeOpcion1)) +
        parseFloat4(Number(cambioPlacasOpcion1)) +
        parseFloat4(Number(cambioColorOpcion1)) +
        parseFloat4(Number(cargoPreparacionArteOpcion1)) +
        parseFloat4(Number(costoUsoPrimeraOpcion1)) +
        parseFloat4(Number(cargoInfoVariableOpcion1)) +
        parseFloat4(Number(pantallaRSSOpcion1)) +
        parseFloat4(Number(costoPantallaBarnizEspecialOpcion1)) +
        parseFloat4(Number(costoDadosOpcion1)) +
        parseFloat4(Number(tintasSemirotativaOpcion1)) +
        parseFloat4(Number(tintasSerigrafiaRSSOpcion1)) +
        parseFloat4(Number(tonerCMYKOpcion1)) +
        parseFloat4(Number(tonerBlancoOpcion1)) +
        parseFloat4(Number(barnizEspecialRSSOpcion1)) +
        parseFloat4(Number(barnizMaquinaOpcion1)) +
        parseFloat4(Number(servicioEpsonOpcion1)) +
        parseFloat4(Number(foilOpcion1))
      // parseFloat4(Number(totalMaquinaSetup1))
    );

    const costoOpcion2 = parseFloat2(
      Number(costoTiempoMaquinaImpDigitalOpcion2) +
        Number(costoTiempoMaquinaImpRSSOpcion2) +
        Number(costoTiempoMaquinaImpOffsetOpcion2) +
        Number(costoTiempoMaquinaAdicionalOpcion2) +
        Number(costoTiempoMaquinaBaseBlancaDigitalOpcion2) +
        Number(costoSetupOffsetOpcion2) +
        Number(costoSetupDigitalOpcion2) +
        Number(costoSetupSerigrafiaOpcion2) +
        Number(costoSetupMaquinasAdicionalesOpcion2) +
        Number(costoSubstratoOpcion2) +
        Number(costoLaminadoOpcion2) +
        Number(costoCorteIndividualOpcion2) +
        Number(costoPreprensaOffsetOpcion2) +
        Number(depreciacionDadoSuajeOpcion2) +
        Number(cambioPlacasOpcion2) +
        Number(cambioColorOpcion2) +
        Number(cargoPreparacionArteOpcion2) +
        Number(costoUsoPrimeraOpcion2) +
        Number(cargoInfoVariableOpcion2) +
        Number(pantallaRSSOpcion2) +
        Number(costoPantallaBarnizEspecialOpcion2) +
        Number(costoDadosOpcion2) +
        Number(tintasSemirotativaOpcion2) +
        Number(tintasSerigrafiaRSSOpcion2) +
        Number(tonerCMYKOpcion2) +
        Number(tonerBlancoOpcion2) +
        Number(barnizEspecialRSSOpcion2) +
        Number(barnizMaquinaOpcion2) +
        Number(servicioEpsonOpcion2) +
        Number(foilOpcion2)
      // Number(totalMaquinaSetup2)
    );

    const costoOpcion3 = parseFloat2(
      Number(costoTiempoMaquinaImpDigitalOpcion3) +
        Number(costoTiempoMaquinaImpRSSOpcion3) +
        Number(costoTiempoMaquinaImpOffsetOpcion3) +
        Number(costoTiempoMaquinaAdicionalOpcion3) +
        Number(costoTiempoMaquinaBaseBlancaDigitalOpcion3) +
        Number(costoSetupOffsetOpcion3) +
        Number(costoSetupDigitalOpcion3) +
        Number(costoSetupSerigrafiaOpcion3) +
        Number(costoSetupMaquinasAdicionalesOpcion3) +
        Number(costoSubstratoOpcion3) +
        Number(costoLaminadoOpcion3) +
        Number(costoCorteIndividualOpcion3) +
        Number(costoPreprensaOffsetOpcion3) +
        Number(depreciacionDadoSuajeOpcion3) +
        Number(cambioPlacasOpcion3) +
        Number(cambioColorOpcion3) +
        Number(cargoPreparacionArteOpcion3) +
        Number(costoUsoPrimeraOpcion3) +
        Number(cargoInfoVariableOpcion3) +
        Number(pantallaRSSOpcion3) +
        Number(costoPantallaBarnizEspecialOpcion3) +
        Number(costoDadosOpcion3) +
        Number(tintasSemirotativaOpcion3) +
        Number(tintasSerigrafiaRSSOpcion3) +
        Number(tonerCMYKOpcion3) +
        Number(tonerBlancoOpcion3) +
        Number(barnizEspecialRSSOpcion3) +
        Number(barnizMaquinaOpcion3) +
        Number(servicioEpsonOpcion3) +
        Number(foilOpcion3)
      // Number(totalMaquinaSetup3)
    );

    const costoOpcion4 = parseFloat2(
      Number(costoTiempoMaquinaImpDigitalOpcion4) +
        Number(costoTiempoMaquinaImpRSSOpcion4) +
        Number(costoTiempoMaquinaImpOffsetOpcion4) +
        Number(costoTiempoMaquinaAdicionalOpcion4) +
        Number(costoTiempoMaquinaBaseBlancaDigitalOpcion4) +
        Number(costoSetupOffsetOpcion4) +
        Number(costoSetupDigitalOpcion4) +
        Number(costoSetupSerigrafiaOpcion4) +
        Number(costoSetupMaquinasAdicionalesOpcion4) +
        Number(costoSubstratoOpcion4) +
        Number(costoLaminadoOpcion4) +
        Number(costoCorteIndividualOpcion4) +
        Number(costoPreprensaOffsetOpcion4) +
        Number(depreciacionDadoSuajeOpcion4) +
        Number(cambioPlacasOpcion4) +
        Number(cambioColorOpcion4) +
        Number(cargoPreparacionArteOpcion4) +
        Number(costoUsoPrimeraOpcion4) +
        Number(cargoInfoVariableOpcion4) +
        Number(pantallaRSSOpcion4) +
        Number(costoPantallaBarnizEspecialOpcion4) +
        Number(costoDadosOpcion4) +
        Number(tintasSemirotativaOpcion4) +
        Number(tintasSerigrafiaRSSOpcion4) +
        Number(tonerCMYKOpcion4) +
        Number(tonerBlancoOpcion4) +
        Number(barnizEspecialRSSOpcion4) +
        Number(barnizMaquinaOpcion4) +
        Number(servicioEpsonOpcion4) +
        Number(foilOpcion4)
      // Number(totalMaquinaSetup4)
    );

    const costoOpcion5 = parseFloat2(
      Number(costoTiempoMaquinaImpDigitalOpcion5) +
        Number(costoTiempoMaquinaImpRSSOpcion5) +
        Number(costoTiempoMaquinaImpOffsetOpcion5) +
        Number(costoTiempoMaquinaAdicionalOpcion5) +
        Number(costoTiempoMaquinaBaseBlancaDigitalOpcion5) +
        Number(costoSetupOffsetOpcion5) +
        Number(costoSetupDigitalOpcion5) +
        Number(costoSetupSerigrafiaOpcion5) +
        Number(costoSetupMaquinasAdicionalesOpcion5) +
        Number(costoSubstratoOpcion5) +
        Number(costoLaminadoOpcion5) +
        Number(costoCorteIndividualOpcion5) +
        Number(costoPreprensaOffsetOpcion5) +
        Number(depreciacionDadoSuajeOpcion5) +
        Number(cambioPlacasOpcion5) +
        Number(cambioColorOpcion5) +
        Number(cargoPreparacionArteOpcion5) +
        Number(costoUsoPrimeraOpcion5) +
        Number(cargoInfoVariableOpcion5) +
        Number(pantallaRSSOpcion5) +
        Number(costoPantallaBarnizEspecialOpcion5) +
        Number(costoDadosOpcion5) +
        Number(tintasSemirotativaOpcion5) +
        Number(tintasSerigrafiaRSSOpcion5) +
        Number(tonerCMYKOpcion5) +
        Number(tonerBlancoOpcion5) +
        Number(barnizEspecialRSSOpcion5) +
        Number(barnizMaquinaOpcion5) +
        Number(servicioEpsonOpcion5) +
        Number(foilOpcion5)
      // Number(totalMaquinaSetup5)
    );

    let salida = {
      empaqueOpcion1: parseFloat2(costoOpcion1 * multiplicadorEmpaque),
      empaqueOpcion2: parseFloat2(costoOpcion2 * multiplicadorEmpaque),
      empaqueOpcion3: parseFloat2(costoOpcion3 * multiplicadorEmpaque),
      empaqueOpcion4: parseFloat2(costoOpcion4 * multiplicadorEmpaque),
      empaqueOpcion5: parseFloat2(costoOpcion5 * multiplicadorEmpaque),

      subtotalOpcion1: parseFloat2(
        Number(costoOpcion1) * (1 + Number(multiplicadorEmpaque)) +
          Number(fleteOpcion1) +
          (Number(total1) || 0) -
          (Number(total1) > 0 ? Number(totalMaquinaSetup1) : 0)
      ),
      subtotalOpcion2:
        opcion2 > 0
          ? parseFloat2(
              Number(costoOpcion2) * (1 + Number(multiplicadorEmpaque)) +
                Number(fleteOpcion2) +
                (Number(total2) || 0)
            )
          : 0,
      subtotalOpcion3:
        opcion3 > 0
          ? parseFloat2(
              Number(costoOpcion3) * (1 + Number(multiplicadorEmpaque)) +
                Number(fleteOpcion3) +
                (Number(total3) || 0)
            )
          : 0,
      subtotalOpcion4:
        opcion4 > 0
          ? parseFloat2(
              Number(costoOpcion4) * (1 + Number(multiplicadorEmpaque)) +
                Number(fleteOpcion4) +
                (Number(total4) || 0)
            )
          : 0,
      subtotalOpcion5:
        opcion5 > 0
          ? parseFloat2(
              Number(costoOpcion5) * (1 + Number(multiplicadorEmpaque)) +
                Number(fleteOpcion5) +
                (Number(total5) || 0)
            )
          : 0,
    };

    if (tipoCaptura === "nueva") {
      salida = {
        ...salida,
        utilidadPorcentajeOpcion1: getUtilidad(opcion1),
        utilidadPorcentajeOpcion2: getUtilidad(opcion2),
        utilidadPorcentajeOpcion3: getUtilidad(opcion3),
        utilidadPorcentajeOpcion4: getUtilidad(opcion4),
        utilidadPorcentajeOpcion5: getUtilidad(opcion5),
      };
    }

    dispatch(changeCotizacion(salida));
  };
};

export const Aplicar = () => {
  return async (dispatch, getState) => {
    await dispatch(setTotalotal());
    await dispatch(setTotalPU());
    await dispatch(setTotalM());
    await dispatch(setTiempoCotizado());
    await dispatch(cleanSeccionModificada());
    await dispatch(setSeccionModificada({ guardar: true }));
  };
};

const getUtilidad = (monto) => {
  let salida;
  if (monto < 1001) salida = 100;
  else if (monto < 2501) salida = 65;
  else if (monto < 5001) salida = 45;
  else salida = 30;

  return salida;
};

const setTiempoCotizado = () => {
  return (dispatch, getState) => {
    const {
      sistemaCotizado,
      tiempoMaquinaImpDigitalOpcion1,
      tiempoMaquinaImpDigitalOpcion2,
      tiempoMaquinaImpDigitalOpcion3,
      tiempoMaquinaImpDigitalOpcion4,
      tiempoMaquinaImpDigitalOpcion5,

      tiempoMaquinaImpOffsetOpcion1,
      tiempoMaquinaImpOffsetOpcion2,
      tiempoMaquinaImpOffsetOpcion3,
      tiempoMaquinaImpOffsetOpcion4,
      tiempoMaquinaImpOffsetOpcion5,

      tiempoMaquinaSetupOffsetOpcion1,
      tiempoMaquinaSetupOffsetOpcion2,
      tiempoMaquinaSetupOffsetOpcion3,
      tiempoMaquinaSetupOffsetOpcion4,
      tiempoMaquinaSetupOffsetOpcion5,
    } = getState().cotizacionesReducer.cotizacion;

    const tiempoCotizadoOpcion1 =
      sistemaCotizado === "D"
        ? tiempoMaquinaImpDigitalOpcion1
        : Number(tiempoMaquinaImpOffsetOpcion1);

    const tiempoCotizadoOpcion2 =
      sistemaCotizado === "D"
        ? tiempoMaquinaImpDigitalOpcion2
        : Number(tiempoMaquinaImpOffsetOpcion2);

    const tiempoCotizadoOpcion3 =
      sistemaCotizado === "D"
        ? tiempoMaquinaImpDigitalOpcion3
        : Number(tiempoMaquinaImpOffsetOpcion3);

    const tiempoCotizadoOpcion4 =
      sistemaCotizado === "D"
        ? tiempoMaquinaImpDigitalOpcion4
        : Number(tiempoMaquinaImpOffsetOpcion4);

    const tiempoCotizadoOpcion5 =
      sistemaCotizado === "D"
        ? tiempoMaquinaImpDigitalOpcion5
        : Number(tiempoMaquinaImpOffsetOpcion5);

    const salida = {
      tiempoCotizadoOpcion1,
      tiempoCotizadoOpcion2,
      tiempoCotizadoOpcion3,
      tiempoCotizadoOpcion4,
      tiempoCotizadoOpcion5,
    };
    dispatch(changeCotizacion(salida));
  };
};
const setTotalotal = () => {
  return (dispatch, getState) => {
    const {
      opcion2,
      opcion3,
      opcion4,
      opcion5,
      subtotalOpcion1,
      subtotalOpcion2,
      subtotalOpcion3,
      subtotalOpcion4,
      subtotalOpcion5,

      utilidadPorcentajeOpcion1,
      utilidadPorcentajeOpcion2,
      utilidadPorcentajeOpcion3,
      utilidadPorcentajeOpcion4,
      utilidadPorcentajeOpcion5,
    } = getState().cotizacionesReducer.cotizacion;

    const salida = {
      utilidadOpcion1: parseFloat2(
        subtotalOpcion1 * (utilidadPorcentajeOpcion1 / 100)
      ),
      utilidadOpcion2:
        opcion2 > 0
          ? parseFloat2(
              Number(subtotalOpcion2) *
                (Number(utilidadPorcentajeOpcion2) / 100)
            )
          : 0,
      utilidadOpcion3:
        opcion3 > 0
          ? parseFloat2(
              Number(subtotalOpcion3) *
                (Number(utilidadPorcentajeOpcion3) / 100)
            )
          : 0,
      utilidadOpcion4:
        opcion4 > 0
          ? parseFloat2(
              Number(subtotalOpcion4) *
                (Number(utilidadPorcentajeOpcion4) / 100)
            )
          : 0,
      utilidadOpcion5:
        opcion5 > 0
          ? parseFloat2(
              Number(subtotalOpcion5) *
                (Number(utilidadPorcentajeOpcion5) / 100)
            )
          : 0,

      totalOpcion1: parseFloat2(
        Number(subtotalOpcion1) * (1 + Number(utilidadPorcentajeOpcion1) / 100)
      ),
      totalOpcion2:
        opcion2 > 0
          ? parseFloat2(
              Number(subtotalOpcion2) *
                (1 + Number(utilidadPorcentajeOpcion2) / 100)
            )
          : 0,
      totalOpcion3:
        opcion3 > 0
          ? parseFloat2(
              Number(subtotalOpcion3) *
                (1 + Number(utilidadPorcentajeOpcion3) / 100)
            )
          : 0,
      totalOpcion4:
        opcion4 > 0
          ? parseFloat2(
              Number(subtotalOpcion4) *
                (1 + Number(utilidadPorcentajeOpcion4) / 100)
            )
          : 0,
      totalOpcion5:
        opcion5 > 0
          ? parseFloat2(
              Number(subtotalOpcion5) *
                (1 + Number(utilidadPorcentajeOpcion5) / 100)
            )
          : 0,
    };

    dispatch(changeCotizacion(salida));
  };
};

const setTotalPU = () => {
  return (dispatch, getState) => {
    const {
      opcion1,
      opcion2,
      opcion3,
      opcion4,
      opcion5,
      totalOpcion1,
      totalOpcion2,
      totalOpcion3,
      totalOpcion4,
      totalOpcion5,
    } = getState().cotizacionesReducer.cotizacion;

    const salida = {
      totalPUOpcion1: parseFloat4(totalOpcion1 / opcion1),
      totalPUOpcion2: opcion2 > 0 ? parseFloat4(totalOpcion2 / opcion2) : 0,
      totalPUOpcion3: opcion3 > 0 ? parseFloat4(totalOpcion3 / opcion3) : 0,
      totalPUOpcion4: opcion4 > 0 ? parseFloat4(totalOpcion4 / opcion4) : 0,
      totalPUOpcion5: opcion5 > 0 ? parseFloat4(totalOpcion5 / opcion5) : 0,
    };
    dispatch(changeCotizacion(salida));
  };
};

const setTotalM = () => {
  return (dispatch, getState) => {
    const {
      totalPUOpcion1,
      totalPUOpcion2,
      totalPUOpcion3,
      totalPUOpcion4,
      totalPUOpcion5,
    } = getState().cotizacionesReducer.cotizacion;

    const salida = {
      totalMOpcion1: parseFloat2(totalPUOpcion1 * 1000),
      totalMOpcion2: parseFloat2(totalPUOpcion2 * 1000),
      totalMOpcion3: parseFloat2(totalPUOpcion3 * 1000),
      totalMOpcion4: parseFloat2(totalPUOpcion4 * 1000),
      totalMOpcion5: parseFloat2(totalPUOpcion5 * 1000),
    };
    dispatch(changeCotizacion(salida));
  };
};

export const setMetrosLineales = () => {
  return (dispatch, getState) => {
    const {
      totalPiesLinealesOpcion1,
      totalPiesLinealesOpcion2,
      totalPiesLinealesOpcion3,
      totalPiesLinealesOpcion4,
      totalPiesLinealesOpcion5,
    } = getState().cotizacionesReducer.cotizacion;

    const salida = {
      metrosLinealesRequeridosOpcion1: parseFloat3(
        totalPiesLinealesOpcion1 * 0.3048
      ),
      metrosLinealesRequeridosOpcion2: parseFloat3(
        totalPiesLinealesOpcion2 * 0.3048
      ),
      metrosLinealesRequeridosOpcion3: parseFloat3(
        totalPiesLinealesOpcion3 * 0.3048
      ),
      metrosLinealesRequeridosOpcion4: parseFloat3(
        totalPiesLinealesOpcion4 * 0.3048
      ),
      metrosLinealesRequeridosOpcion5: parseFloat3(
        totalPiesLinealesOpcion5 * 0.3048
      ),
    };

    dispatch(changeCotizacion(salida));
  };
};

export const setCostoMateriasPrimas = () => {
  return (dispatch, getState) => {
    const {
      costoSubstratoOpcion1,
      costoSubstratoOpcion2,
      costoSubstratoOpcion3,
      costoSubstratoOpcion4,
      costoSubstratoOpcion5,

      costoLaminadoOpcion1,
      costoLaminadoOpcion2,
      costoLaminadoOpcion3,
      costoLaminadoOpcion4,
      costoLaminadoOpcion5,

      costoPreprensaOffsetOpcion1,
      costoPreprensaOffsetOpcion2,
      costoPreprensaOffsetOpcion3,
      costoPreprensaOffsetOpcion4,
      costoPreprensaOffsetOpcion5,

      pantallaRSSOpcion1,
      pantallaRSSOpcion2,
      pantallaRSSOpcion3,
      pantallaRSSOpcion4,
      pantallaRSSOpcion5,

      costoPantallaBarnizEspecialOpcion1,
      costoPantallaBarnizEspecialOpcion2,
      costoPantallaBarnizEspecialOpcion3,
      costoPantallaBarnizEspecialOpcion4,
      costoPantallaBarnizEspecialOpcion5,

      costoDadosOpcion1,
      costoDadosOpcion2,
      costoDadosOpcion3,
      costoDadosOpcion4,
      costoDadosOpcion5,

      tintasSemirotativaOpcion1,
      tintasSemirotativaOpcion2,
      tintasSemirotativaOpcion3,
      tintasSemirotativaOpcion4,
      tintasSemirotativaOpcion5,

      tintasSerigrafiaRSSOpcion1,
      tintasSerigrafiaRSSOpcion2,
      tintasSerigrafiaRSSOpcion3,
      tintasSerigrafiaRSSOpcion4,
      tintasSerigrafiaRSSOpcion5,

      tonerCMYKOpcion1,
      tonerCMYKOpcion2,
      tonerCMYKOpcion3,
      tonerCMYKOpcion4,
      tonerCMYKOpcion5,

      tonerBlancoOpcion1,
      tonerBlancoOpcion2,
      tonerBlancoOpcion3,
      tonerBlancoOpcion4,
      tonerBlancoOpcion5,

      barnizEspecialRSSOpcion1,
      barnizEspecialRSSOpcion2,
      barnizEspecialRSSOpcion3,
      barnizEspecialRSSOpcion4,
      barnizEspecialRSSOpcion5,

      barnizMaquinaOpcion1,
      barnizMaquinaOpcion2,
      barnizMaquinaOpcion3,
      barnizMaquinaOpcion4,
      barnizMaquinaOpcion5,

      servicioEpsonOpcion1,
      servicioEpsonOpcion2,
      servicioEpsonOpcion3,
      servicioEpsonOpcion4,
      servicioEpsonOpcion5,

      foilOpcion1,
      foilOpcion2,
      foilOpcion3,
      foilOpcion4,
      foilOpcion5,

      empaqueOpcion1,
      empaqueOpcion2,
      empaqueOpcion3,
      empaqueOpcion4,
      empaqueOpcion5,
    } = getState().cotizacionesReducer.cotizacion;

    const salida = {
      costoMateriasPrimasOpcion1: parseFloat2(
        Number(costoSubstratoOpcion1) +
          Number(costoLaminadoOpcion1) +
          Number(costoPreprensaOffsetOpcion1) +
          Number(pantallaRSSOpcion1) +
          Number(costoPantallaBarnizEspecialOpcion1) +
          Number(costoDadosOpcion1) +
          Number(tintasSemirotativaOpcion1) +
          Number(tintasSerigrafiaRSSOpcion1) +
          Number(tonerCMYKOpcion1) +
          Number(tonerBlancoOpcion1) +
          Number(barnizEspecialRSSOpcion1) +
          Number(barnizMaquinaOpcion1) +
          Number(servicioEpsonOpcion1) +
          Number(foilOpcion1) +
          Number(empaqueOpcion1)
      ),
      costoMateriasPrimasOpcion2: parseFloat2(
        Number(costoSubstratoOpcion2) +
          Number(costoLaminadoOpcion2) +
          Number(costoPreprensaOffsetOpcion2) +
          Number(pantallaRSSOpcion2) +
          Number(costoPantallaBarnizEspecialOpcion2) +
          Number(costoDadosOpcion2) +
          Number(tintasSemirotativaOpcion2) +
          Number(tintasSerigrafiaRSSOpcion2) +
          Number(tonerCMYKOpcion2) +
          Number(tonerBlancoOpcion2) +
          Number(barnizEspecialRSSOpcion2) +
          Number(barnizMaquinaOpcion2) +
          Number(servicioEpsonOpcion2) +
          Number(foilOpcion2) +
          Number(empaqueOpcion2)
      ),
      costoMateriasPrimasOpcion3: parseFloat2(
        Number(costoSubstratoOpcion3) +
          Number(costoLaminadoOpcion3) +
          Number(costoPreprensaOffsetOpcion3) +
          Number(pantallaRSSOpcion3) +
          Number(costoPantallaBarnizEspecialOpcion3) +
          Number(costoDadosOpcion3) +
          Number(tintasSemirotativaOpcion3) +
          Number(tintasSerigrafiaRSSOpcion3) +
          Number(tonerCMYKOpcion3) +
          Number(tonerBlancoOpcion3) +
          Number(barnizEspecialRSSOpcion3) +
          Number(barnizMaquinaOpcion3) +
          Number(servicioEpsonOpcion3) +
          Number(foilOpcion3) +
          Number(empaqueOpcion3)
      ),
      costoMateriasPrimasOpcion4: parseFloat2(
        Number(costoSubstratoOpcion4) +
          Number(costoLaminadoOpcion4) +
          Number(costoPreprensaOffsetOpcion4) +
          Number(pantallaRSSOpcion4) +
          Number(costoPantallaBarnizEspecialOpcion4) +
          Number(costoDadosOpcion4) +
          Number(tintasSemirotativaOpcion4) +
          Number(tintasSerigrafiaRSSOpcion4) +
          Number(tonerCMYKOpcion4) +
          Number(tonerBlancoOpcion4) +
          Number(barnizEspecialRSSOpcion4) +
          Number(barnizMaquinaOpcion4) +
          Number(servicioEpsonOpcion4) +
          Number(foilOpcion4) +
          Number(empaqueOpcion4)
      ),
      costoMateriasPrimasOpcion5: parseFloat2(
        Number(costoSubstratoOpcion5) +
          Number(costoLaminadoOpcion5) +
          Number(costoPreprensaOffsetOpcion5) +
          Number(pantallaRSSOpcion5) +
          Number(costoPantallaBarnizEspecialOpcion5) +
          Number(costoDadosOpcion5) +
          Number(tintasSemirotativaOpcion5) +
          Number(tintasSerigrafiaRSSOpcion5) +
          Number(tonerCMYKOpcion5) +
          Number(tonerBlancoOpcion5) +
          Number(barnizEspecialRSSOpcion5) +
          Number(barnizMaquinaOpcion5) +
          Number(servicioEpsonOpcion5) +
          Number(foilOpcion5) +
          Number(empaqueOpcion5)
      ),
    };

    dispatch(changeCotizacion(salida));
  };
};

export const setTiempoMaquinaImpDigital = () => {
  return (dispatch, getState) => {
    const {
      sistemaCotizado,
      totalPiesLinealesOpcion1,
      totalPiesLinealesOpcion2,
      totalPiesLinealesOpcion3,
      totalPiesLinealesOpcion4,
      totalPiesLinealesOpcion5,

      velocidadOpcion1,
      velocidadOpcion2,
      velocidadOpcion3,
      velocidadOpcion4,
      velocidadOpcion5,
    } = getState().cotizacionesReducer.cotizacion;

    const salida = {
      tiempoMaquinaImpDigitalOpcion1:
        sistemaCotizado === "D"
          ? parseFloat2(
              Number(totalPiesLinealesOpcion1) /
                (Number(velocidadOpcion1) + 10) /
                60
            )
          : 0,

      tiempoMaquinaImpDigitalOpcion2:
        sistemaCotizado === "D"
          ? parseFloat2(
              Number(totalPiesLinealesOpcion2) /
                (Number(velocidadOpcion2) + 10) /
                60
            )
          : 0,

      tiempoMaquinaImpDigitalOpcion3:
        sistemaCotizado === "D"
          ? parseFloat2(
              Number(totalPiesLinealesOpcion3) /
                (Number(velocidadOpcion3) + 10) /
                60
            )
          : 0,

      tiempoMaquinaImpDigitalOpcion4:
        sistemaCotizado === "D"
          ? parseFloat2(
              Number(totalPiesLinealesOpcion4) /
                (Number(velocidadOpcion4) + 10) /
                60
            )
          : 0,

      tiempoMaquinaImpDigitalOpcion5:
        sistemaCotizado === "D"
          ? parseFloat2(
              Number(totalPiesLinealesOpcion5) /
                (Number(velocidadOpcion5) + 10) /
                60
            )
          : 0,
    };

    dispatch(changeCotizacion(salida));
  };
};

export const setTiempoMaquinaImpOff = () => {
  return (dispatch, getState) => {
    const {
      sistemaCotizado,
      noTintas,
      noCambioPlacas,
      noCambiosColor,
      noGolpesCalculoOpcion1,
      noGolpesCalculoOpcion2,
      noGolpesCalculoOpcion3,
      noGolpesCalculoOpcion4,
      noGolpesCalculoOpcion5,

      velocidadOpcion1,
      velocidadOpcion2,
      velocidadOpcion3,
      velocidadOpcion4,
      velocidadOpcion5,
    } = getState().cotizacionesReducer.cotizacion;

    const {
      tiempoMaquinaSetupOffset: tiempoMaquinaSetupOffsetCONS,
      tiempoMaquinaCambioPlacas: tiempoMaquinaCambioPlacasCONS,
      tiempoMaquinaCambioColor: tiempoMaquinaCambioColorCONS,
    } = getState().constantesReducer;

    const tiempoMaquinaSetupOffset = parseFloat2(
      Number(noTintas) * tiempoMaquinaSetupOffsetCONS
    );
    const tiempoMaquinaCambioPlacas = parseFloat2(
      Number(noCambioPlacas) * tiempoMaquinaCambioPlacasCONS
    );
    const tiempoMaquinaCambioColor = parseFloat2(
      Number(noCambiosColor) * tiempoMaquinaCambioColorCONS
    );
    const totalesTiempo =
      Number(tiempoMaquinaSetupOffset) +
      Number(tiempoMaquinaCambioPlacas) +
      Number(tiempoMaquinaCambioColor);

    const salida = {
      tiempoMaquinaImpOffsetOpcion1:
        sistemaCotizado === "O"
          ? parseFloat2(
              (noGolpesCalculoOpcion1 / velocidadOpcion1 + totalesTiempo) / 60
            )
          : 0,

      tiempoMaquinaImpOffsetOpcion2:
        sistemaCotizado === "O"
          ? parseFloat2(
              (noGolpesCalculoOpcion2 / velocidadOpcion2 + totalesTiempo) / 60
            )
          : 0,

      tiempoMaquinaImpOffsetOpcion3:
        sistemaCotizado === "O"
          ? parseFloat2(
              (noGolpesCalculoOpcion3 / velocidadOpcion3 + totalesTiempo) / 60
            )
          : 0,

      tiempoMaquinaImpOffsetOpcion4:
        sistemaCotizado === "O"
          ? parseFloat2(
              (noGolpesCalculoOpcion4 / velocidadOpcion4 + totalesTiempo) / 60
            )
          : 0,

      tiempoMaquinaImpOffsetOpcion5:
        sistemaCotizado === "O"
          ? parseFloat2(
              (noGolpesCalculoOpcion5 / velocidadOpcion5 + totalesTiempo) / 60
            )
          : 0,

      tiempoMaquinaSetupOffsetOpcion1: tiempoMaquinaSetupOffset,
      tiempoMaquinaSetupOffsetOpcion2: tiempoMaquinaSetupOffset,
      tiempoMaquinaSetupOffsetOpcion3: tiempoMaquinaSetupOffset,
      tiempoMaquinaSetupOffsetOpcion4: tiempoMaquinaSetupOffset,
      tiempoMaquinaSetupOffsetOpcion5: tiempoMaquinaSetupOffset,

      tiempoMaquinaCambioPlacasOpcion1: tiempoMaquinaCambioPlacas,
      tiempoMaquinaCambioPlacasOpcion2: tiempoMaquinaCambioPlacas,
      tiempoMaquinaCambioPlacasOpcion3: tiempoMaquinaCambioPlacas,
      tiempoMaquinaCambioPlacasOpcion4: tiempoMaquinaCambioPlacas,
      tiempoMaquinaCambioPlacasOpcion5: tiempoMaquinaCambioPlacas,

      tiempoMaquinaCambioColorOpcion1: tiempoMaquinaCambioColor,
      tiempoMaquinaCambioColorOpcion2: tiempoMaquinaCambioColor,
      tiempoMaquinaCambioColorOpcion3: tiempoMaquinaCambioColor,
      tiempoMaquinaCambioColorOpcion4: tiempoMaquinaCambioColor,
      tiempoMaquinaCambioColorOpcion5: tiempoMaquinaCambioColor,
    };

    dispatch(changeCotizacion(salida));
  };
};
