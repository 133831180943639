import React from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import BotonSubmit from "../../../components/Boton/BotonSubmit";
import { startPostCotizacionExterna } from "../../../context/actions/cotizacionesActions";

export const BotonActualizar = () => {
  const dispatch = useDispatch();
  const { cotizacionID } = useSelector(
    (state) => state.cotizacionesReducer.cotizacion
  );

  const handleSubmit = () => dispatch(startPostCotizacionExterna());
  return (
    <div style={{ textAlign: "center" }}>
      <BotonSubmit
        disabled={cotizacionID == 0 || !cotizacionID}
        onClick={handleSubmit}
        tituloBoton="Guardar Datos"
      />
    </div>
  );
};
