import { useDispatch, useSelector } from "react-redux";
import InputSin from "../../../components/input/InputSin";
import { setCotizacionExterna } from "../../../context/actions/cotizacionesActions";
import ColOpcion from "../Calculos/renglonesCostos/ColOpcion";
import ColTitulo from "../Calculos/renglonesCostos/ColTitulo";
import RowCosto from "../Calculos/renglonesCostos/RowCosto";

export const Proveedor = () => {
  const dispatch = useDispatch();

  const { proveedor } = useSelector(
    (state) => state.cotizacionesReducer.cotizacionExterna
  );

  const handleChange = ({ target }) => {
    dispatch(
      setCotizacionExterna({
        [target.name]: target.value,
      })
    );
  };

  //
  return (
    <RowCosto>
      <ColTitulo>Proveedor</ColTitulo>
      <ColOpcion>
        <InputSin name="proveedor" onChange={handleChange} value={proveedor} />
      </ColOpcion>
    </RowCosto>
  );
};
