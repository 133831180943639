import { Input } from "antd";

export default function InputLabel({
  name,
  label,
  placeholder,
  onChange,
  value,
  disabled,
  error,
}) {
  return (
    <div>
      <div className={`inputLabel ${error && "error"} `}>{label}</div>

      <Input
        disabled={disabled}
        value={value}
        name={name}
        className="input"
        placeholder={placeholder}
        onChange={onChange}
      />
    </div>
  );
}
