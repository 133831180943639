import parseFloat2 from "../../utils/parseFloat2";
import parseFloat3 from "../../utils/parseFloat3";
import { changeCotizacion } from "./cotizacionesActions";

export const setRepeticionPlantillaImpresion = () => {
  return (dispatch, getState) => {
    const { downMedida, downGap, etiquetasRepeticion } =
      getState().cotizacionesReducer.cotizacion;

    const repeticionPlantillaImpresion =
      (Number(downMedida) + Number(downGap)) * etiquetasRepeticion;

    dispatch(
      changeCotizacion({
        repeticionPlantillaImpresion: parseFloat3(repeticionPlantillaImpresion),
      })
    );
  };
};

export const setPiezasImpresion = () => {
  return (dispatch, getState) => {
    const { piezasAcross, etiquetasRepeticion } =
      getState().cotizacionesReducer.cotizacion;

    const piezasImpresion = Number(piezasAcross) * Number(etiquetasRepeticion);

    dispatch(
      changeCotizacion({
        piezasImpresion: parseFloat2(piezasImpresion),
      })
    );
  };
};
