import { Select } from "antd";
const { Option } = Select;

export default function SelectLabelSinFiltro({
  label,
  onChange,
  placeholder,
  options,
  value,
  name,
  error,
}) {
  const handleChange = (val) => {
    onChange({ target: { name, value: val } });
  };

  return (
    <div>
      <div className={`inputLabel ${error && "error"} `}>{label}</div>

      <Select
        value={value}
        style={{ width: "100%" }}
        placeholder={placeholder}
        onChange={handleChange}
      >
        {options.map((o) => (
          <Option key={o.value} value={o.value}>
            {o.text}
          </Option>
        ))}
      </Select>
    </div>
  );
}
