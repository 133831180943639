import { useSelector } from "react-redux";
import InputSinNumber from "../../../../components/input/InputSinNumber";

import ColOpcion from "./ColOpcion";
import ColTitulo from "./ColTitulo";
import RowCosto from "./RowCosto";

export default function CostoPreprensaOffset() {

  const {
     costoPreprensaOffsetOpcion1,
    costoPreprensaOffsetOpcion2,
    costoPreprensaOffsetOpcion3,
    costoPreprensaOffsetOpcion4,
    costoPreprensaOffsetOpcion5,
  } = useSelector((state) => state.cotizacionesReducer.cotizacion);

  return (
    <RowCosto>
      <ColTitulo>Costo pre-prensa offset</ColTitulo>
      <ColOpcion>
        <InputSinNumber value={costoPreprensaOffsetOpcion1} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={costoPreprensaOffsetOpcion2} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={costoPreprensaOffsetOpcion3} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={costoPreprensaOffsetOpcion4} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={costoPreprensaOffsetOpcion5} disabled />
      </ColOpcion>
    </RowCosto>
  );
}
