import { useSelector } from "react-redux";
import InputSinNumber from "../../../components/input/InputSinNumber";
import ColOpcion from "../Calculos/renglonesCostos/ColOpcion";
import ColTitulo from "../Calculos/renglonesCostos/ColTitulo";
import RowCosto from "../Calculos/renglonesCostos/RowCosto";

export default function Subtotal() {
  const {
    subtotalOpcion1,
    subtotalOpcion2,
    subtotalOpcion3,
    subtotalOpcion4,
    subtotalOpcion5,
  } = useSelector((state) => state.cotizacionesReducer.cotizacion);

  return (
    <RowCosto>
      <ColTitulo>Subtotal</ColTitulo>
      <ColOpcion>
        <InputSinNumber value={subtotalOpcion1} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={subtotalOpcion2} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={subtotalOpcion3} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={subtotalOpcion4} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={subtotalOpcion5} disabled />
      </ColOpcion>
    </RowCosto>
  );
}
