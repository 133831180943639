import { Col, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import InputSinNumber from "../../../components/input/InputSinNumber";
import {
  changeCotizacion,
  setDatosDado,
} from "../../../context/actions/cotizacionesActions";
import ImgPosicion from "../../../assets/Posicion.PNG";
import { getNumberOptions } from "../../../utils/getNumberOptions";
import InputLabelNumber from "../../../components/input/InputLabelNumber";
import { setSeccionModificada } from "../../../context/actions/stepActions";
import DropdownDado from "../../../components/DropdownCatalogo/DropdownDado";
import LabelValorWide from "../../../components/labelValor/LabelValorWide";
import { useEffect } from "react";

export default function FormaMedidas() {
  const dispatch = useDispatch();

  const {
    dadoMedidas,
    acrossMedida,
    acrossGap,
    acrossRadio,
    downMedida,
    downGap,
    posicion,
    descripcion,
    qty_across,
    qty_down,
  } = useSelector((state) => state.cotizacionesReducer.cotizacion);



  const { posicionErr, dadoMedidasErr } = useSelector(
    (state) => state.erroresReducer
  );
  const posicionOptions = getNumberOptions(6);

  const onChange = ({ target: { name, value } }) => {
    dispatch(changeCotizacion({ [name]: value }));
    dispatch(setSeccionModificada({ solicitudCotizacion: true }));
  };

  const dadoChange = ({ target: { value } }) => {
    dispatch(setDatosDado(value));
    dispatch(setSeccionModificada({ solicitudCotizacion: true }));
  };

  return (
    <>
      <Row justify="center" gutter={[10, 10]} style={{ marginBottom: 10 }}>
        <Col span={24}>
          <DropdownDado
            error={dadoMedidasErr}
            label="Dados"
            value={Number(dadoMedidas)}
            onChange={dadoChange}
          />
        </Col>
      </Row>
      <Row gutter={[10, 10]} style={{ marginBottom: 10 }}>
        <Col span={3}></Col>
        <Col span={7} className="tituloGrid">
          Medida
        </Col>
        <Col span={7} className="tituloGrid">
          Gap
        </Col>
        <Col span={7} className="tituloGrid">
          Radio
        </Col>
      </Row>
      <Row gutter={[10, 10]} style={{ marginBottom: 10 }}>
        <Col span={3} className="labelGrid">
          Across
        </Col>
        <Col span={7}>
          <InputSinNumber
            name="acrossMedida"
            value={acrossMedida}
            onChange={onChange}
          />
        </Col>
        <Col span={7}>
          <InputSinNumber
            name="acrossGap"
            value={acrossGap}
            onChange={onChange}
          />
        </Col>
        <Col span={7}>
          <InputSinNumber
            name="acrossRadio"
            value={acrossRadio}
            onChange={onChange}
          />
        </Col>
      </Row>
      <Row gutter={[10, 10]} style={{ marginBottom: 10 }}>
        <Col span={3} className="labelGrid">
          Down
        </Col>
        <Col span={7}>
          <InputSinNumber
            name="downMedida"
            value={downMedida}
            onChange={onChange}
          />
        </Col>
        <Col span={7}>
          <InputSinNumber name="downGap" value={downGap} onChange={onChange} />
        </Col>
        <Col span={7}></Col>
      </Row>
      <Row gutter={10}>
        <Col span={3}></Col>
        <Col span={7}>
          <LabelValorWide label="Qty across" valor={qty_across} />
        </Col>
        <Col span={7}>
          <LabelValorWide label="Qty down" valor={qty_down} />
        </Col>
      </Row>
      <Row>
        <Col span={3}></Col>
        <Col span={14}>
          <LabelValorWide label="descripción" valor={descripcion} />
        </Col>
      </Row>
      <Row>
        <Col span={24} style={{ textAlign: "center" }}>
          <img src={ImgPosicion} className="imgPosicion" />
        </Col>
      </Row>
      <Row justify="center" gutter={[10, 10]} style={{ marginBottom: 10 }}>
        <Col span={12}>
          <InputLabelNumber
            error={posicionErr}
            name="posicion"
            value={posicion}
            onChange={onChange}
            label="Posición"
            options={posicionOptions}
          />
        </Col>
      </Row>
    </>
  );
}
