import { useSelector } from "react-redux";
import InputSinNumber from "../../../../components/input/InputSinNumber";
import ColOpcion from "./ColOpcion";
import ColTitulo from "./ColTitulo";
import RowCosto from "./RowCosto";

export default function TiempoTotalImpresion() {
  const {
    tiempoMaquinaImpOffsetOpcion1,
    tiempoMaquinaImpOffsetOpcion2,
    tiempoMaquinaImpOffsetOpcion3,
    tiempoMaquinaImpOffsetOpcion4,
    tiempoMaquinaImpOffsetOpcion5,
  } = useSelector((state) => state.cotizacionesReducer.cotizacion);

  return (
    <RowCosto>
      <ColTitulo>Tiempo TOTAL impresion <br />+ setups + placas + color (Hrs)</ColTitulo>
      <ColOpcion>
        <InputSinNumber value={tiempoMaquinaImpOffsetOpcion1} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={tiempoMaquinaImpOffsetOpcion2} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={tiempoMaquinaImpOffsetOpcion3} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={tiempoMaquinaImpOffsetOpcion4} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={tiempoMaquinaImpOffsetOpcion5} disabled />
      </ColOpcion>
    </RowCosto>
  );
}
