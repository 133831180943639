import { Col, Row, Spin } from "antd";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

import FormaCliente from "./_FormaCliente";
import FormaMedidas from "./_FormaMedidas";
import { useSelector } from "react-redux";
import CardPage from "../../../layout/CardPage";
import { useHistory } from "react-router-dom";
import SpinPage from "../../../components/SpinPage/SpinPage";
import ButtonContinuar from "./_ButtonContinuar";

export default function SolicitudCotizacion() {
  const { cotizacionID } = useParams();
  const history = useHistory();
  const { downloadingData } = useSelector((state) => state.loadingReducer);

  const getTexto = () => (cotizacionID == 0 ? " ~ nueva ~" : cotizacionID);

  return (
    <CardPage
      titulo={`Solicitud de cotización ${getTexto()}`}
      tituloBoton="Regresar"
      accionBoton={() => history.push(`/cotizaciones/details/${cotizacionID}`)}
    >
      <SpinPage loading={downloadingData}>
        <Row gutter={[10, 10]} style={{ marginBottom: 10 }}>
          <Col xs={24} md={14}>
            <FormaCliente />
          </Col>
          <Col xs={24} md={10}>
            <FormaMedidas />
          </Col>
        </Row>

        <ButtonContinuar />
      </SpinPage>
    </CardPage>
  );
}
