import { DeleteOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import { useDispatch } from "react-redux";
import {
  removeCotizacionItem,
  startDeleCotizacionItem,
  startSetOpcion1,
  startUpdateCantidadItem,
  startUpdateOpcion1,
} from "../../../context/actions/cotizacionesActions";

import { useState } from "react";
import { useParams } from "react-router-dom";

///
export default function ArticulosListaItem({
  item: { articulo, nuevoReimpresion, cantidad, cotizacionItemsID },
  index,
}) {
  const dispatch = useDispatch();
  const [value, setValue] = useState(cantidad);
  const { cotizacionID } = useParams();

  //
  const handleClick = () => {
    dispatch(removeCotizacionItem(index));
    dispatch(startSetOpcion1());

    if (cotizacionItemsID !== 0) {
      dispatch(startDeleCotizacionItem(cotizacionItemsID));
      dispatch(startUpdateOpcion1());
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      const cantidad = Number(value);
      if (!isNaN(cantidad)) {
        const body = {
          cantidad,
          cotizacionItemsID,
          cotizacionID,
        };
        dispatch(startUpdateCantidadItem(body));
      }
    }
  };

  ///
  return (
    <Row gutter={10}>
      <Col xs={7} sm={6} xl={4} style={{ textAlign: "center" }}>
        {articulo}
      </Col>
      <Col xs={7} sm={6} xl={4}>
        {nuevoReimpresion}
      </Col>
      <Col xs={7} sm={6} xl={4} style={{ textAlign: "right" }}>
        <input
          onKeyDown={handleKeyDown}
          onChange={({ target }) => setValue(target.value)}
          className="inputLimpio"
          value={value}
        />
        {/* <NumberFormat
          value={cantidad}
          displayType={"text"}
          thousandSeparator={true}
        /> */}
      </Col>
      <Col span={3}>
        <DeleteOutlined onClick={handleClick} className="eyeIcon" />
      </Col>
    </Row>
  );
}
