import { useSelector } from "react-redux";
import InputSinNumber from "../../../../components/input/InputSinNumber";

import ColOpcion from "./ColOpcion";
import ColTitulo from "./ColTitulo";
import RowCosto from "./RowCosto";

export default function CostoUsoPrimera() {

  const {
    costoUsoPrimeraOpcion1,
    costoUsoPrimeraOpcion2,
    costoUsoPrimeraOpcion3,
    costoUsoPrimeraOpcion4,
    costoUsoPrimeraOpcion5,
  } = useSelector((state) => state.cotizacionesReducer.cotizacion);

  
  return (
    <RowCosto>
      <ColTitulo>Cargo por uso en primera</ColTitulo>
      <ColOpcion>
        <InputSinNumber value={costoUsoPrimeraOpcion1} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={costoUsoPrimeraOpcion2} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={costoUsoPrimeraOpcion3} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={costoUsoPrimeraOpcion4} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={costoUsoPrimeraOpcion5} disabled />
      </ColOpcion>
    </RowCosto>
  );
}
