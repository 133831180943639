import { useSelector } from "react-redux";
import InputSinNumber from "../../../../components/input/InputSinNumber";

import ColOpcion from "./ColOpcion";
import ColTitulo from "./ColTitulo";
import RowCosto from "./RowCosto";

export default function CostoTiempoMaquinaAdicional() {

  const {
    costoTiempoMaquinaAdicionalOpcion1,
    costoTiempoMaquinaAdicionalOpcion2,
    costoTiempoMaquinaAdicionalOpcion3,
    costoTiempoMaquinaAdicionalOpcion4,
    costoTiempoMaquinaAdicionalOpcion5,
  } = useSelector((state) => state.cotizacionesReducer.cotizacion);

  return (
    <RowCosto>
      <ColTitulo>Costo tiempo máquina Adicional</ColTitulo>
      <ColOpcion>
        <InputSinNumber value={costoTiempoMaquinaAdicionalOpcion1} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={costoTiempoMaquinaAdicionalOpcion2} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={costoTiempoMaquinaAdicionalOpcion3} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={costoTiempoMaquinaAdicionalOpcion4} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={costoTiempoMaquinaAdicionalOpcion5} disabled />
      </ColOpcion>
    </RowCosto>
  );
}
