import { URL_CLIENTES } from "../../constants/url";
import {
  composeErrorNotification,
  composeSuccessNotification,
} from "../../utils/composeNotification";
import { get, post, put } from "../../utils/fetch";
import { types } from "../types/types";
import { notification } from "./notificationActions";

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const startLoadClientes = () => {
  return async (dispatch) => {
    const data = await get(URL_CLIENTES);
    if (data == -1) {
      // error
      return;
    }
    dispatch(storeClientes(data));
  };
};

const storeClientes = (payload) => ({
  type: types.storeClientes,
  payload,
});

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const startLoadCliente = (clienteID) => {
  return async (dispatch) => {
    const data = await get(`${URL_CLIENTES}/${clienteID}`);

    if (data !== -1) {
      dispatch(storeClienteFUll(data));
    } else {
      dispatch(
        notification(
          composeErrorNotification("Error de conexion", "Load cliente")
        )
      );
    }
  };
};

const storeClienteFUll = (payload) => ({
  type: types.storeClienteFUll,
  payload,
});

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const startLoadCotizacionesCliente = (clienteID) => {
  return async (dispatch) => {
    const data = await get(
      `${URL_CLIENTES}/GetCotizacionesByClienteID/${clienteID}`
    );

    if (data !== -1) {
      dispatch(storeCotizacionesByCliente(data));
    } else {
      dispatch(
        notification(
          composeErrorNotification("Error de conexion", "Load cotizaciones")
        )
      );
    }
  };
};

const storeCotizacionesByCliente = (cotizaciones) => ({
  type: types.storeCotizacionesByCliente,
  payload: cotizaciones,
});

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const startDuplicar = ({ cotizacionID, clienteID }) => {
  return async (dispatch) => {
    const data = await post(`${URL_CLIENTES}/Duplicar/${cotizacionID}`);

    if (data !== -1) {
      dispatch(startLoadCotizacionesCliente(clienteID));
      dispatch(notification(composeSuccessNotification(data)));
    } else {
      dispatch(
        notification(
          composeErrorNotification("Error de conexion", "Duplicar cotizaciones")
        )
      );
    }
  };
};

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const startBackorder = ({ cotizacionID, clienteID }) => {
  return async (dispatch) => {
    const data = await post(`${URL_CLIENTES}/Backorder/${cotizacionID}`);

    if (data !== -1) {
      dispatch(startLoadCotizacionesCliente(clienteID));
      dispatch(notification(composeSuccessNotification(data)));
    } else {
      dispatch(
        notification(composeErrorNotification("Error de conexion", "Backorder"))
      );
    }
  };
};

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const changeCliente = (elemento) => ({
  type: types.changeCliente,
  payload: elemento,
});

export const cleanCliente = () => ({
  type: types.cleanCliente,
  payload: null,
});
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const startPostCliente = () => {
  return async (dispatch, getState) => {
    const { cliente } = getState().clientesReducer;
    const data = await post(URL_CLIENTES, cliente);
    if (data !== -1) {
      dispatch(notification(composeSuccessNotification(data)));
    } else {
      dispatch(
        notification(
          composeErrorNotification("Error de conexion", "Post cliente")
        )
      );
    }
  };
};
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const startPutCliente = () => {
  return async (dispatch, getState) => {
    const { cliente } = getState().clientesReducer;
    const data = await put(`${URL_CLIENTES}/${cliente.clienteID}`, cliente);

    if (data !== -1) {
      dispatch(notification(composeSuccessNotification(data)));
    } else {
      dispatch(
        notification(
          composeErrorNotification("Error de conexion", "Put cliente")
        )
      );
    }
  };
};

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
