
import { useSelector } from "react-redux";
import InputSinNumber from "../../../../components/input/InputSinNumber";

import ColOpcion from "./ColOpcion";
import ColTitulo from "./ColTitulo";
import RowCosto from "./RowCosto";

export default function CostoCorteIndividual() {

  const {
  costoCorteIndividualOpcion1,
    costoCorteIndividualOpcion2,
    costoCorteIndividualOpcion3,
    costoCorteIndividualOpcion4,
    costoCorteIndividualOpcion5,
  } = useSelector((state) => state.cotizacionesReducer.cotizacion);


  return (
    <RowCosto>
      <ColTitulo>Corte individual</ColTitulo>
      <ColOpcion>
        <InputSinNumber value={costoCorteIndividualOpcion1} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={costoCorteIndividualOpcion2} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={costoCorteIndividualOpcion3} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={costoCorteIndividualOpcion4} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={costoCorteIndividualOpcion5} disabled />
      </ColOpcion>
    </RowCosto>
  );
}
