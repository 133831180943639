
import { useSelector } from "react-redux";
import InputSinNumber from "../../../../components/input/InputSinNumber";

import ColOpcion from "./ColOpcion";
import ColTitulo from "./ColTitulo";
import RowCosto from "./RowCosto";

export default function CantidadTotal() {

  const {
    cantidadTotalOpcion1,
    cantidadTotalOpcion2,
    cantidadTotalOpcion3,
    cantidadTotalOpcion4,
    cantidadTotalOpcion5,
  } = useSelector((state) => state.cotizacionesReducer.cotizacion);

  return (
    <RowCosto>
      <ColTitulo>Cantidad solicitada total</ColTitulo>
      <ColOpcion>
        <InputSinNumber
          name="cantidadTotalOpcion1"
          value={cantidadTotalOpcion1}
          disabled
        />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber
          readonly={true}
          name="cantidadTotalOpcion2"
          value={cantidadTotalOpcion2}
          disabled
        />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber
          readonly={true}
          name="cantidadTotalOpcion3"
          value={cantidadTotalOpcion3}
          disabled
        />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber
          readonly={true}
          name="cantidadTotalOpcion4"
          value={cantidadTotalOpcion4}
          disabled
        />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber
          readonly={true}
          name="cantidadTotalOpcion5"
          value={cantidadTotalOpcion5}
          disabled
        />
      </ColOpcion>
    </RowCosto>
  );
}
