export const cotizacionInitial = {
  tipoCaptura: "nuevo",
  dadoMedidas: null,
  fecha: "2010-10-10",
  step: 0,
  cotizacionID: 0,
  //
  clienteID: null,
  marca: "",
  representante: "",
  noParte: "",
  acrossMedida: 0,
  acrossGap: 0,
  acrossRadio: 0,
  downMedida: 0,
  downGap: 0,
  //
  tamanoTerminado: 0,
  noTintas: 0,
  requiereSeleccionColor: null,
  noColoresPMS: 0,
  porcentajeImpresion: "",
  posicion: null,
  requiereCorteIndividual: null,
  aplicacion: "",
  noCambioPlacas: null,
  noCambiosColor: null,
  piezasPorRollo: 0,
  requierePressProof: null,
  requiereInformacionVariable: null,
  presentacion: "",

  requiereBarnizMini: false,
  requiereLaminado: false,
  requiereBarnizEspecial: false,
  requiereBarnizEspecialCobertura: 0,
  requiereFoil: false,
  requiereFoilCobertura: 0,

  lab: "",
  tiempoEntrega: "",
  presentacion: "",

  totalCantidadItems: 0,

  // cotizador

  etiquetasRepeticion: 1,
  repeticionPlantillaImpresion: 0,
  anchoBanda: 0,
  piezasAcross: 0,
  piezasImpresion: 0,
  tipoCambio: 0,
  costoPreprensa: 0,
  costoDadoSuaje: 0,
  costoDadoSuajeDepreciacion: 0,
  costoDadoGrabado: 0,
  costoDadoFoil: 0,
  desglozarCostoDados: true,
  sistemaCotizado: "",
  impresionOffset: false,
  cortePRIMERA: false,
  noMaquinasAdicionales: 0,
  requiereBaseBlancaDigital: false,
  porcentajeBaseBlancaDigital: 0,
  cotizacionEstatusID: 0,
  cotizacionEstatus: null,
  items: [],

  // Terminados
  sustratoID: 0,
  descripcionSustrato: "",
  costoMSISustrato: 0,
  EspecificacionSustrato: "",
  proveedorSustrato: "",

  barnizID: 0,
  descripcionBarniz: "",
  costoMSIBarniz: 0,
  EspecificacionBarniz: "",
  proveedorBarniz: "",

  laminadoID: 0,
  descripcionLaminado: "",
  costoMSILaminado: 0,
  EspecificacionLaminado: "",
  proveedorLaminado: "",

  foilID: 0,
  descripcionFoil: "",
  costoMSIFoil: 0,
  EspecificacionFoil: "",
  proveedorFoil: "",

  barnizEspecialID: 0,
  descripcionBarnizEspecial: "",
  costoMSIBarnizEspecial: 0,
  EspecificacionBarnizEspecial: "",
  proveedorBarnizEspecial: "",

  // costos

  opcion1: 0,
  opcion2: 0,
  opcion3: 0,
  opcion4: 0,
  opcion5: 0,

  cantidadTotalOpcion1: 0,
  cantidadTotalOpcion2: 0,
  cantidadTotalOpcion3: 0,
  cantidadTotalOpcion4: 0,
  cantidadTotalOpcion5: 0,

  porcentajeMermaOpcion1: 3.0,
  porcentajeMermaOpcion2: 3.0,
  porcentajeMermaOpcion3: 3.0,
  porcentajeMermaOpcion4: 3.0,
  porcentajeMermaOpcion5: 3.0,

  cantidadTotalOpcion1: 0,
  cantidadTotalOpcion2: 0,
  cantidadTotalOpcion3: 0,
  cantidadTotalOpcion4: 0,
  cantidadTotalOpcion5: 0,

  velocidadOpcion1: 1,
  velocidadOpcion2: 1,
  velocidadOpcion3: 1,
  velocidadOpcion4: 1,
  velocidadOpcion5: 1,

  tamanoPlantillaImpresion: 0,

  MSIRequeridosImpresionOpcion1: 0,
  MSIRequeridosImpresionOpcion2: 0,
  MSIRequeridosImpresionOpcion3: 0,
  MSIRequeridosImpresionOpcion4: 0,
  MSIRequeridosImpresionOpcion5: 0,

  MSIRequeridosSetupOpcion1: 0,
  MSIRequeridosSetupOpcion2: 0,
  MSIRequeridosSetupOpcion3: 0,
  MSIRequeridosSetupOpcion4: 0,
  MSIRequeridosSetupOpcion5: 0,

  MSIRequeridosAcabadosOpcion1: 0,
  MSIRequeridosAcabadosOpcion2: 0,
  MSIRequeridosAcabadosOpcion3: 0,
  MSIRequeridosAcabadosOpcion4: 0,
  MSIRequeridosAcabadosOpcion5: 0,

  MSITotalOpcion1: 0,
  MSITotalOpcion2: 0,
  MSITotalOpcion3: 0,
  MSITotalOpcion4: 0,
  MSITotalOpcion5: 0,

  totalPiesLinealesOpcion1: 0,
  totalPiesLinealesOpcion2: 0,
  totalPiesLinealesOpcion3: 0,
  totalPiesLinealesOpcion4: 0,
  totalPiesLinealesOpcion5: 0,

  noGolpesCalculoOpcion1: 0,
  noGolpesCalculoOpcion2: 0,
  noGolpesCalculoOpcion3: 0,
  noGolpesCalculoOpcion4: 0,
  noGolpesCalculoOpcion5: 0,

  fleteOpcion1: 0,
  fleteOpcion2: 0,
  fleteOpcion3: 0,
  fleteOpcion4: 0,
  fleteOpcion5: 0,

  /////

  costoTiempoMaquinaImpDigitalOpcion1: 0,
  costoTiempoMaquinaImpDigitalOpcion2: 0,
  costoTiempoMaquinaImpDigitalOpcion3: 0,
  costoTiempoMaquinaImpDigitalOpcion4: 0,
  costoTiempoMaquinaImpDigitalOpcion5: 0,

  costoTiempoMaquinaImpRSSOpcion1: 0,
  costoTiempoMaquinaImpRSSOpcion2: 0,
  costoTiempoMaquinaImpRSSOpcion3: 0,
  costoTiempoMaquinaImpRSSOpcion4: 0,
  costoTiempoMaquinaImpRSSOpcion5: 0,

  costoTiempoMaquinaImpOffsetOpcion1: 0,
  costoTiempoMaquinaImpOffsetOpcion2: 0,
  costoTiempoMaquinaImpOffsetOpcion3: 0,
  costoTiempoMaquinaImpOffsetOpcion4: 0,
  costoTiempoMaquinaImpOffsetOpcion5: 0,

  costoTiempoMaquinaAdicionalOpcion1: 0,
  costoTiempoMaquinaAdicionalOpcion2: 0,
  costoTiempoMaquinaAdicionalOpcion3: 0,
  costoTiempoMaquinaAdicionalOpcion4: 0,
  costoTiempoMaquinaAdicionalOpcion5: 0,

  CostoTiempoMaquinaBaseBlancaDigitalOpcion1: 0,
  CostoTiempoMaquinaBaseBlancaDigitalOpcion2: 0,
  CostoTiempoMaquinaBaseBlancaDigitalOpcion3: 0,
  CostoTiempoMaquinaBaseBlancaDigitalOpcion4: 0,
  CostoTiempoMaquinaBaseBlancaDigitalOpcion5: 0,

  costoSetupOffsetOpcion1: 0,
  costoSetupOffsetOpcion2: 0,
  costoSetupOffsetOpcion3: 0,
  costoSetupOffsetOpcion4: 0,
  costoSetupOffsetOpcion5: 0,

  costoSetupDigitalOpcion1: 0,
  costoSetupDigitalOpcion2: 0,
  costoSetupDigitalOpcion3: 0,
  costoSetupDigitalOpcion4: 0,
  costoSetupDigitalOpcion5: 0,

  costoSetupSerigrafiaOpcion1: 0,
  costoSetupSerigrafiaOpcion2: 0,
  costoSetupSerigrafiaOpcion3: 0,
  costoSetupSerigrafiaOpcion4: 0,
  costoSetupSerigrafiaOpcion5: 0,

  //
  totalMaquinaSetup1: 0,
  totalMaquinaSetup2: 0,
  totalMaquinaSetup3: 0,
  totalMaquinaSetup4: 0,
  totalMaquinaSetup5: 0,
  //

  costoSetupMaquinasAdicionalesOpcion1: 0,
  costoSetupMaquinasAdicionalesOpcion2: 0,
  costoSetupMaquinasAdicionalesOpcion3: 0,
  costoSetupMaquinasAdicionalesOpcion4: 0,
  costoSetupMaquinasAdicionalesOpcion5: 0,

  costoSubstratoOpcion1: 0,
  costoSubstratoOpcion2: 0,
  costoSubstratoOpcion3: 0,
  costoSubstratoOpcion4: 0,
  costoSubstratoOpcion5: 0,

  costoLaminadoOpcion1: 0,
  costoLaminadoOpcion2: 0,
  costoLaminadoOpcion3: 0,
  costoLaminadoOpcion4: 0,
  costoLaminadoOpcion5: 0,

  costoCorteIndividualOpcion1: 0,
  costoCorteIndividualOpcion2: 0,
  costoCorteIndividualOpcion3: 0,
  costoCorteIndividualOpcion4: 0,
  costoCorteIndividualOpcion5: 0,

  costoPreprensaOffsetOpcion1: 0,
  costoPreprensaOffsetOpcion2: 0,
  costoPreprensaOffsetOpcion3: 0,
  costoPreprensaOffsetOpcion4: 0,
  costoPreprensaOffsetOpcion5: 0,

  depreciacionDadoSuajeOpcion1: 0,
  depreciacionDadoSuajeOpcion2: 0,
  depreciacionDadoSuajeOpcion3: 0,
  depreciacionDadoSuajeOpcion4: 0,
  depreciacionDadoSuajeOpcion5: 0,

  cambioPlacasOpcion1: 0,
  cambioPlacasOpcion2: 0,
  cambioPlacasOpcion3: 0,
  cambioPlacasOpcion4: 0,
  cambioPlacasOpcion5: 0,

  cambioColorOpcion1: 0,
  cambioColorOpcion2: 0,
  cambioColorOpcion3: 0,
  cambioColorOpcion4: 0,
  cambioColorOpcion5: 0,

  cargoPreparacionArteOpcion1: 0,
  cargoPreparacionArteOpcion2: 0,
  cargoPreparacionArteOpcion3: 0,
  cargoPreparacionArteOpcion4: 0,
  cargoPreparacionArteOpcion5: 0,

  costoUsoPrimeraOpcion1: 0,
  costoUsoPrimeraOpcion2: 0,
  costoUsoPrimeraOpcion3: 0,
  costoUsoPrimeraOpcion4: 0,
  costoUsoPrimeraOpcion5: 0,

  cargoInfoVariableOpcion1: 0,
  cargoInfoVariableOpcion2: 0,
  cargoInfoVariableOpcion3: 0,
  cargoInfoVariableOpcion4: 0,
  cargoInfoVariableOpcion5: 0,

  pantallaRSSOpcion1: 0,
  pantallaRSSOpcion2: 0,
  pantallaRSSOpcion3: 0,
  pantallaRSSOpcion4: 0,
  pantallaRSSOpcion5: 0,

  costoPantallaBarnizEspecialOpcion1: 0,
  costoPantallaBarnizEspecialOpcion2: 0,
  costoPantallaBarnizEspecialOpcion3: 0,
  costoPantallaBarnizEspecialOpcion4: 0,
  costoPantallaBarnizEspecialOpcion5: 0,

  costoDadosOpcion1: 0,
  costoDadosOpcion2: 0,
  costoDadosOpcion3: 0,
  costoDadosOpcion4: 0,
  costoDadosOpcion5: 0,

  tintasSemirotativaOpcion1: 0,
  tintasSemirotativaOpcion2: 0,
  tintasSemirotativaOpcion3: 0,
  tintasSemirotativaOpcion4: 0,
  tintasSemirotativaOpcion5: 0,

  tintasSerigrafiaRSSOpcion1: 0,
  tintasSerigrafiaRSSOpcion2: 0,
  tintasSerigrafiaRSSOpcion3: 0,
  tintasSerigrafiaRSSOpcion4: 0,
  tintasSerigrafiaRSSOpcion5: 0,

  tonerCMYKOpcion1: 0,
  tonerCMYKOpcion2: 0,
  tonerCMYKOpcion3: 0,
  tonerCMYKOpcion4: 0,
  tonerCMYKOpcion5: 0,

  tonerBlancoOpcion1: 0,
  tonerBlancoOpcion2: 0,
  tonerBlancoOpcion3: 0,
  tonerBlancoOpcion4: 0,
  tonerBlancoOpcion5: 0,

  barnizEspecialRSSOpcion1: 0,
  barnizEspecialRSSOpcion2: 0,
  barnizEspecialRSSOpcion3: 0,
  barnizEspecialRSSOpcion4: 0,
  barnizEspecialRSSOpcion5: 0,

  barnizMaquinaOpcion1: 0,
  barnizMaquinaOpcion2: 0,
  barnizMaquinaOpcion3: 0,
  barnizMaquinaOpcion4: 0,
  barnizMaquinaOpcion5: 0,

  servicioEpsonOpcion1: 0,
  servicioEpsonOpcion2: 0,
  servicioEpsonOpcion3: 0,
  servicioEpsonOpcion4: 0,
  servicioEpsonOpcion5: 0,

  foilOpcion1: 0,
  foilOpcion2: 0,
  foilOpcion3: 0,
  foilOpcion4: 0,
  foilOpcion5: 0,

  empaqueOpcion1: 0,
  empaqueOpcion2: 0,
  empaqueOpcion3: 0,
  empaqueOpcion4: 0,
  empaqueOpcion5: 0,

  ////   resultados

  subtotalOpcion1: 0,
  subtotalOpcion2: 0,
  subtotalOpcion3: 0,
  subtotalOpcion4: 0,
  subtotalOpcion5: 0,

  utilidadPorcentajeOpcion1: 0,
  utilidadPorcentajeOpcion2: 0,
  utilidadPorcentajeOpcion3: 0,
  utilidadPorcentajeOpcion4: 0,
  utilidadPorcentajeOpcion5: 0,

  utilidadOpcion1: 0,
  utilidadOpcion2: 0,
  utilidadOpcion3: 0,
  utilidadOpcion4: 0,
  utilidadOpcion5: 0,

  totalOpcion1: 0,
  totalOpcion2: 0,
  totalOpcion3: 0,
  totalOpcion4: 0,
  totalOpcion5: 0,

  totalPUOpcion1: 0,
  totalPUOpcion2: 0,
  totalPUOpcion3: 0,
  totalPUOpcion4: 0,
  totalPUOpcion5: 0,

  totalMOpcion1: 0,
  totalMOpcion2: 0,
  totalMOpcion3: 0,
  totalMOpcion4: 0,
  totalMOpcion5: 0,
};

export const seccionModificadaVacio = {
  solicitudCotizacion: false,
  articulos: false,
  cotizador: false,
  terminados: false,
  calculoUtilidad: false,
  Guardar: false,
};

export const cotExternaVacia = {
  cotizacion1: "",
  cotizacion2: "",
  cotizacion3: "",
  cotizacion4: "",
  cotizacion5: "",

  precioCotizado1: 0,
  precioCotizado2: 0,
  precioCotizado3: 0,
  precioCotizado4: 0,
  precioCotizado5: 0,

  fleteOpcion1: 0,
  fleteOpcion2: 0,
  fleteOpcion3: 0,
  fleteOpcion4: 0,
  fleteOpcion5: 0,

  utilidad1: 0,
  utilidad2: 0,
  utilidad3: 0,
  utilidad4: 0,
  utilidad5: 0,

  total1: 0,
  total2: 0,
  total3: 0,
  total4: 0,
  total5: 0,

  porcentajeFlete: 0.1,
  porcentajeUtilidad: 0,
  proveedor: "",
  notas: "",
};

export const cotizacionesInitial = {
  subestatus: [],
  filtro: {
    mes: 1 + new Date().getMonth(),
    anio: new Date().getFullYear(),
    cotizacionEstatusID: null,
  },
  cotizaciones: [],
  cotizacion: cotizacionInitial,
  files: [],
  seccionModificada: seccionModificadaVacio,
  cotizacionExterna: cotExternaVacia,
};

export const utilidadCero = {
  utilidadOpcion1: 0,
  utilidadOpcion2: 0,
  utilidadOpcion3: 0,
  utilidadOpcion4: 0,
  utilidadOpcion5: 0,

  totalOpcion1: 0,
  totalOpcion2: 0,
  totalOpcion3: 0,
  totalOpcion4: 0,
  totalOpcion5: 0,

  totalPUOpcion1: 0,
  totalPUOpcion2: 0,
  totalPUOpcion3: 0,
  totalPUOpcion4: 0,
  totalPUOpcion5: 0,

  totalMOpcion1: 0,
  totalMOpcion2: 0,
  totalMOpcion3: 0,
  totalMOpcion4: 0,
  totalMOpcion5: 0,
};
