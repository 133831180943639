import { Divider, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import ColResponsive from "../../../components/Col/ColResponsive";
import InputLabel from "../../../components/input/InputLabel";
import SelectLabel from "../../../components/input/SelectLabel";
import SelectLabelBool from "../../../components/input/SelectLabelBool";
import { changeCotizacion } from "../../../context/actions/cotizacionesActions";
import { getNumberOptions } from "../../../utils/getNumberOptions";
import getTextOptions from "../../../utils/getTextOptions";
import parseFloat2 from "../../../utils/parseFloat2";
import useFormaCotizador from "./useFormaCotizador";
import CardPage from "../../../layout/CardPage";
import ButtonContinuar from "./_ButtonContinuar";
import Acabados from "./_Acabados";
import InputLabelNumber from "../../../components/input/InputLabelNumber";
import { setSeccionModificada } from "../../../context/actions/stepActions";
import { useEffect } from "react";

////////////////////////////////
export default function FormaCotizador() {
  const dispatch = useDispatch();

  useFormaCotizador();
  const {
    acrossGap,
    acrossMedida,
    etiquetasRepeticion,
    anchoBanda,
    piezasAcross,
    piezasImpresion,
    tipoCambio,
    costoDadoFoil,
    costoDadoGrabado,
    costoDadoSuajeDepreciacion,
    costoDadoSuaje,
    desglozarCostoDados,
    sistemaCotizado,
    impresionOffset,
    cortePRIMERA,
    noMaquinasAdicionales,
    requiereBaseBlancaDigital,
    porcentajeBaseBlancaDigital,
    repeticionPlantillaImpresion,
    anchoMinimo,
    moneda,
    costoPreprensa,
  } = useSelector((state) => state.cotizacionesReducer.cotizacion);

  const {
    anchoBandaErr,
    piezasAcrossErr,
    tipoCambioErr,
    costoDadoSuajeErr,
    costoDadoSuajeDepreciacionErr,
    costoDadoGrabadoErr,
    costoDadoFoilErr,
    sistemaCotizadoErr,
    noMaquinasAdicionalesErr,
    porcentajeBaseBlancaDigitalErr,
    monedaErr,
    costoPreprensaErr,
  } = useSelector((state) => state.erroresReducer);

  const onChange = ({ target: { name, value } }) => {
    dispatch(changeCotizacion({ [name]: value }));
    dispatch(setSeccionModificada({ cotizador: true }));
  };

  const etiquetasRepeticionOptions = getNumberOptions(10);
  const sistemaCotizadoOptions = getTextOptions(["S", "D", "O"]);
  const monedaOptions = getTextOptions(["USD", "MXN"]);

  useEffect(() => {
    const valor =
      acrossGap * piezasAcross + acrossMedida * piezasAcross + 0.4 + 0.45;

    dispatch(changeCotizacion({ anchoMinimo: parseFloat2(valor) }));
  }, [acrossGap, piezasAcross, acrossMedida]);

  ////////////////////////////////
  return (
    <CardPage titulo="Cotizador">
      <Row gutter={[10, 10]} style={{ marginBottom: 10 }}>
        <ColResponsive>
          <InputLabelNumber
            name="etiquetasRepeticion"
            value={etiquetasRepeticion}
            onChange={onChange}
            label="Etiquetas en repetición"
            options={etiquetasRepeticionOptions}
          />
        </ColResponsive>
        <ColResponsive>
          <InputLabel
            name="repeticionPlantillaImpresion"
            value={repeticionPlantillaImpresion}
            onChange={onChange}
            label="Rep. plantilla imp."
            disabled
          />
        </ColResponsive>
        <ColResponsive>
          <InputLabel
            error={anchoBandaErr}
            name="anchoBanda"
            value={anchoBanda}
            onChange={onChange}
            label="Ancho de banda"
          />
        </ColResponsive>
        <ColResponsive>
          <InputLabel
            error={piezasAcrossErr}
            name="piezasAcross"
            value={piezasAcross}
            onChange={onChange}
            label={`Piezas across ( ${parseFloat2(
              (Number(anchoBanda) - 0.8) /
                (Number(acrossMedida) + Number(acrossGap))
            )} )`}
          />
        </ColResponsive>
      </Row>
      <Row gutter={[10, 10]} style={{ marginBottom: 10 }}>
        <ColResponsive>
          <InputLabel
            name="piezasImpresion"
            value={piezasImpresion}
            onChange={onChange}
            label="Piezas por impresión"
            disabled
          />
        </ColResponsive>
        <ColResponsive>
          <InputLabel
            error={tipoCambioErr}
            name="tipoCambio"
            value={tipoCambio}
            onChange={onChange}
            label="Tipo de cambio"
          />
        </ColResponsive>
        <ColResponsive>
          <SelectLabel
            error={monedaErr}
            name="moneda"
            value={moneda}
            onChange={onChange}
            label="Moneda"
            options={monedaOptions}
          />
        </ColResponsive>
        <ColResponsive>
          <InputLabel
            error={costoPreprensaErr}
            name="costoPreprensa"
            value={costoPreprensa}
            onChange={onChange}
            label="Costo preparación arte"
          />
        </ColResponsive>
      </Row>
      <Row gutter={[10, 10]} style={{ marginBottom: 10 }}>
        <ColResponsive>
          <InputLabel
            error={costoDadoSuajeErr}
            name="costoDadoSuaje"
            value={costoDadoSuaje}
            onChange={onChange}
            label="Costo dado de suaje (USD)"
          />
        </ColResponsive>

        <ColResponsive>
          <InputLabel
            error={costoDadoSuajeDepreciacionErr}
            name="costoDadoSuajeDepreciacion"
            value={costoDadoSuajeDepreciacion}
            onChange={onChange}
            label="Costo dado suaje dep."
          />
        </ColResponsive>

        <ColResponsive>
          <InputLabel
            error={costoDadoGrabadoErr}
            name="costoDadoGrabado"
            value={costoDadoGrabado}
            onChange={onChange}
            label="Costo dado grabado"
          />
        </ColResponsive>

        <ColResponsive>
          <InputLabel
            error={costoDadoFoilErr}
            name="costoDadoFoil"
            value={costoDadoFoil}
            onChange={onChange}
            label="Costo dado de foil"
          />
        </ColResponsive>

        <ColResponsive>
          <SelectLabelBool
            name="desglozarCostoDados"
            value={desglozarCostoDados}
            onChange={onChange}
            label="Desglozar costo dados"
          />
        </ColResponsive>
        <ColResponsive>
          <SelectLabel
            error={sistemaCotizadoErr}
            name="sistemaCotizado"
            value={sistemaCotizado}
            onChange={onChange}
            label="Sistema cotizado"
            options={sistemaCotizadoOptions}
          />
        </ColResponsive>
        <ColResponsive>
          <SelectLabelBool
            name="impresionOffset"
            value={impresionOffset}
            onChange={onChange}
            label="Impresión offset"
          />
        </ColResponsive>

        <ColResponsive>
          <SelectLabelBool
            name="cortePRIMERA"
            value={cortePRIMERA}
            onChange={onChange}
            label="Corte en PRIEMRA"
          />
        </ColResponsive>
        <ColResponsive>
          <InputLabel
            error={noMaquinasAdicionalesErr}
            name="noMaquinasAdicionales"
            value={noMaquinasAdicionales}
            onChange={onChange}
            label="No. máquinas ad."
          />
        </ColResponsive>
        <ColResponsive>
          <SelectLabelBool
            name="requiereBaseBlancaDigital"
            value={requiereBaseBlancaDigital}
            onChange={onChange}
            label="Base blanca DIG."
          />
        </ColResponsive>
        <ColResponsive>
          <InputLabel
            error={porcentajeBaseBlancaDigitalErr}
            name="porcentajeBaseBlancaDigital"
            value={porcentajeBaseBlancaDigital}
            onChange={onChange}
            label=" % Base blanca DIG."
          />
        </ColResponsive>

        <ColResponsive>
          <InputLabel
            name="anchoMinimo"
            value={anchoMinimo}
            onChange={onChange}
            label="Ancho mínimo"
            disabled={true}
          />
        </ColResponsive>
      </Row>

      <Divider />
      <Acabados />
      <ButtonContinuar />
    </CardPage>
  );
}
