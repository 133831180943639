import { useDispatch, useSelector } from "react-redux";
import InputSin from "../../../components/input/InputSin";
import { setCotizacionExterna } from "../../../context/actions/cotizacionesActions";
import ColOpcion from "../Calculos/renglonesCostos/ColOpcion";
import ColTitulo from "../Calculos/renglonesCostos/ColTitulo";
import RowCosto from "../Calculos/renglonesCostos/RowCosto";

export const Cotizacion = () => {
  const dispatch = useDispatch();

  const { cotizacion1, cotizacion2, cotizacion3, cotizacion4, cotizacion5 } =
    useSelector((state) => state.cotizacionesReducer.cotizacionExterna);

  const handleChange = ({ target }) => {
    dispatch(
      setCotizacionExterna({
        [target.name]: target.value,
      })
    );
  };

  //
  return (
    <RowCosto>
      <ColTitulo>Cotización #</ColTitulo>
      <ColOpcion>
        <InputSin
          name="cotizacion1"
          onChange={handleChange}
          value={cotizacion1}
        />
      </ColOpcion>
      <ColOpcion>
        <InputSin
          name="cotizacion2"
          onChange={handleChange}
          value={cotizacion2}
        />
      </ColOpcion>
      <ColOpcion>
        <InputSin
          name="cotizacion3"
          onChange={handleChange}
          value={cotizacion3}
        />
      </ColOpcion>
      <ColOpcion>
        <InputSin
          name="cotizacion4"
          onChange={handleChange}
          value={cotizacion4}
        />
      </ColOpcion>
      <ColOpcion>
        <InputSin
          name="cotizacion5"
          onChange={handleChange}
          value={cotizacion5}
        />
      </ColOpcion>
    </RowCosto>
  );
};
