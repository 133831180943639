export const types = {
  setLoadingStatus: "[loadingAction] setLoadingStatus",
  setCalculing: "[loadingAction] setCalculing",
  setTipoCaptura: "[capturaActions] setTipoCaptura",
  storeVer: "[verActions] storeVer",

  ////
  storeClientes: "[clientesAction] storeClientes",
  changeCliente: "[clientesAction] changeCliente",
  storeClienteFUll: "[clientesAction] storeClienteFUll",
  cleanCliente: "[clientesAction] cleanCliente",
  storeCotizacionesByCliente: "[clientesAction] storeCotizacionesByCliente",

  ////
  storeProveedores: "[proveedoresAction] storeProveedores",
  changeProveedor: "[proveedoresAction] changeProveedor",
  storeProveedorFull: "[proveedoresAction] storeProveedorFull",
  cleanProveedor: "[proveedoresAction] cleanProveedor",

  ////
  storeArticulos: "[articulosAction] storeArticulos",
  changeArticulo: "[articulosAction] changeArticulo",

  storeArticuloFull: "[articulosAction] storeArticuloFull",
  cleanArticulo: "[articulosAction] cleanArticulo",

  ////
  storeConstantes: "[constantesAction] storeConstantes",
  changeConstantes: "[constantesAction] changeConstantes",

  ////
  storeCatalogos: "[catalogosAction] storeCatalogos",
  setCategoriaSelected: "[catalogosAction] setCategoriaSelected",

  notification: "[] notification",

  storePaises: "[paisAction] storePaises",

  ////

  setFiltro: "[cotizacionesAction] setFiltro",
  storeCotizaciones: "[cotizacionesAction] storeCotizaciones",
  storeCotizacion: "[cotizacionesAction] storeCotizacion",
  storeFiles: "[cotizacionesAction] storeFiles",

  changeCotizacion: "[cotizacionesAction] changeCotizacion",
  addCotizacionItem: "[cotizacionesAction] addCotizacionItem",
  removeCotizacionItem: "[cotizacionesAction] removeCotizacionItem",
  cleanCotizacion: "[cotizacionesAction] cleanCotizacion",
  ceroUtildiad: "[cotizacionesAction] ceroUtildiad",

  setOpcion1: "[cotizacionesAction] setOpcion1",
  setSeccionModificada: "[stepAction] setSeccionModificada",
  cleanSeccionModificada: "[stepAction] cleanSeccionModificada",

  setStep: "[setAction] setStep",
  setLoginErr: "[authErrAcion] setLoginErr",
  storeUserData: "[loginAcion] storeUserData",
  setLogged: "[loginAcion] setLogged",

  setTerminado: "[terminadosActions] setTerminado",
  storeHistorial: "[historialAction] storeHistorial",
  setError: "[cotizacionesAction] setError",
  cleanTerminados: "[cotizacionesAction] cleanTerminados",
  setCotizacionExterna: "[cotizacionesAction] setCotizacionExterna",
  setCotizacionExternaFull: "[cotizacionesAction] setCotizacionExternaFull",
  cleanCotExterna: "[cotizacionesAction] cleanCotExterna",
  setArticulosEnCotizacion: "[articulosAction] setArticulosEnCotizacion",
  
  storeSubestatus: "[cotizacionesAction] storeSubestatus",
  
};
