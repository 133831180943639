import { DatePicker } from "antd";
import moment from "moment";

export default function DatePickerLabel({
  label,
  placeholder,
  onChange,
  name,
  value,
}) {
  const handleChange = (date, dateString) => {
    onChange({ target: { name, value: dateString } });
  };
  return (
    <div>
      <div className="inputLabel">{label}</div>

      <DatePicker
        name={name}
        style={{ width: "100%" }}
        className="input"
        onChange={handleChange}
        placeholder=""
        value={value}
      />
    </div>
  );
}
