import { useDispatch } from "react-redux";
import BotonSubmit from "../../components/Boton/BotonSubmit";
import { startPutCliente } from "../../context/actions/clientesActions";

export default function SubmitCreate() {
  const dispatch = useDispatch();

  const handleClick = () => dispatch(startPutCliente());
  return (
    <div style={{ marginTop: 20 }}>
      <BotonSubmit onClick={handleClick} tituloBoton="Actualizar Cliente" />
    </div>
  );
}
