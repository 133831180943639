import React from "react";
import InputSinNumber from "../../../../components/input/InputSinNumber";
import RowCosto from "./RowCosto";
import ColOpcion from "./ColOpcion";
import ColTitulo from "./ColTitulo";
import { Tooltip } from "antd";
import { useSelector } from "react-redux";

import parseFloat2 from "../../../../utils/parseFloat2";

export const GastosSubcontratados = () => {
  const {
    totalMaquinaSetup1,
    totalMaquinaSetup2,
    totalMaquinaSetup3,
    totalMaquinaSetup4,
    totalMaquinaSetup5,
  } = useSelector((state) => state.cotizacionesReducer.cotizacion);

  ////
  return (
    <RowCosto>
      <ColTitulo style={{ color: "orange", cursor: "help" }}>
        <Tooltip title="Si hay maquila aplica la suma para restarse después">
          * Gastos subcontratados
        </Tooltip>
      </ColTitulo>
      <ColOpcion>
        <InputSinNumber disabled value={parseFloat2(totalMaquinaSetup1)} />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber disabled value={parseFloat2(totalMaquinaSetup2)} />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber disabled value={parseFloat2(totalMaquinaSetup3)} />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber disabled value={parseFloat2(totalMaquinaSetup4)} />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber disabled value={parseFloat2(totalMaquinaSetup5)} />
      </ColOpcion>
    </RowCosto>
  );
};
