import { Col, Input, Row } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import BotonCard from "../../../components/Boton/BotonCard";
import ColOpcion from "../Calculos/renglonesCostos/ColOpcion";
import ColTitulo from "../Calculos/renglonesCostos/ColTitulo";
import RowCosto from "../Calculos/renglonesCostos/RowCosto";

export const CalculadoraUnitario = () => {
  const [data, setData] = useState({});
  const {
    opcion1,
    opcion2,
    opcion3,
    opcion4,
    opcion5,
    subtotalOpcion1,
    subtotalOpcion2,
    subtotalOpcion3,
    subtotalOpcion4,
    subtotalOpcion5,
  } = useSelector((state) => state.cotizacionesReducer.cotizacion);

  const hanldeChange = ({ target }) => {
    setData({
      ...data,
      [target.name]: target.value,
    });
  };

  const calcula = () => {
    const total1 = opcion1 * data.unitario1;
    const utildiad1 = total1 - subtotalOpcion1;
    const porcentajeUtildiad1 = utildiad1 / subtotalOpcion1;

    const total2 = opcion2 * data.unitario2;
    const utildiad2 = total2 - subtotalOpcion2;
    const porcentajeUtildiad2 = utildiad2 / subtotalOpcion2;

    const total3 = opcion3 * data.unitario3;
    const utildiad3 = total3 - subtotalOpcion3;
    const porcentajeUtildiad3 = utildiad3 / subtotalOpcion3;

    const total4 = opcion4 * data.unitario4;
    const utildiad4 = total4 - subtotalOpcion4;
    const porcentajeUtildiad4 = utildiad4 / subtotalOpcion4;

    const total5 = opcion5 * data.unitario5;
    const utildiad5 = total5 - subtotalOpcion5;
    const porcentajeUtildiad5 = utildiad5 / subtotalOpcion5;
    setData({
      ...data,
      nuevoPorcentaje1: (porcentajeUtildiad1 * 100).toFixed(4),
      nuevoPorcentaje2: (porcentajeUtildiad2 * 100).toFixed(4),
      nuevoPorcentaje3: (porcentajeUtildiad3 * 100).toFixed(4),
      nuevoPorcentaje4: (porcentajeUtildiad4 * 100).toFixed(4),
      nuevoPorcentaje5: (porcentajeUtildiad5 * 100).toFixed(4),
    });
  };

  return (
    <>
      <RowCosto>
        <ColTitulo>P/U Buscado</ColTitulo>
        <ColOpcion>
          <Input
            onChange={hanldeChange}
            name="unitario1"
            value={data.uniatrio1}
          />
        </ColOpcion>
        <ColOpcion>
          <Input
            onChange={hanldeChange}
            name="unitario2"
            value={data.uniatrio2}
          />
        </ColOpcion>
        <ColOpcion>
          <Input
            onChange={hanldeChange}
            name="unitario3"
            value={data.uniatrio3}
          />
        </ColOpcion>
        <ColOpcion>
          <Input
            onChange={hanldeChange}
            name="unitario4"
            value={data.uniatrio4}
          />
        </ColOpcion>
        <ColOpcion>
          <Input
            onChange={hanldeChange}
            name="unitario5"
            value={data.uniatrio5}
          />
        </ColOpcion>
      </RowCosto>
      <RowCosto>
        <ColTitulo>Usar porcentaje</ColTitulo>
        <ColOpcion style={{ textAlign: "right" }}>
          {data.nuevoPorcentaje1} %
        </ColOpcion>
        <ColOpcion style={{ textAlign: "right" }}>
          {data.nuevoPorcentaje2} %
        </ColOpcion>
        <ColOpcion style={{ textAlign: "right" }}>
          {data.nuevoPorcentaje3} %
        </ColOpcion>
        <ColOpcion style={{ textAlign: "right" }}>
          {data.nuevoPorcentaje4} %
        </ColOpcion>
        <ColOpcion style={{ textAlign: "right" }}>
          {data.nuevoPorcentaje5} %
        </ColOpcion>
      </RowCosto>
      <div style={{ textAlign: "center" }}>
        <BotonCard tituloBoton=" - Calcular - " onClick={calcula} />
      </div>
    </>
  );
};
