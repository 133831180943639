import { useHistory } from "react-router";
import useLoadClientes from "../../hooks/useLoadClientes";
import CardPage from "../../layout/CardPage";
import Lista from "./Lista";

export default function Clientes() {
  const history = useHistory();
  useLoadClientes();
  
  return (
    <CardPage
      titulo="Clientes"
      tituloBoton="Agregar Cliente"
      accionBoton={() => history.push("/clientes/create")}
    >
     <Lista />
    </CardPage>
  );
}
