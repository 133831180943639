import { useSelector } from "react-redux";
import InputSinNumber from "../../../../components/input/InputSinNumber";
import ColOpcion from "./ColOpcion";
import ColTitulo from "./ColTitulo";
import RowCosto from "./RowCosto";

export default function TamanoPlantillaImpresion() {

  const { tamanoPlantillaImpresion } =
    useSelector((state) => state.cotizacionesReducer.cotizacion);

 
  //
  return (
    <RowCosto>
      <ColTitulo xs={24} md={12} xl={8} style={{ textAlign: "right" }}>
        Tamaño planilla de impresión IN2
      </ColTitulo>
      <ColOpcion>
        <InputSinNumber
          name="tamanoPlantillaImpresion"
          value={tamanoPlantillaImpresion}
          disabled
        />
      </ColOpcion>
    </RowCosto>
  );
}
