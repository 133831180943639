import { useSelector } from "react-redux";
import InputSinNumber from "../../../../components/input/InputSinNumber";

import ColOpcion from "./ColOpcion";
import ColTitulo from "./ColTitulo";
import RowCosto from "./RowCosto";

export default function CostoSetupSerigrafia() {

  const {

    costoSetupSerigrafiaOpcion1,
    costoSetupSerigrafiaOpcion2,
    costoSetupSerigrafiaOpcion3,
    costoSetupSerigrafiaOpcion4,
    costoSetupSerigrafiaOpcion5,
  } = useSelector((state) => state.cotizacionesReducer.cotizacion);


  return (
    <RowCosto>
      <ColTitulo>Costo setup serigrafía</ColTitulo>
      <ColOpcion>
        <InputSinNumber value={costoSetupSerigrafiaOpcion1} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={costoSetupSerigrafiaOpcion2} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={costoSetupSerigrafiaOpcion3} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={costoSetupSerigrafiaOpcion4} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={costoSetupSerigrafiaOpcion5} disabled />
      </ColOpcion>
    </RowCosto>
  );
}
