import { useSelector } from "react-redux";
import InputSinNumber from "../../../../components/input/InputSinNumber";

import ColOpcion from "./ColOpcion";
import ColTitulo from "./ColTitulo";
import RowCosto from "./RowCosto";

export default function MSIRequeridosAcabados() {
  const {
    MSIRequeridosAcabadosOpcion1,
    MSIRequeridosAcabadosOpcion2,
    MSIRequeridosAcabadosOpcion3,
    MSIRequeridosAcabadosOpcion4,
    MSIRequeridosAcabadosOpcion5,
  } = useSelector((state) => state.cotizacionesReducer.cotizacion);

  return (
    <RowCosto>
      <ColTitulo>
        MSI requeridos para cabados (10mts x máquina adicional)
      </ColTitulo>
      <ColOpcion>
        <InputSinNumber value={MSIRequeridosAcabadosOpcion1} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={MSIRequeridosAcabadosOpcion2} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={MSIRequeridosAcabadosOpcion3} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={MSIRequeridosAcabadosOpcion4} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={MSIRequeridosAcabadosOpcion5} disabled />
      </ColOpcion>
    </RowCosto>
  );
}
