import React from "react";
import { useSelector } from "react-redux";
import { getNumberComa } from "../../../utils/getNumberComa";
import ColOpcion from "../Calculos/renglonesCostos/ColOpcion";
import ColTitulo from "../Calculos/renglonesCostos/ColTitulo";
import RowCosto from "../Calculos/renglonesCostos/RowCosto";

export const Cantidad = () => {
  const { opcion1, opcion2, opcion3, opcion4, opcion5 } = useSelector(
    (state) => state.cotizacionesReducer.cotizacion
  );
  return (
    <RowCosto>
      <ColTitulo>Cantidad</ColTitulo>
      <ColOpcion style={style}>{getNumberComa(opcion1)}</ColOpcion>
      <ColOpcion style={style}>{getNumberComa(opcion2)}</ColOpcion>
      <ColOpcion style={style}>{getNumberComa(opcion3)}</ColOpcion>
      <ColOpcion style={style}>{getNumberComa(opcion4)}</ColOpcion>
      <ColOpcion style={style}>{getNumberComa(opcion5)}</ColOpcion>
    </RowCosto>
  );
};

const style = {
  textAlign: "center",
  fontWeight: 600,
  color: "#ad7100",
  fontSize: 15,
};
