
import { useSelector } from "react-redux";
import InputSinNumber from "../../../../components/input/InputSinNumber";

import ColOpcion from "./ColOpcion";
import ColTitulo from "./ColTitulo";
import RowCosto from "./RowCosto";

export default function CargoPreparacionArte() {

  const {
    cargoPreparacionArteOpcion1,
    cargoPreparacionArteOpcion2,
    cargoPreparacionArteOpcion3,
    cargoPreparacionArteOpcion4,
    cargoPreparacionArteOpcion5,
  } = useSelector((state) => state.cotizacionesReducer.cotizacion);


  return (
    <RowCosto>
      <ColTitulo>Cargo preparación de arte</ColTitulo>
      <ColOpcion>
        <InputSinNumber value={cargoPreparacionArteOpcion1} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={cargoPreparacionArteOpcion2} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={cargoPreparacionArteOpcion3} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={cargoPreparacionArteOpcion4} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={cargoPreparacionArteOpcion5} disabled />
      </ColOpcion>
    </RowCosto>
  );
}
