import { useState } from "react";
import { Layout, Menu } from "antd";
import {
  PoweroffOutlined,
  TeamOutlined,
  DatabaseOutlined,
  FontColorsOutlined,
  ApartmentOutlined,
  DollarCircleOutlined,
  UserOutlined,
} from "@ant-design/icons";
import Logo from "./Logo";
import { useHistory } from "react-router-dom";
import Notificacion from "./Notificacion";
import { startLogoff } from "../context/actions/loginActions";
import { useDispatch, useSelector } from "react-redux";
import { ambiente, ver } from "../constants/url";
import { Ver } from "./Ver";

const { Content, Sider } = Layout;

////////////////////////////////////////////////////////////////////////////////
export function LayoutPage({ children }) {
  const { user } = useSelector((state) => state.userReducer);
  const dispatch = useDispatch();
  const history = useHistory();
  const [collapsed, setCollapsed] = useState(false);

  const onCollapse = () => {
    setCollapsed(!collapsed);
  };

  const handleClick = ({ key }) => {
    if (key !== "/logoff") history.push(key);
    else dispatch(startLogoff());
  };
  ////////////////////////////////////////////////////////////////////////////////
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={onCollapse}
        style={{ backgroundColor: "#fff" }}
      >
        <Logo isCollapsed={collapsed} />

        <Menu
          onClick={handleClick}
          style={{ marginTop: 50 }}
          theme="light"
          defaultSelectedKeys={["1"]}
          mode="inline"
        >
          {!collapsed && (
            <div style={{ marginLeft: 27, marginBottom: 20, color: "#ffa500" }}>
              <UserOutlined />{" "}
              <span style={{ marginLeft: 5 }}>{user?.Nombre}</span>
            </div>
          )}

          <Menu.Item key="/" icon={<DollarCircleOutlined />}>
            Cotizaciones
          </Menu.Item>
          <Menu.Item key="/clientes" icon={<TeamOutlined />}>
            Clientes
          </Menu.Item>

          <Menu.Item key="/proveedores" icon={<TeamOutlined />}>
            Proveedores
          </Menu.Item>
          <Menu.Item key="/articulos" icon={<DatabaseOutlined />}>
            Artículos
          </Menu.Item>

          <Menu.Item key="/catalogos" icon={<ApartmentOutlined />}>
            Catálogos
          </Menu.Item>

          <Menu.Item key="/constantes" icon={<FontColorsOutlined />}>
            Constantes
          </Menu.Item>

          <Menu.Item key="/logoff" icon={<PoweroffOutlined />}>
            cerrar sesión
          </Menu.Item>
        </Menu>
        <Ver />
      </Sider>

      <Layout>
        <Content style={{ paddingLeft: 10, paddingTop: 20, paddingRight: 10 }}>
          {children}
        </Content>
      </Layout>
      <Notificacion />
    </Layout>
  );
}
