import { types } from "../types/types";
import { startPostFase } from "./cotizacionesActions";
import {
  setBarnizEspecialRSS,
  setBarnizMaquina,
  setCambioColor,
  setCambioPlacas,
  setCantidadTotal,
  setCargoInfoVariable,
  setCargoPreparacionArte,
  setCostoCorteIndividual,
  setCostoDados,
  setCostoLaminado,
  setCostoPantallaBarnizEspecial,
  setCostoPreprensaOffset,
  setCostoSetupDigital,
  setCostoSetupMaquinasAdicionales,
  setCostoSetupOffset,
  setCostoSetupSerigrafia,
  setCostoSubstrato,
  setCostoTiempoMaquinaAdicional,
  setCostoTiempoMaquinaBaseBlancaDigital,
  setCostoTiempoMaquinaImpDigital,
  setCostoTiempoMaquinaImpOffset,
  setCostoTiempoMaquinaImpRSS,
  setCostoUsoPrimera,
  setDepreciacionDadoSuaje,
  setFoil,
  setMSIRequeridosAcabados,
  setMSIRequeridosImpresion,
  setMSIRequeridosSetup,
  setMSITotal,
  setNoGolpesCalculo,
  setPantallaRSS,
  setServicioEpson,
  setTamanoPlantillaImpresion,
  setTiempoMaquinaImpDigital,
  setTintasSemirotativa,
  setTintasSerigrafiaRSS,
  setTonerBlanco,
  setTonerCMYK,
  setTotalMaquinaSetup,
  setTotalPiesLineales,
} from "./cotizacionesCostosActions";
import {
  setCostoMateriasPrimas,
  setEmpaque,
  setMetrosLineales,
  setMetrosLinealesRequeridos,
  setTiempoMaquinaImpOff,
} from "./EmpaqueAction";
import { setCalculing } from "./loadingActions";
import { setStep } from "./stepActions";

export const startCalculate = (hacerPost) => {
  return async (dispatch, getState) => {
    const mayorCero = (num) =>
      num || num !== undefined || num !== "" || num > 0;

    const {
      velocidadOpcion1,
      velocidadOpcion2,
      velocidadOpcion3,
      velocidadOpcion4,
    } = getState().cotizacionesReducer.cotizacion;
    if (
      !mayorCero(velocidadOpcion1) ||
      !mayorCero(velocidadOpcion2) ||
      !mayorCero(velocidadOpcion3) ||
      !mayorCero(velocidadOpcion4)
    ) {
      dispatch(generaErrorVelocidad());
      return;
    }

    await dispatch(setCantidadTotal());
    await dispatch(setTamanoPlantillaImpresion());
    await dispatch(setMSIRequeridosImpresion());
    await dispatch(setMSIRequeridosSetup());

    await dispatch(setMSIRequeridosAcabados());
    await dispatch(setMSITotal());
    await dispatch(setTotalPiesLineales());
    await dispatch(setNoGolpesCalculo());

    await dispatch(setCostoTiempoMaquinaImpDigital());
    await dispatch(setCostoTiempoMaquinaImpRSS());
    await dispatch(setCostoTiempoMaquinaImpOffset());

    await dispatch(setCostoTiempoMaquinaAdicional());

    await dispatch(setCostoTiempoMaquinaBaseBlancaDigital());
    await dispatch(setCostoSetupOffset());
    await dispatch(setCostoSetupDigital());
    await dispatch(setCostoSetupSerigrafia());

    await dispatch(setCostoSetupMaquinasAdicionales());
    await dispatch(setCostoSubstrato());
    await dispatch(setCostoLaminado());
    await dispatch(setCostoCorteIndividual());

    await dispatch(setCostoPreprensaOffset());
    await dispatch(setDepreciacionDadoSuaje());
    await dispatch(setCambioPlacas());
    await dispatch(setCambioColor());

    await dispatch(setCargoPreparacionArte());
    await dispatch(setTiempoMaquinaImpDigital());
    await dispatch(setCostoUsoPrimera());
    await dispatch(setCargoInfoVariable());
    await dispatch(setPantallaRSS());

    await dispatch(setCostoPantallaBarnizEspecial());
    await dispatch(setCostoDados());
    await dispatch(setTintasSerigrafiaRSS());
    await dispatch(setTonerCMYK());
    await dispatch(setTonerBlanco());

    await dispatch(setBarnizEspecialRSS());
    await dispatch(setTintasSemirotativa());
    await dispatch(setBarnizMaquina());
    await dispatch(setServicioEpson());
    await dispatch(setFoil());

    await dispatch(setMetrosLineales());
    await dispatch(setCostoMateriasPrimas());
    await dispatch(setTiempoMaquinaImpDigital());
    await dispatch(setTiempoMaquinaImpOff());

    await dispatch(ceroUtildiad());
    await dispatch(setTotalMaquinaSetup());
    await dispatch(setEmpaque());

    if (hacerPost) {
      await dispatch(startPostFase("fase4"));
      dispatch(setStep(5));
    }

    dispatch(setCalculing(false));
  };
};

const ceroUtildiad = () => ({
  type: types.ceroUtildiad,
  payload: null,
});

const generaErrorVelocidad = () => {};
