import { Col, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import BotonCard from "../../../components/Boton/BotonCard";
import { SinGuardar } from "../../../components/Boton/SinGuardar";
import { startCalculate } from "../../../context/actions/calculosActions";
import { setCalculing } from "../../../context/actions/loadingActions";

////
export default function ButtonContinuar() {
  const {
    cotizacion: { step },
    seccionModificada: { terminados },
  } = useSelector((state) => state.cotizacionesReducer);

  const dispatch = useDispatch();
  const { calculing } = useSelector((state) => state.loadingReducer);

  const accionBoton = (soloCalcular) => {
    dispatch(setCalculing(true));
    setTimeout(() => {
      dispatch(startCalculate(soloCalcular));
    }, 50);
  };

  // const soloCalcular = () => {
  //   dispatch(setCalculing(true));
  //   setTimeout(() => {
  //     dispatch(startCalculate(false));
  //   }, 50);
  // };

  return (
    <Row justify="end">
      <Col span={18}>
        <BotonCard
          onClick={() => accionBoton(false)}
          tituloBoton={"Solo Calcular"}
          style={{ color: "green" }}
        />
      </Col>
      <Col span={2}>
        <SinGuardar show={terminados} style={{ marginTop: 3 }} />
      </Col>
      <Col span={3}>
        <BotonCard
          loading={calculing}
          onClick={() => accionBoton(true)}
          tituloBoton={"Calcular y avanzar"}
          style={step > 4 ? { color: "green" } : { color: "#ad7100" }}
        />
      </Col>
    </Row>
  );
}
