import { articuloInit, articulosInitial } from "../initial/articulosInitial";
import { types } from "../types/types";

export const articulosReducer = (state = articulosInitial, action) => {
  switch (action.type) {

    case types.setArticulosEnCotizacion:
      return {
        ...state,
        articulosEnCotizacion: action.payload
      }
      
    case types.storeArticulos:
      return {
        ...state,
        articulos: action.payload,
      };

    case types.changeArticulo:
      return {
        ...state,
        articulo: { ...state.articulo, ...action.payload },
      };

      case types.changeArticulo:
        return {
          ...state,
          articulo: { ...state.articulo, ...action.payload },
        };

        case types.storeArticuloFull:
          return {
            ...state,
            articulo: action.payload,
          };

        case types.cleanArticulo:
          return {
            ...state,
            articulo: articuloInit,
          };
    


    default:
      return state;
  }
};
