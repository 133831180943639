import { Input, Table } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useArticulosColumns from "./useArticulosColumns";

export default function Lista() {
  const { articulosColumns } = useArticulosColumns();
  const { articulos } = useSelector((state) => state.articulosReducer);

  const [listaFinal, setListaFinal] = useState([]);

  useEffect(() => {
    setListaFinal(articulos);
  }, [articulos]);

  const filter = ({ target }) => {
    if (target.value !== "") {
      var newList = articulos.filter(
        (a) =>
          (a.nombre &&
            a.nombre.toLowerCase().includes(target.value.toLowerCase())) ||
          (a.descripcion &&
            a.descripcion.toLowerCase().includes(target.value.toLowerCase())) ||
          (a.grupo &&
            a.grupo.toLowerCase().includes(target.value.toLowerCase()))
      );
      setListaFinal(newList);
    } else {
      setListaFinal(articulos);
    }
  };

  return (
    <>
      <Input
        onChange={filter}
        placeholder="buscar"
        style={{ width: 300, marginBottom: 10 }}
      />
      <Table
        rowKey="articuloID"
        columns={articulosColumns}
        dataSource={listaFinal}
      />
    </>
  );
}
