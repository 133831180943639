import parseFloat2 from "../../utils/parseFloat2";
import parseFloat4 from "../../utils/parseFloat4";
import { changeCotizacion } from "./cotizacionesActions";

/////////////////////////////////////////////////////////////////////////////////////////
export const setCantidadTotal = () => {
  return (dispatch, getState) => {
    const {
      porcentajeMermaOpcion1,
      porcentajeMermaOpcion2,
      porcentajeMermaOpcion3,
      porcentajeMermaOpcion4,
      porcentajeMermaOpcion5,
      opcion1,
      opcion2,
      opcion3,
      opcion4,
      opcion5,
    } = getState().cotizacionesReducer.cotizacion;

    const cantidadTotalOpcion1 =
      (Number(porcentajeMermaOpcion1) / 100 + 1) * Number(opcion1);

    const cantidadTotalOpcion2 =
      (Number(porcentajeMermaOpcion2) / 100 + 1) * Number(opcion2);

    const cantidadTotalOpcion3 =
      (Number(porcentajeMermaOpcion3) / 100 + 1) * Number(opcion3);

    const cantidadTotalOpcion4 =
      (Number(porcentajeMermaOpcion4) / 100 + 1) * Number(opcion4);

    const cantidadTotalOpcion5 =
      (Number(porcentajeMermaOpcion5) / 100 + 1) * Number(opcion5);

    dispatch(
      changeCotizacion({
        cantidadTotalOpcion1: parseFloat2(cantidadTotalOpcion1),
        cantidadTotalOpcion2: parseFloat2(cantidadTotalOpcion2),
        cantidadTotalOpcion3: parseFloat2(cantidadTotalOpcion3),
        cantidadTotalOpcion4: parseFloat2(cantidadTotalOpcion4),
        cantidadTotalOpcion5: parseFloat2(cantidadTotalOpcion5),
      })
    );
  };
};

/////////////////////////////////////////////////////////////////////////////////////////
export const setTamanoPlantillaImpresion = () => {
  return (dispatch, getState) => {
    const { anchoBanda, repeticionPlantillaImpresion } =
      getState().cotizacionesReducer.cotizacion;

    const tamanoPlantillaImpresion =
      Number(anchoBanda) * Number(repeticionPlantillaImpresion);

    dispatch(
      changeCotizacion({
        tamanoPlantillaImpresion: parseFloat2(tamanoPlantillaImpresion),
      })
    );
  };
};

/////////////////////////////////////////////////////////////////////////////////////////
export const setMSIRequeridosImpresion = () => {
  return (dispatch, getState) => {
    const {
      cantidadTotalOpcion1,
      cantidadTotalOpcion2,
      cantidadTotalOpcion3,
      cantidadTotalOpcion4,
      cantidadTotalOpcion5,
      piezasImpresion,
      tamanoPlantillaImpresion,
    } = getState().cotizacionesReducer.cotizacion;

    const MSIRequeridosImpresionOpcion1 =
      ((Number(cantidadTotalOpcion1) / Number(piezasImpresion)) *
        Number(tamanoPlantillaImpresion)) /
      1000;

    const MSIRequeridosImpresionOpcion2 =
      ((Number(cantidadTotalOpcion2) / Number(piezasImpresion)) *
        Number(tamanoPlantillaImpresion)) /
      1000;
    const MSIRequeridosImpresionOpcion3 =
      ((Number(cantidadTotalOpcion3) / Number(piezasImpresion)) *
        Number(tamanoPlantillaImpresion)) /
      1000;

    const MSIRequeridosImpresionOpcion4 =
      ((Number(cantidadTotalOpcion4) / Number(piezasImpresion)) *
        Number(tamanoPlantillaImpresion)) /
      1000;

    const MSIRequeridosImpresionOpcion5 =
      ((Number(cantidadTotalOpcion5) / Number(piezasImpresion)) *
        Number(tamanoPlantillaImpresion)) /
      1000;

    dispatch(
      changeCotizacion({
        MSIRequeridosImpresionOpcion1: parseFloat2(
          MSIRequeridosImpresionOpcion1
        ),
      })
    );
    dispatch(
      changeCotizacion({
        MSIRequeridosImpresionOpcion2: parseFloat2(
          MSIRequeridosImpresionOpcion2
        ),
      })
    );
    dispatch(
      changeCotizacion({
        MSIRequeridosImpresionOpcion3: parseFloat2(
          MSIRequeridosImpresionOpcion3
        ),
      })
    );
    dispatch(
      changeCotizacion({
        MSIRequeridosImpresionOpcion4: parseFloat2(
          MSIRequeridosImpresionOpcion4
        ),
      })
    );

    dispatch(
      changeCotizacion({
        MSIRequeridosImpresionOpcion5: parseFloat2(
          MSIRequeridosImpresionOpcion5
        ),
      })
    );
  };
};

/////////////////////////////////////////////////////////////////////////////////////////
export const setMSIRequeridosSetup = () => {
  return (dispatch, getState) => {
    const { sistemaCotizado, anchoBanda, noTintas } =
      getState().cotizacionesReducer.cotizacion;

    const MSIRequeridosSetup =
      sistemaCotizado === "O"
        ? ((Number(anchoBanda) * 1575) / 1000) * noTintas
        : (Number(anchoBanda) * 394) / 1000;

    dispatch(
      changeCotizacion({
        MSIRequeridosSetupOpcion1: parseFloat2(MSIRequeridosSetup),
      })
    );
    dispatch(
      changeCotizacion({
        MSIRequeridosSetupOpcion2: parseFloat2(MSIRequeridosSetup),
      })
    );
    dispatch(
      changeCotizacion({
        MSIRequeridosSetupOpcion3: parseFloat2(MSIRequeridosSetup),
      })
    );
    dispatch(
      changeCotizacion({
        MSIRequeridosSetupOpcion4: parseFloat2(MSIRequeridosSetup),
      })
    );

    dispatch(
      changeCotizacion({
        MSIRequeridosSetupOpcion5: parseFloat2(MSIRequeridosSetup),
      })
    );
  };
};

/////////////////////////////////////////////////////////////////////////////////////////
export const setMSIRequeridosAcabados = () => {
  return (dispatch, getState) => {
    const { anchoBanda, noMaquinasAdicionales } =
      getState().cotizacionesReducer.cotizacion;

    const MSIRequeridosAcabados =
      (Number(noMaquinasAdicionales) * Number(anchoBanda) * 394) / 1000;

    dispatch(
      changeCotizacion({
        MSIRequeridosAcabadosOpcion1: parseFloat2(MSIRequeridosAcabados),
        MSIRequeridosAcabadosOpcion2: parseFloat2(MSIRequeridosAcabados),
        MSIRequeridosAcabadosOpcion3: parseFloat2(MSIRequeridosAcabados),
        MSIRequeridosAcabadosOpcion4: parseFloat2(MSIRequeridosAcabados),
        MSIRequeridosAcabadosOpcion5: parseFloat2(MSIRequeridosAcabados),
      })
    );
  };
};

/////////////////////////////////////////////////////////////////////////////////////////
export const setMSITotal = () => {
  return (dispatch, getState) => {
    const {
      MSIRequeridosImpresionOpcion1,
      MSIRequeridosImpresionOpcion2,
      MSIRequeridosImpresionOpcion3,
      MSIRequeridosImpresionOpcion4,
      MSIRequeridosImpresionOpcion5,

      MSIRequeridosSetupOpcion1,
      MSIRequeridosSetupOpcion2,
      MSIRequeridosSetupOpcion3,
      MSIRequeridosSetupOpcion4,
      MSIRequeridosSetupOpcion5,

      MSIRequeridosAcabadosOpcion1,
      MSIRequeridosAcabadosOpcion2,
      MSIRequeridosAcabadosOpcion3,
      MSIRequeridosAcabadosOpcion4,
      MSIRequeridosAcabadosOpcion5,
    } = getState().cotizacionesReducer.cotizacion;

    const MSITotalOpcion1 =
      Number(MSIRequeridosImpresionOpcion1) +
      Number(MSIRequeridosSetupOpcion1) +
      Number(MSIRequeridosAcabadosOpcion1);

    const MSITotalOpcion2 =
      Number(MSIRequeridosImpresionOpcion2) +
      Number(MSIRequeridosSetupOpcion2) +
      Number(MSIRequeridosAcabadosOpcion2);

    const MSITotalOpcion3 =
      Number(MSIRequeridosImpresionOpcion3) +
      Number(MSIRequeridosSetupOpcion3) +
      Number(MSIRequeridosAcabadosOpcion3);

    const MSITotalOpcion4 =
      Number(MSIRequeridosImpresionOpcion4) +
      Number(MSIRequeridosSetupOpcion4) +
      Number(MSIRequeridosAcabadosOpcion4);

    const MSITotalOpcion5 =
      Number(MSIRequeridosImpresionOpcion5) +
      Number(MSIRequeridosSetupOpcion5) +
      Number(MSIRequeridosAcabadosOpcion5);

    dispatch(
      changeCotizacion({
        MSITotalOpcion1: parseFloat2(MSITotalOpcion1),
      })
    );
    dispatch(
      changeCotizacion({
        MSITotalOpcion2: parseFloat2(MSITotalOpcion2),
      })
    );
    dispatch(
      changeCotizacion({
        MSITotalOpcion3: parseFloat2(MSITotalOpcion3),
      })
    );
    dispatch(
      changeCotizacion({
        MSITotalOpcion4: parseFloat2(MSITotalOpcion4),
      })
    );
    dispatch(
      changeCotizacion({
        MSITotalOpcion5: parseFloat2(MSITotalOpcion5),
      })
    );
  };
};

/////////////////////////////////////////////////////////////////////////////////////////
export const setTotalPiesLineales = () => {
  return (dispatch, getState) => {
    const {
      MSITotalOpcion1,
      MSITotalOpcion2,
      MSITotalOpcion3,
      MSITotalOpcion4,
      MSITotalOpcion5,

      anchoBanda,
    } = getState().cotizacionesReducer.cotizacion;

    const totalPiesLinealesOpcion1 =
      (Number(MSITotalOpcion1) * 1000) / anchoBanda / 12;

    const totalPiesLinealesOpcion2 =
      (Number(MSITotalOpcion2) * 1000) / anchoBanda / 12;

    const totalPiesLinealesOpcion3 =
      (Number(MSITotalOpcion3) * 1000) / anchoBanda / 12;

    const totalPiesLinealesOpcion4 =
      (Number(MSITotalOpcion4) * 1000) / anchoBanda / 12;

    const totalPiesLinealesOpcion5 =
      (Number(MSITotalOpcion5) * 1000) / anchoBanda / 12;

    dispatch(
      changeCotizacion({
        totalPiesLinealesOpcion1: parseFloat2(totalPiesLinealesOpcion1),
      })
    );
    dispatch(
      changeCotizacion({
        totalPiesLinealesOpcion2: parseFloat2(totalPiesLinealesOpcion2),
      })
    );
    dispatch(
      changeCotizacion({
        totalPiesLinealesOpcion3: parseFloat2(totalPiesLinealesOpcion3),
      })
    );
    dispatch(
      changeCotizacion({
        totalPiesLinealesOpcion4: parseFloat2(totalPiesLinealesOpcion4),
      })
    );

    dispatch(
      changeCotizacion({
        totalPiesLinealesOpcion5: parseFloat2(totalPiesLinealesOpcion5),
      })
    );
  };
};

/////////////////////////////////////////////////////////////////////////////////////////
export const setNoGolpesCalculo = () => {
  return (dispatch, getState) => {
    const {
      totalPiesLinealesOpcion1,
      totalPiesLinealesOpcion2,
      totalPiesLinealesOpcion3,
      totalPiesLinealesOpcion4,
      totalPiesLinealesOpcion5,

      repeticionPlantillaImpresion,
    } = getState().cotizacionesReducer.cotizacion;

    const noGolpesCalculoOpcion1 =
      (Number(totalPiesLinealesOpcion1) * 12) / repeticionPlantillaImpresion;

    const noGolpesCalculoOpcion2 =
      (Number(totalPiesLinealesOpcion2) * 12) / repeticionPlantillaImpresion;

    const noGolpesCalculoOpcion3 =
      (Number(totalPiesLinealesOpcion3) * 12) / repeticionPlantillaImpresion;

    const noGolpesCalculoOpcion4 =
      (Number(totalPiesLinealesOpcion4) * 12) / repeticionPlantillaImpresion;

    const noGolpesCalculoOpcion5 =
      (Number(totalPiesLinealesOpcion5) * 12) / repeticionPlantillaImpresion;

    dispatch(
      changeCotizacion({
        noGolpesCalculoOpcion1: parseFloat2(noGolpesCalculoOpcion1),
      })
    );
    dispatch(
      changeCotizacion({
        noGolpesCalculoOpcion2: parseFloat2(noGolpesCalculoOpcion2),
      })
    );
    dispatch(
      changeCotizacion({
        noGolpesCalculoOpcion3: parseFloat2(noGolpesCalculoOpcion3),
      })
    );
    dispatch(
      changeCotizacion({
        noGolpesCalculoOpcion4: parseFloat2(noGolpesCalculoOpcion4),
      })
    );
    dispatch(
      changeCotizacion({
        noGolpesCalculoOpcion5: parseFloat2(noGolpesCalculoOpcion5),
      })
    );
  };
};

/////////////////////////////////////////////////////////////////////////////////////////
export const setCostoTiempoMaquinaImpDigital = () => {
  return (dispatch, getState) => {
    const {
      totalPiesLinealesOpcion1,
      totalPiesLinealesOpcion2,
      totalPiesLinealesOpcion3,
      totalPiesLinealesOpcion4,
      totalPiesLinealesOpcion5,

      velocidadOpcion1,
      velocidadOpcion2,
      velocidadOpcion3,
      velocidadOpcion4,
      velocidadOpcion5,

      sistemaCotizado,
    } = getState().cotizacionesReducer.cotizacion;

    const costoTiempoMaquinaImpDigitalOpcion1 =
      sistemaCotizado === "D"
        ? (Number(totalPiesLinealesOpcion1) / (Number(velocidadOpcion1) * 60)) *
          95
        : // cossto x hr digtial
          0;

    const costoTiempoMaquinaImpDigitalOpcion2 =
      sistemaCotizado === "D"
        ? (Number(totalPiesLinealesOpcion2) / (Number(velocidadOpcion2) * 60)) *
          95
        : // cossto x hr digtial
          0;

    const costoTiempoMaquinaImpDigitalOpcion3 =
      sistemaCotizado === "D"
        ? (Number(totalPiesLinealesOpcion3) / (Number(velocidadOpcion3) * 60)) *
          95
        : // cossto x hr digtial
          0;

    const costoTiempoMaquinaImpDigitalOpcion4 =
      sistemaCotizado === "D"
        ? (Number(totalPiesLinealesOpcion4) / (Number(velocidadOpcion4) * 60)) *
          95
        : // cossto x hr digtial
          0;

    const costoTiempoMaquinaImpDigitalOpcion5 =
      sistemaCotizado === "D"
        ? (Number(totalPiesLinealesOpcion5) / (Number(velocidadOpcion5) * 60)) *
          95
        : // cossto x hr digtial
          0;

    dispatch(
      changeCotizacion({
        costoTiempoMaquinaImpDigitalOpcion1: parseFloat4(
          costoTiempoMaquinaImpDigitalOpcion1
        ),
      })
    );
    dispatch(
      changeCotizacion({
        costoTiempoMaquinaImpDigitalOpcion2: parseFloat4(
          costoTiempoMaquinaImpDigitalOpcion2
        ),
      })
    );
    dispatch(
      changeCotizacion({
        costoTiempoMaquinaImpDigitalOpcion3: parseFloat4(
          costoTiempoMaquinaImpDigitalOpcion3
        ),
      })
    );
    dispatch(
      changeCotizacion({
        costoTiempoMaquinaImpDigitalOpcion4: parseFloat4(
          costoTiempoMaquinaImpDigitalOpcion4
        ),
      })
    );

    dispatch(
      changeCotizacion({
        costoTiempoMaquinaImpDigitalOpcion5: parseFloat4(
          costoTiempoMaquinaImpDigitalOpcion5
        ),
      })
    );
  };
};

/////////////////////////////////////////////////////////////////////////////////////////
export const setCostoTiempoMaquinaImpRSS = () => {
  return (dispatch, getState) => {
    const {
      noGolpesCalculoOpcion1,
      noGolpesCalculoOpcion2,
      noGolpesCalculoOpcion3,
      noGolpesCalculoOpcion4,
      noGolpesCalculoOpcion5,
      velocidadOpcion1,
      velocidadOpcion2,
      velocidadOpcion3,
      velocidadOpcion4,
      velocidadOpcion5,
      sistemaCotizado,
      noTintas,
    } = getState().cotizacionesReducer.cotizacion;

    const costoTiempoMaquinaImpRSSOpcion1 =
      sistemaCotizado === "S"
        ? parseFloat2(
            (Number(noGolpesCalculoOpcion1) / (Number(velocidadOpcion1) * 60)) *
              145
          ) * noTintas
        : 0;

    const costoTiempoMaquinaImpRSSOpcion2 =
      sistemaCotizado === "S"
        ? parseFloat2(
            (Number(noGolpesCalculoOpcion2) / (Number(velocidadOpcion2) * 60)) *
              145
          ) * noTintas
        : 0;

    const costoTiempoMaquinaImpRSSOpcion3 =
      sistemaCotizado === "S"
        ? parseFloat2(
            (Number(noGolpesCalculoOpcion3) / (Number(velocidadOpcion3) * 60)) *
              145
          ) * noTintas
        : 0;

    const costoTiempoMaquinaImpRSSOpcion4 =
      sistemaCotizado === "S"
        ? parseFloat2(
            (Number(noGolpesCalculoOpcion4) / (Number(velocidadOpcion4) * 60)) *
              145
          ) * noTintas
        : 0;

    const costoTiempoMaquinaImpRSSOpcion5 =
      sistemaCotizado === "S"
        ? parseFloat2(
            (Number(noGolpesCalculoOpcion5) / (Number(velocidadOpcion5) * 60)) *
              145
          ) * noTintas
        : 0;

    dispatch(
      changeCotizacion({
        costoTiempoMaquinaImpRSSOpcion1: parseFloat4(
          costoTiempoMaquinaImpRSSOpcion1
        ),
        costoTiempoMaquinaImpRSSOpcion2: parseFloat4(
          costoTiempoMaquinaImpRSSOpcion2
        ),
        costoTiempoMaquinaImpRSSOpcion3: parseFloat4(
          costoTiempoMaquinaImpRSSOpcion3
        ),
        costoTiempoMaquinaImpRSSOpcion4: parseFloat4(
          costoTiempoMaquinaImpRSSOpcion4
        ),
        costoTiempoMaquinaImpRSSOpcion5: parseFloat4(
          costoTiempoMaquinaImpRSSOpcion5
        ),
      })
    );
  };
};

/////////////////////////////////////////////////////////////////////////////////////////
export const setCostoTiempoMaquinaImpOffset = () => {
  return (dispatch, getState) => {
    const {
      noGolpesCalculoOpcion1,
      noGolpesCalculoOpcion2,
      noGolpesCalculoOpcion3,
      noGolpesCalculoOpcion4,
      noGolpesCalculoOpcion5,
      velocidadOpcion1,
      velocidadOpcion2,
      velocidadOpcion3,
      velocidadOpcion4,
      velocidadOpcion5,
      sistemaCotizado,
    } = getState().cotizacionesReducer.cotizacion;

    console.log({
      noGolpesCalculoOpcion1,
      velocidadOpcion1,
      costoHora: getState().constantesReducer.costO_MAQUINA_HORA_IMPRESION,
    });
    const costoTiempoMaquinaImpOffsetOpcion1 =
      sistemaCotizado === "O"
        ? (Number(noGolpesCalculoOpcion1) / (Number(velocidadOpcion1) * 60)) *
          getState().constantesReducer.costO_MAQUINA_HORA_IMPRESION
        : 0;

    const costoTiempoMaquinaImpOffsetOpcion2 =
      sistemaCotizado === "O"
        ? (Number(noGolpesCalculoOpcion2) / (Number(velocidadOpcion2) * 60)) *
          getState().constantesReducer.costO_MAQUINA_HORA_IMPRESION
        : 0;

    const costoTiempoMaquinaImpOffsetOpcion3 =
      sistemaCotizado === "O"
        ? (Number(noGolpesCalculoOpcion3) / (Number(velocidadOpcion3) * 60)) *
          getState().constantesReducer.costO_MAQUINA_HORA_IMPRESION
        : 0;

    const costoTiempoMaquinaImpOffsetOpcion4 =
      sistemaCotizado === "O"
        ? (Number(noGolpesCalculoOpcion4) / (Number(velocidadOpcion4) * 60)) *
          getState().constantesReducer.costO_MAQUINA_HORA_IMPRESION
        : 0;

    const costoTiempoMaquinaImpOffsetOpcion5 =
      sistemaCotizado === "O"
        ? (Number(noGolpesCalculoOpcion5) / (Number(velocidadOpcion5) * 60)) *
          getState().constantesReducer.costO_MAQUINA_HORA_IMPRESION
        : 0;

    dispatch(
      changeCotizacion({
        costoTiempoMaquinaImpOffsetOpcion1: parseFloat4(
          costoTiempoMaquinaImpOffsetOpcion1
        ),
      })
    );
    dispatch(
      changeCotizacion({
        costoTiempoMaquinaImpOffsetOpcion2: parseFloat4(
          costoTiempoMaquinaImpOffsetOpcion2
        ),
      })
    );
    dispatch(
      changeCotizacion({
        costoTiempoMaquinaImpOffsetOpcion3: parseFloat4(
          costoTiempoMaquinaImpOffsetOpcion3
        ),
      })
    );
    dispatch(
      changeCotizacion({
        costoTiempoMaquinaImpOffsetOpcion4: parseFloat4(
          costoTiempoMaquinaImpOffsetOpcion4
        ),
      })
    );

    dispatch(
      changeCotizacion({
        costoTiempoMaquinaImpOffsetOpcion5: parseFloat4(
          costoTiempoMaquinaImpOffsetOpcion5
        ),
      })
    );
  };
};

/////////////////////////////////////////////////////////////////////////////////////////
export const setCostoTiempoMaquinaAdicional = () => {
  return (dispatch, getState) => {
    const {
      costoTiempoMaquinaImpRSSOpcion1,
      costoTiempoMaquinaImpRSSOpcion2,
      costoTiempoMaquinaImpRSSOpcion3,
      costoTiempoMaquinaImpRSSOpcion4,
      costoTiempoMaquinaImpRSSOpcion5,
      costoTiempoMaquinaImpDigitalOpcion1,
      costoTiempoMaquinaImpDigitalOpcion2,
      costoTiempoMaquinaImpDigitalOpcion3,
      costoTiempoMaquinaImpDigitalOpcion4,
      costoTiempoMaquinaImpDigitalOpcion5,
      costoTiempoMaquinaImpOffsetOpcion1,
      costoTiempoMaquinaImpOffsetOpcion2,
      costoTiempoMaquinaImpOffsetOpcion3,
      costoTiempoMaquinaImpOffsetOpcion4,
      costoTiempoMaquinaImpOffsetOpcion5,
      noMaquinasAdicionales,
    } = getState().cotizacionesReducer.cotizacion;

    const costoTiempoMaquinaAdicionalOpcion1 =
      (Number(costoTiempoMaquinaImpRSSOpcion1) +
        Number(costoTiempoMaquinaImpDigitalOpcion1) +
        Number(costoTiempoMaquinaImpOffsetOpcion1)) *
      noMaquinasAdicionales;

    const costoTiempoMaquinaAdicionalOpcion2 =
      (Number(costoTiempoMaquinaImpRSSOpcion2) +
        Number(costoTiempoMaquinaImpDigitalOpcion2) +
        Number(costoTiempoMaquinaImpOffsetOpcion2)) *
      noMaquinasAdicionales;

    const costoTiempoMaquinaAdicionalOpcion3 =
      (Number(costoTiempoMaquinaImpRSSOpcion3) +
        Number(costoTiempoMaquinaImpDigitalOpcion3) +
        Number(costoTiempoMaquinaImpOffsetOpcion3)) *
      noMaquinasAdicionales;

    const costoTiempoMaquinaAdicionalOpcion4 =
      (Number(costoTiempoMaquinaImpRSSOpcion4) +
        Number(costoTiempoMaquinaImpDigitalOpcion4) +
        Number(costoTiempoMaquinaImpOffsetOpcion4)) *
      noMaquinasAdicionales;

    const costoTiempoMaquinaAdicionalOpcion5 =
      (Number(costoTiempoMaquinaImpRSSOpcion5) +
        Number(costoTiempoMaquinaImpDigitalOpcion5) +
        Number(costoTiempoMaquinaImpOffsetOpcion5)) *
      noMaquinasAdicionales;

    dispatch(
      changeCotizacion({
        costoTiempoMaquinaAdicionalOpcion1: parseFloat4(
          costoTiempoMaquinaAdicionalOpcion1
        ),
      })
    );
    dispatch(
      changeCotizacion({
        costoTiempoMaquinaAdicionalOpcion2: parseFloat4(
          costoTiempoMaquinaAdicionalOpcion2
        ),
      })
    );
    dispatch(
      changeCotizacion({
        costoTiempoMaquinaAdicionalOpcion3: parseFloat4(
          costoTiempoMaquinaAdicionalOpcion3
        ),
      })
    );
    dispatch(
      changeCotizacion({
        costoTiempoMaquinaAdicionalOpcion4: parseFloat4(
          costoTiempoMaquinaAdicionalOpcion4
        ),
      })
    );

    dispatch(
      changeCotizacion({
        costoTiempoMaquinaAdicionalOpcion5: parseFloat4(
          costoTiempoMaquinaAdicionalOpcion5
        ),
      })
    );
  };
};

/////////////////////////////////////////////////////////////////////////////////////////
export const setCostoTiempoMaquinaBaseBlancaDigital = () => {
  return (dispatch, getState) => {
    const {
      requiereBaseBlancaDigital,
      costoTiempoMaquinaImpDigitalOpcion1,
      costoTiempoMaquinaImpDigitalOpcion2,
      costoTiempoMaquinaImpDigitalOpcion3,
      costoTiempoMaquinaImpDigitalOpcion4,
      costoTiempoMaquinaImpDigitalOpcion5,
    } = getState().cotizacionesReducer.cotizacion;

    console.log({
      requiereBaseBlancaDigital,
      costoTiempoMaquinaImpDigitalOpcion1,
    });
    const costoTiempoMaquinaBaseBlancaDigitalOpcion1 = requiereBaseBlancaDigital
      ? Number(costoTiempoMaquinaImpDigitalOpcion1) * 0.75
      : 0;

    const costoTiempoMaquinaBaseBlancaDigitalOpcion2 = requiereBaseBlancaDigital
      ? Number(costoTiempoMaquinaImpDigitalOpcion2) * 0.75
      : 0;

    const costoTiempoMaquinaBaseBlancaDigitalOpcion3 = requiereBaseBlancaDigital
      ? Number(costoTiempoMaquinaImpDigitalOpcion3) * 0.75
      : 0;

    const costoTiempoMaquinaBaseBlancaDigitalOpcion4 = requiereBaseBlancaDigital
      ? Number(costoTiempoMaquinaImpDigitalOpcion4) * 0.75
      : 0;

    const costoTiempoMaquinaBaseBlancaDigitalOpcion5 = requiereBaseBlancaDigital
      ? Number(costoTiempoMaquinaImpDigitalOpcion5) * 0.75
      : 0;

    dispatch(
      changeCotizacion({
        costoTiempoMaquinaBaseBlancaDigitalOpcion1: parseFloat4(
          costoTiempoMaquinaBaseBlancaDigitalOpcion1
        ),
      })
    );
    dispatch(
      changeCotizacion({
        costoTiempoMaquinaBaseBlancaDigitalOpcion2: parseFloat4(
          costoTiempoMaquinaBaseBlancaDigitalOpcion2
        ),
      })
    );
    dispatch(
      changeCotizacion({
        costoTiempoMaquinaBaseBlancaDigitalOpcion3: parseFloat4(
          costoTiempoMaquinaBaseBlancaDigitalOpcion3
        ),
      })
    );
    dispatch(
      changeCotizacion({
        costoTiempoMaquinaBaseBlancaDigitalOpcion4: parseFloat4(
          costoTiempoMaquinaBaseBlancaDigitalOpcion4
        ),
      })
    );

    dispatch(
      changeCotizacion({
        costoTiempoMaquinaBaseBlancaDigitalOpcion5: parseFloat4(
          costoTiempoMaquinaBaseBlancaDigitalOpcion5
        ),
      })
    );
  };
};

/////////////////////////////////////////////////////////////////////////////////////////
export const setCostoSetupOffset = () => {
  return (dispatch, getState) => {
    const { sistemaCotizado, noTintas } =
      getState().cotizacionesReducer.cotizacion;

    const { costO_SETUP_CONVENCIONAL } = getState().constantesReducer;
    const costoSetupOffset =
      sistemaCotizado === "O" ? noTintas * costO_SETUP_CONVENCIONAL : 0;

    dispatch(
      changeCotizacion({
        costoSetupOffsetOpcion1: parseFloat4(costoSetupOffset),
        costoSetupOffsetOpcion2: parseFloat4(costoSetupOffset),
        costoSetupOffsetOpcion3: parseFloat4(costoSetupOffset),
        costoSetupOffsetOpcion4: parseFloat4(costoSetupOffset),
        costoSetupOffsetOpcion5: parseFloat4(costoSetupOffset),
      })
    );
  };
};

/////////////////////////////////////////////////////////////////////////////////////////
export const setCostoSetupDigital = () => {
  return (dispatch, getState) => {
    const { sistemaCotizado } = getState().cotizacionesReducer.cotizacion;

    const { costO_SETUP_DIGITAL } = getState().constantesReducer;
    const costoSetupDigital = sistemaCotizado === "D" ? costO_SETUP_DIGITAL : 0;

    dispatch(
      changeCotizacion({
        costoSetupDigitalOpcion1: parseFloat4(costoSetupDigital),
        costoSetupDigitalOpcion2: parseFloat4(costoSetupDigital),
        costoSetupDigitalOpcion3: parseFloat4(costoSetupDigital),
        costoSetupDigitalOpcion4: parseFloat4(costoSetupDigital),
        costoSetupDigitalOpcion5: parseFloat4(costoSetupDigital),
      })
    );
  };
};

/////////////////////////////////////////////////////////////////////////////////////////
export const setCostoSetupSerigrafia = () => {
  return (dispatch, getState) => {
    const { sistemaCotizado, noTintas } =
      getState().cotizacionesReducer.cotizacion;

    const { costO_SETUP_DIGITAL } = getState().constantesReducer;
    const costoSetupSerigrafia =
      sistemaCotizado === "S" ? noTintas * costO_SETUP_DIGITAL : 0;

    dispatch(
      changeCotizacion({
        costoSetupSerigrafiaOpcion1: parseFloat4(costoSetupSerigrafia),
        costoSetupSerigrafiaOpcion2: parseFloat4(costoSetupSerigrafia),
        costoSetupSerigrafiaOpcion3: parseFloat4(costoSetupSerigrafia),
        costoSetupSerigrafiaOpcion4: parseFloat4(costoSetupSerigrafia),
        costoSetupSerigrafiaOpcion5: parseFloat4(costoSetupSerigrafia),
      })
    );
  };
};

export const setTotalMaquinaSetup = () => {
  return (dispatch, getState) => {
    const {
      costoTiempoMaquinaImpDigitalOpcion1,
      costoTiempoMaquinaImpDigitalOpcion2,
      costoTiempoMaquinaImpDigitalOpcion3,
      costoTiempoMaquinaImpDigitalOpcion4,
      costoTiempoMaquinaImpDigitalOpcion5,

      costoTiempoMaquinaImpRSSOpcion1,
      costoTiempoMaquinaImpRSSOpcion2,
      costoTiempoMaquinaImpRSSOpcion3,
      costoTiempoMaquinaImpRSSOpcion4,
      costoTiempoMaquinaImpRSSOpcion5,

      costoTiempoMaquinaImpOffsetOpcion1,
      costoTiempoMaquinaImpOffsetOpcion2,
      costoTiempoMaquinaImpOffsetOpcion3,
      costoTiempoMaquinaImpOffsetOpcion4,
      costoTiempoMaquinaImpOffsetOpcion5,

      CostoTiempoMaquinaBaseBlancaDigitalOpcion1,
      CostoTiempoMaquinaBaseBlancaDigitalOpcion2,
      CostoTiempoMaquinaBaseBlancaDigitalOpcion3,
      CostoTiempoMaquinaBaseBlancaDigitalOpcion4,
      CostoTiempoMaquinaBaseBlancaDigitalOpcion5,

      costoSetupOffsetOpcion1,
      costoSetupOffsetOpcion2,
      costoSetupOffsetOpcion3,
      costoSetupOffsetOpcion4,
      costoSetupOffsetOpcion5,

      costoSetupDigitalOpcion1,
      costoSetupDigitalOpcion2,
      costoSetupDigitalOpcion3,
      costoSetupDigitalOpcion4,
      costoSetupDigitalOpcion5,

      costoSetupSerigrafiaOpcion1,
      costoSetupSerigrafiaOpcion2,
      costoSetupSerigrafiaOpcion3,
      costoSetupSerigrafiaOpcion4,
      costoSetupSerigrafiaOpcion5,

      costoSubstratoOpcion1,
      costoSubstratoOpcion2,
      costoSubstratoOpcion3,
      costoSubstratoOpcion4,
      costoSubstratoOpcion5,

      costoPreprensaOffsetOpcion1,
      costoPreprensaOffsetOpcion2,
      costoPreprensaOffsetOpcion3,
      costoPreprensaOffsetOpcion4,
      costoPreprensaOffsetOpcion5,

      cambioPlacasOpcion1,
      cambioPlacasOpcion2,
      cambioPlacasOpcion3,
      cambioPlacasOpcion4,
      cambioPlacasOpcion5,

      cambioColorOpcion1,
      cambioColorOpcion2,
      cambioColorOpcion3,
      cambioColorOpcion4,
      cambioColorOpcion5,

      cargoInfoVariableOpcion1,
      cargoInfoVariableOpcion2,
      cargoInfoVariableOpcion3,
      cargoInfoVariableOpcion4,
      cargoInfoVariableOpcion5,

      tintasSemirotativaOpcion1,
      tintasSemirotativaOpcion2,
      tintasSemirotativaOpcion3,
      tintasSemirotativaOpcion4,
      tintasSemirotativaOpcion5,

      tonerCMYKOpcion1,
      tonerCMYKOpcion2,
      tonerCMYKOpcion3,
      tonerCMYKOpcion4,
      tonerCMYKOpcion5,

      tonerBlancoOpcion1,
      tonerBlancoOpcion2,
      tonerBlancoOpcion3,
      tonerBlancoOpcion4,
      tonerBlancoOpcion5,

      servicioEpsonOpcion1,
      servicioEpsonOpcion2,
      servicioEpsonOpcion3,
      servicioEpsonOpcion4,
      servicioEpsonOpcion5,

      costoTiempoMaquinaBaseBlancaDigitalOpcion1,
      costoTiempoMaquinaBaseBlancaDigitalOpcion2,
      costoTiempoMaquinaBaseBlancaDigitalOpcion3,
      costoTiempoMaquinaBaseBlancaDigitalOpcion4,
      costoTiempoMaquinaBaseBlancaDigitalOpcion5,
    } = getState().cotizacionesReducer.cotizacion;

    const { total1 } = getState().cotizacionesReducer.cotizacionExterna;

    const totalMaquinaSetup1 =
      Number(costoTiempoMaquinaImpDigitalOpcion1) +
      Number(costoTiempoMaquinaImpRSSOpcion1) +
      Number(costoTiempoMaquinaImpOffsetOpcion1) +
      Number(CostoTiempoMaquinaBaseBlancaDigitalOpcion1) +
      Number(costoSetupOffsetOpcion1) +
      Number(costoSetupDigitalOpcion1) +
      Number(costoSetupSerigrafiaOpcion1) +
      Number(costoSubstratoOpcion1) +
      Number(costoPreprensaOffsetOpcion1) +
      Number(cambioPlacasOpcion1) +
      Number(cambioColorOpcion1) +
      Number(cargoInfoVariableOpcion1) +
      Number(tintasSemirotativaOpcion1) +
      Number(tonerCMYKOpcion1) +
      Number(tonerBlancoOpcion1) +
      Number(costoTiempoMaquinaBaseBlancaDigitalOpcion1) +
      Number(servicioEpsonOpcion1);

    const totalMaquinaSetup2 =
      Number(costoTiempoMaquinaImpDigitalOpcion2) +
      Number(costoTiempoMaquinaImpRSSOpcion2) +
      Number(costoTiempoMaquinaImpOffsetOpcion2) +
      Number(CostoTiempoMaquinaBaseBlancaDigitalOpcion2) +
      Number(costoSetupOffsetOpcion2) +
      Number(costoSetupDigitalOpcion2) +
      Number(costoSetupSerigrafiaOpcion2) +
      Number(costoSubstratoOpcion2) +
      Number(costoPreprensaOffsetOpcion2) +
      Number(cambioPlacasOpcion2) +
      Number(cambioColorOpcion2) +
      Number(cargoInfoVariableOpcion2) +
      Number(tintasSemirotativaOpcion2) +
      Number(tonerCMYKOpcion2) +
      Number(tonerBlancoOpcion2) +
      Number(costoTiempoMaquinaBaseBlancaDigitalOpcion2) +
      Number(servicioEpsonOpcion2);

    const totalMaquinaSetup3 =
      Number(costoTiempoMaquinaImpDigitalOpcion3) +
      Number(costoTiempoMaquinaImpRSSOpcion3) +
      Number(costoTiempoMaquinaImpOffsetOpcion3) +
      Number(CostoTiempoMaquinaBaseBlancaDigitalOpcion3) +
      Number(costoSetupOffsetOpcion3) +
      Number(costoSetupDigitalOpcion3) +
      Number(costoSetupSerigrafiaOpcion3) +
      Number(costoSubstratoOpcion3) +
      Number(costoPreprensaOffsetOpcion3) +
      Number(cambioPlacasOpcion3) +
      Number(cambioColorOpcion3) +
      Number(cargoInfoVariableOpcion3) +
      Number(tintasSemirotativaOpcion3) +
      Number(tonerCMYKOpcion3) +
      Number(tonerBlancoOpcion3) +
      Number(costoTiempoMaquinaBaseBlancaDigitalOpcion3) +
      Number(servicioEpsonOpcion3);

    const totalMaquinaSetup4 =
      Number(costoTiempoMaquinaImpDigitalOpcion4) +
      Number(costoTiempoMaquinaImpRSSOpcion4) +
      Number(costoTiempoMaquinaImpOffsetOpcion4) +
      Number(CostoTiempoMaquinaBaseBlancaDigitalOpcion4) +
      Number(costoSetupOffsetOpcion4) +
      Number(costoSetupDigitalOpcion4) +
      Number(costoSetupSerigrafiaOpcion4) +
      Number(costoSubstratoOpcion4) +
      Number(costoPreprensaOffsetOpcion4) +
      Number(cambioPlacasOpcion4) +
      Number(cambioColorOpcion4) +
      Number(cargoInfoVariableOpcion4) +
      Number(tintasSemirotativaOpcion4) +
      Number(tonerCMYKOpcion4) +
      Number(tonerBlancoOpcion4) +
      Number(costoTiempoMaquinaBaseBlancaDigitalOpcion4) +
      Number(servicioEpsonOpcion4);

    const totalMaquinaSetup5 =
      Number(costoTiempoMaquinaImpDigitalOpcion5) +
      Number(costoTiempoMaquinaImpRSSOpcion5) +
      Number(costoTiempoMaquinaImpOffsetOpcion5) +
      Number(CostoTiempoMaquinaBaseBlancaDigitalOpcion5) +
      Number(costoSetupOffsetOpcion5) +
      Number(costoSetupDigitalOpcion5) +
      Number(costoSetupSerigrafiaOpcion5) +
      Number(costoSubstratoOpcion5) +
      Number(costoPreprensaOffsetOpcion5) +
      Number(cambioPlacasOpcion5) +
      Number(cambioColorOpcion5) +
      Number(cargoInfoVariableOpcion5) +
      Number(tintasSemirotativaOpcion5) +
      Number(tonerCMYKOpcion5) +
      Number(tonerBlancoOpcion5) +
      Number(costoTiempoMaquinaBaseBlancaDigitalOpcion5) +
      Number(servicioEpsonOpcion5);

    if (total1 > 0) {
      dispatch(
        changeCotizacion({
          totalMaquinaSetup1,
          totalMaquinaSetup2,
          totalMaquinaSetup3,
          totalMaquinaSetup4,
          totalMaquinaSetup5,
        })
      );
    } else {
      dispatch(
        changeCotizacion({
          totalMaquinaSetup1: 0,
          totalMaquinaSetup2: 0,
          totalMaquinaSetup3: 0,
          totalMaquinaSetup4: 0,
          totalMaquinaSetup5: 0,
        })
      );
    }
  };
};

/////////////////////////////////////////////////////////////////////////////////////////
export const setCostoSetupMaquinasAdicionales = () => {
  return (dispatch, getState) => {
    const { noMaquinasAdicionales } = getState().cotizacionesReducer.cotizacion;

    const { costO_SETUP_ACABADOS } = getState().constantesReducer;
    const costoSetupMaquinasAdicionales =
      noMaquinasAdicionales * costO_SETUP_ACABADOS;

    dispatch(
      changeCotizacion({
        costoSetupMaquinasAdicionalesOpcion1: parseFloat2(
          costoSetupMaquinasAdicionales
        ),
        costoSetupMaquinasAdicionalesOpcion2: parseFloat2(
          costoSetupMaquinasAdicionales
        ),
        costoSetupMaquinasAdicionalesOpcion3: parseFloat2(
          costoSetupMaquinasAdicionales
        ),
        costoSetupMaquinasAdicionalesOpcion4: parseFloat2(
          costoSetupMaquinasAdicionales
        ),
        costoSetupMaquinasAdicionalesOpcion5: parseFloat2(
          costoSetupMaquinasAdicionales
        ),
      })
    );
  };
};

/////////////////////////////////////////////////////////////////////////////////////////
export const setCostoSubstrato = () => {
  return (dispatch, getState) => {
    const {
      costoMSISustrato,
      MSITotalOpcion1,
      MSITotalOpcion2,
      MSITotalOpcion3,
      MSITotalOpcion4,
      MSITotalOpcion5,
    } = getState().cotizacionesReducer.cotizacion;

    const salida = {
      costoSubstratoOpcion1: parseFloat2(MSITotalOpcion1 * costoMSISustrato),
      costoSubstratoOpcion2: parseFloat2(MSITotalOpcion2 * costoMSISustrato),
      costoSubstratoOpcion3: parseFloat2(MSITotalOpcion3 * costoMSISustrato),
      costoSubstratoOpcion4: parseFloat2(MSITotalOpcion4 * costoMSISustrato),
      costoSubstratoOpcion5: parseFloat2(MSITotalOpcion5 * costoMSISustrato),
    };

    dispatch(changeCotizacion(salida));
  };
};

/////////////////////////////////////////////////////////////////////////////////////////
export const setCostoLaminado = () => {
  return (dispatch, getState) => {
    const {
      costoMSILaminado,
      MSITotalOpcion1,
      MSITotalOpcion2,
      MSITotalOpcion3,
      MSITotalOpcion4,
      MSITotalOpcion5,
    } = getState().cotizacionesReducer.cotizacion;

    const salida = {
      costoLaminadoOpcion1: parseFloat2(MSITotalOpcion1 * costoMSILaminado),
      costoLaminadoOpcion2: parseFloat2(MSITotalOpcion2 * costoMSILaminado),
      costoLaminadoOpcion3: parseFloat2(MSITotalOpcion3 * costoMSILaminado),
      costoLaminadoOpcion4: parseFloat2(MSITotalOpcion4 * costoMSILaminado),
      costoLaminadoOpcion5: parseFloat2(MSITotalOpcion5 * costoMSILaminado),
    };
    dispatch(changeCotizacion(salida));
  };
};

/////////////////////////////////////////////////////////////////////////////////////////
export const setCostoCorteIndividual = () => {
  return (dispatch, getState) => {
    const { velocidaD_CORTE, costO_MAQUINA_HORA_CORTE } =
      getState().constantesReducer;

    const {
      requiereCorteIndividual,
      totalPiesLinealesOpcion1,
      totalPiesLinealesOpcion2,
      totalPiesLinealesOpcion3,
      totalPiesLinealesOpcion4,
      totalPiesLinealesOpcion5,
    } = getState().cotizacionesReducer.cotizacion;

    const costoCorteIndividualOpcion1 = requiereCorteIndividual
      ? (totalPiesLinealesOpcion1 / velocidaD_CORTE / 60) *
        costO_MAQUINA_HORA_CORTE
      : 0;

    const costoCorteIndividualOpcion2 = requiereCorteIndividual
      ? (totalPiesLinealesOpcion2 / velocidaD_CORTE / 60) *
        costO_MAQUINA_HORA_CORTE
      : 0;

    const costoCorteIndividualOpcion3 = requiereCorteIndividual
      ? (totalPiesLinealesOpcion3 / velocidaD_CORTE / 60) *
        costO_MAQUINA_HORA_CORTE
      : 0;

    const costoCorteIndividualOpcion4 = requiereCorteIndividual
      ? (totalPiesLinealesOpcion4 / velocidaD_CORTE / 60) *
        costO_MAQUINA_HORA_CORTE
      : 0;

    const costoCorteIndividualOpcion5 = requiereCorteIndividual
      ? (totalPiesLinealesOpcion5 / velocidaD_CORTE / 60) *
        costO_MAQUINA_HORA_CORTE
      : 0;

    dispatch(
      changeCotizacion({
        costoCorteIndividualOpcion1: parseFloat2(costoCorteIndividualOpcion1),
        costoCorteIndividualOpcion2: parseFloat2(costoCorteIndividualOpcion2),
        costoCorteIndividualOpcion3: parseFloat2(costoCorteIndividualOpcion3),
        costoCorteIndividualOpcion4: parseFloat2(costoCorteIndividualOpcion4),
        costoCorteIndividualOpcion5: parseFloat2(costoCorteIndividualOpcion5),
      })
    );
  };
};

/////////////////////////////////////////////////////////////////////////////////////////
export const setCostoPreprensaOffset = () => {
  return (dispatch, getState) => {
    const { sistemaCotizado, noTintas, noCambioPlacas } =
      getState().cotizacionesReducer.cotizacion;

    const { costO_PLACA_OFFSET } = getState().constantesReducer;

    const costoPreprensaOffset =
      sistemaCotizado === "O"
        ? parseFloat2(
            costO_PLACA_OFFSET * noTintas + costO_PLACA_OFFSET * noCambioPlacas
          )
        : 0;

    const salida = {
      costoPreprensaOffsetOpcion1: costoPreprensaOffset,
      costoPreprensaOffsetOpcion2: costoPreprensaOffset,
      costoPreprensaOffsetOpcion3: costoPreprensaOffset,
      costoPreprensaOffsetOpcion4: costoPreprensaOffset,
      costoPreprensaOffsetOpcion5: costoPreprensaOffset,
    };
    dispatch(changeCotizacion(salida));
  };
};

/////////////////////////////////////////////////////////////////////////////////////////
export const setDepreciacionDadoSuaje = () => {
  return (dispatch, getState) => {
    const {
      noGolpesCalculoOpcion1,
      noGolpesCalculoOpcion2,
      noGolpesCalculoOpcion3,
      noGolpesCalculoOpcion4,
      noGolpesCalculoOpcion5,

      costoDadoSuajeDepreciacion,
    } = getState().cotizacionesReducer.cotizacion;

    const { duracioN_DADO_SUAJE } = getState().constantesReducer;

    const depreciacionDadoSuajeOpcion1 = parseFloat2(
      (costoDadoSuajeDepreciacion / duracioN_DADO_SUAJE) *
        noGolpesCalculoOpcion1
    );
    const depreciacionDadoSuajeOpcion2 = parseFloat2(
      (costoDadoSuajeDepreciacion / duracioN_DADO_SUAJE) *
        noGolpesCalculoOpcion2
    );
    const depreciacionDadoSuajeOpcion3 = parseFloat2(
      (costoDadoSuajeDepreciacion / duracioN_DADO_SUAJE) *
        noGolpesCalculoOpcion3
    );
    const depreciacionDadoSuajeOpcion4 = parseFloat2(
      (costoDadoSuajeDepreciacion / duracioN_DADO_SUAJE) *
        noGolpesCalculoOpcion4
    );
    const depreciacionDadoSuajeOpcion5 = parseFloat2(
      (costoDadoSuajeDepreciacion / duracioN_DADO_SUAJE) *
        noGolpesCalculoOpcion5
    );

    dispatch(
      changeCotizacion({
        depreciacionDadoSuajeOpcion1,
        depreciacionDadoSuajeOpcion2,
        depreciacionDadoSuajeOpcion3,
        depreciacionDadoSuajeOpcion4,
        depreciacionDadoSuajeOpcion5,
      })
    );
  };
};

/////////////////////////////////////////////////////////////////////////////////////////
export const setCambioPlacas = () => {
  return (dispatch, getState) => {
    const { noCambioPlacas } = getState().cotizacionesReducer.cotizacion;

    const {
      tiempoMaquinaCambioPlacas,
      costO_MAQUINA_HORA_IMPRESION,
      costO_TIMPO_MAQUINA_CAMBIO_PLACAS,
    } = getState().constantesReducer;

    console.log({
      noCambioPlacas,
      costO_TIMPO_MAQUINA_CAMBIO_PLACAS,
      costO_MAQUINA_HORA_IMPRESION,
    });
    const cambioPlacas = parseFloat4(
      ((Number(noCambioPlacas) * Number(tiempoMaquinaCambioPlacas)) / 60) *
        costO_TIMPO_MAQUINA_CAMBIO_PLACAS
    );

    const salida = {
      cambioPlacasOpcion1: cambioPlacas,
      cambioPlacasOpcion2: cambioPlacas,
      cambioPlacasOpcion3: cambioPlacas,
      cambioPlacasOpcion4: cambioPlacas,
      cambioPlacasOpcion5: cambioPlacas,
    };
    dispatch(changeCotizacion(salida));
  };
};

/////////////////////////////////////////////////////////////////////////////////////////
export const setCambioColor = () => {
  return (dispatch, getState) => {
    const { noCambiosColor } = getState().cotizacionesReducer.cotizacion;

    const { tiempoMaquinaCambioColor, costO_TIMPO_MAQUINA_PARO_CAMBIO_COLOR } =
      getState().constantesReducer;

    const cambioColor = parseFloat4(
      ((noCambiosColor * tiempoMaquinaCambioColor) / 60) *
        costO_TIMPO_MAQUINA_PARO_CAMBIO_COLOR
    );

    const salida = {
      cambioColorOpcion1: cambioColor,
      cambioColorOpcion2: cambioColor,
      cambioColorOpcion3: cambioColor,
      cambioColorOpcion4: cambioColor,
      cambioColorOpcion5: cambioColor,
    };
    dispatch(changeCotizacion(salida));
  };
};

/////////////////////////////////////////////////////////////////////////////////////////
export const setCargoPreparacionArte = () => {
  return (dispatch, getState) => {
    const { items } = getState().cotizacionesReducer.cotizacion;
    const { costO_UNITARIO_PREPARACION_ARCHIVOS } =
      getState().constantesReducer;

    const newItems = items.filter((a) => a.nuevoReimpresion === "Nuevo");
    const cargoPreparacionArte =
      newItems.length > 1
        ? parseFloat2(
            costO_UNITARIO_PREPARACION_ARCHIVOS * (newItems.length - 1)
          )
        : 0;

    const salida = {
      cargoPreparacionArteOpcion1: cargoPreparacionArte,
      cargoPreparacionArteOpcion2: cargoPreparacionArte,
      cargoPreparacionArteOpcion3: cargoPreparacionArte,
      cargoPreparacionArteOpcion4: cargoPreparacionArte,
      cargoPreparacionArteOpcion5: cargoPreparacionArte,
    };
    dispatch(changeCotizacion(salida));
  };
};

/////////////////////////////////////////////////////////////////////////////////////////
export const setTiempoMaquinaImpDigital = () => {
  return (dispatch, getState) => {
    const {
      sistemaCotizado,
      totalPiesLinealesOpcion1,
      totalPiesLinealesOpcion2,
      totalPiesLinealesOpcion3,
      totalPiesLinealesOpcion4,
      totalPiesLinealesOpcion5,
      velocidadOpcion1,
      velocidadOpcion2,
      velocidadOpcion3,
      velocidadOpcion4,
      velocidadOpcion5,
    } = getState().cotizacionesReducer.cotizacion;

    const tiempoMaquinaImpDigitalOpcion1 =
      sistemaCotizado === "D"
        ? parseFloat2((totalPiesLinealesOpcion1 / velocidadOpcion1 + 10) / 60)
        : 0;

    const tiempoMaquinaImpDigitalOpcion2 =
      sistemaCotizado === "D"
        ? parseFloat2((totalPiesLinealesOpcion2 / velocidadOpcion2 + 10) / 60)
        : 0;

    const tiempoMaquinaImpDigitalOpcion3 =
      sistemaCotizado === "D"
        ? parseFloat2((totalPiesLinealesOpcion3 / velocidadOpcion3 + 10) / 60)
        : 0;

    const tiempoMaquinaImpDigitalOpcion4 =
      sistemaCotizado === "D"
        ? parseFloat2((totalPiesLinealesOpcion4 / velocidadOpcion4 + 10) / 60)
        : 0;

    const tiempoMaquinaImpDigitalOpcion5 =
      sistemaCotizado === "D"
        ? parseFloat2((totalPiesLinealesOpcion5 / velocidadOpcion5 + 10) / 60)
        : 0;

    const salida = {
      tiempoMaquinaImpDigitalOpcion1,
      tiempoMaquinaImpDigitalOpcion2,
      tiempoMaquinaImpDigitalOpcion3,
      tiempoMaquinaImpDigitalOpcion4,
      tiempoMaquinaImpDigitalOpcion5,
    };
    dispatch(changeCotizacion(salida));
  };
};

/////////////////////////////////////////////////////////////////////////////////////////
export const setCostoUsoPrimera = () => {
  return (dispatch, getState) => {
    const {
      cortePRIMERA,
      tiempoMaquinaImpDigitalOpcion1,
      tiempoMaquinaImpDigitalOpcion2,
      tiempoMaquinaImpDigitalOpcion3,
      tiempoMaquinaImpDigitalOpcion4,
      tiempoMaquinaImpDigitalOpcion5,
    } = getState().cotizacionesReducer.cotizacion;

    const { cargO_HORA_USO_PRIMERA } = getState().constantesReducer;

    const costoUsoPrimeraOpcion1 = cortePRIMERA
      ? parseFloat2(tiempoMaquinaImpDigitalOpcion1 * cargO_HORA_USO_PRIMERA)
      : 0;

    const costoUsoPrimeraOpcion2 = cortePRIMERA
      ? parseFloat2(tiempoMaquinaImpDigitalOpcion2 * cargO_HORA_USO_PRIMERA)
      : 0;

    const costoUsoPrimeraOpcion3 = cortePRIMERA
      ? parseFloat2(tiempoMaquinaImpDigitalOpcion3 * cargO_HORA_USO_PRIMERA)
      : 0;

    const costoUsoPrimeraOpcion4 = cortePRIMERA
      ? parseFloat2(tiempoMaquinaImpDigitalOpcion4 * cargO_HORA_USO_PRIMERA)
      : 0;

    const costoUsoPrimeraOpcion5 = cortePRIMERA
      ? parseFloat2(tiempoMaquinaImpDigitalOpcion5 * cargO_HORA_USO_PRIMERA)
      : 0;

    const salida = {
      costoUsoPrimeraOpcion1,
      costoUsoPrimeraOpcion2,
      costoUsoPrimeraOpcion3,
      costoUsoPrimeraOpcion4,
      costoUsoPrimeraOpcion5,
    };
    dispatch(changeCotizacion(salida));
  };
};

/////////////////////////////////////////////////////////////////////////////////////////
export const setCargoInfoVariable = () => {
  return (dispatch, getState) => {
    const {
      requiereInformacionVariable,
      piezasImpresion,
      opcion1,
      opcion2,
      opcion3,
      opcion4,
      opcion5,
    } = getState().cotizacionesReducer.cotizacion;

    const { cargO_INFORMACION_VARIABLE } = getState().constantesReducer;

    const cargoInfoVariableOpcion1 =
      requiereInformacionVariable && piezasImpresion > 0
        ? parseFloat2(
            (opcion1 / piezasImpresion / 1000) * cargO_INFORMACION_VARIABLE
          )
        : 0;

    const cargoInfoVariableOpcion2 =
      requiereInformacionVariable && piezasImpresion > 0
        ? parseFloat2(
            (opcion2 / piezasImpresion / 1000) * cargO_INFORMACION_VARIABLE
          )
        : 0;

    const cargoInfoVariableOpcion3 =
      requiereInformacionVariable && piezasImpresion > 0
        ? parseFloat2(
            (opcion3 / piezasImpresion / 1000) * cargO_INFORMACION_VARIABLE
          )
        : 0;

    const cargoInfoVariableOpcion4 =
      requiereInformacionVariable && piezasImpresion > 0
        ? parseFloat2(
            (opcion4 / piezasImpresion / 1000) * cargO_INFORMACION_VARIABLE
          )
        : 0;

    const cargoInfoVariableOpcion5 =
      requiereInformacionVariable && piezasImpresion > 0
        ? parseFloat2(
            (opcion5 / piezasImpresion / 1000) * cargO_INFORMACION_VARIABLE
          )
        : 0;

    const salida = {
      cargoInfoVariableOpcion1,
      cargoInfoVariableOpcion2,
      cargoInfoVariableOpcion3,
      cargoInfoVariableOpcion4,
      cargoInfoVariableOpcion5,
    };
    dispatch(changeCotizacion(salida));
  };
};

/////////////////////////////////////////////////////////////////////////////////////////
export const setPantallaRSS = () => {
  return (dispatch, getState) => {
    const { noTintas, noCambiosColor, sistemaCotizado } =
      getState().cotizacionesReducer.cotizacion;

    const { costO_NEGATIVO } = getState().constantesReducer;

    const pantallaRSS =
      sistemaCotizado === "S"
        ? parseFloat2(
            Number(costO_NEGATIVO) * Number(noTintas) +
              Number(costO_NEGATIVO) * Number(noCambiosColor)
          )
        : 0;

    const salida = {
      pantallaRSSOpcion1: pantallaRSS,
      pantallaRSSOpcion2: pantallaRSS,
      pantallaRSSOpcion3: pantallaRSS,
      pantallaRSSOpcion4: pantallaRSS,
      pantallaRSSOpcion5: pantallaRSS,
    };
    dispatch(changeCotizacion(salida));
  };
};

/////////////////////////////////////////////////////////////////////////////////////////
export const setCostoPantallaBarnizEspecial = () => {
  return (dispatch, getState) => {
    const { requiereBarnizEspecial } =
      getState().cotizacionesReducer.cotizacion;

    const { costO_NEGATIVO } = getState().constantesReducer;

    const costoPantallaBarnizEspecial = requiereBarnizEspecial
      ? parseFloat2(costO_NEGATIVO)
      : 0;

    const salida = {
      costoPantallaBarnizEspecialOpcion1: costoPantallaBarnizEspecial,
      costoPantallaBarnizEspecialOpcion2: costoPantallaBarnizEspecial,
      costoPantallaBarnizEspecialOpcion3: costoPantallaBarnizEspecial,
      costoPantallaBarnizEspecialOpcion4: costoPantallaBarnizEspecial,
      costoPantallaBarnizEspecialOpcion5: costoPantallaBarnizEspecial,
    };
    dispatch(changeCotizacion(salida));
  };
};

/////////////////////////////////////////////////////////////////////////////////////////
export const setCostoDados = () => {
  return (dispatch, getState) => {
    const {
      desglozarCostoDados,
      costoDadoSuaje,
      costoDadoGrabado,
      costoDadoFoil,
    } = getState().cotizacionesReducer.cotizacion;

    const costoDados = !desglozarCostoDados
      ? parseFloat2(
          Number(costoDadoSuaje) +
            Number(costoDadoGrabado) +
            Number(costoDadoFoil)
        )
      : 0;

    const salida = {
      costoDadosOpcion1: costoDados,
      costoDadosOpcion2: costoDados,
      costoDadosOpcion3: costoDados,
      costoDadosOpcion4: costoDados,
      costoDadosOpcion5: costoDados,
    };

    dispatch(changeCotizacion(salida));
  };
};

/////////////////////////////////////////////////////////////////////////////////////////
export const setTintasSerigrafiaRSS = () => {
  return (dispatch, getState) => {
    const {
      porcentajeImpresion,
      sistemaCotizado,
      MSITotalOpcion1,
      MSITotalOpcion2,
      MSITotalOpcion3,
      MSITotalOpcion4,
      MSITotalOpcion5,
    } = getState().cotizacionesReducer.cotizacion;

    const { costO_MSI_TINTA_SERIGRAFIA } = getState().constantesReducer;

    const tintasSerigrafiaRSSOpcion1 =
      sistemaCotizado === "S"
        ? parseFloat2(
            Number(MSITotalOpcion1) *
              Number(costO_MSI_TINTA_SERIGRAFIA) *
              (Number(porcentajeImpresion) / 100)
          )
        : 0;

    const tintasSerigrafiaRSSOpcion2 =
      sistemaCotizado === "S"
        ? parseFloat2(
            Number(MSITotalOpcion2) *
              Number(costO_MSI_TINTA_SERIGRAFIA) *
              (Number(porcentajeImpresion) / 100)
          )
        : 0;

    const tintasSerigrafiaRSSOpcion3 =
      sistemaCotizado === "S"
        ? parseFloat2(
            Number(MSITotalOpcion3) *
              Number(costO_MSI_TINTA_SERIGRAFIA) *
              (Number(porcentajeImpresion) / 100)
          )
        : 0;

    const tintasSerigrafiaRSSOpcion4 =
      sistemaCotizado === "S"
        ? parseFloat2(
            Number(MSITotalOpcion4) *
              Number(costO_MSI_TINTA_SERIGRAFIA) *
              (Number(porcentajeImpresion) / 100)
          )
        : 0;

    const tintasSerigrafiaRSSOpcion5 =
      sistemaCotizado === "S"
        ? parseFloat2(
            Number(MSITotalOpcion5) *
              Number(costO_MSI_TINTA_SERIGRAFIA) *
              (Number(porcentajeImpresion) / 100)
          )
        : 0;

    const salida = {
      tintasSerigrafiaRSSOpcion1,
      tintasSerigrafiaRSSOpcion2,
      tintasSerigrafiaRSSOpcion3,
      tintasSerigrafiaRSSOpcion4,
      tintasSerigrafiaRSSOpcion5,
    };
    dispatch(changeCotizacion(salida));
  };
};

/////////////////////////////////////////////////////////////////////////////////////////
export const setTonerCMYK = () => {
  return (dispatch, getState) => {
    const {
      porcentajeImpresion,
      sistemaCotizado,
      MSITotalOpcion1,
      MSITotalOpcion2,
      MSITotalOpcion3,
      MSITotalOpcion4,
      MSITotalOpcion5,
    } = getState().cotizacionesReducer.cotizacion;

    const { costO_TONER_CMYK_MSI } = getState().constantesReducer;

    const tonerCMYKOpcion1 =
      sistemaCotizado === "D"
        ? Number(MSITotalOpcion1) *
          Number(costO_TONER_CMYK_MSI) *
          (Number(porcentajeImpresion) / 100)
        : 0;

    const tonerCMYKOpcion2 =
      sistemaCotizado === "D"
        ? Number(MSITotalOpcion2) *
          Number(costO_TONER_CMYK_MSI) *
          (Number(porcentajeImpresion) / 100)
        : 0;

    const tonerCMYKOpcion3 =
      sistemaCotizado === "D"
        ? Number(MSITotalOpcion3) *
          Number(costO_TONER_CMYK_MSI) *
          (Number(porcentajeImpresion) / 100)
        : 0;

    const tonerCMYKOpcion4 =
      sistemaCotizado === "D"
        ? Number(MSITotalOpcion4) *
          Number(costO_TONER_CMYK_MSI) *
          (Number(porcentajeImpresion) / 100)
        : 0;

    const tonerCMYKOpcion5 =
      sistemaCotizado === "D"
        ? Number(MSITotalOpcion5) *
          Number(costO_TONER_CMYK_MSI) *
          (Number(porcentajeImpresion) / 100)
        : 0;

    const salida = {
      tonerCMYKOpcion1: parseFloat2(tonerCMYKOpcion1),
      tonerCMYKOpcion2: parseFloat2(tonerCMYKOpcion2),
      tonerCMYKOpcion3: parseFloat2(tonerCMYKOpcion3),
      tonerCMYKOpcion4: parseFloat2(tonerCMYKOpcion4),
      tonerCMYKOpcion5: parseFloat2(tonerCMYKOpcion5),
    };
    dispatch(changeCotizacion(salida));
  };
};

/////////////////////////////////////////////////////////////////////////////////////////
export const setTonerBlanco = () => {
  return (dispatch, getState) => {
    const {
      requiereBaseBlancaDigital,
      porcentajeBaseBlancaDigital,
      MSITotalOpcion1,
      MSITotalOpcion2,
      MSITotalOpcion3,
      MSITotalOpcion4,
      MSITotalOpcion5,
    } = getState().cotizacionesReducer.cotizacion;

    const { costO_TONER_BLANCO_MSI } = getState().constantesReducer;

    const tonerBlancoOpcion1 = requiereBaseBlancaDigital
      ? Number(MSITotalOpcion1) *
        Number(costO_TONER_BLANCO_MSI) *
        (Number(porcentajeBaseBlancaDigital) / 100)
      : 0;

    const tonerBlancoOpcion2 = requiereBaseBlancaDigital
      ? Number(MSITotalOpcion2) *
        Number(costO_TONER_BLANCO_MSI) *
        (Number(porcentajeBaseBlancaDigital) / 100)
      : 0;

    const tonerBlancoOpcion3 = requiereBaseBlancaDigital
      ? Number(MSITotalOpcion3) *
        Number(costO_TONER_BLANCO_MSI) *
        (Number(porcentajeBaseBlancaDigital) / 100)
      : 0;

    const tonerBlancoOpcion4 = requiereBaseBlancaDigital
      ? Number(MSITotalOpcion4) *
        Number(costO_TONER_BLANCO_MSI) *
        (Number(porcentajeBaseBlancaDigital) / 100)
      : 0;

    const tonerBlancoOpcion5 = requiereBaseBlancaDigital
      ? Number(MSITotalOpcion5) *
        Number(costO_TONER_BLANCO_MSI) *
        (Number(porcentajeBaseBlancaDigital) / 100)
      : 0;

    const salida = {
      tonerBlancoOpcion1: parseFloat2(tonerBlancoOpcion1),
      tonerBlancoOpcion2: parseFloat2(tonerBlancoOpcion2),
      tonerBlancoOpcion3: parseFloat2(tonerBlancoOpcion3),
      tonerBlancoOpcion4: parseFloat2(tonerBlancoOpcion4),
      tonerBlancoOpcion5: parseFloat2(tonerBlancoOpcion5),
    };
    dispatch(changeCotizacion(salida));
  };
};

/////////////////////////////////////////////////////////////////////////////////////////
export const setBarnizEspecialRSS = () => {
  return (dispatch, getState) => {
    const {
      costoMSIBarnizEspecial,
      requiereBarnizEspecialCobertura,
      requiereBarnizEspecial,
      MSITotalOpcion1,
      MSITotalOpcion2,
      MSITotalOpcion3,
      MSITotalOpcion4,
      MSITotalOpcion5,
    } = getState().cotizacionesReducer.cotizacion;

    const barnizEspecialRSSOpcion1 = requiereBarnizEspecial
      ? Number(MSITotalOpcion1) *
        Number(costoMSIBarnizEspecial) *
        (Number(requiereBarnizEspecialCobertura) / 100)
      : 0;

    const barnizEspecialRSSOpcion2 = requiereBarnizEspecial
      ? Number(MSITotalOpcion2) *
        Number(costoMSIBarnizEspecial) *
        (Number(requiereBarnizEspecialCobertura) / 100)
      : 0;

    const barnizEspecialRSSOpcion3 = requiereBarnizEspecial
      ? Number(MSITotalOpcion3) *
        Number(costoMSIBarnizEspecial) *
        (Number(requiereBarnizEspecialCobertura) / 100)
      : 0;

    const barnizEspecialRSSOpcion4 = requiereBarnizEspecial
      ? Number(MSITotalOpcion4) *
        Number(costoMSIBarnizEspecial) *
        (Number(requiereBarnizEspecialCobertura) / 100)
      : 0;

    const barnizEspecialRSSOpcion5 = requiereBarnizEspecial
      ? Number(MSITotalOpcion5) *
        Number(costoMSIBarnizEspecial) *
        (Number(requiereBarnizEspecialCobertura) / 100)
      : 0;

    const salida = {
      barnizEspecialRSSOpcion1: parseFloat2(barnizEspecialRSSOpcion1),
      barnizEspecialRSSOpcion2: parseFloat2(barnizEspecialRSSOpcion2),
      barnizEspecialRSSOpcion3: parseFloat2(barnizEspecialRSSOpcion3),
      barnizEspecialRSSOpcion4: parseFloat2(barnizEspecialRSSOpcion4),
      barnizEspecialRSSOpcion5: parseFloat2(barnizEspecialRSSOpcion5),
    };
    dispatch(changeCotizacion(salida));
  };
};

/////////////////////////////////////////////////////////////////////////////////////////
export const setTintasSemirotativa = () => {
  return (dispatch, getState) => {
    const {
      sistemaCotizado,
      porcentajeImpresion,
      MSITotalOpcion1,
      MSITotalOpcion2,
      MSITotalOpcion3,
      MSITotalOpcion4,
      MSITotalOpcion5,
    } = getState().cotizacionesReducer.cotizacion;

    const { costO_MSI_TINTA_SEMIROTATIVA } = getState().constantesReducer;

    const tintasSemirotativaOpcion1 =
      sistemaCotizado === "O"
        ? parseFloat2(
            Number(MSITotalOpcion1) *
              (Number(porcentajeImpresion) / 100) *
              Number(costO_MSI_TINTA_SEMIROTATIVA)
          )
        : 0;

    const tintasSemirotativaOpcion2 =
      sistemaCotizado === "O"
        ? parseFloat2(
            Number(MSITotalOpcion2) *
              (Number(porcentajeImpresion) / 100) *
              Number(costO_MSI_TINTA_SEMIROTATIVA)
          )
        : 0;

    const tintasSemirotativaOpcion3 =
      sistemaCotizado === "O"
        ? parseFloat2(
            Number(MSITotalOpcion3) *
              (Number(porcentajeImpresion) / 100) *
              Number(costO_MSI_TINTA_SEMIROTATIVA)
          )
        : 0;

    const tintasSemirotativaOpcion4 =
      sistemaCotizado === "O"
        ? parseFloat2(
            Number(MSITotalOpcion4) *
              (Number(porcentajeImpresion) / 100) *
              Number(costO_MSI_TINTA_SEMIROTATIVA)
          )
        : 0;

    const tintasSemirotativaOpcion5 =
      sistemaCotizado === "O"
        ? parseFloat2(
            Number(MSITotalOpcion5) *
              (Number(porcentajeImpresion) / 100) *
              Number(costO_MSI_TINTA_SEMIROTATIVA)
          )
        : 0;

    const salida = {
      tintasSemirotativaOpcion1,
      tintasSemirotativaOpcion2,
      tintasSemirotativaOpcion3,
      tintasSemirotativaOpcion4,
      tintasSemirotativaOpcion5,
    };
    dispatch(changeCotizacion(salida));
  };
};

/////////////////////////////////////////////////////////////////////////////////////////
export const setBarnizMaquina = () => {
  return (dispatch, getState) => {
    const {
      requiereBarnizMini,
      costoMSIBarniz,
      MSITotalOpcion1,
      MSITotalOpcion2,
      MSITotalOpcion3,
      MSITotalOpcion4,
      MSITotalOpcion5,
    } = getState().cotizacionesReducer.cotizacion;

    const barnizMaquinaOpcion1 = requiereBarnizMini
      ? parseFloat2(Number(costoMSIBarniz) * Number(MSITotalOpcion1))
      : 0;

    const barnizMaquinaOpcion2 = requiereBarnizMini
      ? parseFloat2(Number(costoMSIBarniz) * Number(MSITotalOpcion2))
      : 0;

    const barnizMaquinaOpcion3 = requiereBarnizMini
      ? parseFloat2(Number(costoMSIBarniz) * Number(MSITotalOpcion3))
      : 0;

    const barnizMaquinaOpcion4 = requiereBarnizMini
      ? parseFloat2(Number(costoMSIBarniz) * Number(MSITotalOpcion4))
      : 0;

    const barnizMaquinaOpcion5 = requiereBarnizMini
      ? parseFloat2(Number(costoMSIBarniz) * Number(MSITotalOpcion5))
      : 0;

    const salida = {
      barnizMaquinaOpcion1,
      barnizMaquinaOpcion2,
      barnizMaquinaOpcion3,
      barnizMaquinaOpcion4,
      barnizMaquinaOpcion5,
    };
    dispatch(changeCotizacion(salida));
  };
};

/////////////////////////////////////////////////////////////////////////////////////////
export const setServicioEpson = () => {
  return (dispatch, getState) => {
    const {
      sistemaCotizado,
      MSITotalOpcion1,
      MSITotalOpcion2,
      MSITotalOpcion3,
      MSITotalOpcion4,
      MSITotalOpcion5,
    } = getState().cotizacionesReducer.cotizacion;

    const { costO_SERVICIO_EPSON } = getState().constantesReducer;

    const servicioEpsonOpcion1 =
      sistemaCotizado === "D"
        ? parseFloat2(Number(costO_SERVICIO_EPSON) * Number(MSITotalOpcion1))
        : 0;

    const servicioEpsonOpcion2 =
      sistemaCotizado === "D"
        ? parseFloat2(Number(costO_SERVICIO_EPSON) * Number(MSITotalOpcion2))
        : 0;

    const servicioEpsonOpcion3 =
      sistemaCotizado === "D"
        ? parseFloat2(Number(costO_SERVICIO_EPSON) * Number(MSITotalOpcion3))
        : 0;

    const servicioEpsonOpcion4 =
      sistemaCotizado === "D"
        ? parseFloat2(Number(costO_SERVICIO_EPSON) * Number(MSITotalOpcion4))
        : 0;

    const servicioEpsonOpcion5 =
      sistemaCotizado === "D"
        ? parseFloat2(Number(costO_SERVICIO_EPSON) * Number(MSITotalOpcion5))
        : 0;

    const salida = {
      servicioEpsonOpcion1,
      servicioEpsonOpcion2,
      servicioEpsonOpcion3,
      servicioEpsonOpcion4,
      servicioEpsonOpcion5,
    };
    dispatch(changeCotizacion(salida));
  };
};

/////////////////////////////////////////////////////////////////////////////////////////
export const setFoil = () => {
  return (dispatch, getState) => {
    const {
      requiereFoil,
      requiereFoilCobertura,
      costoMSIFoil,
      MSITotalOpcion1,
      MSITotalOpcion2,
      MSITotalOpcion3,
      MSITotalOpcion4,
      MSITotalOpcion5,
    } = getState().cotizacionesReducer.cotizacion;

    const foilOpcion1 = requiereFoil
      ? parseFloat2(
          Number(costoMSIFoil) *
            (Number(requiereFoilCobertura) / 100) *
            Number(MSITotalOpcion1)
        )
      : 0;

    const foilOpcion2 = requiereFoil
      ? parseFloat2(
          Number(costoMSIFoil) *
            (Number(requiereFoilCobertura) / 100) *
            Number(MSITotalOpcion2)
        )
      : 0;

    const foilOpcion3 = requiereFoil
      ? parseFloat2(
          Number(costoMSIFoil) *
            (Number(requiereFoilCobertura) / 100) *
            Number(MSITotalOpcion3)
        )
      : 0;

    const foilOpcion4 = requiereFoil
      ? parseFloat2(
          Number(costoMSIFoil) *
            (Number(requiereFoilCobertura) / 100) *
            Number(MSITotalOpcion4)
        )
      : 0;

    const foilOpcion5 = requiereFoil
      ? parseFloat2(
          Number(costoMSIFoil) *
            (Number(requiereFoilCobertura) / 100) *
            Number(MSITotalOpcion5)
        )
      : 0;

    const salida = {
      foilOpcion1,
      foilOpcion2,
      foilOpcion3,
      foilOpcion4,
      foilOpcion5,
    };
    dispatch(changeCotizacion(salida));
  };
};
