import { types } from "../types/types";

export const setStep = (payload) => ({
  type: types.setStep,
  payload,
});

export const setSeccionModificada = (payload) => ({
  type: types.setSeccionModificada,
  payload,
});

export const cleanSeccionModificada = () => ({
  type: types.cleanSeccionModificada,
  payload: null,
});
