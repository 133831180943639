import useGetCatalogoTextNum from "../../hooks/useGetCatalogoTextNum";
import SelectLabelNullable from "../input/SelectLabelNullable";

export default function DropDownTextNumNullable({
  onChange,
  grupo,
  value,
  name,
  label,
}) {
  const { catalogo } = useGetCatalogoTextNum(grupo);

  return (
    <SelectLabelNullable
      label={label}
      onChange={onChange}
      placeholder="Selecciona"
      options={catalogo}
      value={value}
      name={name}
    />
  );
}
