import { useSelector } from "react-redux";
import InputSinNumber from "../../../../components/input/InputSinNumber";
import ColOpcion from "./ColOpcion";
import ColTitulo from "./ColTitulo";
import RowCosto from "./RowCosto";



export default function CostoMateriasPrimas() {
  const {
    costoMateriasPrimasOpcion1,
    costoMateriasPrimasOpcion2,
    costoMateriasPrimasOpcion3,
    costoMateriasPrimasOpcion4,
    costoMateriasPrimasOpcion5,
  } = useSelector((state) => state.cotizacionesReducer.cotizacion);

  return (
    <RowCosto>
      <ColTitulo>Costo materias primas</ColTitulo>
      <ColOpcion>
        <InputSinNumber value={costoMateriasPrimasOpcion1} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={costoMateriasPrimasOpcion2} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={costoMateriasPrimasOpcion3} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={costoMateriasPrimasOpcion4} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={costoMateriasPrimasOpcion5} disabled />
      </ColOpcion>
    </RowCosto>
  );
}
