import { useSelector } from "react-redux";
import InputSinNumber from "../../../../components/input/InputSinNumber";
import ColOpcion from "./ColOpcion";
import ColTitulo from "./ColTitulo";
import RowCosto from "./RowCosto";


export default function MetrosLinealesRequeridos() {
  const {
    metrosLinealesRequeridosOpcion1,
    metrosLinealesRequeridosOpcion2,
    metrosLinealesRequeridosOpcion3,
    metrosLinealesRequeridosOpcion4,
    metrosLinealesRequeridosOpcion5,
  } = useSelector((state) => state.cotizacionesReducer.cotizacion);

  return (
    <RowCosto>
      <ColTitulo>Metros lineales requeridos</ColTitulo>
      <ColOpcion>
        <InputSinNumber value={metrosLinealesRequeridosOpcion1} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={metrosLinealesRequeridosOpcion2} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={metrosLinealesRequeridosOpcion3} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={metrosLinealesRequeridosOpcion4} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={metrosLinealesRequeridosOpcion5} disabled />
      </ColOpcion>
    </RowCosto>
  );
}
