import { consLogged } from "../../constants/consLogged";
import { URL_LOGIN } from "../../constants/url";
import { post } from "../../utils/fetch";
import parseJwt from "../../utils/parseJwt";
import { types } from "../types/types";
import { setLoginErr } from "./authErrActions";

export const StartLogin = (body) => {
  return async (dispatch) => {
    dispatch(setLoginErr(null));
    const data = await post(URL_LOGIN, body);
    if (data !== -1) {
      localStorage.setItem("token", data.token);
      dispatch(storeUserData(parseJwt(data.token)));
      dispatch(setLogged(consLogged.LOGGED));
    } else {
      dispatch(setLoginErr("Error de credenciales"));
    }
  };
};

export const verificaLogin = () => {
  return (dispatch) => {
    const token = localStorage.getItem("token");
    if (token) {
      dispatch(storeUserData(parseJwt(token)));
      dispatch(setLogged(consLogged.LOGGED));
    } else {
      dispatch(setLogged(consLogged.NOTLOGGED));
    }
  };
};

const storeUserData = (payload) => ({
  type: types.storeUserData,
  payload,
});

const setLogged = (payload) => ({
  type: types.setLogged,
  payload,
});

export const startLogoff = () => {
  return (dispatch) => {
    dispatch(setLogged(consLogged.NOTLOGGED));
    localStorage.removeItem("token");
  };
};
