import { Col, Row } from "antd";
import FormaArticulo from "./_FormaArticulo";
import FormaMedidas from "./_FormaMedidas";

export default function Forma() {
  return (<>
    <Row gutter={30}>
      <Col xs={24} md={12}>
        <FormaArticulo />
      </Col>
      <Col xs={24} md={12}>
        <FormaMedidas />
      </Col>
    </Row>
    </>
  );
}
