import { useState } from "react";
import { Button, Col, Divider, Input, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { startPostHistorial } from "../../../context/actions/historialActions";
import PresentaComentarios from "./_PresentaComentarios";

///
export default function Historial() {
  const dispatch = useDispatch();
  const [comentarios, setComentarios] = useState("");
  const { cotizacionID } = useParams();

  //
  const handleClick = () => {
    if (comentarios !== "") {
      dispatch(startPostHistorial(comentarios, cotizacionID));
      setComentarios("");
    }
  };

  const comentariosFull = useSelector((state) => state.historialReducer);
  return (
    <>
      <Divider orientation="left" plain>
        Historial de comentarios
      </Divider>
      <Row gutter={15}>
        <Col span={16}>
          <PresentaComentarios comentarios={comentariosFull} />
        </Col>
        <Col span={8} style={{ textAlign: "center" }}>
          <Input.TextArea
            onChange={({ target }) => setComentarios(target.value)}
            value={comentarios}
          ></Input.TextArea>
          <Button onClick={handleClick} style={{ color: "orange" }} type="text">
            Agregar Comentario
          </Button>
        </Col>
      </Row>
    </>
  );
}
