import {  useSelector } from "react-redux";
import InputSinNumber from "../../../../components/input/InputSinNumber";

import ColOpcion from "./ColOpcion";
import ColTitulo from "./ColTitulo";
import RowCosto from "./RowCosto";

export default function NoGolpesCalculo() {

  const {
    noGolpesCalculoOpcion1,
    noGolpesCalculoOpcion2,
    noGolpesCalculoOpcion3,
    noGolpesCalculoOpcion4,
    noGolpesCalculoOpcion5,

  } = useSelector((state) => state.cotizacionesReducer.cotizacion);


  return (
    <RowCosto>
      <ColTitulo>No. Golpes para calculo de depresiación dado</ColTitulo>
      <ColOpcion>
        <InputSinNumber value={noGolpesCalculoOpcion1} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={noGolpesCalculoOpcion2} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={noGolpesCalculoOpcion3} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={noGolpesCalculoOpcion4} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={noGolpesCalculoOpcion5} disabled />
      </ColOpcion>
    </RowCosto>
  );
}
