import {  useSelector } from "react-redux";
import InputSinNumber from "../../../../components/input/InputSinNumber";

import ColOpcion from "./ColOpcion";
import ColTitulo from "./ColTitulo";
import RowCosto from "./RowCosto";

export default function MSIRequeridosImpresion() {

  const {
    MSIRequeridosImpresionOpcion1,
    MSIRequeridosImpresionOpcion2,
    MSIRequeridosImpresionOpcion3,
    MSIRequeridosImpresionOpcion4,
    MSIRequeridosImpresionOpcion5,
  } = useSelector((state) => state.cotizacionesReducer.cotizacion);

  
  return (
    <RowCosto>
      <ColTitulo>MSI requeridos para el impresión</ColTitulo>
      <ColOpcion>
        <InputSinNumber value={MSIRequeridosImpresionOpcion1} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={MSIRequeridosImpresionOpcion2} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={MSIRequeridosImpresionOpcion3} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={MSIRequeridosImpresionOpcion4} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={MSIRequeridosImpresionOpcion5} disabled />
      </ColOpcion>
    </RowCosto>
  );
}
