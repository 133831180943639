import { UnlockOutlined, LockOutlined } from "@ant-design/icons";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { startDeleCotizacion } from "../../../context/actions/cotizacionesActions";

export default function Eliminar() {
  const [click, setClick] = useState(false);
  const { cotizacionID } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const handleDelete = async () => {
    await dispatch(startDeleCotizacion(cotizacionID));
    history.push("/");
  };

  if (!click) {
    return (
      <div style={{ padding: 10, marginTop: 15, marginBottom: 15 }}>
        <div className="dele botonEliminar" onClick={() => setClick(true)}>
          <LockOutlined /> Eliminar esta cotización
        </div>
      </div>
    );
  } else {
    return (
      <div style={{ padding: 10, marginTop: 15, marginBottom: 15 }}>
        <div className="dele botonEliminarActivo" onClick={handleDelete}>
          <UnlockOutlined /> ¿Estas seguro de eliminar?
        </div>
        <div style={{ fontSize: 12 }}>
          Esto borrará todos los datos y archivos generaros para esta
          cotización, pudes{" "}
          <span
            onClick={() => setClick(false)}
            style={{ color: "orange", cursor: "pointer" }}
          >
            cancelar esta acción
          </span>{" "}
          si deseas
        </div>
      </div>
    );
  }
}
