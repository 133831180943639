import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import {
  cleanCotizacion,
  startLoadCotizacion,
} from "../../context/actions/cotizacionesActions";
import { cleanSeccionModificada } from "../../context/actions/stepActions";
import Articulos from "./Articulos/Articulos";
import Calculos from "./Calculos/Calculos";
import { CotizacionExterna } from "./cotizacionExterna/CotizacionExterna";
import Cotizador from "./Cotizador/Cotizador";
import Resultados from "./Resultados/Resultados";
import SolicitudCotizacion from "./SolicitudCotizacion/SolicitudCotizacion";
import Terminados from "./Terminados/Terminados";

export default function ViewCotizacion() {
  const dispatch = useDispatch();
  const { cotizacionID } = useParams();
  useEffect(() => {
    dispatch(cleanSeccionModificada());
    // if (cotizacionID && Number(cotizacionID) !== 0)
    //   dispatch(startLoadCotizacion(cotizacionID));
  }, []);

  return (
    <div style={{ marginBottom: 200 }}>
      <SolicitudCotizacion />
      <Articulos />
      <Cotizador />
      <CotizacionExterna />
      <Terminados />
      <Resultados />
      <Calculos />
    </div>
  );
}
