import CardPage from "../../../layout/CardPage";
import TermiandosDatos from "./_TermiandosDatos";
import ButtonContinuar from "./_ButtonContinuar";
import Adicionales from "./_Adicionales";
import { Cantidad } from "./_Cantidad";

export default function Forma() {
  //////
  return (
    <CardPage titulo="Terminados">
      <TermiandosDatos />
      <hr />
      <Cantidad />
      <Adicionales />
      <ButtonContinuar />
    </CardPage>
  );
}
