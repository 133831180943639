import useLoadConstantes from "../../hooks/useLoadConstantes";
import CardPage from "../../layout/CardPage";
import Forma from "./Forma";
import SubmitActualizar from "./SubmitActualizar";

export default function Constantes() {
  return (<>
    <CardPage titulo="Constantes">
      <Forma />
     
    </CardPage>
     <SubmitActualizar />
  </>);
}
