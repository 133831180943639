import { useDispatch } from "react-redux";
import BotonSubmit from "../../components/Boton/BotonSubmit";
import { startPostProveedor } from "../../context/actions/proveedoresActions";

export default function SubmitCreate() {

  const dispatch = useDispatch();
  const handleClick = () => dispatch(startPostProveedor());

  return (
    <div style={{ marginTop: 20 }}>
      <BotonSubmit onClick={handleClick} tituloBoton="Agregar Proveedor" />
    </div>
  );
}
