import { useSelector } from "react-redux";
import InputSinNumber from "../../../../components/input/InputSinNumber";

import ColOpcion from "./ColOpcion";
import ColTitulo from "./ColTitulo";
import RowCosto from "./RowCosto";

export default function CostoTiempoMaquinaImpOffset() {
  const {
    costoTiempoMaquinaImpOffsetOpcion1,
    costoTiempoMaquinaImpOffsetOpcion2,
    costoTiempoMaquinaImpOffsetOpcion3,
    costoTiempoMaquinaImpOffsetOpcion4,
    costoTiempoMaquinaImpOffsetOpcion5,
  } = useSelector((state) => state.cotizacionesReducer.cotizacion);

  return (
    <RowCosto>
      <ColTitulo>Costo tiempo máquina IMPRESIÓN Offset</ColTitulo>
      <ColOpcion>
        <InputSinNumber value={costoTiempoMaquinaImpOffsetOpcion1} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={costoTiempoMaquinaImpOffsetOpcion2} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={costoTiempoMaquinaImpOffsetOpcion3} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={costoTiempoMaquinaImpOffsetOpcion4} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={costoTiempoMaquinaImpOffsetOpcion5} disabled />
      </ColOpcion>
    </RowCosto>
  );
}
