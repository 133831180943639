import { useDispatch, useSelector } from "react-redux";
import InputSinNumber from "../../../components/input/InputSinNumber";
import { changeCotizacion } from "../../../context/actions/cotizacionesActions";
import { setSeccionModificada } from "../../../context/actions/stepActions";

import ColOpcion from "../Calculos/renglonesCostos/ColOpcion";
import ColTitulo from "../Calculos/renglonesCostos/ColTitulo";
import RowCosto from "../Calculos/renglonesCostos/RowCosto";

export default function PorcentajeMerma() {
  const dispatch = useDispatch();
  const {
    porcentajeMermaOpcion1,
    porcentajeMermaOpcion2,
    porcentajeMermaOpcion3,
    porcentajeMermaOpcion4,
    porcentajeMermaOpcion5,
  } = useSelector((state) => state.cotizacionesReducer.cotizacion);

  const onChange = ({ target: { name, value } }) => {
    console.log({ name, value });
    dispatch(changeCotizacion({ [name]: value }));
    dispatch(setSeccionModificada({ terminados: true }));
  };

  return (
    <RowCosto>
      <ColTitulo>Porcentaje merma</ColTitulo>
      <ColOpcion>
        <InputSinNumber
          name="porcentajeMermaOpcion1"
          value={Number(porcentajeMermaOpcion1)}
          onChange={onChange}
        />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber
          name="porcentajeMermaOpcion2"
          value={porcentajeMermaOpcion2}
          onChange={onChange}
        />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber
          name="porcentajeMermaOpcion3"
          value={porcentajeMermaOpcion3}
          onChange={onChange}
        />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber
          name="porcentajeMermaOpcion4"
          value={porcentajeMermaOpcion4}
          onChange={onChange}
        />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber
          name="porcentajeMermaOpcion5"
          value={porcentajeMermaOpcion5}
          onChange={onChange}
        />
      </ColOpcion>
    </RowCosto>
  );
}
