import { EyeOutlined } from "@ant-design/icons";
import { useHistory } from "react-router";

//////////////
export default function useArticulosColumns() {
  const history = useHistory();

  const handleClick = ({ articuloID }) =>
    history.push(`/articulos/details/${articuloID}`);

  const articulosColumns = [
    {
      title: "Artículo",
      dataIndex: "nombre",
    },
    {
      title: "Descripción",
      dataIndex: "descripcion",
    },
    {
      title: "Grupo",
      dataIndex: "grupo",
    },
    {
      title: "Estatus",
      dataIndex: "estatus",
    },
    {
      title: "Proveedor",
      dataIndex: "proveedor",
    },
    {
      title: "",
      width: 30,
      render: (text, record) => (
        <EyeOutlined onClick={() => handleClick(record)} className="eyeIcon" />
      ),
    },
  ];

  return { articulosColumns };
}
