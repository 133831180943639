import { useSelector } from "react-redux";
import CardPage from "../../../layout/CardPage";
import BotonEnviar from "./_BotonEnviar";
import BotoinEnviar from "./_BotonEnviar";
import BotonUtilidad from "./_BotonUtilidad";
import Subtotal from "./_Subtotal";
import Totales from "./_Totales";

import UtilidadPorcentaje from "./_UtilidadPorcentaje";

export default function Datos() {
  const { subtotalOpcion1 } = useSelector(
    (state) => state.cotizacionesReducer.cotizacion
  );

  return (
    <CardPage titulo="Resultados">
      <Subtotal />

      {subtotalOpcion1 !== 0 && (
        <>
          <UtilidadPorcentaje />
          <BotonUtilidad />
          <Totales />

        </>
      )}
    </CardPage>
  );
}
