import { Col } from "antd";
import React from "react";

export default function LabelValorClick({ label, valor, onClick }) {
  return (
    <Col
      onClick={onClick}
      xs={24}
      sm={12}
      md={10}
      xl={6}
      style={{ cursor: "pointer" }}
    >
      <div className="label">{label}</div>
      <div className="valor">{valor}</div>
    </Col>
  );
}
