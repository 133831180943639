import { Col, Input, Row } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import InputSinNumber from "../../../components/input/InputSinNumber";
import { UnoCien } from "../../../components/Select/UnoCien";
import { setCotizacionExterna } from "../../../context/actions/cotizacionesActions";
import parseFloat2 from "../../../utils/parseFloat2";

import ColOpcion from "../Calculos/renglonesCostos/ColOpcion";
import ColTitulo from "../Calculos/renglonesCostos/ColTitulo";
import RowCosto from "../Calculos/renglonesCostos/RowCosto";
import parseFloat4 from "../../../utils/parseFloat4";

export const Flete = () => {
  const dispatch = useDispatch();

  const {
    fleteOpcion1,
    fleteOpcion2,
    fleteOpcion3,
    fleteOpcion4,
    fleteOpcion5,
    porcentajeFlete,

    precioCotizado1,
    precioCotizado2,
    precioCotizado3,
    precioCotizado4,
    precioCotizado5,
  } = useSelector((state) => state.cotizacionesReducer.cotizacionExterna);

  useEffect(() => {
    setNewValor({
      fleteOpcion1: parseFloat4(precioCotizado1 * porcentajeFlete),
    });
  }, [precioCotizado1, porcentajeFlete]);

  useEffect(() => {
    setNewValor({
      fleteOpcion2: parseFloat4(precioCotizado2 * porcentajeFlete),
    });
  }, [precioCotizado2, porcentajeFlete]);

  useEffect(() => {
    setNewValor({
      fleteOpcion3: parseFloat4(precioCotizado3 * porcentajeFlete),
    });
  }, [precioCotizado3, porcentajeFlete]);

  useEffect(() => {
    setNewValor({
      fleteOpcion4: parseFloat4(precioCotizado4 * porcentajeFlete),
    });
  }, [precioCotizado4, porcentajeFlete]);

  useEffect(() => {
    setNewValor({
      fleteOpcion5: parseFloat4(precioCotizado5 * porcentajeFlete),
    });
  }, [precioCotizado5, porcentajeFlete]);

  ////////////////
  const handleChange = ({ target }) => {
    setNewValor({
      [target.name]: Number(target.value),
    });
  };

  const handleChangePorcentaje = (val) => {
    setNewValor({
      porcentajeFlete: Number(val),
    });
  };

  const setNewValor = (ele) => dispatch(setCotizacionExterna(ele));

  ////////////////
  return (
    <RowCosto>
      <ColTitulo>
        <Row gutter={10} align="end">
          <Col span={6}>
            <div style={{ marginBottom: 3 }}>Flete</div>
            <UnoCien
              value={porcentajeFlete}
              onChange={handleChangePorcentaje}
              size="small"
            />
          </Col>
        </Row>
      </ColTitulo>

      <ColOpcion>
        <Input
          name="fleteOpcion1"
          onChange={handleChange}
          value={fleteOpcion1}
        />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber
          name="fleteOpcion2"
          onChange={handleChange}
          value={fleteOpcion2}
        />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber
          name="fleteOpcion3"
          onChange={handleChange}
          value={fleteOpcion3}
        />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber
          name="fleteOpcion4"
          onChange={handleChange}
          value={fleteOpcion4}
        />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber
          name="fleteOpcion5"
          onChange={handleChange}
          value={fleteOpcion5}
        />
      </ColOpcion>
    </RowCosto>
  );
};
