import { useSelector } from "react-redux";
import InputSinNumber from "../../../../components/input/InputSinNumber";


import ColOpcion from "./ColOpcion";
import ColTitulo from "./ColTitulo";
import RowCosto from "./RowCosto";

export default function TotalPiesLineales() {
  const {
    totalPiesLinealesOpcion1,
    totalPiesLinealesOpcion2,
    totalPiesLinealesOpcion3,
    totalPiesLinealesOpcion4,
    totalPiesLinealesOpcion5,

  } = useSelector((state) => state.cotizacionesReducer.cotizacion);

  return (
    <RowCosto>
      <ColTitulo>Total pies lineales</ColTitulo>
      <ColOpcion>
        <InputSinNumber value={totalPiesLinealesOpcion1} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={totalPiesLinealesOpcion2} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={totalPiesLinealesOpcion3} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={totalPiesLinealesOpcion4} disabled />
      </ColOpcion>
      <ColOpcion>
        <InputSinNumber value={totalPiesLinealesOpcion5} disabled />
      </ColOpcion>
    </RowCosto>
  );
}
