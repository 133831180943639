import { URL_POST_ESTATUS, URL_POST_ESTATUSPPD } from "../../constants/url";
import {
  composeErrorNotification,
  composeSuccessNotification,
} from "../../utils/composeNotification";
import { post } from "../../utils/fetch";
import { startLoadCotizacion } from "./cotizacionesActions";
import { startLoadHistorial } from "./historialActions";
import { notification } from "./notificationActions";

///
export const startPostEstatus = (body) => {
  return async (dispatch, getState) => {
    const { Email } = getState().userReducer.user;
    body = { ...body, Email };
    const data = await post(URL_POST_ESTATUS, body);

    if (data !== -1) {
      dispatch(notification(composeSuccessNotification(data)));
      dispatch(startLoadCotizacion(body.cotizacionID));
      dispatch(startLoadHistorial(body.cotizacionID));
    } else {
      dispatch(
        notification(
          composeErrorNotification(
            "Error de conexion",
            "Post " + body.cotizacionEstatusID
          )
        )
      );
    }
  };
};

////////////
export const startPostEstatusPPD = (body) => {
  return async (dispatch, getState) => {
    const { Email } = getState().userReducer.user;

    const data = await post(URL_POST_ESTATUSPPD, { ...body, Email });

    if (data !== -1) {
      dispatch(notification(composeSuccessNotification(data)));
      dispatch(startLoadCotizacion(body.cotizacionID));
      dispatch(startLoadHistorial(body.cotizacionID));
    } else {
      dispatch(
        notification(
          composeErrorNotification(
            "Error de conexion",
            "Post " + body.cotizacionEstatusID
          )
        )
      );
    }
  };
};
