import { useDispatch } from "react-redux";
import BotonSubmit from "../../components/Boton/BotonSubmit";
import { startPutProveedor } from "../../context/actions/proveedoresActions";

export default function SubmitEdit() {

  const dispatch = useDispatch();
  const handleClick = () => dispatch(startPutProveedor());

  return (
    <div style={{ marginTop: 20 }}>
      <BotonSubmit onClick={handleClick} tituloBoton="Editar Proveedor" />
    </div>
  );
}
