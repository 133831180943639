import { Button, Col, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { SinGuardar } from "../../../components/Boton/SinGuardar";
import { startPostFase } from "../../../context/actions/cotizacionesActions";

export default function BotonEnviar() {
  const history = useHistory();
  const {
    seccionModificada: { guardar },
  } = useSelector((state) => state.cotizacionesReducer);

  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(startPostFase("faseFinal")).then((resp) => {
      if (resp === 0) history.push("/");
    });
  };

  ///
  return (
    <Row justify="center" style={{ marginBottom: 20 }}>
      <Col span={24} style={{ textAlign: "center" }}>
        <Button onClick={handleClick} style={{ width: 300 }} type="primary">
          Guardar Cotización
        </Button>
        <SinGuardar show={guardar} />
      </Col>
    </Row>
  );
}
