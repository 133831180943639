import logo from '../assets/logo.png'
import logoSmall from '../assets/logo_small.png'

export default function Logo({isCollapsed}) {

    const texto = isCollapsed ? <img src={logoSmall} className="logo" /> : <img src={logo} className="logo" />

    return (
        <div style={{fontSize: 20, width: '100%', textAlign: 'center', marginTop: 10, color: 'orange'}}>
            {texto}
        </div>
    )
    
}
