import { URL_CONSTANTES } from "../../constants/url";
import { composeSuccessNotification } from "../../utils/composeNotification";
import { get, put } from "../../utils/fetch";
import { types } from "../types/types";
import { notification } from "./notificationActions";

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const startLoadConstantes = () => {
  return async (dispatch) => {
    const data = await get(URL_CONSTANTES);
    if (data == -1) {
      // error
      return;
    }
    dispatch(storeConstantes(data));
  };
};

const storeConstantes = (payload) => ({
  type: types.storeConstantes,
  payload,
});

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const changeConstantes = (elemento) => ({
  type: types.changeConstantes,
  payload: elemento,
});

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const startPutConstantes = () => {
  return async (dispatch, getState) => {
    const constantes = getState().constantesReducer;
    const data = await put(`${URL_CONSTANTES}`, constantes); 
    if (data !== -1) {
      dispatch(notification(composeSuccessNotification(data)));
    }
  };
};

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
